import apiHelper from "./apiHelper";
import { generateFormData } from "../utils/general";

export const getObservationsApi = ({
  disciplineId,
  pageSize,
  pageNo,
  mine,
  safeObservation,
  type,
  startTargetDate,
  endTargetDate,
  attr,
  searchTerm,
  locationId,
  directorateId,
  unitId,
  companyId,
  sort,
}) => {
  return new Promise((resolve, reject) => {
    let sortString = null;
    if (sort) {
      const otherSort = { ...sort };
      switch (otherSort.field) {
        case "id":
          otherSort.field = "observationId";
          break;
        case "observationDate":
          otherSort.field = "observedAt";
          break;
        case "observer":
          otherSort.field = "observerName";
          break;
        default:
          break;
      }
      sortString = `${otherSort.field}+${otherSort.sort}`;
    }
    apiHelper
      .get(
        `/api/observations${attr ? `?attr=${attr}` : ``}${
          sortString ? `&sort=${sortString}` : ``
        }`,
        {
          params: {
            disciplineId,
            pageSize,
            pageNo,
            mine,
            safeObservation,
            startDate: startTargetDate,
            endDate: endTargetDate,
            textSearch: searchTerm,
            ...(type !== "ALL" ? { status: type } : {}),
            ...(locationId ? { locationId } : {}),
            ...(directorateId ? { directorateId } : {}),
            ...(companyId ? { companyId } : {}),
            ...(unitId ? { unitId } : {}),
          },
        }
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const submitObservationApi = (body) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/observations`, bodyFormData)
      .then((res) => {
        resolve(res.data?.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const editObservationApi = (body, observationId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/observations/${observationId}`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const getObservationDetailsApi = (observationId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/${observationId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const returnToObserverApi = (body, observationId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/observations/${observationId}/returnToObserver`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const completeAndCloseApi = (body, observationId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/observations/${observationId}/closeNoAction`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const rejectObservationApi = (body, observationId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/observations/${observationId}/reject`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const closeWithActionApi = (body, observationId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/observations/${observationId}/closeWithAction`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const assignObservationApi = (body, observationId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/observations/${observationId}/assignAction`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const trackObservationApi = (observationId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/${observationId}/track`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const markAsIncidentApi = (body, observationId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/observations/${observationId}/markNearMiss`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const provideAppreciationApi = (body, observationId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .put(
        `/api/observations/${observationId}/provideAppreciation`,
        bodyFormData
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const getStatsApi = ({
  mine,
  startTargetDate,
  endTargetDate,
  safeObservation,
  searchTerm,
  locationId,
  directorateId,
  unitId,
  companyId,
  type,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/countStats`, {
        params: {
          safeObservation,
          startDate: startTargetDate,
          endDate: endTargetDate,
          textSearch: searchTerm,
          ...(type !== "ALL" ? { status: type } : {}),
          ...(locationId ? { locationId } : {}),
          ...(directorateId ? { directorateId } : {}),
          ...(companyId ? { companyId } : {}),
          ...(unitId ? { unitId } : {}),
          ...(mine ? { mine: true } : {}),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

// WIP_OBS_CARDS
export const downloadObsCardApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/test-template`, { responseType: "blob" }) // route will be changed later
      .then(async (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Obs_Card_${Date.now().toLocaleString()}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        resolve(res);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const uploadBulkObsApi = (body) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/observations/bulk-upload`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const getDirectorateFilterApi = (locationId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/directoratesFilter`, {
        params: {
          pageSize: 999999,
          pageNo: 1,
          locationId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompanyFilterApi = (locationId, directorateId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/companiesFilter`, {
        params: {
          pageSize: 999999,
          pageNo: 1,
          locationId,
          directorateId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocationFilterApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/locationsFilter`, {
        params: {
          pageSize: 999999,
          pageNo: 1,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getUnitFilterApi = (locationId, directorateId, companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/unitsFilter`, {
        params: {
          pageSize: 999999,
          pageNo: 1,
          locationId,
          directorateId,
          companyId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
