import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { version } from "../utils/constants";
import { useEffect, useState } from "react";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Box, Container, Typography } from "@mui/material";
// layouts
import AuthLayout from "../layouts/AuthLayout";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import { LoginForm } from "../components/authentication/login";
import { RecoveryEmailForm } from "../components/authentication/recover-password";
import { ResetPasswordForm } from "../components/authentication/reset-password";
import { ValidateOtpForm } from "../components/authentication/validate-one-time-password";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  backgroundImage: `url(${"/static/login/landing_md.jpg"})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: theme.spacing(2, 0, 2, 2),
  background: "none",
  boxShadow: "none",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login(formOptions) {
  const { t } = useTranslation();
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleRecoveryPassword = (showForgotPassword) => {
    // 👇️ take parameter passed from Child component
    setShowForgotPassword(showForgotPassword);
  };

  const handleShowRecoveryEmailForm = (showRecoveryForm) => {
    // 👇️ take parameter passed from Child component
    setShowForgotPassword(showRecoveryForm);
  };

  return (
    <RootStyle title="IHTIMAM Login">
      <AuthLayout></AuthLayout>

      <Container maxWidth="sm">
        <ContentStyle>
          {showForgotPassword === true ? (
            <RecoveryEmailForm
              showRecoveryEmailForm={handleShowRecoveryEmailForm}
            />
          ) : formOptions.showResetPasswordForm === true ? (
            <>
              <ResetPasswordForm />
            </>
          ) : formOptions.showValidateOtpForm === true ? (
            <>
              <ValidateOtpForm />
            </>
          ) : (
            <LoginForm handleRecoveryPassword={handleRecoveryPassword} />
          )}

          {/* <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              {t(`login.missingAccount`)}
              <Link variant="subtitle2" component={RouterLink} to="register">
                {t(`login.getStarted`)}
              </Link>
            </Typography>
          </MHidden> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
