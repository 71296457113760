import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// material
import { Box, Container, Grid, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

// components
import Page from "../../components/Page";
import ProgressBar from "../../components/ProgressBar";
import DefaultTable from "../../components/tables/DefaultTable";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";

// api
import { getImplementationsByCompanyApi } from "../../api/implementation";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import useAuth from "../../context/useAuth";
import { getCompanyOptions } from "../../api/kpiConfigurations";

const filterParamDefaults = {
  implementation: "",
  status: null,
  startDate: null,
  completionPercentage: 0,
  disciplineId: null,
  progress: null,
  pageSize: 10,
  pageNo: 1,
};

const displayActivitiesStatus = (colors) => {
  return <ProgressBar colors={colors} />;
};

export default function ListCompanyImplementations() {
  const { access } = useAuth();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [rowsLoading, setRowsLoading] = useState(false);
  const [filterParams, setFilterParams] = useState(filterParamDefaults);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [kpiPermissions, setKpiPermissions] = useState([]);

  const columns = [
    {
      field: "implementation",
      headerName: t("table.implementation"),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "startDate",
      headerName: t("table.startDate"),
      width: 100,
      sortable: false,
    },
    {
      field: "endDate",
      headerName: t("table.endDate"),
      width: 100,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("table.status"),
      width: 140,
      sortable: false,
    },
    // { field: "completionPercentage", headerName: "Completion %", width: 130 },
    {
      field: "progress",
      headerName: t("table.progress"),
      minWidth: 200,
      flex: 1,
      renderCell: (param) => displayActivitiesStatus(param.row.statusColors),
      sortable: false,
    },
  ];

  const getPlans = async () => {
    try {
      if (
        access.includes("VIEW_IMPL_PLN") ||
        access.includes("CON_DIR_IMPL_PLN") ||
        access.includes("CRUD_IMPL_PLN")
      ) {
        setRowsLoading(true);
        const plans = await getImplementationsByCompanyApi(
          companyId,
          filterParams.pageNo,
          filterParams.pageSize
        );

        const planRows = plans.data.map((plan) => {
          return {
            id: plan.id,
            implementation: plan.name,
            startDate: moment(plan.startDate).format("DD/MM/YY"),
            endDate: moment(plan.endDate).format("DD/MM/YY"),
            status: plan.implementationStatus,
            completionPercentage: plan.completionPercentage,
            companyId: plan.companyId,
            statusColors: plan.statusColors,
          };
        });

        setRows(planRows);
        setTableInfo({
          totalCount: plans.totalItems,
          totalPages: plans.totalPages,
        });
        setRowsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setRowsLoading(true);
    }
  };

  const getKpiPerms = async () => {
    try {
      const kpiPerms = await getCompanyOptions(companyId);
      setKpiPermissions(kpiPerms);
    } catch (err) {}
  };

  useEffect(() => {
    getKpiPerms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPlans();
  }, [filterParams]);

  return (
    <Page title={t("implementations.view")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <HeaderBackButton />
              <Typography variant="h4">
                {t("implementations.companyImplementations", {
                  name: state.companyName,
                })}
              </Typography>
            </div>
            {kpiPermissions.length > 0 && (
              <div>
                <SecondaryButton
                  style={{ width: "max-content" }}
                  icon={<EditIcon />}
                  label={t("kpi.configurations")}
                  alignment={"left"}
                  onClick={() =>
                    navigate(`/implementations/kpiConfiguration/${companyId}`, {
                      state: {
                        kpiPermissions,
                      },
                    })
                  }
                  disabled={false}
                />
              </div>
            )}
          </div>
        </Box>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <DefaultTable
              minHeight={"75vh"}
              rows={rows}
              hiddenColumns={{
                status: false,
              }}
              columns={columns}
              loading={rowsLoading}
              tableInfo={tableInfo}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              onRowClick={(p) => {
                navigate(
                  `/implementations/details/${p.row.id}/${p.row.companyId}`,
                  {
                    state: {
                      planId: p.row.id,
                      companyName: "user.company?.name",
                      companyId: p.row.companyId,
                    },
                  }
                );
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
