import { useState } from "react";
import { useTranslation } from "react-i18next";
// material
import { Box, Container, Typography, Stack } from "@mui/material";
// components
import Page from "../../components/Page";
import KPIOverview from "./kpiOverview/KPIOverview";

export default function KPIDashboards() {
  const { t } = useTranslation();

  const renderDashboardsView = () => <KPIOverview />;

  return (
    <Page title={t("sideBar.kpis")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Typography variant="h4">{t("sideBar.kpis")}</Typography>
          </Stack>
        </Box>
        {renderDashboardsView()}
      </Container>
    </Page>
  );
}
