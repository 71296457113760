import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx, src }) {
  return (
    <Box
      component="img"
      src={src || "/static/ihtimam_pdo_logo.svg"}
      sx={{ width: 90, height: 90, ...sx }}
    />
  );
}
