import faker from "faker";
import PieChart from "../../components/charts/PieChart";
import BarChart from "../../components/charts/BarChart";
import { isEmpty, isEqual, xorWith, omit } from "lodash";
import { getUserPreferencesApi, saveUserPreferencesApi } from "../../api/users";
import HeatmapChart from "../../components/charts/HeatmapChart";
import WordcloudChart from "../../components/charts/WordcloudChart";
import { Grid, Stack, Card, Typography } from "@mui/material";
import { displayToast, returnColourArray } from "../../utils/general";
import CircularStatus from "../../components/CircularStatus";

import {
  getActionStatus,
  getBehaviorNames,
  getObservationTrends,
  getBehaviourTypes,
  getCardCount,
  getCompanyKpis,
  getDisciplineDistribution,
  getLocationKpisTrends,
  getObservationsPerYear,
  getObservationStatus,
  getTopUnsafeBehavior,
  getTotalObservations,
  getTotalObservationsPersonal,
  lessonLearntCard,
  lessonLearntDetail,
  saveDashboardPreferences,
  getTrainingStatuses,
} from "../../api/pdoDashboards";
import CounterChart from "../../components/charts/CounterChart";
import StackedBarChart from "../../components/charts/StackedBarChart";
import LineChart from "../../components/charts/LineChart";
import DenseTable from "../../components/tables/DenseTable";
import moment from "moment";
import {
  getCompanyFiltersApi,
  getLocationsAndUnitsFiltersApi,
  getDisciplineFiltersApi,
} from "../../api/ai_dashboard";
import GroupedBarChart from "../../components/charts/GroupedBarChart";
import { getDirectorateHeatMap } from "../../api/kpiDashboards";

const heatMapOptions = {
  chart: {
    fontFamily: "Cairo, sans-serif",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    heatmap: {
      enableShades: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 65,
            color: "#FF0000",
          },
          {
            from: 65,
            to: 85,
            color: "#FDD600",
          },
          {
            from: 85,
            to: 100,
            color: "#00B050",
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter: (val) => `${val}%`,
    style: {
      fontSize: "14px",
    },
  },
  tooltip: {
    y: {
      formatter: (val) => `${val}%`,
    },
  },
  xaxis: {
    type: "category",
    position: "top",
    labels: {
      offsetY: 10,
    },
  },
  axisTicks: {
    show: false,
  },
  labels: {
    show: true,
  },
  legend: {
    show: false,
    position: "bottom",
  },
  yaxis: {
    labels: {
      style: {
        cssClass: "",
      },
    },
  },
};

export const returnTabValue = (text) => {
  switch (text) {
    case "feedback not matching suggested root cause":
    case "feedback not matching actual root cause":
    case "feedback not matching root cause":
      return "feedbackRoot";

    case "feedback not matching behavior":
      return "feedbackBehavior";

    case "the count of low effort observations":
      return "lowEffort";

    case "observations with lower appreciation":
      return "incidentPrevention";

    default:
      return "overview";
  }
};

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

export const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

export const renderChartAdapted = (
  chartType,
  data,
  demo = false,
  labels = []
) => {
  return renderChart(
    chartType,
    { aKey: { ...data, chartObject: { type: chartType } } },
    "aKey",
    demo,
    labels
  );
};
export const renderChart = (
  chartType,
  data_api,
  chartId = null,
  demo = false,
  labels = []
) => {
  let chart;
  let data = data_api || [];
  if (data_api?.[chartId]) chartType = data_api?.[chartId]?.chartObject?.type;
  switch (chartType) {
    case "noOfObservationsPie":
      chart = <PieChart />;
      break;
    case "totalObservationsCounter":
      chart = (
        <CounterChart
          demo={demo}
          title={getChartDefaults(chartType)?.title}
          data={data_api?.[chartId]}
        />
      );
      break;
    case "lessonLearntCardCounterOne":
      chart = (
        <CounterChart
          demo={demo}
          title={getChartDefaults(chartType)?.title}
          data={data_api?.[chartId]}
        />
      );
      break;
    case "totalObservationsPersonalCounter":
      chart = (
        <CounterChart
          demo={demo}
          title={getChartDefaults(chartType)?.title}
          data={data_api?.[chartId]}
        />
      );
      break;
    case "totalObservationsPie":
      chart = <PieChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "totalObservationsPersonalPie":
      chart = <PieChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "behaviourTypePie":
      chart = <PieChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "observationStatusPie":
      chart = <PieChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "actionStatusPie":
      chart = <PieChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "behaviourNameColumn":
      chart = <BarChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "behaviourNameCriticalColumn":
      chart = <BarChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "behaviourNameOtherColumn":
      chart = <BarChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "trainingStatusColumn":
      chart = <BarChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "disciplineDistributionColumn":
      chart = <BarChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "topReportedUnsafeBehavioursColumn":
      chart = <BarChart demo={demo} data={data_api?.[chartId]} />;
      break;
    case "observationsPerYearStacked":
      chart = (
        <StackedBarChart demo={demo} data={data_api?.[chartId]} blankLoading />
      );
      break;
    case "observationTrendsMultiline":
      chart = (
        <LineChart
          demo={demo}
          data={data_api?.[chartId]}
          name={data_api?.[chartId]?.unitName ?? null}
        />
      );
      break;
    case "cardCountTable":
      chart = (
        <DenseTable
          maxHeight
          demo={demo}
          labels={labels?.length > 0 ? labels : data_api?.[chartId]?.labels}
          dataSet={data_api?.[chartId]?.dataSet}
        />
      );
      break;
    case "lessonLearntDetailTable":
      chart = (
        <DenseTable
          demo={demo}
          labels={labels?.length > 0 ? labels : data_api?.[chartId]?.labels}
          dataSet={data_api?.[chartId]?.dataSet}
        />
      );
      break;
    case "locationKpisMultiline":
      chart = (
        <LineChart
          demo={demo}
          data={data_api?.[chartId]}
          name={data_api?.[chartId]?.unitName}
        />
      );
      break;

    case "companyKpisKpiColumn":
      if (!demo) {
        chart = (
          <GroupedBarChart
            chartData={
              data_api?.[chartId]?.companyWideData?.length > 0
                ? data_api?.[chartId]?.companyWideData?.map((entry, index) => {
                    return entry;
                  })
                : []
            }
            chartOptions={{
              chart: {
                type: "bar",
                height: 350,
              },
              colors: returnColourArray(),
              title: {
                text: data_api?.[chartId]?.companyName,
                align: "center",
                floating: true,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "55%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: true,
                formatter: (val) => {
                  return `${val}%`;
                },
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories:
                  data_api?.[chartId]?.companyWideCategory?.length > 0
                    ? data_api?.[chartId]?.companyWideCategory
                    : [],
              },
              yaxis: {
                min: 0,
                max: 100,
                title: {
                  text: "KPI in %",
                },
              },
              fill: {
                // colors: ['#ffa229', '#ee9225', '#dd8221','#cc721d','#bd6319','#ac5315','#9a4110','#8a310c','#792108','#6c1405','#580000'],
                opacity: 1,
              },
              tooltip: {
                y: {
                  formatter: (val) => {
                    return `${val}%`;
                  },
                },
              },
            }}
          />
        );
      } else {
        chart = (
          <GroupedBarChart
            height="400px"
            chartData={[
              {
                name: "Planned Observations",
                stub: "NUM_PLANNED_OBS",
                data: [21],
              },
              {
                name: "Intervention Observations",
                stub: "INTV_OBS",
                data: [25],
              },
              {
                name: "Action Close out",
                stub: "ACT_CLOSE_OUTS",
                data: [10],
              },
              {
                name: "Training completion",
                stub: "TRAIN_COMPLETION",
                data: [7],
              },
              {
                name: "CB contributing to incident",
                stub: "CB_TO_INCIDENT",
                data: [48],
              },
              {
                name: "Retired Behavior",
                stub: "RETIRED_BEHAVIORS",
                data: [8],
              },
              {
                name: "Safe score",
                stub: "SAFE_SCORE",
                data: [12],
              },
              {
                name: "Leadership Visibility",
                stub: "LDRSHP_VIS",
                data: [56],
              },
              {
                name: "Steering Committee",
                stub: "STEER_CMITE",
                data: [43],
              },
              {
                name: "Knowledge",
                stub: "KNOWLEDGE",
                data: [40],
              },
              {
                name: "Quality Observation",
                stub: "QUALITY_OBS",
                data: [55],
              },
              {
                name: "Audit",
                stub: "AUDIT",
                data: [31],
              },
            ]}
            chartOptions={{
              chart: {
                type: "bar",
              },
              title: {
                text: "Example Contractor",
                align: "center",
                floating: true,
              },
              colors: returnColourArray(),
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "55%",
                  endingShape: "rounded",
                },
              },
              dataLabels: {
                enabled: true,
                formatter: (val) => {
                  return `${val}%`;
                },
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories: ["Contractor Wide"],
              },
              yaxis: {
                min: 0,
                max: 100,
                title: {
                  text: "KPI in %",
                },
              },
              fill: {
                opacity: 1,
              },
              tooltip: {
                y: {
                  formatter: (val) => {
                    return `${val}%`;
                  },
                },
              },
            }}
          />
        );
      }
      break;

    case "noOfObservationsBar":
      //  Placeholder data
      chart = (
        <BarChart
          chartOptions={{
            colors: ["#D12D26", "#F8931F", "#42934B"],
            labels: ["Rejected", "Returned", "Completed"],
            stroke: { colors: ["#FEFEFE"] },
            legend: { floating: true, horizontalAlign: "center" },
            dataLabels: { enabled: true, dropShadow: { enabled: false } },
            xaxis: {
              categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
            },
          }}
          chartData={[
            {
              name: "series-1",
              data: [30, 40, 45, 50, 49, 60, 70, 91],
            },
          ]}
        />
      );
      break;

    case "totalObservationsOverview":
      chart = (
        <HeatmapChart
          chartOptions={{
            dataLabels: {
              enabled: false,
            },
            colors: ["#008FFB"],
            title: {
              text: "HeatMap Chart (Single color)",
            },
          }}
          chartData={data}
        />
      );
      break;

    case "observationTrendsOverview":
      chart = (
        <HeatmapChart
          chartOptions={{
            dataLabels: {
              enabled: false,
            },
            colors: ["#008FFB"],
            title: {
              text: "HeatMap Chart (Single color)",
            },
          }}
          chartData={[
            {
              name: "Location A",
              data: [
                { x: "Q1", y: 1 },
                { x: "Q2", y: 33 },
                { x: "Q3", y: 81 },
                { x: "Q4", y: 89 },
              ],
            },
            {
              name: "Location B",
              data: [
                { x: "Q1", y: 14 },
                { x: "Q2", y: 53 },
                { x: "Q3", y: 91 },
                { x: "Q4", y: 39 },
              ],
            },
          ]}
        />
      );
      break;

    case "qualityIndexBar":
      //  Placeholder data
      chart = (
        <BarChart
          chartOptions={{
            colors: ["#D12D26", "#F8931F", "#42934B"],
            labels: ["Rejected", "Returned", "Completed"],
            stroke: { colors: ["#FEFEFE"] },
            legend: { floating: true, horizontalAlign: "center" },
            dataLabels: { enabled: true, dropShadow: { enabled: false } },
            xaxis: {
              categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
            },
          }}
          chartData={[
            {
              name: "series-1",
              data: [30, 40, 45, 50, 49, 60, 70, 91],
            },
          ]}
        />
      );
      break;

    case "wordCloudTextAnalysis":
      chart = (
        <WordcloudChart
          height={"138px"}
          chartData={[
            {
              text: "told",
              value: 640,
            },
            {
              text: "mistake",
              value: 11,
            },
            {
              text: "thought",
              value: 160,
            },
            {
              text: "bad",
              value: 170,
            },
            {
              text: "correct",
              value: 100,
            },
            {
              text: "day",
              value: 540,
            },
            {
              text: "prescription",
              value: 120,
            },
            {
              text: "time",
              value: 770,
            },
            {
              text: "thing",
              value: 450,
            },
            {
              text: "left",
              value: 190,
            },
          ]}
        />
      );
      break;

    case "mostCommonPhrases":
      //  Placeholder data
      chart = (
        <BarChart
          chartOptions={{
            colors: ["#D12D26", "#F8931F", "#42934B"],
            labels: ["Rejected", "Returned", "Completed"],
            stroke: { colors: ["#FEFEFE"] },
            legend: { floating: true, horizontalAlign: "center" },
            dataLabels: { enabled: true, dropShadow: { enabled: false } },
            xaxis: {
              categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
            },
          }}
          chartData={[
            {
              name: "series-1",
              data: [30, 40, 45, 50, 49, 60, 70, 91],
            },
          ]}
        />
      );
      break;

    case "sentimentByLocationAndDiscipline":
      chart = (
        <HeatmapChart
          chartOptions={{
            dataLabels: {
              enabled: false,
            },
            colors: ["#008FFB"],
            title: {
              text: "HeatMap Chart (Single color)",
            },
          }}
          chartData={[
            {
              name: "series-1",
              data: [
                { x: "Q1", y: 1 },
                { x: "Q2", y: 33 },
                { x: "Q3", y: 81 },
                { x: "Q4", y: 89 },
              ],
            },
          ]}
        />
      );
      break;

    case "topObservationTextBySentiment":
      //  Placeholder data
      chart = (
        <BarChart
          chartOptions={{
            colors: ["#D12D26", "#F8931F", "#42934B"],
            labels: ["Rejected", "Returned", "Completed"],
            stroke: { colors: ["#FEFEFE"] },
            legend: { floating: true, horizontalAlign: "center" },
            dataLabels: { enabled: true, dropShadow: { enabled: false } },
            xaxis: {
              categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
            },
          }}
          chartData={[
            {
              name: "series-1",
              data: [30, 40, 45, 50, 49, 60, 70, 91],
            },
          ]}
        />
      );
      break;

    case "top20SentimentByBehaviour":
      //  Placeholder data
      chart = (
        <BarChart
          chartOptions={{
            colors: ["#D12D26", "#F8931F", "#42934B"],
            labels: ["Rejected", "Returned", "Completed"],
            stroke: { colors: ["#FEFEFE"] },
            legend: { floating: true, horizontalAlign: "center" },
            dataLabels: { enabled: true, dropShadow: { enabled: false } },
            xaxis: {
              categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
            },
          }}
          chartData={[
            {
              name: "series-1",
              data: [30, 40, 45, 50, 49, 60, 70, 91],
            },
          ]}
        />
      );
      break;

    case "riskHeatmap":
      chart = (
        <HeatmapChart
          chartOptions={{
            dataLabels: {
              enabled: false,
            },
            colors: ["#008FFB"],
            title: {
              text: "HeatMap Chart (Single color)",
            },
          }}
          chartData={[
            {
              name: "series-1",
              data: [
                { x: "Q1", y: 1 },
                { x: "Q2", y: 33 },
                { x: "Q3", y: 81 },
                { x: "Q4", y: 89 },
              ],
            },
          ]}
        />
      );
      break;

    case "componentScore":
      //  Placeholder data
      chart = (
        <BarChart
          chartOptions={{
            colors: ["#D12D26", "#F8931F", "#42934B"],
            labels: ["Rejected", "Returned", "Completed"],
            stroke: { colors: ["#FEFEFE"] },
            legend: { floating: true, horizontalAlign: "center" },
            dataLabels: { enabled: true, dropShadow: { enabled: false } },
            xaxis: {
              categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
            },
          }}
          chartData={[
            {
              name: "series-1",
              data: [30, 40, 45, 50, 49, 60, 70, 91],
            },
          ]}
        />
      );
      break;

    case "wordCloudRiskSnapshot":
      chart = (
        <WordcloudChart
          chartData={[
            {
              text: "told",
              value: 640,
            },
            {
              text: "mistake",
              value: 11,
            },
            {
              text: "thought",
              value: 160,
            },
            {
              text: "bad",
              value: 170,
            },
            {
              text: "correct",
              value: 100,
            },
            {
              text: "day",
              value: 540,
            },
            {
              text: "prescription",
              value: 120,
            },
            {
              text: "time",
              value: 770,
            },
            {
              text: "thing",
              value: 450,
            },
            {
              text: "left",
              value: 190,
            },
          ]}
        />
      );
      break;

    case "riskbySafeScore":
      //  Placeholder data
      chart = (
        <BarChart
          chartOptions={{
            colors: ["#D12D26", "#F8931F", "#42934B"],
            labels: ["Rejected", "Returned", "Completed"],
            stroke: { colors: ["#FEFEFE"] },
            legend: { floating: true, horizontalAlign: "center" },
            dataLabels: { enabled: true, dropShadow: { enabled: false } },
            xaxis: {
              categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
            },
          }}
          chartData={[
            {
              name: "series-1",
              data: [30, 40, 45, 50, 49, 60, 70, 91],
            },
          ]}
        />
      );
      break;

    case "directorateKpisheatmap":
      chart = demo ? (
        <Card
          style={{
            height: 350,
          }}
        >
          <Grid container spacing={1} style={{ height: "100%" }}>
            <Grid item xs={12} sm={2}>
              <Stack spacing={3}>
                <Typography variant="h4" align="center">
                  {"Directorate 1"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={10}>
              <HeatmapChart
                demo={demo}
                height={"100%"}
                chartOptions={heatMapOptions}
              />
            </Grid>
          </Grid>
        </Card>
      ) : (
        data_api?.[chartId].heatMap?.length > 0 &&
        data_api?.[chartId].heatMap?.map((entry, index) => (
          <Card
            style={{
              height:
                entry.data?.length > 24
                  ? 1700
                  : entry.data?.length > 10
                  ? 800
                  : 350,
            }}
          >
            <Grid container spacing={1} style={{ height: "100%" }}>
              <Grid item xs={12} sm={2}>
                <Stack spacing={3}>
                  <Typography variant="h4" align="center">
                    {entry?.name}
                  </Typography>
                  <CircularStatus
                    value={entry.overAll}
                    label={`${entry.overAll}%`}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={10}>
                <HeatmapChart
                  demo={demo}
                  height={entry?.data?.length < 3 ? "90%" : "95%"}
                  chartData={entry?.data}
                  chartOptions={heatMapOptions}
                />
              </Grid>
            </Grid>
          </Card>
        ))
      );
      break;
    default:
      chart = "No Chart Available";
      break;
  }

  return chart;
};

export const getChartDefaults = (chartType) => {
  let chartOptions;
  switch (chartType) {
    case "noOfObservationsPie":
      chartOptions = {
        minH: 2,
        minW: 3,
        title: "Number of observations",
        api: "noOfObservations1",
      };
      break;
    case "lessonLearntCardCounterOne":
      chartOptions = {
        minH: 2,
        minW: 3,
        title: "Lesson Learnt Card",
        api: "lessonLearntCard",
      };
      break;
    case "noOfObservationsBar":
      chartOptions = {
        minH: 2,
        minW: 3,
        title: "Number of observations",
        api: "noOfObservations",
      };
      break;
    case "totalObservationsPersonalCounter":
      chartOptions = {
        minH: 2,
        minW: 12,
        title: "Total Observations (Personal)",
        api: "totalObservationsPersonal",
      };
      break;
    case "totalObservationsCounter":
      chartOptions = {
        minH: 2,
        minW: 12,
        title: "Total Observations",
        api: "totalObservations",
      };
      break;
    case "totalObservationsPie":
      chartOptions = {
        minH: 3,
        minW: 5,
        title: "Total observations",
        api: "totalObservations",
      };
      break;
    case "totalObservationsPersonalPie":
      chartOptions = {
        minH: 3,
        minW: 5,
        title: "Total observations (Personal)",
        api: "totalObservationsPersonal",
      };
      break;
    case "behaviourTypePie":
      chartOptions = {
        minH: 3,
        minW: 5,
        title: "Observation Type (Planned vs Intervention)",
        api: "behaviorType",
      };
      break;
    case "observationStatusPie":
      chartOptions = {
        minH: 3,
        minW: 5,
        title: "Status of Observations",
        api: "observationStatus",
      };
      break;
    case "actionStatusPie":
      chartOptions = {
        minH: 3,
        minW: 5,
        title: "Action Status",
        api: "actionStatus",
      };
      break;
    case "behaviourNameColumn":
      chartOptions = {
        minH: 3,
        minW: 12,
        title: "Behavior Names",
        api: "behaviorNames",
      };
      break;
    case "disciplineDistributionColumn":
      chartOptions = {
        minH: 3,
        minW: 5,
        title: "Discipline Distribution Graph",
        api: "disciplineDistribution",
      };
      break;
    case "topReportedUnsafeBehavioursColumn":
      chartOptions = {
        minH: 3,
        minW: 5,
        title: "Top Reported Unsafe Behaviours",
        api: "topUnsafeBehavior",
      };
      break;
    case "observationsPerYearStacked":
      chartOptions = {
        minH: 3,
        minW: 5,
        title: "Observations per year",
        api: "observationsPerYear",
      };
      break;
    case "observationTrendsMultiline":
      chartOptions = {
        minH: 3,
        minW: 12,
        title: "Observation Trends",
        api: "observationTrends",
      };
      break;
    case "locationKpisMultiline":
      chartOptions = {
        minH: 3,
        minW: 12,
        title: "Location/Unit KPI",
        api: "locationKpis",
      };
      break;
    case "companyKpisKpiColumn":
      chartOptions = {
        minH: 3,
        minW: 12,
        title: "Contractor Wide KPI",
        api: "companyKpis",
      };
      break;
    case "cardCountTable":
      chartOptions = {
        minH: 2,
        minW: 5,
        title: "Card Counts",
        api: "cardCount",
      };
      break;
    case "lessonLearntDetailTable":
      chartOptions = {
        minH: 2,
        minW: 5,
        title: "Lesson Learnt Summary",
        api: "lessonLearntDetail",
      };
      break;
    default:
      chartOptions = {
        minH: 2,
        minW: 3,
        title: "No chart title.",
        api: "noOfObservations",
      };
      break;
  }
  chartOptions.chartType = chartType;
  return chartOptions;
};

export const getChartEnums = () => {
  let chartNames = [
    "totalObservationsPersonalCounter",
    "totalObservationsCounter",
    "totalObservationsPie",
    "totalObservationsPersonalPie",
    "behaviourTypePie",
    "observationStatusPie",
    "actionStatusPie",
    "behaviourNameColumn",
    "disciplineDistributionColumn",
    "topReportedUnsafeBehavioursColumn",
    "observationsPerYearStacked",
    "observationTrendsMultiline",
    "cardCountTable",
  ];
  return chartNames;
};

export const submitAddChart = (
  layout,
  setLayout,
  chartType,
  chartIds,
  setChartIds
) => {
  const newLayout = [...layout];
  const id = faker.datatype.uuid();
  let highestY = 0;
  let totalRowWidth = 0;

  let finalX = 0;
  let finalY = 0;

  newLayout.forEach((l) => {
    if (l.y > highestY) {
      highestY = l.y;
      totalRowWidth = 0;
    }

    if (l.y === highestY) {
      totalRowWidth += l.w;
      if (totalRowWidth >= 9) {
        finalY = highestY + 1;
        finalX = 0;
      } else {
        finalY = highestY;
        finalX = totalRowWidth;
      }
    }
  });

  const chartDefaults = getChartDefaults(chartType);
  const finalLayout = newLayout.concat({
    i: id,
    x: finalX,
    y: finalY,
    minW: chartDefaults.minW,
    minH: chartDefaults.minH,
    h: chartDefaults.minH,
    w: chartDefaults.minW,
  });
  setChartIds({
    ...chartIds,
    [id]: {
      type: chartType,
      title: chartDefaults.title,
      api: chartDefaults.api,
      filters: {
        companyIds: [],
        directorateIds: [],
        startDate: new Date(),
        endDate: new Date(),
        dateEnabled: false,
      },
    },
  });
  setLayout(finalLayout);
};

export const removeWidget = (id, layout, setLayout, chartIds, setChartIds) => {
  const newLayout = layout.filter((item) => {
    return item.i !== id;
  });

  const newChartIds = omit(chartIds, id);

  setLayout(newLayout);
  setChartIds(newChartIds);
};

export const handleLayoutChange = (
  newLayout,
  dashboardLayout,
  setDashboardLayout
) => {
  if (!isArrayEqual(newLayout, dashboardLayout)) {
    setDashboardLayout(newLayout);
  }
};

export const handleTabChange = (
  event,
  value,
  setTabValue,
  setFilterModal,
  filterModal
) => {
  setTabValue(value);
  setFilterModal({
    ...filterModal,
    tab: value,
    [value]: {
      ...filterModal[value],
    },
  });
};

export const saveLayout = async (
  gridOptions,
  setGridOptions,
  setMode,
  setLoading,
  dashboardLayout,
  chartIds,
  userPreferences,
  isDashboard = false
) => {
  try {
    setLoading(true);
    const newGridOptions = {
      ...gridOptions,
      isDraggable: false,
      isResizable: false,
    };
    setGridOptions(newGridOptions);
    setMode("view");
    const updatedPreferences = {
      ...userPreferences,
      dashboardDetails: {
        layout: dashboardLayout,
        chartIds: chartIds,
      },
    };
    isDashboard
      ? await saveDashboardPreferences(updatedPreferences)
      : await saveUserPreferencesApi(updatedPreferences);
    setLoading(false);
    return true;
  } catch (err) {
    console.log(err);
    setLoading(false);
  }
};

export const setEditMode = (
  gridOptions,
  setGridOptions,
  setMode,
  dashboardLayout,
  setOldLayout,
  chartIds,
  setOldChartIds
) => {
  const newGridOptions = {
    ...gridOptions,
    isDraggable: true,
    isResizable: true,
  };
  setGridOptions(newGridOptions);
  setOldLayout(dashboardLayout);
  setOldChartIds(chartIds);
  setMode("edit");
};

export const cancelLayoutEdit = (
  oldLayout,
  setDashboardLayout,
  setMode,
  oldChartIds,
  setChartIds,
  gridOptions,
  setGridOptions
) => {
  const newGridOptions = {
    ...gridOptions,
    isDraggable: false,
    isResizable: false,
  };
  setGridOptions(newGridOptions);
  setMode("view");
  setChartIds(oldChartIds);
  setDashboardLayout(oldLayout);
};

export const getUserPreferences = async (
  setUserPreferences,
  setDashboardLayout,
  setChartIds,
  setLoading
) => {
  try {
    setLoading(true);
    const preferences = await getUserPreferencesApi();
    if (preferences) {
      setUserPreferences(preferences);

      if (
        preferences &&
        preferences.dashboardDetails &&
        preferences.dashboardDetails.layout
      ) {
        setDashboardLayout(preferences.dashboardDetails.layout);
      }

      if (
        preferences &&
        preferences.dashboardDetails &&
        preferences.dashboardDetails.chartIds
      ) {
        setChartIds(preferences.dashboardDetails.chartIds);
      }
    } else {
      setUserPreferences({});
    }
    setLoading(false);
  } catch (err) {
    console.log(err);
    setLoading(false);
  }
};

export const getApiFunctions = async (ChartObject, setFunction) => {
  let tempData = {};
  const dateEnabled = ChartObject?.filters?.dateEnabled;
  const startDate = ChartObject?.filters?.startDate;
  const endDate = ChartObject?.filters?.endDate;
  const limit = ChartObject?.filters?.limit;
  const companyString = ChartObject?.filters?.companyIds
    ?.map((entry) => entry.value)
    ?.join(",");
  const directorateString = ChartObject?.filters?.directorateIds
    ?.map((entry) => entry.value)
    .join(",");
  let filterString = {
    ...(companyString ? { company: companyString } : {}),
    ...(directorateString ? { directorate: directorateString } : {}),
    ...(dateEnabled && startDate ? { startDate: new Date(startDate) } : {}),
    ...(dateEnabled && endDate ? { endDate: new Date(endDate) } : {}),
    ...(limit ? { top: limit } : {}),
  };
  try {
    switch (ChartObject.api) {
      case "totalObservations":
        tempData = await getTotalObservations(filterString);
        break;
      case "totalObservationsPersonal":
        tempData = await getTotalObservationsPersonal(filterString);
        break;
      case "behaviorType":
        tempData = await getBehaviourTypes(filterString);
        break;
      case "observationStatus":
        tempData = await getObservationStatus(filterString);
        break;
      case "actionStatus":
        tempData = await getActionStatus(filterString);
        break;
      case "behaviorNames":
        tempData = await getBehaviorNames(filterString);
        break;
      case "trainingStatus":
        tempData = await getTrainingStatuses(filterString);
        break;
      case "behaviorNamesCritical":
        filterString.type = "CRITICAL";
        tempData = await getBehaviorNames(filterString);
        break;
      case "behaviorNamesOther":
        filterString.type = "OTHER";
        tempData = await getBehaviorNames(filterString);
        break;
      case "disciplineDistribution":
        tempData = await getDisciplineDistribution(filterString);
        break;
      case "topUnsafeBehavior":
        tempData = await getTopUnsafeBehavior(filterString);
        break;
      case "observationTrends":
        tempData = await getObservationTrends(filterString);
        break;
      case "locationKpis":
        if (ChartObject?.filters?.locationId) {
          let isLocation = false;
          let splitLocation = ChartObject?.filters?.locationId.split("--");

          if (splitLocation[2] === "location") isLocation = true;
          filterString.locationId = `${splitLocation[1]}`;
          filterString.isLocation = `${isLocation}`;
          filterString.companyId = `${ChartObject?.filters?.companyId}`;
          filterString.directorateId = `${ChartObject?.filters?.directorateId}`;
          if (ChartObject?.filters?.audit != "false")
            filterString.audit = `true`;
          if (ChartObject?.filters?.selectedKpis?.length > 0) {
            filterString.kpis = ChartObject?.filters?.selectedKpis
              ?.map((entry) => entry.value)
              .join(",");
          }
          if (
            ChartObject?.filters?.datesEnabled &&
            ChartObject?.filters?.startDate &&
            ChartObject?.filters?.endDate
          ) {
            filterString.startDate = ChartObject.filters.startDate;
            filterString.endDate = ChartObject.filters.endDate;
          }
        }
        tempData = await getLocationKpisTrends(filterString);
        break;
      case "companyKpis":
        if (ChartObject?.filters?.companyId) {
          filterString.companyId = `${ChartObject?.filters?.companyId}`;
          filterString.directorateId = `${ChartObject?.filters?.directorateId}`;
          if (
            ChartObject?.filters?.datesEnabled &&
            ChartObject?.filters?.startDate &&
            ChartObject?.filters?.endDate
          ) {
            filterString.startDate = ChartObject.filters.startDate;
            filterString.endDate = ChartObject.filters.endDate;
          }
        }
        if (ChartObject?.filters?.audit != "false") filterString.audit = `true`;
        if (ChartObject?.filters?.selectedKpis?.length > 0) {
          filterString.kpis = ChartObject?.filters?.selectedKpis
            ?.map((entry) => entry.value)
            .join(",");
        }
        tempData = await getCompanyKpis(filterString);
        break;
      case "observationsPerYear":
        tempData = await getObservationsPerYear(filterString);
        break;
      case "cardCount":
        tempData = await getCardCount(filterString);
        break;
      case "lessonLearntCard":
        tempData = await lessonLearntCard(filterString);
        break;
      case "lessonLearntDetail":
        tempData = await lessonLearntDetail(filterString);
        break;
      case "directorateKpis":
        let query = `?`;
        if (filterString.startDate) {
          query += `startDate=${moment(filterString.startDate).format(
            "MM-YYYY"
          )}`;
        } else {
          query += `startDate=${moment(new Date())
            .subtract(5, "M")
            .format("MM-YYYY")}`;
        }
        if (filterString.endDate) {
          query += `&endDate=${moment(filterString.endDate).format("MM-YYYY")}`;
        } else {
          query += `&endDate=${moment(new Date()).format("MM-YYYY")}`;
        }
        if (filterString.directorate && filterString.directorate.length > 0) {
          query += `&directorates=${filterString.directorate}`;
        }
        if (filterString.company && filterString.company.length > 0) {
          query += `&companies=${filterString.company}`;
        }
        if (filterString.showAudit === "true") {
          query += `&audit=true`;
        }
        tempData = await getDirectorateHeatMap(query);
        break;
      default:
        tempData = null;
        break;
    }
    if (tempData.heatMap && tempData.heatMap.length === 0) {
      displayToast(() => {}, "error", "No data found");
      return "error";
    }
    return setFunction(ChartObject, tempData?.data ? tempData?.data : tempData);
  } catch (e) {
    //  TODOC
    console.log("e------------", e);
    displayToast(() => {}, "error", e);
    return "error";
  }
};

export const downloadCSVFromJson = (dataObj) => {
  let chartType = dataObj?.chartObject?.type;
  let chartTitle = `${
    getChartDefaults(dataObj?.chartObject?.type)?.title
  }-${moment().format("DD/MM/YYYY")}`;
  let jsonArray = jsonArrayTemplateMaker(dataObj, chartType);

  // convert JSON to CSV
  const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
  const header = Object.keys(jsonArray[0]);
  let csv = jsonArray.map((row) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(",")
  );
  csv.unshift(header.join(","));
  csv = csv.join("\r\n");

  // Create link and download
  var link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
  );
  link.setAttribute("download", chartTitle);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const jsonArrayTemplateMaker = (entry, chartType) => {
  let data = [];
  switch (chartType) {
    case "totalObservationsPersonalCounter":
    case "totalObservationsCounter":
    case "behaviourNameColumn":
    case "disciplineDistributionColumn":
    case "topReportedUnsafeBehavioursColumn":
    case "totalObservationsPie":
    case "totalObservationsPersonalPie":
    case "behaviourTypePie":
    case "observationStatusPie":
    case "actionStatusPie":
    case "companyKpisKpiColumn":
    case "lessonLearntCardCounterOne":
      data = defaultChartJsonToCsv(entry);
      break;
    case "observationsPerYearStacked":
    case "observationTrendsMultiline":
    case "locationKpisMultiline":
      data = stackedChartJsonToCsv(entry);
      break;
    case "cardCountTable":
    case "lessonLearntDetailTable":
      data = tableJsonToCsv(entry);
      break;
    default:
      break;
  }
  return data;
};

const tableJsonToCsv = (data) => {
  let tempArray = [{}];
  data?.labels.forEach((entry) => {
    let tempObj = {};
    tempObj[entry] = "-";
    tempArray[0] = { ...tempArray[0], ...tempObj };
  });
  data?.dataSet.forEach((entry) => {
    tempArray.push(entry);
  });
  return tempArray;
};

const stackedChartJsonToCsv = (data) => {
  let tempArray = [{ Name: "-" }];
  data?.labels?.forEach((entry) => {
    tempArray[0][`-${entry}`] = "-";
  });
  data?.values.forEach((entry) => {
    let tempObj = { Name: entry.name };
    for (let i = 0; i < data.labels.length; i++) {
      tempObj[`-${data.labels[i]}`] = entry.data?.[i] ? entry.data[i] : 0;
    }
    tempArray.push(tempObj);
  });
  return tempArray;
};

const defaultChartJsonToCsv = (data) => {
  let tempArray = [{ Label: "-", Count: "-" }];
  data.labels?.forEach((entry, index) => {
    tempArray.push({
      Label: entry,
      Count: data.values[index],
    });
  });
  return tempArray;
};

export const getCompanyFilters = async ({
  setLoading,
  directorateFilter,
  setCompanyDropdown,
}) => {
  try {
    setLoading(true);
    if (!directorateFilter || directorateFilter === "all") {
      setCompanyDropdown([]);
    } else {
      const companies = await getCompanyFiltersApi(directorateFilter);
      setCompanyDropdown(companies);
    }
    setLoading(false);
  } catch (err) {
    setLoading(false);
  }
};

export const getLocationsAndUnitsFilters = async ({
  setLoading,
  companyFilter,
  setLocationDropdown,
  setUnitDropdown,
}) => {
  try {
    setLoading(true);
    if (!companyFilter || companyFilter === "all") {
      setLocationDropdown([]);
    } else {
      const companies = await getLocationsAndUnitsFiltersApi(companyFilter);
      setLocationDropdown(companies.locations);
      setUnitDropdown(companies.units);
    }
    setLoading(false);
  } catch (err) {
    setLoading(false);
  }
};

export const getDisciplineFilters = async ({
  setLoading,
  companyFilter,
  locationFilter,
  unitFilter,
  setDisciplineDropdown,
}) => {
  try {
    setLoading(true);
    if (
      !companyFilter ||
      companyFilter === "all" ||
      !locationFilter ||
      locationFilter === "all"
    ) {
      setDisciplineDropdown([]);
    } else {
      const disciplines = await getDisciplineFiltersApi(
        companyFilter,
        locationFilter,
        !unitFilter || unitFilter === "all" ? null : unitFilter
      );
      setDisciplineDropdown(disciplines);
    }
    setLoading(false);
  } catch (err) {
    setLoading(false);
  }
};

export const returnAIColor = (value) => {
  if (!value) return "#858585";
  if (value >= 1 && value < 65) return "#DC143C";
  else if (value >= 65 && value <= 85) return "#F2994A";
  else if (value >= 85) return "#1bc262";
};

export const heatmapColors = [
  {
    from: 0,
    to: 0,
    color: "#858585",
    name: "No data",
  },
  {
    from: 1,
    to: 65,
    color: "#DC143C",
    name: "Low: Below 65%",
  },
  {
    from: 65,
    to: 85,
    color: "#F2994A",
    name: "Moderate: 65% - 85%",
  },
  {
    from: 85,
    to: 100,
    color: "#1bc262",
    name: "High: Above 85%",
  },
];
