import apiHelper from "./apiHelper";

export const getCriticalBehaviorsApi = (
  companyId,
  disciplineId,
  locationId,
  unitId
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/behaviors`, {
        params: {
          companyId,
          locationId,
          unitId: unitId || null,
          ...(disciplineId ? { disciplineId } : {}),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getObsCriticalBehaviorsApi = (
  companyId,
  disciplineId,
  locationId,
  unitId,
  directorateId
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/behaviors/forObservation`, {
        params: {
          companyId,
          locationId,
          unitId: unitId || null,
          directorateId,
          ...(disciplineId ? { disciplineId } : {}),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getOtherBehaviorsApi = (disciplineId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/behaviors?type=OTHER`, {
        params: {
          pageSize: 999,
          pageNo: 1,
          disciplineId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getObsOtherBehaviorsApi = (categoryId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/behaviors?type=CRITICAL`, {
        params: {
          pageSize: 999,
          pageNo: 1,
          categoryId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getBehaviorsApi = (disciplineId, type) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/behaviors?type=${type}&disciplineId=${disciplineId}`, {
        params: {
          pageSize: 999,
          pageNo: 1,
        },
      })
      .then((res) => {
        resolve(res.data.data.data);
      })
      .catch((err) => {
        reject(err.response.data.data.errorMessage);
      });
  });
};

export const getBehaviorsByImplementationId = (planId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/behaviors/plans/${planId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateCriticalBehaviorsByImplementationId = (planId, body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/behaviors/plans/${planId}`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getBehaviorCategoriesApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/behaviors/categories?type=CRITICAL&categoryType=BEHAVIOR`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const retireCriticialBehaviorByCompany = (companyId,behaviorId,body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/behaviors/company/${companyId}/retireBehavior/${behaviorId}`,body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};