import PropTypes from "prop-types";

// material
import { Box, Tooltip } from "@mui/material";
import { red, green } from "@mui/material/colors";

// ----------------------------------------------------------------------

const ProgressBar = (colorsArray) => {
  return (
    <Box sx={{ display: "flex", height: "90%", padding: "4px" }}>
      {colorsArray &&
        colorsArray.colors.map((color, index) => (
          <Tooltip title={color?.activityName}>
            <div
            key={index}
            style={{
              backgroundColor:
              color?.color === "red"
                  ? red[600]
                  : color?.color === "green"
                  ? green[600]
                  : color?.color,
              width: "16px",
              height: "auto",
              margin: "0px 2px",
              borderRadius: "4px",
            }}
          ></div>
          </Tooltip>
        ))}
    </Box>
  );
};

ProgressBar.propTypes = {
  colors: PropTypes.array,
};

export default ProgressBar;
