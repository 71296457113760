import { useState, useEffect, useRef } from "react";
import { renewTokenApi } from "../api/auth";

export default function Renewer() {
  const interval = useRef();
  const expiryMinutes = 15;
  const [idleMinutes, setIdleMinutes] = useState(0);
  const [trueMinutes, setTrueMinutes] = useState(0);
  const [active, setActive] = useState(false);

  const handleActive = () => {
    setIdleMinutes(0);
    setActive(true);
  };

  //  Count up idle minutes
  useEffect(() => {
    const sessionCheck = async () => {
      const handleTimer = () => {
        interval.current = setInterval(() => {
          setIdleMinutes((minutes) => minutes + 1);
          setTrueMinutes((minutes) => minutes + 1);
        }, 60000);
      };

      if (trueMinutes > expiryMinutes - 2 && active) {
        await renewTokenApi();
        setTrueMinutes(0);
        setActive(false);
      }

      if (idleMinutes >= expiryMinutes && interval.current) {
        //  End condition
        clearInterval(interval.current);
        localStorage.clear();
        setActive(false);
        window.location.href = "/login?expiry=true";
      } else if (idleMinutes === 0) {
        //  Run another timer
        clearInterval(interval.current);
        handleTimer();
      }
    };
    sessionCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idleMinutes]);

  //  Renew token
  useEffect(() => {}, []);

  //  Window listeners
  useEffect(() => {
    window.addEventListener("click", handleActive);
    window.addEventListener("keydown", handleActive);

    return () => {
      window.removeEventListener("click", handleActive);
      window.removeEventListener("keydown", handleActive);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
