import apiHelper from "./apiHelper";

export const getUnsafeConditionsApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/unsafeConditions`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getMainConditionsApi = (pageNo = 1, pageSize = 9999) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/disciplines?type=CONDITION`, { params: { pageNo, pageSize } })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getObsMainConditionsApi = (pageNo = 1, pageSize = 9999) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/behaviors/categories?type=CONDITION&categoryType=CONDITION`, {
        params: { pageNo, pageSize },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

// export const getObsMainConditionsApi = (pageNo = 1, pageSize = 9999) => {
//   return new Promise((resolve, reject) => {
//     apiHelper
//       .get(`/api/observations/disciplines?type=CONDITION`, {
//         params: { pageNo, pageSize },
//       })
//       .then((res) => {
//         resolve(res.data.data);
//       })
//       .catch((err) => {
//         reject(err.response.data.errorMessage);
//       });
//   });
// };
