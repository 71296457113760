import apiHelper from "./apiHelper";
import { generateFormData } from "../utils/general";
//  Type can be one of:
// [PENDING, COMPLETED, ALL]
export const getMyKnowledgeApi = ({
  fileType,
  order,
  pageNo,
  pageSize,
  searchTerm,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/knowledgeBases`, {
        params: {
          fileType,
          order,
          pageNo,
          pageSize,
          searchTerm,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const createDocument = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/knowledgeBases`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getKnowledgeDetailsApi = (knowledgeId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/knowledgeBases/${knowledgeId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateKnowledgeBaseApi = (body, knowledgeId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/knowledgeBases/${knowledgeId}`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const updateKnowledgeViewCountApi = (body, knowledgeId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/knowledgeBases/${knowledgeId}/view`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const ShareFileApi = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/knowledgeBases/shareFileWithUsers`, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};


export const deleteKnowledgeBase = knowledgeId => {
  return new Promise((resolve, reject) => {
    apiHelper
      .delete(`/api/knowledgeBases/${knowledgeId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
}
