import apiHelper from "./apiHelper";

export const getBehaviors = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/LTI/categories?${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocations = (query) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/LTI/locationOptions?${query}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const createLTI = (body, planId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/LTI/${planId}`, body)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLTI = (planId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/LTI/${planId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const deleteLtiApi = (planId,ltiId) => {
    return new Promise((resolve, reject) => {
      apiHelper
        .delete(`/api/LTI/${planId}/${ltiId}`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err.response.data.errorMessage);
        });
    });
  };