import { AddCircle } from "@mui/icons-material";
import { Checkbox, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import TableButton from "../../../components/buttons/TableButton";
import InfoBox from "../../../components/InfoBox";
import AutocompleteInput from "../../../components/inputs/AutocompleteInput";
import DefaultTable from "../../../components/tables/DefaultTable";
import { useTheme, styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import SelectInput from "../../../components/inputs/SelectInput";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  createLTI,
  deleteLtiApi,
  getBehaviors,
  getLocations,
  getLTI,
} from "../../../api/lostTimeInjury";
import { useParams } from "react-router";
import { displayToast } from "../../../utils/general";

const RRow = styled("div")(({ theme, active, disabled }) => ({
  cursor: "pointer",
  minHeight: "50px",
  color: disabled
    ? theme.palette.primary.disabledFont
    : theme.palette.primary.black,
  background: disabled
    ? theme.palette.primary.disabled
    : "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
  boxShadow: disabled ? "none" : " 0px 4px 9px rgba(0, 0, 0, 0.11)",
  borderRadius: "6px",
  border: active
    ? `2px solid ${theme.palette.primary.hoverOrange}`
    : `1px solid #F1F3F4`,
  padding: "12px 0",
}));

const deleteDefaults = {
  open: false,
  id: null,
  behaviorName: "",
  behaviorNameAR: "",
};

function LtiList() {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { companyId, planId } = useParams();
  const [filterParams, setFilterParams] = useState({
    pageSize: 10,
    pageNo: 1,
  });
  const [tableInfo, setTableInfo] = useState({ totalPages: 1, totalCount: 0 });
  const [rows, setRows] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [behaviorsDropdown, setBehaviorsDropdown] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesApiData, setCategoriesApiData] = useState([]);
  const [locationsAndUnits, setLocationsAndUnits] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBehavior, setSelectedBehavior] = useState(null);
  const [textSearch, setTextSearch] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState(deleteDefaults);

  const columns = [
    {
      field: "behaviorName",
      headerName: t("table.behavior"),
      width: 300,
      sortable: false,
      renderCell: (params) => {
        return currentLanguage === "ar"
          ? params.row.behaviorNameAR
          : params.row.behaviorName;
      },
    },
    {
      field: "placeName",
      headerName: t("table.locationUnit"),
      width: 300,
      sortable: false,
    },
    {
      field: "addedBy",
      headerName: t("table.addedBy"),
      width: 250,
      sortable: false,
    },
    {
      field: "addedAt",
      headerName: t("table.addedAt"),
      width: 200,
      sortable: false,
    },
    {
      field: "actions",
      headerName: t("table.actions"),
      width: 80,
      alignItems: "center",
      sortable: false,
      renderCell: (params) => (
        <TableButton
          red
          disabled={deleteLoading}
          label={t("general.delete")}
          onClick={() =>
            setDeleteDetails({
              open: true,
              id: params.row.id,
              behaviorName: params.row.behaviorName,
              behaviorNameAR: params.row.behaviorNameAR,
            })
          }
          icon={<DeleteIcon />}
        />
      ),
    },
  ];

  const renderAddView = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ pt: 0 }}>
        <Typography variant="body1">
          {t("implementations.identifyCBText")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          handler={(e, v) =>
            v ? setSelectedCategory(v) : setBehaviorsDropdown([])
          }
          value={selectedCategory}
          label={t("implementations.selectCategory")}
          placeholder={t("implementations.selectCategoryPlease")}
          options={categories}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          {t("implementations.selectBehavior")}
        </Typography>
        <Stack
          spacing={2}
          style={{
            border: `1px solid ${theme.palette.primary.lighter}`,
            padding: "8px",
            borderRadius: "8px",
            minHeight: "40vh",
          }}
        >
          {behaviorsDropdown.length > 0 ? (
            behaviorsDropdown.map((b) => (
              <RRow disabled={false}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Checkbox
                      checked={selectedBehavior === b.id ? true : false}
                      onChange={(event) =>
                        event.target.checked
                          ? setSelectedBehavior(b.id)
                          : setSelectedBehavior(null)
                      }
                      disabled={false}
                      sx={{
                        color: theme.palette.primary.orange,
                        "&.Mui-checked": {
                          color: theme.palette.primary.orange,
                        },
                        "&:hover": {
                          backgroundColor: "rgba(248,147,31,0.08)",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    style={{
                      ...(!b.name
                        ? { display: "flex", alignItems: "center" }
                        : {}),
                      paddingLeft: "18px",
                    }}
                  >
                    <Typography variant="body1">{b.label}</Typography>
                    {/* {b.lsrTitle && (
                      <p
                        style={{
                          fontWeight: "lighter",
                          fontSize: "12px",
                        }}
                      >
                        {b.lsrTitle}
                      </p>
                    )} */}
                  </Grid>
                </Grid>
              </RRow>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">
                {t("implementations.selectCategoryText")}
              </Typography>
            </div>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <>
          <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
            {t("implementations.selectLocation")}
          </Typography>
          <Stack
            justifyContent="space-between"
            style={{
              border: `1px solid ${theme.palette.primary.lighter}`,
              padding: "12px",
              borderRadius: "8px",
              minHeight: "40vh",
            }}
          >
            <SelectInput
              disabled={false}
              options={locationsAndUnits}
              handler={(newValue) => setSelectedLocation(newValue.target.value)}
              values={selectedLocation}
            />
          </Stack>
        </>
      </Grid>
    </Grid>
  );

  const renderDeleteModal = () => (
    <InfoBox
      disabled={deleteLoading}
      open={deleteDetails.open}
      title={`${t(`remove`)} ${
        currentLanguage === "ar"
          ? deleteDetails.behaviorNameAR
          : deleteDetails.behaviorName
      }`}
      content={
        <Stack>
          <Typography variant="h6" style={{ color: theme.palette.primary.red }}>
            {t(`implementations.removeConfirm`)}
          </Typography>
        </Stack>
      }
      buttonLabel={t(`general.delete`)}
      handleClose={() => setDeleteDetails(deleteDefaults)}
      handleAction={deleteLti}
    />
  );

  const fetchBehaviors = async () => {
    try {
      let query = `type=CRITICAL`;
      if (textSearch) query += `&textSearch=${textSearch}`;
      let req = await getBehaviors(query);
      if (req?.length > 0) {
        let tempOptions = req.map((entry) => {
          return {
            label: entry.name,
            value: entry.id,
          };
        });
        setCategories(tempOptions);
        setCategoriesApiData(req);
      }
    } catch (e) {
      console.log("ErrorHere", e);
    }
  };

  const fetchLocations = async () => {
    try {
      let query = `companyId=${companyId}`;
      let request = await getLocations(query);
      setLocationsAndUnits(request?.options);
    } catch (e) {
      console.log("errorHere", e);
    }
  };

  const submitLti = async () => {
    try {
      if (!submitLoading) {
        displayToast(
          setSubmitLoading,
          "loading",
          t("implementations.loadingSubmitLti"),
          "loadingSubmitLti"
        );
        let tempBody = {
          locationId: null,
          unitId: null,
          behaviorId: null,
        };
        if (selectedCategory === null) {
          displayToast(
            setSubmitLoading,
            "error",
            t("implementations.selectCategoryText"),
            "errorSubmitLti"
          );
        } else if (selectedBehavior === null) {
          displayToast(
            setSubmitLoading,
            "error",
            t("implementations.addDisciplines.behaviorError"),
            "errorSubmitLti"
          );
        } else if (selectedLocation === null) {
          displayToast(
            setSubmitLoading,
            "error",
            t("implementations.selectCategoryText"),
            "errorSubmitLti"
          );
        } else {
          const id = selectedLocation?.split("-");
          if (id) {
            id[1] === "unit"
              ? (tempBody.unitId = id[0])
              : (tempBody.locationId = id[0]);
          }
          tempBody.behaviorId = selectedBehavior;
          let submitRequest = await createLTI(tempBody, planId);
          if (submitRequest?.id) {
            setSubmitLoading(false);
            await fetchLtis();
            setAddModalOpen(false);
            setSelectedBehavior(null);
            setSelectedCategory(null);
            setSelectedLocation(null);
            displayToast(
              setSubmitLoading,
              "success",
              t("implementations.submitLtiSuccessfull"),
              "succesSubmitLti"
            );
          }
        }
      }
    } catch (e) {
      displayToast(setSubmitLoading, "error", e, "errorSubmitLti");
    }
  };

  const deleteLti = async () => {
    try {
      displayToast(
        setDeleteLoading,
        "loading",
        t(`implementations.removingLTI`),
        "deleteLti"
      );
      const requestDelete = await deleteLtiApi(planId, deleteDetails.id);
      if (requestDelete) {
        await fetchLtis();
        setDeleteDetails(deleteDefaults);
        displayToast(
          setDeleteLoading,
          "success",
          t(`implementations.removedLTI`),
          "errorSubmitLti"
        );
      }
    } catch (e) {
      displayToast(setDeleteLoading, "error", e, "errorSubmitLti");
      setDeleteDetails(deleteDefaults);
    }
  };

  useEffect(() => {
    if (selectedCategory?.value) {
      let find = categoriesApiData.findIndex(
        (c) => c.id === selectedCategory.value
      );
      let tempData = categoriesApiData[find]?.behaviors.map((entry) => {
        return {
          id: entry.id,
          value: entry.id,
          label: entry.name,
        };
      });

      setBehaviorsDropdown(tempData);
    }
  }, [selectedCategory]);

  const fetchLtis = async () => {
    setLoadingTable(true);
    try {
      const requestLtis = await getLTI(planId);
      if (requestLtis?.length > 0) {
        let tempData = requestLtis?.map((entry) => {
          return {
            id: entry.id,
            behaviorName: entry?.behavior?.name,
            behaviorNameAR: entry?.behavior?.nameAR,
            placeName: entry.location
              ? entry?.location?.name
              : entry?.unit?.name,
            addedBy: `${entry?.creator?.firstName ?? ""} ${
              entry?.creator?.lastName ?? ""
            }`,
            addedAt: moment(entry?.createdAt).format("DD/MM/YY"),
          };
        });
        setRows(tempData);
        setLoadingTable(false);
      } else {
        setRows([]);
        setLoadingTable(false);
      }
    } catch (e) {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    fetchLtis();
    fetchBehaviors();
    fetchLocations();
  }, []);

  return (
    <>
      <Stack spacing={2}>
        <DefaultTable
          hidePagination
          rows={rows}
          columns={columns}
          loading={loadingTable}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          rowSelectable={false}
          tableInfo={tableInfo}
        />
        <PrimaryButton
          label={t("implementations.addLti")}
          icon={<AddCircle />}
          onClick={() => {
            setSelectedBehavior(null);
            setSelectedLocation(null);
            setSelectedCategory(null);
            setBehaviorsDropdown([]);
            setAddModalOpen(true);
          }}
        />
      </Stack>
      <InfoBox
        disabled={false}
        minWidth="70vw"
        open={addModalOpen}
        title={t("implementations.identifyCB")}
        content={renderAddView()}
        handleClose={() => {
          setAddModalOpen(false);
          //   setBehaviorsDropdown([]);
        }}
        buttonLabel={t("implementations.addLti")}
        handleAction={() => {
          submitLti();
        }}
      />
      {renderDeleteModal()}
    </>
  );
}

export default LtiList;
