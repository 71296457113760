import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// material
import { Stack, useTheme } from "@mui/material";
import { displayToast } from "../../../utils/general";
//  components
import TextInput from "../../inputs/TextInput";
import PrimaryButton from "../../buttons/PrimaryButton";
import AlertBox from "../../AlertBox";
// ----------------------------------------------------------------------
// API
import { validateOtpApi, sendOtpApiToken } from "../../../api/users";

export default function ValidateOtpForm() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const [details, setDetails] = useState({
    otp: "",
  });
  const [validateOtpTries, setValidateOtpTries] = useState(5);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleFormEntry = (e, type) => {
    switch (type) {
      case "enter":
        if (e.code === "Enter") {
          validateOtp(details);
        }
        break;

      case "otp":
        setDetails({ ...details, otp: e.target.value });
        setOtp(e.target.value);
        break;

      default:
        console.log("Please enter a valid password.");
    }
  };
  const sendOtp = async (formDetails) => {
    const activeToast = displayToast(setLoading, "loading", t("otp.onSendOtp"));
    try {
      await sendOtpApiToken(token);
      displayToast(
        setLoading,
        "success",
        t("otp.onOtpSendSuccess"),
        activeToast
      );
    } catch (err) {
      setLoading(false);
      if (err.message) {
        displayToast(setLoading, "error", err.message, activeToast);
        setError(err.message);
      } else {
        displayToast(setLoading, "error", t("otp.onOtpSendError"), activeToast);
        setError("There was some error sending OTP");
      }
    }
  };

  const validateOtp = async (formDetails) => {
    try {
      const response = await validateOtpApi(formDetails, token);
      if (response.message === "Otp matched") {
        displayToast(setLoading, "success", t("otp.onOtpVerfied"));
        navigate(`/resetPassword?token=${token}`);
      } else if (response.message === "Otp does not matched") {
        setValidateOtpTries((previous) => {
          if (previous > 0) {
            console.log(previous);
            return previous - 1;
          } else return previous;
        });
        if (validateOtpTries >= 0)
          displayToast(
            setLoading,
            "error",
            t("otp.onOtpMismatched", { count: validateOtpTries })
          );
        if (validateOtpTries === 0) navigate("/login");
      } else if (response.message === "Otp Expired. Please get a new one") {
        displayToast(setLoading, "error", t("otp.otpExpired"));
        navigate(`/login`);
      }
    } catch (err) {
      setLoading(false);
      if (err.message) {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    sendOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AlertBox open={error ? true : false} type="error" message={error} />
      <Stack spacing={3}>
        <TextInput
          label={t(`otp.enterOtp`)}
          placeholder={t(`otp.enterOtp`)}
          value={details.otp}
          disabled={loading}
          handler={(e) => handleFormEntry(e, "otp")}
          keyPressHandler={(e) => handleFormEntry(e, "enter")}
          labelColor={theme.palette.primary.white}
        />
        <PrimaryButton
          label={t(`otp.confirm`)}
          alignment="left"
          disabled={otp.length < 4 || otp.length > 4}
          onClick={() => validateOtp(details)}
        />
      </Stack>
    </>
  );
}
