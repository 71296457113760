/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { displayToast } from "../../utils/general";
// MUI
import { Grid, Container, Box } from "@mui/material";
import { Typography } from "@mui/material";
import GenerateIcon from "@mui/icons-material/FileOpenOutlined";
//  Components
import Page from "../../components/Page";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import SelectInput from "../../components/inputs/SelectInput";
import FileUploader from "../../components/inputs/FileUploader";
//  API
import { uploadBulkObsApi } from "../../api/observation";
import { getLocationsForObsApi } from "../../api/location";
import { getCompaniesUsingLocationApi } from "../../api/company";
import { getUnitsTiedApi } from "../../api/unit";
import { getCompanyDisciplinesApi } from "../../api/disciplines";
import { generateTemplateApi } from "../../api/template";

export const observationDetailsDefault = {
  observationType: "BEHAVIOR",
  company: "",
  directorate: "",
  location: "",
  unit: "",
  discipline: "",
  date: new Date(),
  unsafeConditions: [],
  observationSubType: "INTERVENTION",
  peopleObserved: 0,
};

export default function ObservationUpload() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [locationsDropdown, setLocationsDropdown] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [directorateDropdown, setDirectorateDropdown] = useState([]);
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [observationDetails, setObservationDetails] = useState(
    observationDetailsDefault
  );
  const [file, setFile] = useState([]);

  const getLocations = async () => {
    try {
      setLoading(true);
      const locationData = await getLocationsForObsApi();
      const locationOptions = locationData.map((c) => {
        return {
          label: c.name,
          value: c.id,
        };
      });

      setObservationDetails({
        ...observationDetails,
        location: "",
        company: "",
        unit: "",
        discipline: "",
        directorate: "",
      });

      setLocationsDropdown(locationOptions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return [];
    }
  };

  const getCompanies = async () => {
    try {
      setLoading(true);
      const companyData = await await getCompaniesUsingLocationApi(
        observationDetails.location
      );
      const companyOptions = companyData.map((c) => {
        return {
          label: c.name,
          value: c.id,
          directorates: c.directorates,
        };
      });
      setCompanyDropdown(companyOptions);

      setDirectorateDropdown([]);
      setUnitDropdown([]);
      setDisciplineDropdown([]);
      setObservationDetails({
        ...observationDetails,
        company: "",
        unit: "",
        discipline: "",
        directorate: "",
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getDirectorates = () => {
    const companyId = observationDetails.company;
    const targetCompany = companyDropdown.find((c) => c.value === companyId);
    let finalDArray = [];
    if (targetCompany && targetCompany.directorates) {
      finalDArray = targetCompany.directorates.map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });

      setDirectorateDropdown(finalDArray);
    }

    setUnitDropdown([]);
    setObservationDetails({
      ...observationDetails,
      unit: "",
      discipline: "",
    });
  };

  const getUnits = async () => {
    try {
      setObservationDetails({
        ...observationDetails,
        unit: "",
        discipline: "",
      });
      setLoading(true);
      const unitData = await getUnitsTiedApi(
        observationDetails.directorate,
        observationDetails.location,
        observationDetails.company
      );
      const unitOptions = unitData.map((u) => {
        return {
          label: u.name,
          value: u.id,
        };
      });
      setUnitDropdown(unitOptions);
      setLoading(false);
    } catch (err) {}
  };

  const getDisciplines = async () => {
    try {
      setLoading(true);
      setObservationDetails({
        ...observationDetails,
        discipline: "",
      });

      const disciplineData = await getCompanyDisciplinesApi(
        observationDetails.directorate,
        observationDetails.company,
        observationDetails.location,
        observationDetails.unit
      );

      const disciplineOptions = disciplineData.map((d) => {
        return {
          label: d.name,
          value: d.id,
          labelAR: d.disciplineAR,
        };
      });

      setDisciplineDropdown(disciplineOptions);
      setLoading(false);
    } catch (err) {}
  };

  const submitGenerateTemplate = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("bulkUploadObservations.generateLoading")
    );
    try {
      await generateTemplateApi(observationDetails);
      displayToast(
        setLoading,
        "success",
        t("bulkUploadObservations.generateSuccess"),
        activeToast
      );
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  const submitUpload = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("bulkUploadObservations.uploadLoading")
    );
    try {
      if (file.length === 0) {
        throw t("bulkUploadObservations.noFileError");
      }
      await uploadBulkObsApi({ attachments: file });
      displayToast(
        setLoading,
        "success",
        t("bulkUploadObservations.uploadSuccess"),
        activeToast
      );
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  //  On location dropdown change
  useEffect(() => {
    if (observationDetails.location) {
      getCompanies();
    }
  }, [observationDetails.location]);

  //  On location or company change
  useEffect(() => {
    if (observationDetails.location && observationDetails.company) {
      getDirectorates();
    }
  }, [observationDetails.location, observationDetails.company]);

  //  On directorate change
  useEffect(() => {
    if (observationDetails.directorate) {
      getUnits();
      getDisciplines();
    }
  }, [observationDetails.directorate]);

  //  On unit change
  useEffect(() => {
    if (observationDetails.unit !== "") {
      getDisciplines();
    }
  }, [observationDetails.unit]);

  return (
    <Page title={t("bulkUploadObservations.title")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">
              {t("bulkUploadObservations.title")}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {t("bulkUploadObservations.step1")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t("bulkUploadObservations.step1Text")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              label={t("bulkUploadObservations.locationDropdown")}
              value={observationDetails.location}
              disabled={loading || locationsDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  location: e.target.value,
                })
              }
              options={locationsDropdown}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              label={t("bulkUploadObservations.companyDropdown")}
              value={observationDetails.company}
              disabled={loading || companyDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  company: e.target.value,
                })
              }
              options={companyDropdown}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              label={t("bulkUploadObservations.directorateDropdown")}
              value={observationDetails.directorate}
              disabled={loading || directorateDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  directorate: e.target.value,
                })
              }
              options={directorateDropdown}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              optional
              label={t("bulkUploadObservations.unitDropdown")}
              value={observationDetails.unit}
              disabled={loading || unitDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  unit: e.target.value,
                })
              }
              options={unitDropdown}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              label={t("bulkUploadObservations.disciplineDropdown")}
              value={observationDetails.discipline}
              disabled={loading || disciplineDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  discipline: e.target.value,
                })
              }
              options={disciplineDropdown}
            />
          </Grid>
          <Grid item xs={12}>
            <SecondaryButton
              disabled={
                loading ||
                !observationDetails.company ||
                !observationDetails.location ||
                !observationDetails.discipline
              }
              icon={<GenerateIcon />}
              alignment="left"
              label={t("bulkUploadObservations.generateButton")}
              onClick={submitGenerateTemplate}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {t("bulkUploadObservations.step2")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t("bulkUploadObservations.step2Text")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FileUploader
              multiple={false}
              files={file}
              setFiles={setFile}
              disabled={loading}
              labelText={t("bulkUploadObservations.uploadButton")}
              acceptedFormats={[
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <PrimaryButton
              label={t("bulkUploadObservations.submitUploadFile")}
              alignment="left"
              disabled={loading}
              onClick={submitUpload}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
