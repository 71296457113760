import { toTitleCase } from "../../../utils/general";

const exampleMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

export const categoryDropdown = [
  {
    label: "People",
    value: "PEOPLE",
  },
  {
    label: "Location",
    value: "LOCATION",
  },
  {
    label: "Equipment",
    value: "TOOLS",
  },
  {
    label: "Behavior",
    value: "BEHAVIOR",
  },
];

export const bubbleChartOptions = {
  chart: {
    fontFamily: "Cairo, sans-serif",
    height: 350,
    type: "bubble",
  },
  plotOptions: {
    bubble: {
      zScaling: true,
      minBubbleRadius: 20,
      maxBubbleRadius: 40,
    },
  },
  colors: ["#0081A7", "#00AFB9", "#F0CEA0", "#F3A712", "#534D41"],
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 0.8,
  },
  xaxis: {
    min: 0,
    max: 100,
    tickAmount: 12,
    type: "category",
    title: {
      text: "Safe Score",
      offsetY: -20,
      style: {
        fontWeight: 600,
      },
    },
  },
  yaxis: {
    max: 100,
    min: -100,
    title: {
      text: "Sentiment",
      style: {
        fontWeight: 600,
      },
    },
  },
  legend: {
    offsetY: -10,
  },
  tooltip: {
    x: {
      show: true,
      formatter: (value) => `Safe Score: ${value}`,
    },
    y: {
      title: {
        formatter: () => "",
      },
      formatter: (value, { seriesIndex, w }) => `Sentiment: ${value}`,
    },
    z: {
      title: " Unsafe Cards: ",
    },
  },
};

export const generateBubbleData = () => {
  return [
    {
      name: "Bubble1",
      data: [0.24, 100, 8],
    },
    {
      name: "Bubble2",
      data: [
        [
          Math.floor(Math.random() * 100) + 1,
          Math.floor(Math.random() * (100 - 0 + 1)),
          Math.floor(Math.random() * (100 - 0 + 1)),
        ],
      ],
    },
    {
      name: "Bubble3",
      data: [
        [
          Math.floor(Math.random() * 100) + 1,
          Math.floor(Math.random() * (100 - 0 + 1)),
          Math.floor(Math.random() * (100 - 0 + 1)),
        ],
      ],
    },
    {
      name: "Bubble4",
      data: [
        [
          Math.floor(Math.random() * 100) + 1,
          Math.floor(Math.random() * (100 - 0 + 1)),
          Math.floor(Math.random() * (100 - 0 + 1)),
        ],
      ],
    },
    {
      name: "Bubble5",
      data: [
        [
          Math.floor(Math.random() * 100) + 1,
          Math.floor(Math.random() * (100 - 0 + 1)),
          Math.floor(Math.random() * (100 - 0 + 1)),
        ],
      ],
    },
  ];
};

export const tableRows = [
  {
    id: "OBS-2021-285830",
    label: "EMPLOYEE",
    text: "tea boy",
    observation: `observed tea boy was holing the base with bare hand while the electricity switch was on`,
  },
  {
    id: "OBS-2021-285830",
    label: "GPE",
    text: "galfar",
    observation: `persone from galfar he was up the tanker when he was fulling the tanker and not wearing ahelmet`,
  },
  {
    id: "OBS-2021-285830",
    label: "FAC",
    text: "fahud airport",
    observation: `during my visited fahud airport rehabilitation project, i found numbers of employees not maintaining physical distancing as per covid-19 mitigatin...`,
  },
  {
    id: "OBS-2021-285830",
    label: "CHEMICALS",
    text: "chemical",
    observation: `observed a trailer driver parking inside the chemical yard without using the shocks`,
  },
  {
    id: "OBS-2021-285830",
    label: "ORG",
    text: "oto driver",
    observation: `observed oto driver walking near the laoding/unlading area with out wearing a proper shoe`,
  },
];

export const generateLineData = () => {
  return exampleMonths.map((m) => {
    return Math.random().toFixed(0) * 100;
  });
};

export const lineChartOptions = {
  series: [
    {
      name: "Rig floor",
      data: generateLineData(),
    },
    {
      name: "Room boy",
      data: generateLineData(),
    },
    {
      name: "Wash gun",
      data: generateLineData(),
    },
  ],
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  colors: ["#3D399F", "#B045B1", "#F19475"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  title: {
    show: false,
  },
  xaxis: {
    categories: exampleMonths,
  },
};
