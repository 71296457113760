import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

HeatmapChart.propTypes = {
  chartOptions: PropTypes.object,
  chartData: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
};

const heatMapOptions = {
  chart: {
    fontFamily: "Cairo, sans-serif",
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    heatmap: {
      enableShades: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 65,
            color: "#FF0000",
          },
          {
            from: 65,
            to: 85,
            color: "#FDD600",
          },
          {
            from: 85,
            to: 100,
            color: "#00B050",
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter: (val) => `${val}%`,
    style: {
      fontSize: "14px",
    },
  },
  tooltip: {
    y: {
      formatter: (val) => `${val}%`,
    },
  },
  xaxis: {
    type: "category",
    position: "top",
  },
  axisTicks: {
    show: false,
  },
  labels: {
    show: true,
  },
  legend: {
    show: false,
    position: "bottom",
  },
  yaxis: {
    labels: {
      style: {
        cssClass: "quality-score-heatmap-yaxis-label",
      },
    },
  },
};

export default function HeatmapChart(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [chartOptions, setChartOptions] = useState(heatMapOptions);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (props.demo && props.demo === true) {
      setChartData([
        {
          name: "A",
          id: 1,
          data: [
            {
              x: "Jan/23",
              y: 0,
              id: 1,
            },
            {
              x: "Feb/23",
              y: 30,
              id: 1,
            },
            {
              x: "Mar/23",
              y: 65,
              id: 1,
            },
            {
              x: "Apr/23",
              y: 84,
              id: 1,
            },
            {
              x: "May/23",
              y: 90,
              id: 1,
            },
            {
              x: "June/23",
              y: 95,
              id: 1,
            },
          ],
        },
        {
          name: "B",
          id: 2,
          data: [
            {
              x: "Jan/23",
              y: 0,
              id: 2,
            },
            {
              x: "Feb/23",
              y: 30,
              id: 2,
            },
            {
              x: "Mar/23",
              y: 65,
              id: 2,
            },
            {
              x: "Apr/23",
              y: 84,
              id: 2,
            },
            {
              x: "May/23",
              y: 90,
              id: 2,
            },
            {
              x: "June/23",
              y: 95,
              id: 2,
            },
          ],
        },
        {
          name: "C",
          id: 3,
          data: [
            {
              x: "Jan/23",
              y: 0,
              id: 3,
            },
            {
              x: "Feb/23",
              y: 30,
              id: 3,
            },
            {
              x: "Mar/23",
              y: 65,
              id: 3,
            },
            {
              x: "Apr/23",
              y: 84,
              id: 3,
            },
            {
              x: "May/23",
              y: 90,
              id: 3,
            },
            {
              x: "June/23",
              y: 95,
              id: 3,
            },
          ],
        },
        {
          name: "D",
          id: 4,
          data: [
            {
              x: "Jan/23",
              y: 0,
              id: 4,
            },
            {
              x: "Feb/23",
              y: 30,
              id: 4,
            },
            {
              x: "Mar/23",
              y: 65,
              id: 4,
            },
            {
              x: "Apr/23",
              y: 84,
              id: 4,
            },
            {
              x: "May/23",
              y: 90,
              id: 4,
            },
            {
              x: "June/23",
              y: 95,
              id: 4,
            },
          ],
        },
      ]);
    }
  }, [props.demo]);

  useEffect(() => {
    if (props.chartData && props.chartOptions) {
      setChartOptions(props.chartOptions);
      setChartData(props.chartData);
    }
  }, [props.chartOptions, props.chartData]);

  if ((chartData && chartOptions) || props.demo) {
    return (
      <div
        style={{
          height: props.height || "88%",
          width: "100%",
          direction: "ltr",
        }}
      >
        <ReactApexChart
          type="heatmap"
          options={chartOptions}
          series={chartData}
          height={props.height || "88%"}
          width={"100%"}
        />
      </div>
    );
  } else {
    return t("general.loading");
  }
}
