import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import Page from "../../../components/Page";
import { CalenderVisit } from "./CalenderVisit";
import VisitManagement from "./VisitManagement";
import TabsBar from "../../../components/navigation/TabsBar";

function VisitMenu(props) {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("calendarVisit");
  return (
    <Page title={t("implementations.schedule.visitsManagement")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {props?.type === "Leadership Visibility"
                ? t("implementations.schedule.leadership")
                : t("implementations.schedule.steeringCommittee")}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TabsBar
              value={tabValue}
              handler={(event, value) => setTabValue(value)}
              tabs={[
                {
                  label: t("implementations.schedule.calender"),
                  value: 0,
                  key: "calendarVisit",
                },
                {
                  label:
                    props?.type === "Leadership Visibility"
                      ? t("implementations.schedule.visitManagement")
                      : t("implementations.schedule.steeringMeetings"),
                  value: 0,
                  key: "visitManagement",
                },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            {tabValue === "calendarVisit" && (
              <CalenderVisit type={props?.type} />
            )}
            {tabValue === "visitManagement" && (
              <VisitManagement type={props?.type} />
            )}
            {/* {tabValue === "three" && <UserTrainings/>} */}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default VisitMenu;
