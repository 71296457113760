import React, { useState, useEffect } from "react";
import { displayToast } from "../../utils/general";
import { Grid, Tab, Tabs, FormControlLabel, Checkbox } from "@mui/material";
import { getChartDefaults, getChartEnums, renderChart } from "./utils";
import PieChartIcon from "@mui/icons-material/PieChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import TocIcon from "@mui/icons-material/Toc";
import TableRowsIcon from "@mui/icons-material/TableRows";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import MultiSelectInput from "../../components/inputs/MultiSelectInput";
import SelectInput from "../../components/inputs/SelectInput";
import { getLocationKpiFilters } from "../../api/pdoDashboards";
import DateInput from "../../components/inputs/DateInput";
import moment from "moment";
import { KPI_ALL, KPI_TYPES, KPI_UNIT_WISE } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

function EditChart(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [tableType, setTableType] = useState(false);
  const [kpiLocationType, setKpiLocationType] = useState(false);
  const [selectedTableColumns, setSelectedTableColumns] = useState([]);
  const [tableOptions, setTableOptions] = useState([
    { label: "BU Name", value: "BU Name" },
    { label: "# Employees", value: "# Employees" },
    { label: "# Cards", value: "# Cards" },
  ]);

  const [kpiDirectorates, setKpiDirectorates] = useState([]);
  const [kpiCompanies, setKpiCompanies] = useState([]);
  const [kpiLocations, setKpiLocations] = useState([]);
  // made separate filter select states to handle better useEffect
  const [startDate, setStartDate] = useState(moment().startOf("year"));
  const [endDate, setEndDate] = useState(moment().startOf("day"));
  const [selectedDirectorate, setSelectedDirectorate] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isAudit, setIsAudit] = useState("false");
  const [datesEnabled, setDatesEnabled] = useState(false);
  const [companyWide, setCompanyWide] = useState(false);
  const [selectedKpis, setSelectedKpis] = useState([]);
  const [specificChartName, setSpecificChartName] = useState("");

  const [selectedTab, setSelectedTab] = useState(0);

  const auditDropdown = [
    {
      label: t("general.yes"),
      value: "true",
    },
    {
      label: t("general.no"),
      value: "false",
    },
  ];

  const checkChartType = (chartName) => {
    if (chartName?.split("Pie")?.length > 1) {
      return {
        label: "Pie Chart",
        value: "Pie",
      };
    } // Check if Chart name consists Location unit KPI type
    else if (chartName === "locationKpisMultiline") {
      setKpiLocationType(true);
      fetchLocationsFilters("directorate");
      return {
        label: "MultiLine Chart",
        value: "Multiline",
      };
    }
    // Check if Chart name consists of Company wide chart type (KPI)
    else if (chartName === "companyKpisKpiColumn") {
      setCompanyWide(true);
      setKpiLocationType(true);
      fetchLocationsFilters("directorate");
      return {
        label: "Column Chart",
        value: "Column",
      };
    }
    //check if column chart
    else if (chartName?.split("Column")?.length > 1) {
      return {
        label: "Column Chart",
        value: "Column",
      };
    }
    //check if stacked chart
    else if (chartName?.split("Stacked")?.length > 1) {
      return {
        label: "Stacked Chart",
        value: "Stacked",
      };
    }
    //check if multiline chart
    else if (chartName?.split("Multiline")?.length > 1) {
      return {
        label: "MultiLine Chart",
        value: "Multiline",
      };
    }
    //check if counter chart
    else if (chartName?.split("Counter")?.length > 1) {
      return {
        label: "Counter Chart",
        value: "Counter",
      };
    } else if (chartName?.split("Table")?.length > 1) {
      setTableType(true);
      if (props?.data?.chartObject?.filters?.showLabels?.length > 0) {
        let tempSelected = [];
        props?.data?.chartObject?.filters?.showLabels.forEach((entry) => {
          let myIndex = tableOptions.findIndex((i) => i.label === entry);
          if (myIndex > -1) tempSelected.push(tableOptions[myIndex]);
        });
        setSelectedTableColumns(tempSelected);
      }
      return {
        label: "Table Chart",
        value: "CountTable",
      };
    } else {
      return false;
    }
  };

  const fetchLocationsFilters = async (type) => {
    displayToast(
      setLoading,
      "pureLoading",
      t("observations.observationDetails.loading")
    );

    try {
      if (type === "directorate") {
        setSelectedDirectorate(null);
        setSelectedCompany(null);
        setSelectedLocation(null);
        let directoratesReq = await getLocationKpiFilters(``);
        if (directoratesReq?.directorates?.length > 0) {
          setKpiDirectorates(directoratesReq?.directorates);
        }
      } else if (type === "company") {
        setSelectedCompany(null);
        setSelectedLocation(null);
        let companiesReq = await getLocationKpiFilters(
          `directorateId=${selectedDirectorate}`
        );
        if (companiesReq?.companies?.length > 0) {
          setKpiCompanies(companiesReq?.companies);
        }
      } else if (type === "location") {
        setSelectedLocation(null);
        let locationsReq = await getLocationKpiFilters(
          `directorateId=${selectedDirectorate}&companyId=${selectedCompany}`
        );
        if (locationsReq?.locations?.length > 0) {
          setKpiLocations(locationsReq?.locations);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDirectorate) fetchLocationsFilters("company");
  }, [selectedDirectorate]);

  useEffect(() => {
    if (selectedCompany) fetchLocationsFilters("location");
  }, [selectedCompany]);

  useEffect(() => {
    let selectedChart = checkChartType(props?.data?.chartObject?.type);
    setSpecificChartName(props?.data?.chartObject?.type);
    setSelectedType(selectedChart?.value);
    let chartArrays = getChartEnums();
    let apiAlias = props?.data?.chartObject?.api;
    let tempTypes = [];
    for (let i = 0; i < chartArrays.length; i++) {
      let chartDefault = getChartDefaults(chartArrays[i]);
      if (chartDefault?.api === apiAlias) {
        let chartType = checkChartType(chartArrays[i]);
        if (chartType !== false) tempTypes.push(chartType);
      }
    }
    let findIndex = tempTypes
      .map((entry) => entry.value)
      .indexOf(selectedChart?.value);
    setSelectedTab(findIndex);
    setTypes([...tempTypes]);
  }, []);

  return (
    <Grid container spacing={2}>
      {types.length > 1 && (
        <Grid
          style={{ display: "flex", justifyContent: "center" }}
          item
          xs={12}
        >
          <div style={{ width: "550px" }}>
            {renderChart(
              `${props?.data?.chartObject?.api}${selectedType}`,
              null,
              null,
              [],
              false
            )}
          </div>
        </Grid>
      )}
      {types?.length > 1 ? (
        <>
          <Grid
            style={{ display: "flex", justifyContent: "center" }}
            item
            xs={12}
          >
            {/* <SelectInput
              label={"Select Chart Type"}
              value={selectedType}
              //   disabled={loading}
              handler={(e) => setSelectedType(e.target.value)}
              options={types}
            /> */}
            <Tabs
              value={selectedTab}
              onChange={(e, v) => {
                setSelectedTab(v);
                setSelectedType(types[v].value);
              }}
              aria-label="icon label tabs example"
            >
              {types.map((entry) => (
                <Tab
                  icon={
                    entry.value === "Pie" ? (
                      <PieChartIcon />
                    ) : entry.value === "Column" ? (
                      <BarChartIcon />
                    ) : entry.value === "Stacked" ? (
                      <StackedBarChartIcon />
                    ) : entry.value === "Multiline" ? (
                      <SsidChartIcon />
                    ) : entry.value === "Counter" ? (
                      <TocIcon />
                    ) : (
                      <TableRowsIcon />
                    )
                  }
                  label={entry.value}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <PrimaryButton
              label={t("dashboards.editChart")}
              alignment="left"
              onClick={() => {
                props?.onAction(
                  props?.data,
                  selectedType,
                  selectedTableColumns
                );
              }}
              disabled={types?.length < 2}
            />
          </Grid>
        </>
      ) : (
        <>
          {tableType ? (
            <>
              <Grid item xs={12}>
                <MultiSelectInput
                  label={t("dashboards.tableColumns")}
                  disabled={false}
                  options={tableOptions}
                  handler={(newValue) => setSelectedTableColumns(newValue)}
                  values={selectedTableColumns}
                />
              </Grid>
              <Grid item xs={12}>
                <PrimaryButton
                  label={t("dashboards.editChart")}
                  alignment="left"
                  onClick={() => {
                    props?.onAction(
                      props?.data,
                      selectedType,
                      selectedTableColumns,
                      true
                    );
                  }}
                  disabled={tableType === true ? false : true}
                />
              </Grid>
            </>
          ) : kpiLocationType ? (
            <>
              <Grid item xs={12}>
                <SelectInput
                  label={`${t(
                    "observations.viewObservations.filters.directorate"
                  )}*`}
                  disabled={false || kpiDirectorates?.length === 0 || loading}
                  options={kpiDirectorates}
                  handler={(newValue) =>
                    setSelectedDirectorate(newValue?.target?.value)
                  }
                  value={selectedDirectorate}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  label={`${t(
                    "observations.viewObservations.filters.company"
                  )}*`}
                  disabled={false || kpiCompanies?.length === 0 || loading}
                  options={kpiCompanies}
                  handler={(newValue) =>
                    setSelectedCompany(newValue?.target?.value)
                  }
                  value={selectedCompany}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  label={
                    specificChartName === "locationKpisMultiline"
                      ? t("kpiDashboards.locationFilterMandatory")
                      : t("observations.viewObservations.filters.location")
                  }
                  disabled={false || kpiLocations?.length === 0 || loading}
                  options={kpiLocations}
                  handler={(newValue) => {
                    setSelectedLocation(newValue?.target?.value);
                  }}
                  value={selectedLocation}
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectInput
                  label={t("kpiDashboards.kpiTypes")}
                  disabled={false}
                  options={
                    companyWide
                      ? KPI_ALL.map((entry) => {
                          return {
                            label: KPI_TYPES[entry],
                            value: entry,
                          };
                        })
                      : KPI_UNIT_WISE.map((entry) => {
                          return {
                            label: KPI_TYPES[entry],
                            value: entry,
                          };
                        })
                  }
                  handler={(newValue) => setSelectedKpis(newValue)}
                  values={selectedKpis}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectInput
                  label={t("kpi.includeAudit")}
                  disabled={false}
                  options={auditDropdown}
                  handler={(newValue) => setIsAudit(newValue?.target?.value)}
                  value={isAudit}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={datesEnabled}
                      name={"filterDates"}
                      onChange={() => setDatesEnabled((prev) => !prev)}
                      sx={{
                        color: theme.palette.primary.orange,
                        "&.Mui-checked": {
                          color: theme.palette.primary.orange,
                        },
                        "&:hover": {
                          backgroundColor: "rgba(248,147,31,0.08)",
                        },
                      }}
                    />
                  }
                  disabled={loading}
                  label={t(`observations.viewObservations.filters.dateRange`)}
                />
              </Grid>
              <Grid item xs={12}>
                <DateInput
                  label={t("kpiDashboards.startDate")}
                  date={startDate}
                  handler={(newDate) => setStartDate(newDate)}
                  monthView={true}
                  disabled={!datesEnabled}
                />
              </Grid>
              <Grid item xs={12}>
                <DateInput
                  label={t("kpiDashboards.endDate")}
                  date={endDate}
                  handler={(newDate) => setEndDate(newDate)}
                  monthView={true}
                  disabled={!datesEnabled}
                />
              </Grid>
              <Grid item xs={12}>
                <PrimaryButton
                  label={t("dashboards.editChart")}
                  alignment="left"
                  onClick={() => {
                    props?.onActionLocationKpi(props?.data, {
                      locationId: selectedLocation,
                      companyId: selectedCompany,
                      directorateId: selectedDirectorate,
                      startDate: startDate?.format("MM-YYYY"),
                      endDate: endDate?.format("MM-YYYY"),
                      audit: isAudit,
                      datesEnabled,
                      selectedKpis,
                    });
                  }}
                  disabled={
                    loading ||
                    (specificChartName === "locationKpisMultiline" &&
                      !selectedLocation) ||
                    (specificChartName === "companyKpisKpiColumn" &&
                      !selectedCompany)
                  }
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              {t("dashboards.noEditOptions")}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default EditChart;
