import apiHelper from "./apiHelper";

export const updateContractorApi = (planId, companyId, userId, payload) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/implementations/${planId}/contractors/${userId}?companyId=${companyId}`, payload)
      .then((res) => {
        resolve(res.data.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
