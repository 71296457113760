import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
//  MUI
import { useTheme } from "@mui/material/styles";
import { Box, Container, Grid, Typography, Stack } from "@mui/material";
//  Components
import Page from "../../components/Page";
import DefaultTable from "../../components/tables/DefaultTable";
import { displayToast } from "../../utils/general";
import InfoBox from "../../components/InfoBox";
//  API
import { getNotificationsApi } from "../../api/notifications";

const infoBoxDefaults = {
  open: false,
  message: "",
  id: "",
};

export default function ListNotifications() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [notificationRows, setNotificationRows] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterParams, setFilterParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const [infoDetails, setInfoDetails] = useState(infoBoxDefaults);

  const tasksColumnDefaults = [
    // { field: "id", headerName: t("table.id"), width: 100 },
    {
      field: "filledMessage",
      headerName: t("table.notification"),
      width: 650,
      sortable: false,
    },
    {
      field: "eventName",
      headerName: t("table.observationType"),
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return params.row.eventName.replace("_", " ");
      },
    },
    {
      field: "createdAt",
      headerName: t("table.notificationTime"),
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return moment(params.row.createdAt).format("DD/MM/YYYY hh:mm a");
      },
    },
    {
      field: "readAt",
      headerName: t("table.readAt"),
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return moment(params.row.readAt).format("DD/MM/YYYY hh:mm a");
      },
    },
  ];

  const getNotificationList = async () => {
    try {
      setLoading(true);

      const notificationsData = await getNotificationsApi("", 1, filterParams);
      setNotificationRows(notificationsData.notifications);

      setTableInfo({
        totalCount: notificationsData.totalCount,
        totalPages: notificationsData.totalPages,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "error", err);
    }
  };

  useEffect(() => {
    getNotificationList();
  }, [filterParams]);

  const handleNotificationClick = async (n) => {
    try {
      setLoading(true);
      //  Update notification if not read
      //  Redirect to relevant page
      const eventName = n.row.eventName || "";
      let link = "";

      switch (eventName) {
        case "ACTION_ASSIGNED":
        case "REASSIGNED":
        case "ACTION_RETURNED_SUP":
          if (n.row.observation && n.row.resourceId) {
            link = `/actions/details/${n.row.observation}/${n.row.resourceId}`;
          }
          break;

        case "CLOSED_NO_ACTION":
        case "OBSERVATION_SUBMITTED":
        case "RETURNED_TO_OBSERVER":
        case "SUP_REJECTED":
          if (n.row.resourceId) {
            link = `/observations/details/${n.row.resourceId}`;
          }
          break;

        case "TRAINING_INVITE":
          if (n.row.resourceId && n.row.taskId && n.row.userId) {
            link = `/training/invite/${n.row.taskId}/${n.row.resourceId}/${n.row.userId}`;
          }
          break;

        case "SUSTAINABILITY_REVIEW_ASSIGNED":
          if (n.row.resourceId) {
            const usableId = n.row.resourceId.split("#");
            if (usableId[0]) {
              link = `/review/view/${usableId[0]}`;
            }
          }
          break;

        default:
          break;
      }
      if (
        eventName === "LESSON_LEARNT" &&
        n.row.lessonLearntDescription &&
        n.row.observationId
      ) {
        setInfoDetails({
          open: true,
          message: n.row.lessonLearntDescription,
          id: n.row.observationId,
        });
        setLoading(false);
      } else if (link) {
        navigate(link);
        setLoading(false);
      } else {
        displayToast(
          setLoading,
          "pureError",
          t("navBar.notifications.linkError")
        );
      }
    } catch (err) {
      console.log("err----------", err);
      setLoading(false);
    }
  };

  return (
    <Page title={t("navBar.notifications.title")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div>
            <Typography variant="h4">
              {t("navBar.notifications.title")}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={2} style={{ paddingTop: "20px" }}>
          <Grid item xs={12}>
            <DefaultTable
              rows={notificationRows}
              columns={tasksColumnDefaults}
              loading={loading}
              tableInfo={tableInfo}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              minHeight="75vh"
              topTextContent={
                <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
                  {filterParams.searchTerm && (
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.grey,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.searchResults")} "${
                      filterParams.searchTerm
                    }" `}</Typography>
                  )}
                  <Typography
                    variant="body2"
                    style={{
                      color: theme.palette.primary.orange,
                      fontWeight: "bold",
                    }}
                  >{`${t("table.totalListed")} ${
                    tableInfo.totalCount
                  } `}</Typography>
                </Stack>
              }
              onRowClick={handleNotificationClick}
            />
          </Grid>
        </Grid>
      </Container>
      <InfoBox
        open={infoDetails.open}
        compact
        title={infoDetails.id}
        handleClose={() => setInfoDetails(infoBoxDefaults)}
        content={
          <Stack>
            <Typography variant="h6" style={{ whiteSpace: "pre-wrap" }}>
              {`${t("table.lessonLearnt")}:`}
            </Typography>
            <Typography variant="body" style={{ whiteSpace: "pre-wrap" }}>
              {infoDetails.message}
            </Typography>
          </Stack>
        }
        handleAction={() => setInfoDetails(infoBoxDefaults)}
        buttonLabel={t("general.close")}
      />
    </Page>
  );
}
