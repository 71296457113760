import apiHelper from "./apiHelper";

export const getDirectoratesApi = (pageNo = 1, pageSize = 9999) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/directorates?pageNo=${pageNo}&pageSize=${pageSize}`)
      .then((res) => {
        resolve(res.data.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getImplementationDirectoratesApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/implementations/directorates`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
