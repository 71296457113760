// 'Defaults'

export const filterModalDefaults = {
  open: false,
  tab: "filterType",
  allKnowledges: {
    fileType: "All",
  },
  allAudios: {
    fileType: "Audios",
  },
  allVideos: {
    fileType: "Videos",
  },
  allDocuments: {
    fileType: "Documents",
  },
  allWebLinks: {
    fileType: "Web Links",
  },
};

export const knowledgeFilterTypesDefault = [
  { label: "Audio", value: "audio" },
  { label: "Video", value: "video" },
  { label: "Document", value: "document" },
  { label: "Web Link", value: "webLink" },
];
