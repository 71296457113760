import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PrimaryButton from "./buttons/PrimaryButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

InfoBox.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAction: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.node,
  buttonLabel: PropTypes.string,
  noClose: PropTypes.bool,
  disabled: PropTypes.bool,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  compact: PropTypes.bool,
  overflowV: PropTypes.bool,
};

const CloseIconStyled = styled(CancelOutlinedIcon)(({ theme }) => ({
  height: "22px",
  width: "22px",
  color: theme.palette.primary.red,
  cursor: "pointer",
}));

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  padding: "16px 24px 24px 24px",
}));

export default function InfoBox({
  open,
  handleClose,
  handleAction,
  title,
  content,
  buttonLabel,
  noClose,
  disabled,
  minWidth,
  minHeight,
  overflowV,
  compact = true,
}) {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableclose={noClose}
      PaperProps={{
        style: {
          minWidth: minWidth ? `${minWidth}` : "400px",
          ...(minHeight ? { minHeight } : {}),
          ...(overflowV ? { overflow: "visible" } : {}),
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          {title && <Typography variant="h5">{title}</Typography>}
          {noClose ? null : (
            <CloseIconStyled onClick={handleClose} disabled={disabled} />
          )}
        </Stack>
      </DialogTitle>
      <DialogContent
        style={{
          marginTop: compact ? 0 : "24px",
          ...(buttonLabel ? {} : { paddingBottom: "16px" }),
          ...(overflowV ? { overflow: "visible" } : {}),
        }}
      >
        {content}
      </DialogContent>
      {buttonLabel && (
        <DialogActionsStyled>
          <PrimaryButton
            label={buttonLabel}
            alignment="left"
            onClick={handleAction}
            disabled={disabled}
          />
        </DialogActionsStyled>
      )}
    </Dialog>
  );
}
