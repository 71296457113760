import apiHelper from "./apiHelper";

export const getTranslations = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/localize/dictionary`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};
