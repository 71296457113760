import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { returnValidUrl } from "../utils/general";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container, Stack } from "@mui/material";
// components
import { MotionContainer, varBounceIn } from "../components/animate";
import Page from "../components/Page";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function PageExit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <RootStyle title={t("pageExit.title")}>
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                {t("pageExit.notice")}
              </Typography>
            </motion.div>
            <Typography sx={{ color: "text.secondary" }}>
              {t("pageExit.subText", { url: state?.exitUrl || "This url" })}
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_leaving.png"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <Stack spacing={1} direction="row" justifyContent="center">
              <Button
                size="large"
                onClick={() => navigate(-1)}
                variant="contained"
              >
                {t("pageExit.goBack")}
              </Button>

              <Button
                size="large"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  if (!state && !state.exitUrl) {
                    navigate("404");
                  } else {
                    window.location.href = returnValidUrl(state.exitUrl);
                  }
                }}
              >
                {t("pageExit.proceed")}
              </Button>
            </Stack>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
