import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, Stack, IconButton, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextInput from "./TextInput";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

TableSearch.propTypes = {
  searchTerm: PropTypes.string,
  handler: PropTypes.func,
  disabled: PropTypes.bool,
  submitSearch: PropTypes.func,
  clearable: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  clearFunction: PropTypes.func,
};

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  height: "43px",
  width: "43px",
  borderRadius: "10px",
  background: theme.palette.primary.grey,
  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverGrey,
  },
}));

const IconStyled = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function TableSearch({
  searchTerm,
  handler,
  disabled,
  submitSearch,
  clearable,
  clearHandler,
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <Stack spacing={2} direction="row" alignItems="center">
        <TextInput
          label={""}
          disabled={disabled}
          value={searchTerm}
          handler={handler}
          placeholder={t("general.search")}
          keyPressHandler={(e) => {
            if (e.code === "Enter") {
              submitSearch();
            }
          }}
          InputProps={{
            endAdornment: clearable ? (
              <IconButton onClick={clearHandler} disabled={disabled}>
                <ClearIcon />
              </IconButton>
            ) : undefined,
            style: { paddingRight: 0 },
          }}
        />
        <Tooltip title={t("general.search")}>
          <IconButtonStyled
            aria-label={"Submit search button"}
            onClick={submitSearch}
          >
            <IconStyled />
          </IconButtonStyled>
        </Tooltip>
      </Stack>
    </Box>
  );
}
