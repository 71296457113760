import { Navigate, useRoutes } from "react-router-dom";
import useAuth from "./context/useAuth";
import AccessControl from "./components/AccessControl";
import { accessList } from "./utils/acl";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
// pages
import Login from "./pages/Login";
import DashboardApp from "./pages/dashboard/DashboardApp";
import NotFound from "./pages/Page404";
import PageExit from "./pages/PageExit";
import SustainabilityReview from "./pages/SustainabilityReview";
import ListTasks from "./pages/tasks/ListTasks";
import UserProfile from "./pages/UserProfile";
import ChangePassword from "./pages/ChangePassword";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RedirectPage from "./pages/RedirectPage";
//  Dashboard
import AiDashboards from "./pages/dashboard/AiDashboards";
import DirectorateDashboard from "./pages/dashboard/directorateDashboard/DirectorateDashboard";
import CompanyDashboard from "./pages/dashboard/companyDashboards/CompanyDashboard";
import KPIDashboards from "./pages/dashboard/KPIDashboards";
// Knowledge Base
import ListKnowledge from "./pages/knowledge/ListKnowledge";
import KnowledgeDetails from "./pages/knowledge/KnowledgeDetails";
//  Observation
import ObservationApp from "./pages/observation/ObservationApp/ObservationApp";
import ListObservations from "./pages/observation/ListObservations/ListObservations";
import ObservationDetails from "./pages/observation/ObservationDetails";
import AssignToParty from "./pages/observation/AssignToParty";
import ActionDetails from "./pages/actions/ActionDetails";
import ObservationCard from "./pages/observation/ObservationCard";
import ObservationUpload from "./pages/observation/ObservationUpload";
//  Implementation
import CreateImplementation from "./pages/implementation/CreateImplementation";
import ListImplementation from "./pages/implementation/ListImplementation";
import ListCompanyImplementations from "./pages/implementation/ListCompanyImplementations";
import ActivityDetails from "./pages/implementation/ActivityDetails";
import { ContractorDirectory } from "./pages/implementation/Management/ContractorDirectory";
import { IdentifyCriticalBehaviors } from "./pages/implementation/Management/IdentifyCriticalBehaviors";
import { KickOffMeeting } from "./pages/implementation/Management/KickOffMeeting";
import { SustainabilityReviewsActivity } from "./pages/implementation/Management/SustainabilityReviewsActivity";
import { IdentifyFocalPoints } from "./pages/implementation/Management/IdentifyFocalPoints";
import { ManageTemplate } from "./pages/implementation/Management/ManageTemplate";
//  Reporting
import ReportView from "./pages/reporting/ReportView";
import ObservationsApp from "./pages/dashboard/ObservationsApp";
import CreateReport from "./pages/reporting/CreateReport";
import ScheduleList from "./pages/reporting/Schedule/ScheduleList";
import ReportCreator from "./pages/reporting/ReportCreator";
import ScheduledReport from "./pages/reporting/ViewScheduledReport";
// Training
import { TrainingSchedule } from "./pages/implementation/Management/TrainingSchedule";
import TrainingManage from "./pages/implementation/Management/TrainingComponents/TrainingManage";
import TrainingConclude from "./pages/implementation/Management/TrainingComponents/TrainingConclude";
import TrainingInvite from "./pages/training/TrainingInvite";
import TrainingList from "./pages/training/TrainingList";
import TrainingDetails from "./pages/training/TrainingDetails";
import TrainingOptFor from "./pages/training/TrainingOptFor";
import KPICompanyDashboard from "./pages/dashboard/companyDashboards/KPICompanyDashboard";
import VisitMenu from "./pages/implementation/VisitMenu/VisitMenu";
import { MeetingDetails } from "./pages/implementation/VisitMenu/MeetingDetails";
import { KpiManagement } from "./pages/implementation/KpiConfigurations/KpiManagement";
import ListActions from "./pages/actions/ListActions/ListActions";
import ListNotifications from "./pages/notifications/ListNotifications";
import AddLti from "./pages/implementation/LTI/AddLti";
import ListScheduledReports from "./pages/reporting/ListScheduledReports";

// ----------------------------------------------------------------------

export default function Router() {
  const { token } = useAuth();

  const privateRoute = () => {
    if (token) {
      return <DashboardLayout />;
    } else {
      return <Navigate to="/login" />;
    }
  };

  return useRoutes([
    {
      path: "/auth/redirect",
      element: <RedirectPage />,
    },
    {
      path: "/user",
      element: privateRoute(),
      children: [
        { element: <Navigate to="/user/profile" replace /> },
        { path: "profile", element: <UserProfile /> },
        { path: "changePassword", element: <ChangePassword /> },
      ],
    },
    {
      path: "/tasks",
      element: privateRoute(),
      children: [
        { element: <Navigate to="/tasks/list" replace /> },
        { path: "list", element: <ListTasks /> },
      ],
    },
    {
      path: "/library",
      element: privateRoute(),
      children: [
        { element: <Navigate to="/library/list" replace /> },
        { path: "list", element: <ListKnowledge /> },
        { path: "details/:id", element: <KnowledgeDetails /> },
      ],
    },
    {
      path: "/notifications",
      element: privateRoute(),
      children: [
        { element: <Navigate to="/notifications/list" replace /> },
        { path: "list", element: <ListNotifications /> },
      ],
    },
    {
      path: "/dashboard",
      element: privateRoute(),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <DashboardApp /> },
        { path: "app/rollout", element: <DashboardApp /> },
        { path: "app/observation", element: <DashboardApp /> },
        { path: "app/observations", element: <ObservationsApp /> },
        {
          path: "aiDashboards",
          element: (
            <AccessControl isRoute {...accessList.aiDashboards}>
              <AiDashboards />
            </AccessControl>
          ),
        },
        {
          path: "directorate-dashboard/:tab/:directorateId",
          element: (
            <AccessControl isRoute {...accessList.aiDashboards}>
              <DirectorateDashboard />
            </AccessControl>
          ),
        },
        {
          path: "company-dashboard/:tab/:companyId",
          element: (
            <AccessControl isRoute {...accessList.aiDashboards}>
              <CompanyDashboard />
            </AccessControl>
          ),
        },
        {
          path: "kpis",
          element: (
            <AccessControl isRoute {...accessList.kpis}>
              <KPIDashboards />
            </AccessControl>
          ),
        },
        {
          path: "kpis/directorate/:directorateId/company/:companyId",
          element: (
            <AccessControl isRoute {...accessList.kpis}>
              <KPICompanyDashboard />
            </AccessControl>
          ),
        },
      ],
    },
    {
      path: "/observations",
      element: privateRoute(),
      children: [
        { element: <Navigate to="/observations/list" replace /> },
        { path: "submit", element: <ObservationApp /> },
        { path: "list", element: <ListObservations /> },
        { path: "details/:id", element: <ObservationDetails /> },
        { path: "assign/:id", element: <AssignToParty /> },
        { path: "edit/:id", element: <ObservationApp /> },
        {
          path: "card",
          element: (
            <AccessControl isRoute {...accessList.observationCards}>
              <ObservationCard />
            </AccessControl>
          ),
        },
        {
          path: "bulk",
          element: (
            <AccessControl isRoute {...accessList.bulkUploadObservation}>
              <ObservationUpload />
            </AccessControl>
          ),
        },
      ],
    },
    {
      path: "/actions",
      element: privateRoute(),
      children: [
        { element: <Navigate to="/observations/list" replace /> },
        { path: "details/:oId/:aId", element: <ActionDetails /> },
        { path: "list", element: <ListActions actions={true} /> },
      ],
    },
    {
      path: "/training",
      element: privateRoute(),
      children: [
        {
          path: "invite/:taskId/:trainingPlanId/:userId",
          element: <TrainingInvite />,
        },
        { path: "list", element: <TrainingList /> },
        { path: "details/:trainingId", element: <TrainingDetails /> },
        {
          path: "optFor/:taskId/:trainingPlanId/:trainingUserId",
          element: <TrainingOptFor />,
        },
      ],
    },
    {
      path: "/implementations",
      element: privateRoute(),
      children: [
        { element: <Navigate to="/implementations/list" replace /> },
        {
          path: "new",
          element: (
            <AccessControl isRoute {...accessList.createImplementation}>
              <CreateImplementation />
            </AccessControl>
          ),
        },
        {
          path: "list",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <ListImplementation />
            </AccessControl>
          ),
        },
        {
          path: "company-implementations/:companyId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <ListCompanyImplementations />
            </AccessControl>
          ),
        },
        {
          path: "details/:planId/:companyId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <ActivityDetails />
            </AccessControl>
          ),
        },
        {
          path: "contractor-directory/:planId/:companyId/:stub/:activityId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <ContractorDirectory />
            </AccessControl>
          ),
        },
        {
          path: "identify-critical-behavior/:stub/:companyId/:planId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <IdentifyCriticalBehaviors />
            </AccessControl>
          ),
        },
        {
          path: "identify-critical-behavior/:stub/:companyId/:planId/add",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <AddLti />
            </AccessControl>
          ),
        },
        {
          path: "manage-kick-off/:planId/:activityId/:activityTypeStub/:activityTypeId/:companyId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <KickOffMeeting />
            </AccessControl>
          ),
        },
        {
          path: "identify-focal-points/:planId/:companyId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <IdentifyFocalPoints />
            </AccessControl>
          ),
        },
        {
          path: "sustainability-reviews/:companyId/:planId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <SustainabilityReviewsActivity />
            </AccessControl>
          ),
        },
        {
          path: "steering-commitee/:planId/:companyId/:activityId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <VisitMenu type="Steering Committee" />
            </AccessControl>
          ),
        },
        {
          path: "leadership-visibility/:planId/:companyId/:activityId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <VisitMenu type="Leadership Visibility" />
            </AccessControl>
          ),
        },
        {
          path: "visit-meeting/:type/:planId/:companyId/:activityId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <MeetingDetails />
            </AccessControl>
          ),
        },
        {
          path: "custom-activity/:planId/:activityId/:activityTypeStub/:activityTypeId/:companyId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <ManageTemplate />
            </AccessControl>
          ),
        },
        {
          path: "training-schedule/:planId/:companyId/:activityId/:activityTypeStub/:activityTypeId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <TrainingSchedule />
            </AccessControl>
          ),
        },
        {
          path: "training-schedule/:planId/:companyId/:activityId/:activityTypeStub/:activityTypeId/:trainingId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <TrainingManage />
            </AccessControl>
          ),
        },
        {
          path: "training-schedule/:planId/:companyId/:activityId/:activityTypeStub/:activityTypeId/:trainingId/conclude",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <TrainingConclude />
            </AccessControl>
          ),
        },
        {
          path: "kpiConfiguration/:companyId",
          element: (
            <AccessControl isRoute {...accessList.viewImplementations}>
              <KpiManagement />
            </AccessControl>
          ),
        },
      ],
    },
    {
      path: "/review",
      element: privateRoute(),
      children: [
        {
          path: "view/:planId",
          element: <SustainabilityReview />,
        },
      ],
    },
    {
      path: "/reports",
      element: privateRoute(),
      children: [
        { element: <Navigate to="/reports/create" replace /> },
        {
          path: "create",
          element: (
            <AccessControl isRoute {...accessList.createReport}>
              <CreateReport />
            </AccessControl>
          ),
        },
        {
          path: "schedule",
          element: (
            <AccessControl isRoute {...accessList.scheduleReport}>
              <ScheduleList />
            </AccessControl>
          ),
        },
        {
          path: "scheduled",
          element: (
            <AccessControl isRoute {...accessList.scheduleReport}>
              <ListScheduledReports />
            </AccessControl>
          ),
        },
        {
          path: "creator",
          element: (
            <AccessControl isRoute {...accessList.progressReports}>
              <ReportCreator />
            </AccessControl>
          ),
        },
        {
          path: ":scheduleId",
          element: (
            <AccessControl isRoute {...accessList.reporting}>
              <ScheduledReport />
            </AccessControl>
          ),
        },
      ],
    },
    {
      path: "/reportView",
      element: (
        <AccessControl isRoute {...accessList.reporting}>
          <ReportView />
        </AccessControl>
      ),
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login showResetPasswordForm={false} /> },
        {
          path: "resetPassword",
          element: <Login showResetPasswordForm={true} />,
        },
        {
          path: "validateOtp",
          element: <Login showValidateOtpForm={true} />,
        },
        { path: "exitPage", element: <PageExit /> },
        { path: "privacy", element: <PrivacyPolicy /> },
        { path: "404", element: <NotFound /> },
        {
          path: "/",
          element: token ? (
            <Navigate to="/tasks/list" />
          ) : (
            <Navigate to="/login" />
          ),
        },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
