import apiHelper from "./apiHelper";

export const getLsrDirectorateFiltersApi = (type) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/directorateLsrFilters`, {
        params: {
          type,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

//  TODO
export const getLsrCompanyFiltersApi = (type, directorateId, companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/companyLsrFilters`, {
        params: {
          type,
          directorateId,
          companyId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTopicFiltersApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/topicFilters`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLSRHeatmapDirectoratesApi = (
  directorateId,
  companyId,
  disciplineId,
  locationId,
  topics
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/lsrDirectorateHeatmap`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          topics,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLSRHeatmapCompaniesApi = (
  directorateId,
  companyId,
  locationId,
  disciplineId,
  topics
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/lsrCompanyHeatmap`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          topics,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLSRBreakdownDirectorateApi = (
  directorateId,
  locationId,
  disciplineId,
  companyId,
  topics
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/lsrBreakdownDirectorate`, {
        params: {
          directorateId,
          locationId,
          disciplineId,
          companyId,
          topics,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLsrBarChartsApi = ({
  directorateId,
  disciplineId,
  locationId,
  companyId,
  type,
  topics,
  startDate,
  endDate,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/lsrBarCharts`, {
        params: {
          directorateId,
          disciplineId,
          locationId,
          companyId,
          type,
          topics,
          startDate,
          endDate,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLsrTableApi = ({
  directorateId,
  companyId,
  disciplineId,
  locationId,
  startDate,
  endDate,
  topics,
  pageNo,
  pageSize,
  search,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/lsrTable`, {
        params: {
          directorateId,
          companyId,
          disciplineId,
          locationId,
          startDate,
          endDate,
          topics,
          pageNo,
          pageSize,
          ...(search && { search }),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLSRAlertsApi = ({
  directorateId,
  companyId,
  locationId,
  disciplineId,
}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/ai/dashboard/smartAlerts`, {
        params: {
          type: "LSR",
          directorateId,
          companyId,
          locationId,
          disciplineId,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
