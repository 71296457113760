// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
import { AuthProvider } from "./context/useAuth";
import { StoreProvider } from "./context/useStore";
import Toast from "./components/Toast";
import Renewer from "./components/Renewer";
//  locales
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export default function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();

  const InsecurePage = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p style={{ fontWeight: "bold", color: "red" }}>
        An application is attempting to interfere with IHTIMAM. Please contact a
        system administrator.
      </p>
    </div>
  );

  return window.self === window.top ? (
    <ThemeConfig>
      <AuthProvider>
        <StoreProvider>
          <ScrollToTop />
          <GlobalStyles />
          <BaseOptionChartStyle />
          <Toast />
          <Router />
          <Renewer />
        </StoreProvider>
      </AuthProvider>
    </ThemeConfig>
  ) : (
    <InsecurePage />
  );
}
