import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DefaultTable from "../../components/tables/DefaultTable";
import { getTrainingPlans } from "../../api/implementation";
import PageLoader from "../../components/PageLoader";
import { displayToast } from "../../utils/general";
import TabsBar from "../../components/navigation/TabsBar";
import moment from "moment";
import TableSearch from "../../components/inputs/TableSearch";
import Page from "../../components/Page";

function TrainingList() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [trainingRows, setTrainingRows] = useState([]);
  const [trainingActive, setTrainingActive] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [tableInfoActive, setTableInfoActive] = useState({
    totalPages: 0,
    totalCount: 0,
  });
  const [tabValue, setTabValue] = useState("myTrainings");
  const [search, setSearch] = useState("");
  const [filterParams, setFilterParams] = useState({
    pageNo: 1,
    pageSize: 10,
    type: "ALL",
    searchTerm: "",
  });
  const [filterParamsActive, setFilterParamsActive] = useState({
    pageNo: 1,
    pageSize: 10,
    type: "ALL",
    searchTerm: "",
  });

  const trainingSelfColumns = [
    { field: "id", headerName: t("training.tableHeaders.tableId"), width: 100 },
    {
      field: "type",
      headerName: t("training.tableHeaders.training"),
      width: 250,
    },
    {
      field: "description",
      headerName: t("training.tableHeaders.description"),
      width: 250,
    },
    {
      field: "status",
      headerName: t("training.tableHeaders.status"),
      width: 150,
      renderCell: (params) => renderTag(params.row),
    },
    {
      field: "location",
      headerName: t("training.tableHeaders.location"),
      width: 150,
    },
    {
      field: "startDate",
      headerName: t("training.tableHeaders.startDate"),
      width: 100,
    },
    {
      field: "endDate",
      headerName: t("training.tableHeaders.endDate"),
      width: 100,
    },
    // { field: "attendees", headerName: "# Attendees", width: 100 },
  ];

  const trainingColumns = [
    {
      field: "id",
      headerName: t("training.tableHeaders.tableId"),
      width: 100,
      sortable: false,
    },
    {
      field: "type",
      headerName: t("training.tableHeaders.training"),
      width: 250,
      sortable: false,
    },
    {
      field: "description",
      headerName: t("training.tableHeaders.description"),
      width: 250,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("training.tableHeaders.status"),
      width: 150,
      sortable: false,
      renderCell: (params) => renderTag(params.row),
    },
    {
      field: "location",
      headerName: t("training.tableHeaders.location"),
      width: 150,
      sortable: false,
    },
    {
      field: "totalSeats",
      headerName: t("training.tableHeaders.totalSeats"),
      width: 100,
      sortable: false,
    },
    {
      field: "waitingSeats",
      headerName: t("training.tableHeaders.waitingSeats"),
      width: 150,
      sortable: false,
    },
    {
      field: "availableSeats",
      headerName: t("training.tableHeaders.availableSeats"),
      width: 150,
      sortable: false,
    },
    {
      field: "startDate",
      headerName: t("training.tableHeaders.startDate"),
      width: 100,
      sortable: false,
    },
    {
      field: "endDate",
      headerName: t("training.tableHeaders.endDate"),
      width: 100,
      sortable: false,
    },
    // { field: "attendees", headerName: "# Attendees", width: 100 },
  ];

  const renderTag = (row) => (
    <>
      {row?.status === "Concluded" ? (
        <div style={{ color: theme.palette.primary.green, fontWeight: "bold" }}>
          Concluded
        </div>
      ) : (
        <div> Active</div>
      )}
    </>
  );

  const getTrainingsApiSelf = async () => {
    setLoading(true);
    try {
      let query = `page=${filterParams.pageNo}&pageSize=${filterParams.pageSize}&meOnly=true`;
      if (filterParams.searchTerm) {
        query = query + `&textSearch=${filterParams.searchTerm}`;
      }
      let tempPlans = await getTrainingPlans(query);
      let tempTrainings = tempPlans?.result?.map((plan, index) => {
        return {
          id: plan.id,
          trainingId: plan.id,
          type: plan.TrainingType?.name,
          description: plan.description,
          attendees: tempPlans.attendingCount,
          location: plan?.location?.name,
          status: plan?.concluded ? "Concluded" : "Active",
          startDate: moment(plan?.startDate).format("DD/MM/YY"),
          endDate: moment(plan?.endDate).format("DD/MM/YY"),
        };
      });
      setTrainingRows(tempTrainings);
      setTableInfo({
        totalCount: tempPlans.totalCount,
        totalPages: parseInt(tempPlans.totalCount / filterParams.pageSize) + 1,
      });
      setLoading(false);
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        t("training.errors.trainingList"),
        "errorLoadingTrainingList"
      );
    }
  };

  const getTrainingsApiActive = async () => {
    setLoading(true);
    try {
      let query = `page=${filterParamsActive.pageNo}&pageSize=${filterParamsActive.pageSize}&activeOnly=true`;
      if (filterParamsActive.searchTerm) {
        query = query + `&textSearch=${filterParamsActive.searchTerm}`;
      }
      let tempPlans = await getTrainingPlans(query);
      let tempTrainings = tempPlans?.result?.map((plan, index) => {
        return {
          id: plan.id,
          trainingId: plan.id,
          type: plan.TrainingType?.name,
          description: plan.description,
          totalSeats: plan.numTotal,
          availableSeats: plan.numTotal - plan.attendingCount,
          waitingSeats: plan.numAwaiting,
          attendees: tempPlans.attendingCount,
          location: plan?.location?.name,
          status: plan?.concluded ? "Concluded" : "Active",
          startDate: moment(plan?.startDate).format("DD/MM/YY"),
          endDate: moment(plan?.endDate).format("DD/MM/YY"),
        };
      });
      setTrainingActive(tempTrainings);
      setTableInfoActive({
        totalCount: tempPlans.totalCount,
        totalPages:
          parseInt(tempPlans.totalCount / filterParamsActive.pageSize) + 1,
      });
      setLoading(false);
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        t("training.errors.trainingList"),
        "errorLoadingTrainingList"
      );
    }
  };

  const submitSearch = async (filter) => {
    try {
      if (tabValue === "allTrainings") {
        setFilterParamsActive({
          ...filterParamsActive,
          pageNo: 1,
          searchTerm: search,
        });
      }
      if (tabValue === "myTrainings") {
        setFilterParams({ ...filterParams, pageNo: 1, searchTerm: search });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTrainingsApiSelf();
  }, [filterParams]);

  useEffect(() => {
    getTrainingsApiActive();
  }, [filterParamsActive]);

  return (
    <Page title={t("training.trainingList")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">{t("training.trainingList")}</Typography>
          </div>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <TabsBar
              value={tabValue}
              handler={(event, value) => {
                setTabValue(value);
                setSearch("");
              }}
              tabs={[
                {
                  label: t("training.myTraining"),
                  key: "myTrainings",
                },
                {
                  label: t("training.allTraining"),
                  key: "allTrainings",
                },
              ]}
            />
          </Grid>

          {tabValue === "myTrainings" && (
            <>
              <Grid item xs={12}>
                <TableSearch
                  searchTerm={search}
                  handler={(e) => setSearch(e.target.value)}
                  disabled={loading}
                  submitSearch={submitSearch}
                  clearable={filterParams.searchTerm}
                  clearHandler={() => {
                    setSearch("");
                    setFilterParams({
                      ...filterParams,
                      pageNo: 1,
                      searchTerm: "",
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <DefaultTable
                  rows={trainingRows}
                  columns={trainingSelfColumns}
                  loading={loading}
                  filterParams={filterParams}
                  setFilterParams={(e) => {
                    setFilterParams(e);
                  }}
                  tableInfo={tableInfo}
                  onRowClick={(p) => {
                    navigate(`/training/details/${p.row.trainingId}`);
                  }}
                  topTextContent={
                    <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
                      {filterParams.searchTerm && (
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.palette.primary.grey,
                            fontWeight: "bold",
                          }}
                        >{`${t("table.searchResults")} "${
                          filterParams.searchTerm
                        }" `}</Typography>
                      )}
                      <Typography
                        variant="body2"
                        style={{
                          color: theme.palette.primary.orange,
                          fontWeight: "bold",
                        }}
                      >{`${t("table.totalListed")} ${
                        tableInfo.totalCount
                      } `}</Typography>
                    </Stack>
                  }
                />
              </Grid>
            </>
          )}
          {tabValue === "allTrainings" && (
            <>
              <Grid item xs={12}>
                <TableSearch
                  searchTerm={search}
                  handler={(e) => setSearch(e.target.value)}
                  disabled={loading}
                  submitSearch={submitSearch}
                  clearable={filterParamsActive.searchTerm}
                  clearHandler={() => {
                    setSearch("");
                    setFilterParams({
                      ...filterParamsActive,
                      pageNo: 1,
                      searchTerm: "",
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <DefaultTable
                  rows={trainingActive}
                  columns={trainingColumns}
                  loading={loading}
                  filterParams={filterParamsActive}
                  setFilterParams={(e) => {
                    setFilterParamsActive(e);
                  }}
                  tableInfo={tableInfoActive}
                  onRowClick={(p) => {
                    navigate(`/training/details/${p.row.trainingId}`);
                  }}
                  topTextContent={
                    <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
                      {filterParamsActive.searchTerm && (
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.palette.primary.grey,
                            fontWeight: "bold",
                          }}
                        >{`${t("table.searchResults")} "${
                          filterParamsActive.searchTerm
                        }" `}</Typography>
                      )}
                      <Typography
                        variant="body2"
                        style={{
                          color: theme.palette.primary.orange,
                          fontWeight: "bold",
                        }}
                      >{`${t("table.totalListed")} ${
                        tableInfo.totalCount
                      } `}</Typography>
                    </Stack>
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}

export default TrainingList;
