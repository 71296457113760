import "./styles.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// material
import { Grid, Stack, Card, Typography, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NoDataIcon from "@mui/icons-material/ErrorOutline";
// components
import SelectInput from "../../../components/inputs/SelectInput";
import HeatmapChart from "../../../components/charts/HeatmapChart";
import BarChart from "../../../components/charts/BarChart";
import PageLoader from "../../../components/PageLoader";
import PopperBox from "../../../components/PopperBox";
import MultiSelectInput from "../../../components/inputs/MultiSelectInput";
import HelpIcon from "../../../components/HelpIcon";
import {
  IconButtonStyled,
  IconStyled,
  MoreIconStyled,
} from "../../observation/ListObservations/styledComponents";
//  API
import {
  getLSRHeatmapCompaniesApi,
  getLSRBreakdownDirectorateApi,
  getTopicFiltersApi,
} from "../../../api/lsrDashboard";
import {
  getCompanyFilters,
  getLocationsAndUnitsFilters,
  getDisciplineFilters,
} from "../utils";

// ----------------------------------------------------------------------
import { lsrBarChartOptions } from "./constants";

const cardStyling = {
  height: 600,
  padding: "16px ",
  borderRadius: "6px",
};

const loaderStyling = {
  height: "90%",
  display: "flex",
  alignItems: "center",
};

export default function DirectorateLsrView({ directorateId, directorateName }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [topicFilters, setTopicFilters] = useState([]);
  const [topicDropdown, setTopicDropdown] = useState([]);
  const [companyFilter, setCompanyFilter] = useState("all");
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [disciplineFilter, setDisciplineFilter] = useState("all");
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [locationFilter, setLocationFilter] = useState("all");
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [unitFilter, setUnitFilter] = useState("all");
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [lsrCountData, setLsrCountData] = useState([]);
  const [lsrBreakdownOptions, setLsrBreakdownOptions] = useState(null);
  const [lsrBreakdownData, setLsrBreakdownData] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [countDataLoaded, setCountDataLoaded] = useState(false);

  const heatMapOptions = {
    chart: {
      fontFamily: "Cairo, sans-serif",
      events: {
        click: (event, chartContext, config) => {
          if (
            event.toElement?.nextSibling?.innerHTML &&
            event.target.parentNode.classList.contains(
              "lsr-count-heatmap-yaxis-label"
            )
          ) {
            const targetName = event.toElement?.nextSibling?.innerHTML;
            const finalText = targetName.replace(/&amp;/g, "&");
            const targetInList = companyNames.find(
              (dObj) => dObj.name === finalText
            );
            if (targetInList) {
              navigate(`/dashboard/company-dashboard/lsrs/${targetInList.id}`, {
                state: {
                  companyName: finalText,
                  directorateId,
                  directorateName,
                },
              });
            }
          }
        },
      },
    },
    colors: ["#FF3131"],
    plotOptions: {
      heatmap: {
        enableShades: true,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 0,
              color: "#1bc262",
            },
          ],
          min: 1,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val,
    },
    tooltip: {
      y: {
        formatter: (val) => val,
      },
    },
    xaxis: {
      type: "category",
      position: "top",
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: true,
    },
    legend: {
      show: false,
      position: "bottom",
    },
    yaxis: {
      labels: {
        style: {
          cssClass: "lsr-count-heatmap-yaxis-label",
        },
      },
    },
  };

  const renderFilters = () => (
    <Grid container spacing={1} sx={{ mt: 1 }} alignItems="flex-end">
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.companyTitle")}
          disabled={loading || companyDropdown.length < 1}
          value={companyFilter}
          handler={(e) => setCompanyFilter(e.target.value)}
          options={companyDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.locationTitle")}
          disabled={loading || locationDropdown.length < 1}
          value={locationFilter}
          handler={(e) => setLocationFilter(e.target.value)}
          options={locationDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.unitTitle")}
          disabled={loading || unitDropdown.length < 1}
          value={unitFilter}
          handler={(e) => setUnitFilter(e.target.value)}
          options={unitDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.disciplineTitle")}
          disabled={loading || disciplineDropdown.length < 1}
          value={disciplineFilter}
          handler={(e) => setDisciplineFilter(e.target.value)}
          options={disciplineDropdown}
        />
      </Grid>
      <Grid item xs={1} sm={1}>
        <Stack spacing={1} direction="row">
          <Tooltip title={t("general.moreFilters")}>
            <IconButtonStyled
              aria-label={"More filters"}
              onClick={(e) => setPopperAnchor(e.currentTarget)}
            >
              <MoreIconStyled />
            </IconButtonStyled>
          </Tooltip>
          <Tooltip title={t("general.applyFilters")}>
            <IconButtonStyled
              aria-label={t("general.applyFilters")}
              onClick={submitFilters}
            >
              <IconStyled />
            </IconButtonStyled>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );

  const renderMoreFilters = () => (
    <PopperBox
      filters
      overflowV
      disabled={loading}
      popperAnchor={popperAnchor}
      minWidth="30vw"
      content={
        <MultiSelectInput
          placeholder={t("general.all")}
          label={t("observations.viewObservations.filters.topics")}
          disabled={loading || topicDropdown.length < 1}
          options={topicDropdown}
          handler={(newValue) => setTopicFilters(newValue)}
          values={topicFilters}
        />
      }
      handlePopperClose={() => setPopperAnchor(null)}
    />
  );

  const renderLSRCountChart = () => (
    <Card style={{ ...cardStyling, height: 700 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {t("dashboards.implementationDashboards.lsrCount")}
        </Typography>
        <HelpIcon text={t("dashboards.tooltips.directorate.lsrHeatmap")} />
      </Stack>
      {!countDataLoaded ? (
        <div style={loaderStyling}>
          <PageLoader />
        </div>
      ) : lsrCountData.length > 0 ? (
        <HeatmapChart
          height="100%"
          chartData={lsrCountData}
          chartOptions={heatMapOptions}
        />
      ) : (
        <Stack
          spacing={1}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NoDataIcon />
          <Typography variant="body1">{t("general.noData")}</Typography>
        </Stack>
      )}
    </Card>
  );

  const renderLSRBreakdownChart = () => (
    <Card style={cardStyling}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {t("dashboards.implementationDashboards.lsrCountBreakdown")}
        </Typography>
        <HelpIcon text={t("dashboards.tooltips.directorate.lsrBreakdown")} />
      </Stack>
      {lsrBreakdownOptions ? (
        <BarChart
          blankLoading
          height="95%"
          chartData={lsrBreakdownData}
          chartOptions={lsrBreakdownOptions}
        />
      ) : (
        <div style={loaderStyling}>
          <PageLoader />
        </div>
      )}
    </Card>
  );

  const getLsrCountData = async () => {
    try {
      setLoading(true);
      const rawData = await getLSRHeatmapCompaniesApi(
        directorateId,
        companyFilter === "all" ? null : companyFilter,
        locationFilter === "all" ? null : locationFilter,
        disciplineFilter === "all" ? null : disciplineFilter,
        topicFilters.length === 0 ? null : topicFilters.map((t) => t.value)
      );
      setLsrCountData(rawData);
      const cNames = rawData.map((c) => {
        return {
          name: c.name,
          id: c.id,
        };
      });
      setCompanyNames(cNames);
      setCountDataLoaded(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getLsrBreakdownData = async () => {
    try {
      setLoading(true);
      const rawData = await getLSRBreakdownDirectorateApi(
        directorateId,
        locationFilter === "all" ? null : locationFilter,
        disciplineFilter === "all" ? null : disciplineFilter,
        companyFilter === "all" ? null : companyFilter,
        topicFilters.length === 0 ? null : topicFilters.map((t) => t.value)
      );

      const tempCats = Object.keys(rawData);
      const chartCats = tempCats.map((c) => {
        const words = c.split(" ");
        return words;
      });

      const chartData = tempCats.map((cat) => {
        return parseFloat(rawData[cat]).toFixed(2) || 0;
      });
      setLsrBreakdownOptions({
        ...lsrBarChartOptions,
        xaxis: {
          ...lsrBarChartOptions.xaxis,
          categories: chartCats,
        },
      });
      setLsrBreakdownData([{ name: "LSR Violation Count", data: chartData }]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitFilters = () => {
    getLsrCountData();
    getLsrBreakdownData();
  };

  useEffect(() => {
    const getTopicFilters = async () => {
      try {
        setLoading(true);
        const topicsRaw = await getTopicFiltersApi();
        setTopicDropdown(topicsRaw);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    getTopicFilters();
    if (directorateId) {
      setCompanyFilter("all");
      getCompanyFilters({
        setLoading,
        directorateFilter: directorateId,
        setCompanyDropdown,
      });
    }
    getLsrCountData();
    getLsrBreakdownData();
  }, []);

  useEffect(() => {
    if (companyFilter && companyFilter !== "all") {
      setLocationFilter("all");
      getLocationsAndUnitsFilters({
        setLoading,
        companyFilter,
        setLocationDropdown,
        setUnitDropdown,
      });
    }
  }, [companyFilter]);

  useEffect(() => {
    if (
      companyFilter &&
      companyFilter !== "all" &&
      locationFilter &&
      locationFilter !== "all"
    ) {
      setDisciplineFilter("all");
      getDisciplineFilters({
        setLoading,
        companyFilter,
        locationFilter,
        unitFilter,
        setDisciplineDropdown,
      });
    }
  }, [companyFilter, locationFilter]);

  return (
    <Stack spacing={2} direction="column">
      {renderFilters()}
      {renderLSRCountChart()}
      {renderLSRBreakdownChart()}
      {renderMoreFilters()}
    </Stack>
  );
}
