import apiHelper from "./apiHelper";

export const getNotificationsApi = (type, read=0, params = {}) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/notifications`, {
        params: {
          type,
          read,
          ...params
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateReadApi = (notificationId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .put(`/api/notifications/${notificationId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getNotificationPreferencesDefaultApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/users/defaultNotificationPreferences`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getNotificationPreferencesApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/users/notificationPreferences`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const saveNotificationPreferencesApi = (payload) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/users/notificationPreferences`, {
        configJSON: payload.data,
        notificationChannel: payload.notificationChannel
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};


export const clearNotifications = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/notifications/clear`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
