import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination } from "@mui/material";
import PageLoader from "../PageLoader";
import { styled } from "@mui/material/styles";

TableChart.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  hidePagination: PropTypes.bool,
  filterParams: PropTypes.object,
  setFilterParams: PropTypes.func,
  tableInfo: PropTypes.object,
  customFontSize: PropTypes.string,
  loading: PropTypes.bool,
};

const loaderStyling = {
  height: "10vh",
  display: "flex",
  alignItems: "center",
};

const StyledPagination = styled(TablePagination)(({ theme }) => ({}));

export default function TableChart({
  rows,
  columns,
  hidePagination,
  filterParams,
  setFilterParams,
  tableInfo,
  customFontSize,
  loading,
}) {
  const { t } = useTranslation();
  const handlePageChange = (event, newPage) => {
    setFilterParams({ ...filterParams, pageNo: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilterParams({
      ...filterParams,
      pageNo: 1,
      pageSize: event.target.value,
    });
  };

  const renderDisplayedPages = () => {
    return t("table.page", {
      pageNo: filterParams.pageNo,
      totalCount: tableInfo.totalPages,
    });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow key={`TableRow-1-Head`}>
              {columns.map((c, index) => (
                <TableCell
                  style={{
                    fontWeight: "bold",
                    fontSize: customFontSize || undefined,
                  }}
                  key={`tableCellHead-${index}`}
                >
                  {c.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <div style={loaderStyling}>
                <PageLoader />
              </div>
            ) : rows.length > 0 ? (
              rows.map((row, index) => (
                <TableRow
                  key={`tableRow-${row.name}-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                    background: index % 2 === 0 ? "#FEFEFE" : "#F8F8F8",
                  }}
                >
                  {columns.map((c, index) => (
                    <TableCell
                      key={`tableCellColumns-${index}`}
                      style={{ fontSize: customFontSize || undefined }}
                    >
                      {c.renderCell ? c.renderCell({ row }) : row[c.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow key={`singleRow}`}>
                <TableCell
                  key={`singleCell`}
                  colSpan={columns.length}
                  align="center"
                  style={{ fontSize: customFontSize || undefined }}
                >
                  {t("general.noData")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!hidePagination && (
        <StyledPagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={tableInfo.totalCount}
          rowsPerPage={filterParams.pageSize}
          page={filterParams.pageNo - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={renderDisplayedPages}
          labelRowsPerPage={t("table.rowsPerPage")}
        />
      )}
    </>
  );
}
