import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { displayToast } from "../../../utils/general";
//  Material UI
import { Grid, Typography, Box, Container } from "@mui/material";
//  Components
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import PageLoader from "../../../components/PageLoader";
import Page from "../../../components/Page";
import AutocompleteInput from "../../../components/inputs/AutocompleteInput";
import FileUploader from "../../../components/inputs/FileUploader";
import AttachmentViewer from "../../../components/AttachmentViewer";
import TextInput from "../../../components/inputs/TextInput";
import InfoBox from "../../../components/InfoBox";
//  API
import {
  getActivityByIdApi,
  manageActivityApi,
  implementationContractorsApi,
} from "../../../api/activities";
import {
  downloadAttachmentApi,
  getAttachmentsApi,
} from "../../../api/attachments";

const activityDefault = {
  minutes: "",
  agenda: "",
  attendeeIds: [],
  noShowIds: [],
};

export const ManageTemplate = () => {
  const { t } = useTranslation();
  const { planId, activityId, activityTypeStub, activityTypeId, companyId } =
    useParams();
  const [loading, setLoading] = useState(false);
  const [activityDetail, setActivityDetail] = useState(activityDefault);
  const [users, setUsers] = useState([]);
  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const [preloadedAttachments, setPreloadedAttachments] = useState([]);
  const [files, setFiles] = useState([]);

  const activityStub = activityTypeStub;

  const generateText = (type) => {
    switch (type) {
      case "SiteEngagement":
        return t("implementationsList.siteEngagement");

      case "DialogSiteEngagement":
        return t("implementationsList.siteEngagementDialogTitle");

      case "PreSiteEngagement":
        return t("implementationsList.preSiteEngagement");

      case "DialogPreSiteEngagement":
        return t("implementationsList.preSiteEngagementDialogTitle");

      case "Meeting":
        return t("implementationsList.meeting");

      case "DialogMeeting":
        return t("implementationsList.meetingDialogTitle");

      default:
        return "";
    }
  };

  const getCurrentActivity = async () => {
    try {
      setLoading(true);
      const userData = await getUsers();
      const activity = await getActivityByIdApi(activityId, activityStub);

      const { minutes, agenda, RolloutPlanMeetings, attachments } = activity;
      const attendeeIds = RolloutPlanMeetings?.length
        ? RolloutPlanMeetings[0].attendeeIds.split(",")
        : [];
      const noShowIds = RolloutPlanMeetings?.length
        ? RolloutPlanMeetings[0].noShowIds.split(",")
        : [];

      const finalAttendees = [];
      const finalNoShows = [];
      attendeeIds.forEach((a) => {
        const foundUser = userData.find((u) => u.value === parseInt(a));
        if (foundUser) {
          finalAttendees.push(foundUser);
        }
      });

      noShowIds.forEach((a) => {
        const foundUser = userData.find((u) => u.value === parseInt(a));
        if (foundUser) {
          finalNoShows.push(foundUser);
        }
      });

      const attachmentIds = [];
      if (attachments) {
        attachments.forEach((a) => {
          attachmentIds.push(a.id);
        });
      }

      if (attachmentIds && attachmentIds.length > 0) {
        const attachmentsData = await getAttachmentsApi(attachmentIds);
        setAttachmentLinks(attachmentsData);
      }

      setActivityDetail({
        minutes,
        agenda,
        attendeeIds: finalAttendees,
        noShowIds: finalNoShows,
      });
      setPreloadedAttachments(attachments || []);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.detailsUpdating")
    );

    try {
      const { minutes, attendeeIds, agenda, noShowIds } = activityDetail;
      const finalAttendees = attendeeIds.map((a) => a.value);
      const finalNoShows = noShowIds.map((n) => n.value);
      const body = {
        minutes,
        attendeeIds: finalAttendees,
        noShowIds: finalNoShows,
        agenda,
        activityId,
        activityTypeStub,
        planId: planId,
        activityTypeId,
        attachments: files,
      };

      await manageActivityApi(body);
      setFiles([]);
      displayToast(
        setLoading,
        "success",
        t("implementations.detailsUpdated"),
        activeToast
      );

      getCurrentActivity();
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const getUsers = async () => {
    const usersData = await implementationContractorsApi({
      planId,
      companyId,
    });
    const users = usersData.data.map((u) => {
      return {
        label: `${u.firstName} ${u.lastName ?? ''}`,
        value: u.id,
      };
    });
    setUsers(users);
    return users;
  };

  const renderMeetingView = () => (
    <>
      <Grid item xs={12}>
        <TextInput
          disabled={loading}
          label={t("implementationsList.agenda")}
          placeholder={t("implementationsList.agendaInput")}
          handler={(e) =>
            setActivityDetail({
              ...activityDetail,
              agenda: e.target.value,
            })
          }
          value={activityDetail.agenda}
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          disabled={loading}
          label={t("implementationsList.minutes")}
          placeholder={t("implementationsList.enterMinutesInput")}
          handler={(e) =>
            setActivityDetail({
              ...activityDetail,
              minutes: e.target.value,
            })
          }
          value={activityDetail.minutes}
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          multiple
          value={activityDetail.attendeeIds}
          handler={(e, v) =>
            setActivityDetail({
              ...activityDetail,
              attendeeIds: v ? v : [],
            })
          }
          options={users}
          label={t("implementationsList.attendeesInputText")}
          disabled={loading || users.length < 1}
        />
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          multiple
          value={activityDetail.noShowIds}
          handler={(e, v) =>
            setActivityDetail({
              ...activityDetail,
              noShowIds: v ? v : [],
            })
          }
          options={users}
          label={t("implementationsList.noShowInputText")}
          disabled={loading || users.length < 1}
        />
      </Grid>
    </>
  );

  const renderEngagementView = () => (
    <>
      <Grid item xs={12}>
        <TextInput
          disabled={loading}
          label={t("implementationsList.agenda")}
          placeholder={t("implementationsList.agendaInput")}
          handler={(e) =>
            setActivityDetail({
              ...activityDetail,
              agenda: e.target.value,
            })
          }
          value={activityDetail.agenda}
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <AttachmentViewer
          disabled={loading}
          attachments={preloadedAttachments || []}
          attachmentLinks={attachmentLinks}
          customLabel={t("general.viewAttachments")}
        />
      </Grid>
      <Grid item xs={12}>
        <FileUploader
          labelText={t("general.uploadAttachments")}
          files={files}
          setFiles={setFiles}
          disabled={loading}
        />
      </Grid>
    </>
  );

  const generateView = (type) => {
    switch (type) {
      case "Meeting":
        return renderMeetingView();

      case "PreSiteEngagement":
      case "SiteEngagement":
        return renderEngagementView();

      default:
        return (
          <Grid item xs={12}>
            Error getting view
          </Grid>
        );
    }
  };

  const returnMandatoryChecks = () => {
    //  Returns false if mandatory fields are filled in
    switch (activityStub) {
      case "Meeting":
        if (
          loading ||
          !activityDetail.agenda ||
          !activityDetail.minutes ||
          activityDetail.attendeeIds.length < 1
        ) {
          return true;
        } else {
          return false;
        }

      case "PreSiteEngagement":
      case "SiteEngagement":
        if (loading || !activityDetail.agenda) {
          return true;
        } else {
          return false;
        }

      default:
        return true;
    }
  };

  useEffect(() => {
    getCurrentActivity();
  }, []);

  return !loading ? (
    <Page title={generateText(activityStub)}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {generateText(`Dialog${activityStub}`)}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={3}>
          {generateView(activityStub)}
          <Grid item xs={12}>
            <PrimaryButton
              disabled={loading || returnMandatoryChecks()}
              alignment="left"
              label={t("general.saveChanges")}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <PageLoader />
    </div>
  );
};
