/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../context/useAuth";
import { hasAccess } from "../../../utils/acl";
import { displayToast } from "../../../utils/general";
// defaults
import {
  actionColumnDefaults,
  actionFilterTypesDefault,
  defaultFiltersPayload,
  defaultObservationAttr,
  filterModalDefaults,
  filterParamDefaults,
  observationColumnValueDefaults,
} from "./defaults";
// material
import ResetIcon from "@mui/icons-material/CloseOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import {
  Badge,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// components
import RadioSet from "../../../components/buttons/RadioSet";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import InfoBox from "../../../components/InfoBox";
import DateInput from "../../../components/inputs/DateInput";
import MultiSelectInput from "../../../components/inputs/MultiSelectInput";
import MultiSelectWithAll from "../../../components/inputs/MultiSelectWithAll";
import SelectInput from "../../../components/inputs/SelectInput";
import TableSearch from "../../../components/inputs/TableSearch";
import TabsBar from "../../../components/navigation/TabsBar";
import Page from "../../../components/Page";
import DefaultTable from "../../../components/tables/DefaultTable";
import { IconButtonStyled, IconStyled } from "./styledComponents";
import CellText from "../../../components/CellText";
//  api
import { getAssignedActionsApi } from "../../../api/actions";
import {
  getObservationsApi,
  getStatsApi,
  getLocationFilterApi,
  getDirectorateFilterApi,
  getCompanyFilterApi,
  getUnitFilterApi,
} from "../../../api/observation";
import {
  getUserPreferencesApi,
  saveUserPreferencesApi,
} from "../../../api/users";

export default function ListObservations() {
  const { t } = useTranslation();
  //  Defaults and helpers that require translations
  const observationColumnDefaults = [
    { field: "id", headerName: t("table.id"), width: 200 },
    {
      field: "status",
      headerName: t("table.status"),
      width: 300,
      renderCell: (param) => (
        <CellText title={t(param.row.status, { ns: "backend" })} />
      ),
    },
    {
      field: "location",
      headerName: t("table.location"),
      width: 300,
      sortable: false,
    },
    { field: "observer", headerName: t("table.observer"), width: 200 },
    {
      field: "observationDate",
      headerName: t("table.observationDate"),
      width: 200,
    },
  ];

  const observationColumnDictionary = {
    id: { field: "id", headerName: t("table.id"), width: 200 },
    observer: {
      field: "observer",
      headerName: t("table.observer"),
      width: 200,
    },
    discipline: {
      field: "discipline",
      headerName: t("table.discipline"),
      width: 300,
      sortable: false,
    },
    directorate: {
      field: "directorate",
      headerName: t("table.directorate"),
      width: 300,
      sortable: false,
    },
    location: {
      field: "location",
      headerName: t("table.location"),
      width: 300,
      sortable: false,
    },
    unit: {
      field: "unit",
      headerName: t("table.unit"),
      width: 300,
      sortable: false,
    },
    behavior: {
      field: "behavior",
      headerName: t("table.behavior"),
      width: 200,
      sortable: false,
    },
    actions: {
      field: "actions",
      headerName: t("table.actions"),
      width: 200,
      sortable: false,
    },
    attachments: {
      field: "attachments",
      headerName: t("table.attachments"),
      width: 200,
      sortable: false,
    },
    observationType: {
      field: "observationType",
      headerName: t("table.observationType"),
      width: 300,
    },
    observationSubType: {
      field: "observationSubType",
      headerName: t("table.observationSubType"),
      width: 300,
    },
    observerType: {
      field: "observerType",
      headerName: t("reporting.observerType"),
      width: 200,
    },
    observedAt: {
      field: "observationDate",
      headerName: t("table.observationDate"),
      width: 200,
    },
    isAnonymous: {
      field: "isAnonymous",
      headerName: t("table.anonymous"),
      width: 200,
    },
    isEscalated: {
      field: "isEscalated",
      headerName: t("table.escalated"),
      width: 200,
      sortable: false,
    },
    feedback: {
      field: "feedback",
      headerName: t("observations.observationDetails.feedback"),
      width: 400,
      sortable: false,
    },
    rootCause: {
      field: "rootCause",
      headerName: t("reporting.rootCause"),
      width: 200,
      sortable: false,
    },
    actionToPrevent: {
      field: "actionToPrevent",
      headerName: t("observations.observationApp.actionCondition"),
      width: 200,
      sortable: false,
    },
    status: {
      field: "status",
      headerName: t("table.status"),
      width: 300,
      renderCell: (param) => (
        <CellText title={t(param.row.status, { ns: "backend" })} />
      ),
    },
    // suggestions: {
    //   field: "suggestions",
    //   headerName: t("observations.observationApp.suggestions"),
    //   width: 200,
    // },
    appreciation: {
      field: "appreciation",
      headerName: t("observations.observationDetails.appreciation"),
      width: 200,
    },
    lessonLearnt: {
      field: "lessonLearnt",
      headerName: t("table.lessonLearnt"),
      width: 200,
      sortable: false,
    },
    // markedAsCompleteAt: {
    //   field: "markedAsCompleteAt",
    //   headerName: t("table.markCompletedDate"),
    //   width: 200,
    // },
    safeObservation: {
      field: "safeObservation",
      headerName: t("observations.observationDetails.safeObservation"),
      width: 200,
    },
    nearMissMarkedAt: {
      field: "nearMissMarkedAt",
      headerName: t("table.markNearMissDate"),
      width: 200,
      sortable: false,
    },
  };

  const observationColumnDropdown = [
    { value: "observer", label: t("table.observer") },
    { value: "discipline", label: t("table.discipline") },
    { value: "directorate", label: t("table.directorate") },
    { value: "location", label: t("table.location") },
    { value: "unit", label: t("table.unit") },
    // { value: "behaviors", label: "Behavior" },
    { value: "attachments", label: t("table.attachments") },
    { value: "actions", label: t("table.actions") },
    { value: "observationType", label: t("table.observationType") },
    { value: "observationSubType", label: t("table.observationSubType") },
    // { value: "observerType", label: t("reporting.observerType") },
    { value: "observedAt", label: t("table.observationDate") },
    { value: "isAnonymous", label: t("table.anonymous") },
    // { value: "isEscalated", label: t("table.escalated") },
    { value: "feedback", label: t("observations.observationDetails.feedback") },
    { value: "rootCause", label: t("reporting.rootCause") },
    {
      value: "actionToPrevent",
      label: t("observations.observationApp.actionCondition"),
    },
    { value: "status", label: t("table.status") },
    // {
    //   value: "suggestions",
    //   label: t("observations.observationApp.suggestions"),
    // },
    {
      value: "appreciation",
      label: t("observations.observationDetails.appreciation"),
    },
    { value: "lessonLearnt", label: t("table.lessonLearnt") },
    // { value: "markedAsCompleteAt", label: t("table.markCompletedDate") },
    {
      value: "safeObservation",
      label: t("observations.observationDetails.safeObservation"),
    },
    { value: "nearMissMarkedAt", label: t("table.markNearMissDate") },
  ];

  const generateColumns = (columnValues) => {
    const finalColumns = [
      { field: "id", headerName: t("table.id"), width: 200 },
    ];
    columnValues.forEach((c) => {
      const key = c.value;
      finalColumns.push(observationColumnDictionary[key]);
    });
    return finalColumns;
  };

  const returnUserColumns = (userPrefAttributes) => {
    const attributes = userPrefAttributes.split("+");
    const finalColumnValues = attributes.map((a) => {
      return {
        label: observationColumnDictionary[a].headerName,
        value: a,
      };
    });

    return finalColumnValues;
  };

  const observationFilterTypesDefault = [
    {
      label: t("OBSERVATION SUBMITTED", { ns: "backend" }),
      value: "OBSERVATION SUBMITTED",
    },
    {
      label: t("OBSERVATION RESUBMITTED", { ns: "backend" }),
      value: "OBSERVATION RESUBMITTED",
    },
    {
      label: t("CLOSED BECAUSE SAFE", { ns: "backend" }),
      value: "CLOSED BECAUSE SAFE",
    },
    {
      label: t("SUPERVISOR CLOSED NO ACTION", { ns: "backend" }),
      value: "SUPERVISOR CLOSED NO ACTION",
    },
    {
      label: t("OBSERVATION CLOSED WITH ACTION", { ns: "backend" }),
      value: "OBSERVATION CLOSED WITH ACTION",
    },
    {
      label: t("RETURNED TO SUPERVISOR", { ns: "backend" }),
      value: "RETURNED TO SUPERVISOR",
    },
    {
      label: t("ACTION ASSIGNED", { ns: "backend" }),
      value: "ACTION ASSIGNED",
    },
    {
      label: t("MARKED AS NEAR MISS", { ns: "backend" }),
      value: "MARKED AS NEAR MISS",
    },
    {
      label: t("OBSERVATION CLOSED", { ns: "backend" }),
      value: "OBSERVATION CLOSED",
    },
    {
      label: t("ACTION COMPLETE", { ns: "backend" }),
      value: "ACTION COMPLETE",
    },
    {
      label: t("RETURNED TO OBSERVER", { ns: "backend" }),
      value: "RETURNED TO OBSERVER",
    },
    {
      label: t("SUPERVISOR REJECTED", { ns: "backend" }),
      value: "SUPERVISOR REJECTED",
    },
    {
      label: t("ACTION RE-ASSIGNED", { ns: "backend" }),
      value: "ACTION RE-ASSIGNED",
    },
    // {
    //   label: t("ACTION PERFORMED REJECTED", { ns: "backend" }),
    //   value: "ACTION PERFORMED REJECTED",
    // },
    // { label: t("general.all"), value: "ALL" },
  ];

  const { access } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const today = new Date();
  const [tabValue, setTabValue] = useState(
    hasAccess(access, "allObservations") ? "allObservations" : "myObservations"
  );
  const [observationRows, setObservationRows] = useState([]);
  const [actionRows, setActionRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [observationRowsLoading, setObservationRowsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filterParams, setFilterParams] = useState(filterParamDefaults);
  const [stats, setStats] = useState({
    pending: 0,
    actionAssigned: 0,
    closed: 0,
    nearMiss: 0,
    total: 0,
  });
  const [myStats, setMyStats] = useState({
    pending: 0,
    actionAssigned: 0,
    closed: 0,
    nearMiss: 0,
    total: 0,
  });
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterModal, setFilterModal] = useState(filterModalDefaults);
  const [filterTypesDropdown, setFilterTypesDropdown] = useState([]);
  const [tabs, setTabs] = useState([
    {
      label: t("observations.viewObservations.tabs.myObservations"),
      value: 0,
      key: "myObservations",
    },
  ]);
  const [userPreferences, setUserPreferences] = useState(defaultFiltersPayload);
  const [triggerFlag, setTriggerFlag] = useState(false);
  const [preferencesLoaded, setPreferencesLoaded] = useState(false);
  const [locationsDropdown, setLocationsDropdown] = useState([]);
  const [directoratesDropdown, setDirectoratesDropdown] = useState([]);
  const [companiesDropdown, setCompaniesDropdown] = useState([]);
  const [unitsDropdown, setUnitsDropdown] = useState([]);

  //  Column functionality
  const [observationColumns, setObservationColumns] = useState(
    observationColumnDefaults
  );

  const handleTabChange = (event, value) => {
    setFilterParams(filterParamDefaults);
    setTabValue(value);
    switch (value) {
      case "myActions":
        setFilterTypesDropdown(actionFilterTypesDefault);
        break;

      default:
        setFilterTypesDropdown(observationFilterTypesDefault);
    }
    setSearch("");
  };

  const handleFilterChange = (event, value) => {
    const key = filterModal.tab;
    setFilterModal({
      ...filterModal,
      [key]: {
        ...filterModal[key],
        observationType: value,
      },
    });
  };

  const submitFilter = () => {
    const key = filterModal.tab;
    let finalColumnString = "";
    filterModal[key].columnValues.forEach((c, index) => {
      if (index === 0) {
        finalColumnString += c.value;
      } else {
        finalColumnString += `+${c.value}`;
      }
    });
    setFilterParams({
      ...filterParams,
      pageNo: 1,
      [key]: {
        observationType: filterModal[key].observationType,
        type: filterModal[key].type,
        startTargetDate: filterModal[key].filterDates
          ? new Date(filterModal[key].startTargetDate)
          : null,
        endTargetDate: filterModal[key].filterDates
          ? new Date(filterModal[key].endTargetDate)
          : null,
        attr: finalColumnString,
        locationId: filterModal[key].locationId,
        directorateId: filterModal[key].directorateId,
        unitId: filterModal[key].unitId,
        companyId: filterModal[key].companyId,
      },
    });
    setFilterModal({ ...filterModal, open: false });
    setTriggerFlag(true);
  };

  const submitSearch = async () => {
    try {
      if (tabValue === "myActions") {
        setFilterParams({ ...filterParams, searchTerm: search });
        const actionsData = await getAssignedActionsApi({
          ...filterParams,
          pageNo: 1,
          searchTerm: search,
        });
        const parsedData = actionsData.data.map((a) => {
          return {
            id: a.id,
            observationId: a.observation.id,
            status: a.status,
            actionRequired: a.actionRequired,
            generatedActionId: a.generatedActionId,
            observer: a.observation.observer
              ? `${a.observation.observer.firstName} ${a.observation.observer.lastName}`
              : "",
            targetDate: moment(a.targetDate).format("DD/MM/YY hh:mm a"),
          };
        });
        setActionRows(parsedData);
        setObservationRows(parsedData);
        setTableInfo({
          totalCount: actionsData.totalItems,
          totalPages: actionsData.totalPages,
        });
      } else if (
        tabValue === "allObservations" ||
        tabValue === "myObservations"
      ) {
        setFilterParams({ ...filterParams, pageNo: 1, searchTerm: search });
        setTriggerFlag(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitSaveFilters = async (action) => {
    setLoading(true);
    const target = tabValue;
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("general.savingPreferences")
    );
    try {
      let payload;

      if (action === "reset") {
        setFilterParams({ ...filterParamDefaults });
        setFilterModal({ ...filterModalDefaults, open: false });
        const key = filterModal.tab;
        filterModal[key].type = filterModalDefaults[key].type;
        filterModal[key].directorateId = filterModalDefaults[key].directorateId;
        filterModal[key].locationId = filterModalDefaults[key].locationId;
        filterModal[key].unitId = filterModalDefaults[key].unitId;
        filterModal[key].companyId = filterModalDefaults[key].companyId;
        filterModal[key].filterDates = filterModalDefaults[key].filterDates;
        filterModal[key].startTargetDate =
          filterModalDefaults[key].startTargetDate;
        filterModal[key].endTargetDate = filterModalDefaults[key].endTargetDate;
        filterModal[key].observationType =
          filterModalDefaults[key].observationType;
        filterModal[key].columnValues = observationColumnValueDefaults;
        payload = {
          ...defaultFiltersPayload,
        };
      } else if (action === "save") {
        let finalColumnString = "";
        filterModal[target].columnValues.forEach((c, index) => {
          if (index === 0) {
            finalColumnString += c.value;
          } else {
            finalColumnString += `+${c.value}`;
          }
        });

        payload = {
          ...userPreferences,
          [`${target}FilterType`]: filterModal[target].type,
          [`${target}FilterObsType`]: filterModal[target].observationType,
          [`${target}FilterDateFlag`]: filterModal[target].filterDates,
          [`${target}FilterDateStart`]: filterModal[target].startTargetDate,
          [`${target}FilterDateEnd`]: filterModal[target].endTargetDate,
          [`${target}Attr`]: finalColumnString,
          [`${target}FilterLocation`]: filterModal[target].locationId,
          [`${target}FilterDirectorate`]: filterModal[target].directorateId,
          [`${target}FilterUnit`]: filterModal[target].unitId,
          [`${target}FilterCompany`]: filterModal[target].companyId,
        };
      }

      await saveUserPreferencesApi(payload);
      submitFilter();
      displayToast(setLoading, t("general.savedPreferences"), activeToast);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  const renderObservationFilters = () => {
    const key = filterModal.tab;

    return (
      <Grid container spacing={2}>
        {(tabValue === "allObservations" || tabValue === "myObservations") && (
          <Grid item xs={12}>
            <Typography variant="h6">
              {t(`observations.viewObservations.filters.display`)}
            </Typography>
            <RadioSet
              disabled={loading}
              label=""
              value={filterModal[key].observationType}
              handleChange={handleFilterChange}
              options={[
                {
                  label: t(
                    `observations.viewObservations.filters.allObservations`
                  ),
                  value: "ALL",
                },
                {
                  label: t(`observations.viewObservations.filters.safeOnly`),
                  value: "SAFE",
                },
                {
                  label: t(`observations.viewObservations.filters.unsafeOnly`),
                  value: "UNSAFE",
                },
              ]}
            />
          </Grid>
        )}

        <Grid item xs={12} md={12} style={{ paddingTop: "8px" }}>
          <MultiSelectWithAll
            values={filterModal[key].type ? filterModal[key].type : []}
            handler={(newValue) =>
              setFilterModal({
                ...filterModal,
                [key]: {
                  ...filterModal[key],
                  type: newValue,
                },
              })
            }
            options={filterTypesDropdown}
            label={t(`observations.viewObservations.filters.status`)}
            disabled={loading}
          />
        </Grid>
        {(tabValue === "allObservations" || tabValue === "myObservations") && (
          <>
            <Grid item xs={12} md={6}>
              <SelectInput
                optional
                value={filterModal[key].locationId}
                handler={handleLocationChange}
                options={locationsDropdown}
                label={t(`observations.viewObservations.filters.location`)}
                disabled={loading || locationsDropdown.length < 1}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                optional
                value={filterModal[key].directorateId}
                handler={handleDirectorateChange}
                options={directoratesDropdown}
                label={t(`observations.viewObservations.filters.directorate`)}
                disabled={loading || directoratesDropdown.length < 1}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                optional
                value={filterModal[key].companyId}
                handler={handleCompanyChange}
                options={companiesDropdown}
                label={t(`observations.viewObservations.filters.company`)}
                disabled={loading || companiesDropdown.length < 1}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                optional
                value={filterModal[key].unitId}
                handler={(e) =>
                  setFilterModal({
                    ...filterModal,
                    [key]: {
                      ...filterModal[key],
                      unitId: e.target.value,
                    },
                  })
                }
                options={unitsDropdown}
                label={t(`observations.viewObservations.filters.unit`)}
                disabled={loading || unitsDropdown.length < 1}
              />
            </Grid>
          </>
        )}

        {(tabValue === "allObservations" || tabValue === "myObservations") && (
          <Grid item xs={12}>
            <MultiSelectInput
              label={t(`observations.viewObservations.filters.columns`)}
              disabled={loading}
              options={observationColumnDropdown}
              handler={(newValue) =>
                setFilterModal({
                  ...filterModal,
                  [key]: {
                    ...filterModal[key],
                    columnValues: newValue,
                  },
                })
              }
              values={filterModal[key].columnValues}
            />
          </Grid>
        )}
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterModal[key].filterDates}
                  name={"filterDates"}
                  onChange={() =>
                    setFilterModal({
                      ...filterModal,
                      [key]: {
                        ...filterModal[key],
                        filterDates: !filterModal[key].filterDates,
                      },
                    })
                  }
                  sx={{
                    color: theme.palette.primary.orange,
                    "&.Mui-checked": {
                      color: theme.palette.primary.orange,
                    },
                    "&:hover": {
                      backgroundColor: "rgba(248,147,31,0.08)",
                    },
                  }}
                />
              }
              disabled={loading}
              label={t(`observations.viewObservations.filters.dateRange`)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              label={t(`observations.viewObservations.filters.startDate`)}
              date={filterModal[key].startTargetDate}
              disabled={loading || !filterModal[key].filterDates}
              handler={(newDate) => {
                setFilterModal({
                  ...filterModal,
                  [key]: {
                    ...filterModal[key],
                    startTargetDate: newDate,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              label={t(`observations.viewObservations.filters.endDate`)}
              date={filterModal[key].endTargetDate}
              disabled={loading || !filterModal[key].filterDates}
              handler={(newDate) => {
                setFilterModal({
                  ...filterModal,
                  [key]: {
                    ...filterModal[key],
                    endTargetDate: newDate,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <SecondaryButton
              label={t(`observations.viewObservations.filters.save`)}
              alignment="left"
              onClick={() => submitSaveFilters("save")}
              disabled={loading}
              icon={<SaveIcon />}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <SecondaryButton
              label={t(`observations.viewObservations.filters.reset`)}
              alignment="left"
              onClick={() => submitSaveFilters("reset")}
              disabled={loading}
              icon={<ResetIcon />}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getUserPrefs = async () => {
    try {
      setLoading(true);
      const userPrefs = await getUserPreferencesApi();
      setUserPreferences(userPrefs);
      const key = tabValue;

      const prefsUpdated = {
        myObservations: {
          type:
            userPrefs.myObservationsFilterType === "ALL"
              ? []
              : userPrefs.myObservationsFilterType,
          filterDates: userPrefs.myObservationsFilterDateFlag || false,
          startTargetDate: userPrefs.myObservationsFilterDateStart || today,
          endTargetDate: userPrefs.myObservationsFilterDateEnd || today,
          observationType: userPrefs.myObservationsFilterObsType || "ALL",
          attr: userPrefs.myObservationsAttr || defaultObservationAttr,
          columnValues: userPrefs.myObservationsAttr
            ? returnUserColumns(userPrefs.myObservationsAttr)
            : observationColumnValueDefaults,
          locationId: userPrefs.myObservationsFilterLocation || "",
          directorateId: userPrefs.myObservationsFilterDirectorate || "",
          companyId: userPrefs.myObservationsFilterCompany || "",
          unitId: userPrefs.myObservationsFilterUnit || "",
        },
        allObservations: {
          type:
            userPrefs.allObservationsFilterType === "ALL"
              ? []
              : userPrefs.allObservationsFilterType,
          filterDates: userPrefs.allObservationsFilterDateFlag || false,
          startTargetDate: userPrefs.allObservationsFilterDateStart || today,
          endTargetDate: userPrefs.allObservationsFilterDateEnd || today,
          observationType: userPrefs.allObservationsFilterObsType || "ALL",
          attr: userPrefs.allObservationsAttr || defaultObservationAttr,
          columnValues: userPrefs.allObservationsAttr
            ? returnUserColumns(userPrefs.allObservationsAttr)
            : observationColumnValueDefaults,
          locationId: userPrefs.allObservationsFilterLocation || "",
          directorateId: userPrefs.allObservationsFilterDirectorate || "",
          companyId: userPrefs.allObservationsFilterCompany || "",
          unitId: userPrefs.allObservationsFilterUnit || "",
        },
        myActions: {
          type:
            userPrefs.myActionsFilterType === "ALL"
              ? []
              : userPrefs.myActionsFilterType,
          filterDates: userPrefs.myActionsFilterDateFlag || false,
          startTargetDate: userPrefs.myActionsFilterDateStart || today,
          endTargetDate: userPrefs.myActionsFilterDateEnd || today,
          observationType: userPrefs.myActionsFilterObsType || "ALL",
          attr: userPrefs.myActionsAttr || defaultObservationAttr,
          columnValues: userPrefs.myActionsAttr
            ? returnUserColumns(userPrefs.myActionsAttr)
            : observationColumnValueDefaults,
          locationId: userPrefs.myObservationsFilterLocation || "",
          directorateId: userPrefs.myObservationsFilterDirectorate || "",
          companyId: userPrefs.myObservationsFilterCompany || "",
          unitId: userPrefs.myObservationsFilterUnit || "",
        },
      };

      //  Need to get the dropdowns that are now dependent, based on filter values
      if (prefsUpdated[key].locationId) {
        const directorates = await getDirectorateFilterApi(
          prefsUpdated[key].locationId
        );
        const directorateList = directorates.data.map((d) => {
          return {
            value: d.id,
            label: d.name,
          };
        });
        setDirectoratesDropdown(directorateList);
        if (prefsUpdated[key].directorateId) {
          const companies = await getCompanyFilterApi(
            prefsUpdated[key].locationId,
            prefsUpdated[key].directorateId
          );
          const companyList = companies.data.map((c) => {
            return {
              value: c.id,
              label: c.name,
            };
          });
          setCompaniesDropdown(companyList);
          if (prefsUpdated[key].companyId) {
            const units = await getUnitFilterApi(
              prefsUpdated[key].locationId,
              prefsUpdated[key].directorateId,
              prefsUpdated[key].companyId
            );
            const unitList = units.data.map((u) => {
              return {
                value: u.id,
                label: u.name,
              };
            });
            setUnitsDropdown(unitList);
          }
        }
      }

      setFilterParams({
        ...filterParams,
        [key]: {
          observationType: prefsUpdated[key].observationType || "ALL",
          type: prefsUpdated[key].type || [],
          startTargetDate:
            prefsUpdated[key].filterDates || false
              ? prefsUpdated[key].startTargetDate
              : null,
          endTargetDate:
            prefsUpdated[key].filterDates || false
              ? prefsUpdated[key].endTargetDate
              : null,
          attr: prefsUpdated[key].attr,
          locationId: prefsUpdated[key].locationId,
          companyId: prefsUpdated[key].companyId,
          directorateId: prefsUpdated[key].directorateId,
          unitId: prefsUpdated[key].unitId,
        },
      });

      setFilterModal({ ...filterModal, ...prefsUpdated });
      setLoading(false);
      setTriggerFlag(true);
      setPreferencesLoaded(true);
    } catch (err) {
      const key = tabValue;
      const defaultPrefs = {
        myObservations: {
          type: [],
          filterDates: false,
          startTargetDate: today,
          endTargetDate: today,
          observationType: "ALL",
          attr: defaultObservationAttr,
          columnValues: observationColumnValueDefaults,
          locationId: "",
          companyId: "",
          directorateId: "",
          unitId: "",
        },
        allObservations: {
          type: [],
          filterDates: false,
          startTargetDate: today,
          endTargetDate: today,
          observationType: "ALL",
          attr: defaultObservationAttr,
          columnValues: observationColumnValueDefaults,
          locationId: "",
          companyId: "",
          directorateId: "",
          unitId: "",
        },
        myActions: {
          type: [],
          filterDates: false,
          startTargetDate: today,
          endTargetDate: today,
          observationType: "ALL",
          attr: defaultObservationAttr,
          columnValues: observationColumnValueDefaults,
          locationId: "",
          companyId: "",
          directorateId: "",
          unitId: "",
        },
      };

      setFilterParams({
        ...filterParams,
        [key]: {
          observationType: defaultPrefs[key].observationType,
          type: [],
          startTargetDate: null,
          endTargetDate: null,
          attr: defaultPrefs[key].attr,
          locationId: "",
          companyId: "",
          directorateId: "",
          unitId: "",
        },
      });
      setFilterModal({ ...filterModal, ...defaultPrefs });
      setTriggerFlag(true);
      setPreferencesLoaded(true);
      setLoading(false);
    }
  };

  const getObservations = async () => {
    setLoading(true);
    setObservationRowsLoading(true);
    try {
      //  If role allows for all observations tab 0 becomes all observations
      if (hasAccess(access, "allObservations")) {
        setTabs([
          {
            label: t("observations.viewObservations.tabs.allObservations"),
            value: 0,
            key: "allObservations",
          },
          {
            label: t("observations.viewObservations.tabs.myObservations"),
            value: 0,
            key: "myObservations",
          },
        ]);
        setFilterModal({ ...filterModal, tab: "allObservations" });
      }

      const targetParams = {
        ...filterParams[tabValue],
        pageSize: filterParams.pageSize,
        pageNo: filterParams.pageNo,
        searchTerm: filterParams.searchTerm,
        sort: filterParams.sort,
      };

      if (tabValue === "allObservations" || tabValue === "myObservations") {
        let finalParams = {
          ...targetParams,
          ...(targetParams.startTargetDate
            ? {
                startTargetDate: moment(targetParams.startTargetDate)
                  .startOf("day")
                  .toISOString(),
              }
            : {}),
          ...(targetParams.endTargetDate
            ? {
                endTargetDate: moment(targetParams.endTargetDate)
                  .endOf("day")
                  .toISOString(),
              }
            : {}),
        };

        if (tabValue === "myObservations") {
          finalParams = { ...targetParams, mine: true };
        }

        if (targetParams.observationType !== "ALL") {
          finalParams = {
            ...finalParams,
            safeObservation:
              targetParams.observationType === "SAFE" ? true : false,
          };
        }
        const type =
          finalParams.type?.length > 0
            ? finalParams.type?.map((item) => item.value)
            : [];
        finalParams.type = type;
        const statsData = await getStatsApi(finalParams);
        const observationsData = await getObservationsApi(finalParams);

        const parsedData = observationsData.data.map((r) => {
          return {
            id: r.observationId,
            dbId: r.id,
            status: r.status,
            location: r.location ? r.location.name : "",
            observer: r.observerName || "",
            observationDate: moment(r.observedAt).format("DD/MM/YY hh:mm a"),
            discipline: r.discipline ? r.discipline.name : "",
            directorate: r.directorate ? r.directorate.name : "",
            unit: r.unit ? r.unit.name : "",
            observationType: r.observationType,
            observationSubType: r.observationSubType,
            isAnonymous: r.isAnonymous ? t("general.yes") : t("general.no"),
            isEscalated: r.isEscalated ? t("general.yes") : t("general.no"),
            feedback: r.feedback || t("general.none"),
            rootCause: r.rootCause || t("general.none"),
            actionToPrevent: r.actionToPrevent || t("general.none"),
            appreciation: r.appreciation || t("general.none"),
            markedAsCompleteAt: r.markedAsCompleteAt
              ? moment(r.markedAsCompleteAt).format("DD/MM/YY hh:mm a")
              : t("general.notCompleted"),
            safeObservation: r.safeObservation
              ? t("general.yes")
              : t("general.no"),
            nearMissMarkedAt: r.nearMissMarkedAt
              ? moment(r.nearMissMarkedAt).format("DD/MM/YY hh:mm a")
              : t("general.notCompleted"),
            actions:
              r.actions && r.actions.length > 0
                ? r.actions[0].comment
                : t("observations.viewObservations.filterError.noActions"),
            // behavior: r.behavior,
            observerType: r.observerType,
            attachments: r.attachments ? r.attachments.length : 0,
            lessonLearnt: r.lessonLearnt ? t("general.yes") : t("general.no"),
          };
        });

        if (preferencesLoaded) {
          setObservationColumns(
            generateColumns(filterModal[tabValue].columnValues)
          );
        }
        if (tabValue === "myObservations") {
          setMyStats(statsData);
        } else {
          setStats(statsData);
        }
        setObservationRows(parsedData);
        setTableInfo({
          totalCount: observationsData.totalItems,
          totalPages: observationsData.totalPages,
        });
      } else if (tabValue === "myActions") {
        const payload = {
          ...targetParams,
          ...(targetParams.startTargetDate
            ? {
                startTargetDate: moment(targetParams.startTargetDate)
                  .startOf("day")
                  .toISOString(),
              }
            : {}),
          ...(targetParams.endTargetDate
            ? {
                endTargetDate: moment(targetParams.endTargetDate)
                  .endOf("day")
                  .toISOString(),
              }
            : {}),
        };

        const actionsData = await getAssignedActionsApi(payload);
        const parsedData = actionsData.data.map((a) => {
          return {
            id: a.id,
            observationId: a.observation.id,
            status: a.status,
            actionRequired: a.actionRequired,
            generatedActionId: a.generatedActionId,
            observer: a.observation.observer
              ? `${a.observation.observer.firstName} ${a.observation.observer.lastName}`
              : "",
            targetDate: moment(a.targetDate).format("DD/MM/YY hh:mm a"),
          };
        });
        setActionRows(parsedData);
        setObservationRows(parsedData);
        setTableInfo({
          totalCount: actionsData.totalItems,
          totalPages: actionsData.totalPages,
        });
      }
      setLoading(false);
      setObservationRowsLoading(false);
    } catch (err) {
      displayToast(setLoading, "error", err);
      setLoading(false);
      setObservationRowsLoading(false);
    }
  };

  const getLocations = async () => {
    setLoading(true);
    try {
      const locationsData = await getLocationFilterApi();
      if (locationsData.data) {
        const locationsList = locationsData.data.map((l) => {
          return { label: l.name, value: l.id };
        });
        setLocationsDropdown(locationsList);
      } else {
        setLocationsDropdown([]);
      }
      setLoading(false);
    } catch (err) {
      displayToast(setLoading, "error", err);
      console.log(err);
    }
  };

  const getCompanies = async (locationId, directorateId) => {
    setLoading(true);
    try {
      const companiesData = await getCompanyFilterApi(
        locationId,
        directorateId
      );
      if (companiesData.data) {
        const companiesList = companiesData.data.map((l) => {
          return { label: l.name, value: l.id };
        });
        setCompaniesDropdown(companiesList);
      } else {
        setCompaniesDropdown([]);
      }

      setLoading(false);
    } catch (err) {
      displayToast(setLoading, "error", err);
      console.log(err);
    }
  };

  const getDirectorates = async (locationId) => {
    setLoading(true);
    try {
      const directorateData = await getDirectorateFilterApi(locationId);
      if (directorateData.data) {
        const directorateList = directorateData.data.map((l) => {
          return { label: l.name, value: l.id };
        });
        setDirectoratesDropdown(directorateList);
      } else {
        setDirectoratesDropdown([]);
      }

      setLoading(false);
    } catch (err) {
      displayToast(setLoading, "error", err);
      console.log(err);
    }
  };

  const getUnits = async (locationId, directorateId, companyId) => {
    setLoading(true);
    try {
      const unitData = await getUnitFilterApi(
        locationId,
        directorateId,
        companyId
      );
      if (unitData.data) {
        const unitList = unitData.data.map((l) => {
          return { label: l.name, value: l.id };
        });
        setUnitsDropdown(unitList);
      } else {
        setUnitsDropdown([]);
      }

      setLoading(false);
    } catch (err) {
      displayToast(setLoading, "error", err);
      console.log(err);
    }
  };

  const handleLocationChange = (e) => {
    if (e.target.value) {
      const key = filterModal.tab;
      setFilterModal({
        ...filterModal,
        [key]: {
          ...filterModal[key],
          locationId: e.target.value,
          directorateId: "",
          unitId: "",
          companyId: "",
        },
      });
      setDirectoratesDropdown([]);
      setUnitsDropdown([]);
      setCompaniesDropdown([]);
      getDirectorates(e.target.value);
    } else {
      const key = filterModal.tab;
      setFilterModal({
        ...filterModal,
        [key]: {
          ...filterModal[key],
          locationId: "",
          directorateId: "",
          unitId: "",
          companyId: "",
        },
      });
      setDirectoratesDropdown([]);
      setUnitsDropdown([]);
      setCompaniesDropdown([]);
    }
  };

  const handleDirectorateChange = (e) => {
    if (e.target.value) {
      const key = filterModal.tab;
      setFilterModal({
        ...filterModal,
        [key]: {
          ...filterModal[key],
          directorateId: e.target.value,
          unitId: "",
          companyId: "",
        },
      });
      setUnitsDropdown([]);
      setCompaniesDropdown([]);
      getCompanies(filterModal[key].locationId, e.target.value);
    } else {
      const key = filterModal.tab;
      setFilterModal({
        ...filterModal,
        [key]: {
          ...filterModal[key],
          directorateId: "",
          unitId: "",
          companyId: "",
        },
      });
      setUnitsDropdown([]);
      setCompaniesDropdown([]);
    }
  };

  const handleCompanyChange = (e) => {
    if (e.target.value) {
      const key = filterModal.tab;
      setFilterModal({
        ...filterModal,
        [key]: {
          ...filterModal[key],
          unitId: "",
          companyId: e.target.value,
        },
      });
      setUnitsDropdown([]);
      getUnits(
        filterModal[key].locationId,
        filterModal[key].directorateId,
        e.target.value
      );
    } else {
      const key = filterModal.tab;
      setFilterModal({
        ...filterModal,
        [key]: {
          ...filterModal[key],
          unitId: "",
          companyId: "",
        },
      });
      setUnitsDropdown([]);
    }
  };

  const handleSortModelChange = (newModel) => {
    if (newModel.length > 0) {
      setFilterParams({
        ...filterParams,
        sort: newModel[0],
      });
    } else {
      setFilterParams({ ...filterParams, sort: null });
    }
    setTriggerFlag(true);
  };

  useEffect(() => {
    getUserPrefs();
  }, [tabValue]);

  useEffect(() => {
    setTriggerFlag(false);
    if (triggerFlag || !userPreferences.myActionsFilterObsType) {
      getObservations();
    }
  }, [filterParams, tabValue, access, t, triggerFlag]);

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <Page title={t("sideBar.viewObservations")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">{t("observations.title")}</Typography>
          </div>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} style={{ paddingTop: "20px" }}>
            <TabsBar value={tabValue} handler={handleTabChange} tabs={tabs} />
          </Grid>
          {tabValue !== "myTasks" && (
            <Grid item xs={12} md={12} style={{ paddingTop: "20px" }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TableSearch
                  searchTerm={search}
                  handler={(e) => setSearch(e.target.value)}
                  disabled={loading}
                  submitSearch={submitSearch}
                  clearable={filterParams.searchTerm}
                  clearHandler={() => {
                    setSearch("");
                    setFilterParams({
                      ...filterParams,
                      pageNo: 1,
                      searchTerm: "",
                    });
                    setTriggerFlag(true);
                  }}
                />
                <Tooltip title={t("general.advancedFilters")}>
                  <IconButtonStyled
                    aria-label={"Open filters"}
                    onClick={() => {
                      setFilterTypesDropdown(observationFilterTypesDefault);
                      setFilterModal({
                        ...filterModal,
                        open: true,
                        tab: tabValue,
                      });
                    }}
                  >
                    <Badge color="primary" variant="dot">
                      <IconStyled />
                    </Badge>
                  </IconButtonStyled>
                </Tooltip>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12} lg={12}>
            {tabValue === "myActions" ? (
              <DefaultTable
                rows={actionRows}
                columns={actionColumnDefaults}
                loading={loading}
                tableInfo={tableInfo}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                triggerFunction={setTriggerFlag}
                topTextContent={
                  <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
                    {filterParams.searchTerm && (
                      <Typography
                        variant="body2"
                        style={{
                          color: theme.palette.primary.grey,
                          fontWeight: "bold",
                        }}
                      >{`${t("table.searchResults")} "${
                        filterParams.searchTerm
                      }" `}</Typography>
                    )}
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.orange,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.totalListed")} ${
                      tableInfo.totalCount
                    } `}</Typography>
                  </Stack>
                }
                onRowClick={(r) =>
                  navigate(
                    `/actions/details/${r.row.observationId}/${r.row.id}`
                  )
                }
              />
            ) : (
              <DefaultTable
                rows={observationRows}
                columns={observationColumns}
                loading={observationRowsLoading}
                tableInfo={tableInfo}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                triggerFunction={setTriggerFlag}
                handleSortModelChange={handleSortModelChange}
                onRowClick={(p) =>
                  navigate(`/observations/details/${p.row.dbId}`)
                }
                topTextContent={
                  <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
                    {filterParams.searchTerm && (
                      <Typography
                        variant="body2"
                        style={{
                          color: theme.palette.primary.grey,
                          fontWeight: "bold",
                        }}
                      >{`Displaying search results for "${filterParams.searchTerm}" `}</Typography>
                    )}
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.orange,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.totalListed")} ${
                      tableInfo.totalCount
                    } `}</Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.buttonOrange,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.pending")}: ${
                      tabValue === "myObservations"
                        ? myStats.pending
                        : stats.pending
                    } `}</Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.buttonOrange,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.assigned")}: ${
                      tabValue === "myObservations"
                        ? myStats.actionAssigned
                        : stats.actionAssigned
                    } `}</Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.buttonOrange,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.closed")}: ${
                      tabValue === "myObservations"
                        ? myStats.closed
                        : stats.closed
                    } `}</Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.buttonOrange,
                        fontWeight: "bold",
                      }}
                    >{`Near miss: ${
                      tabValue === "myObservations"
                        ? myStats.nearMiss
                        : stats.nearMiss
                    } `}</Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.buttonOrange,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.totalObservations")}: ${
                      tabValue === "myObservations"
                        ? myStats.total
                        : stats.total
                    } `}</Typography>
                  </Stack>
                }
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <InfoBox
        compact
        open={filterModal.open}
        title={t("observations.viewObservations.filters.title")}
        content={renderObservationFilters()}
        buttonLabel={t("general.applyFilters")}
        handleClose={() => setFilterModal({ ...filterModal, open: false })}
        handleAction={submitFilter}
        minWidth="1000px"
      />
    </Page>
  );
}
