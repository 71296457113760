import toast from "react-hot-toast";

export const openAttachment = (attachmentLinks, targetId) => {
  let attachmentUrl = "";
  attachmentLinks.forEach((a) => {
    if (a.id === targetId) {
      attachmentUrl = a.url;
    }
  });
  const pdfWindow = window.open();
  pdfWindow.location.href = attachmentUrl;
};

export const generateFormData = (json) => {
  const formData = new FormData();
  Object.keys(json).forEach((key) => {
    if (key === "attachments") {
      json.attachments.forEach((f) => {
        formData.append(key, f);
      });
    } else if (key === "noShows") {
      formData.append(key, json[key]);
    } else if (json[key] || json[key] === false) {
      formData.append(key, json[key]);
    }
  });
  return formData;
};

export const displayToast = (loadingFunction, state, message, toastId) => {
  toast.remove();
  switch (state) {
    case "loading":
      const thisToaster = toast.loading(message, { duration: Infinity });
      loadingFunction(true);
      return thisToaster;

    case "pureLoading":
      toast.loading(message, { duration: 2000 });
      loadingFunction(true);
      break;

    case "success":
      loadingFunction(false);
      toast.success(message, { id: toastId });
      break;

    case "successLoading":
      toast.success(message, { id: toastId });
      break;

    case "error":
      loadingFunction(false);
      toast.error(message, { id: toastId });
      break;

    case "pureError":
      if (loadingFunction) {
        loadingFunction(false);
      }
      toast.error(message);
      break;

    case "downloadWait":
      loadingFunction(false);
      toast.success(message);
      break;

    case "downloadDone":
      loadingFunction(false);
      toast.success(message);
      break;

    case "downloadError":
      loadingFunction(false);
      toast.error(message);
      break;

    case "scheduleSuccess":
      loadingFunction(false);
      toast.success(message);
      break;

    case "scheduleError":
      loadingFunction(false);
      toast.error(message);
      break;

    default:
      console.log("displayToast: Supply proper parameters please.");
      return null;
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getMonthDiff = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const diff =
    end.getMonth() -
    start.getMonth() +
    12 * (end.getFullYear() - start.getFullYear());

  return diff;
};

export const loaderStyle = {
  display: "flex",
  height: "80%",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
};

export const returnPositive = (value) => {
  if (!value || isNaN(value)) {
    return 0;
  } else {
    return Math.abs(parseFloat(value).toFixed(0));
  }
};

export const percentFormatter = (val) => {
  return val ? `${(val * 100).toFixed(0)}%` : "";
};

export const recolor = (color, amount) => {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
};

export const checkBetweenRange = (min, max, input) => {
  if (input > min && input < max) {
    return parseFloat(input);
  } else {
    return 0;
  }
};

export const returnValidUrl = (url) => {
  const pattern = /^((http|https):\/\/)/;

  if (!pattern.test(url)) {
    return "http://" + url;
  } else {
    return url;
  }
};

export const returnColourArray = () => {
  return [
    "#00ff00",
    "#ff0000",
    "#ff922b",
    "#ffd43b",
    "#94d82d",
    "#12b886",
    "#22b8cf",
    "#228be6",
    "#bac8ff",
    "#be4bdb",
    "#f06595",
    "#f03e3e",
    "#5D737E",
    "#002642",
    "#5DFDCB",
    "#7CC6FE",
    "#69db7c",
  ];
};
