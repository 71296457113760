import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  padding: "14px",
  borderRadius: "50%",
  background: theme.palette.primary.grey,
  "&:hover": {
    background: theme.palette.primary.hoverGrey,
  },
  height: "40px",
  width: "40px",
}));

const RoundedButton = ({ label, icon, onClick, disabled }) => {
  return (
    <Tooltip title={label}>
      <IconButtonStyle aria-label={label} onClick={onClick} disabled={disabled}>
        {icon}
      </IconButtonStyle>
    </Tooltip>
  );
};

RoundedButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RoundedButton;
