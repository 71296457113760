import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useTheme, styled } from "@mui/material/styles";
import { Typography, Grid, Divider, useMediaQuery, Stack } from "@mui/material";
import PageLoader from "../PageLoader";

ActivityTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  tableInfo: PropTypes.object,
  onRowClick: PropTypes.func,
  topTextContent: PropTypes.node,
};

const HeaderStyled = styled("div")(({ theme }) => ({
  width: "99.5%",
  color: "#A3A3A3",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
  boxShadow: " 0px 4px 9px rgba(0, 0, 0, 0.11)",
  borderRadius: "6px",
  border: "1px solid #f1f3f4",
  minHeight: "56px",
  maxHeight: "56px",
  lineHeight: " 56px",
}));

const MobileHeaderStyled = styled("div")(({ theme }) => ({
  color: "#A3A3A3",
  background:
    "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
  boxShadow: " 0px 4px 9px rgba(0, 0, 0, 0.11)",
  borderRadius: "6px",
  border: "1px solid #f1f3f4",
  minHeight: "56px",
  maxHeight: "56px",
  lineHeight: " 56px",
  width: "1000px",
}));

export default function ActivityTable({
  rows,
  columns,
  loading,
  tableInfo,
  topTextContent,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const { totalCount } = tableInfo;
  const [widthDictionary, setWidthDictionary] = useState({});

  //    styles
  const dataRowStyle = {
    width: isMobile ? "1000px" : "99.5%",
    color: theme.palette.primary.black,
    background:
      "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
    boxShadow: " 0px 4px 9px rgba(0, 0, 0, 0.11)",
    borderRadius: "6px",
    border: "1px solid #f1f3f4",
    margin: "6px 0",
    minHeight: "56px",
  };

  const headerCellStyle = {
    paddingLeft: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "56px",
  };

  const rowCellStyle = {
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  };

  const dividerStyle = {
    borderColor: "#f1f3f4",
    margin: "21px 0",
    borderRightWidth: "2px",
  };

  //  functions
  const calculateWidths = () => {
    const newDict = {};
    columns.forEach((c) => {
      let calculatedWidth = 0;
      if (c.width) {
        calculatedWidth = Math.ceil(c.width / 12);
      }

      newDict[c.field] = calculatedWidth;
    });

    setWidthDictionary(newDict);
  };

  useEffect(() => {
    if (columns) {
      calculateWidths();
    }
  }, [columns]);

  if (isMobile) {
    return (
      <div style={{ overflowX: "auto" }}>
        {topTextContent ? (
          topTextContent
        ) : (
          <Typography
            variant="body2"
            style={{
              marginBottom: "15px",
              color: theme.palette.primary.orange,
              fontWeight: "bold",
            }}
          >{`${t("table.totalListed")} ${totalCount} `}</Typography>
        )}
        <MobileHeaderStyled>
          <Stack direction="row">
            {columns &&
              columns.map((c, index) => (
                <div
                  style={{
                    ...headerCellStyle,
                    width: `${1000 / columns.length}px`,
                  }}
                >
                  <Typography style={{ fontSize: "14px" }}>
                    {c.headerName}
                  </Typography>
                  {index !== columns.length && (
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      style={dividerStyle}
                    />
                  )}
                </div>
              ))}
          </Stack>
        </MobileHeaderStyled>
        {loading ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PageLoader />
          </div>
        ) : (
          rows &&
          rows.map((r) => (
            <Stack style={dataRowStyle} direction="row">
              {Object.keys(widthDictionary).map((keyName) => (
                <div
                  style={{ ...rowCellStyle, width: `${1000 / rows.length}px` }}
                >
                  {columns.find((c) => c.field === keyName).renderCell
                    ? columns
                        .find((c) => c.field === keyName)
                        .renderCell({ row: r })
                    : r[keyName]}
                </div>
              ))}
            </Stack>
          ))
        )}
      </div>
    );
  } else {
    return (
      <div style={{ width: "100%" }}>
        {topTextContent ? (
          topTextContent
        ) : (
          <Typography
            variant="body2"
            style={{
              marginBottom: "15px",
              color: theme.palette.primary.orange,
              fontWeight: "bold",
            }}
          >{`${t("table.totalListed")} ${totalCount} `}</Typography>
        )}
        <HeaderStyled>
          <Grid container>
            {columns &&
              columns.map((c, index) => (
                <Grid
                  item
                  xs={widthDictionary[c.field] || 12}
                  style={headerCellStyle}
                >
                  <Typography style={{ fontSize: "14px" }}>
                    {c.headerName}
                  </Typography>
                  {index !== columns.length && (
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      style={dividerStyle}
                    />
                  )}
                </Grid>
              ))}
          </Grid>
        </HeaderStyled>
        {loading ? (
          <div
            style={{
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PageLoader />
          </div>
        ) : (
          rows &&
          rows.map((r) => (
            <Grid container style={dataRowStyle}>
              {Object.keys(widthDictionary).map((keyName) => (
                <Grid
                  item
                  xs={widthDictionary[keyName] || 12}
                  style={rowCellStyle}
                >
                  {columns.find((c) => c.field === keyName).renderCell
                    ? columns
                        .find((c) => c.field === keyName)
                        .renderCell({ row: r })
                    : r[keyName]}
                </Grid>
              ))}
            </Grid>
          ))
        )}
      </div>
    );
  }
}
