import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
// material
import { Box, Container, Typography } from "@mui/material";
// components
import Page from "../../../components/Page";
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import TabsBar from "../../../components/navigation/TabsBar";
import DirectorateQualityView from "./DirectorateQualityView";
import DirectorateTrendsView from "./DirectorateTrendsView";
import DirectorateLsrView from "./DirectorateLsrView";

export default function DirectorateDashboard() {
  const { t } = useTranslation();
  const { tab, directorateId } = useParams();
  const { state } = useLocation();
  const [tabValue, setTabValue] = useState(tab || "quality");

  const renderDashboardsView = () => (
    <div>
      <div>
        <TabsBar
          value={tabValue}
          handler={(event, value) => setTabValue(value)}
          tabs={[
            {
              label: t("dashboards.implementationDashboards.quality"),
              value: 0,
              key: "quality",
            },
            {
              label: t("dashboards.implementationDashboards.lsrs"),
              value: 0,
              key: "lsrs",
            },
            {
              label: t("dashboards.implementationDashboards.smartTrends"),
              value: 0,
              key: "smartTrends",
            },
          ]}
        />
      </div>
      {tabValue === "quality" && (
        <DirectorateQualityView
          directorateId={directorateId}
          directorateName={state.directorateName}
        />
      )}
      {tabValue === "lsrs" && (
        <DirectorateLsrView
          directorateId={directorateId}
          directorateName={state.directorateName}
        />
      )}
      {tabValue === "smartTrends" && (
        <DirectorateTrendsView
          directorateId={directorateId}
          alertCompanyId={state?.companyId || null}
          state={state}
        />
      )}
    </div>
  );

  return (
    <Page title={`${state.directorateName} ${t("sideBar.dashboards")}`}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {t("dashboards.dashboards", {
                name: state.directorateName,
              })}
            </Typography>
          </div>
        </Box>
        {renderDashboardsView()}
      </Container>
    </Page>
  );
}
