/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { heatMapOptions } from "./constants";
import { subDays, addDays } from "date-fns";
import { safeUnsafeOptions } from "../directorateDashboard/constants";
import moment from "moment";
// material
import { Grid, Stack, Card, Typography, Tooltip } from "@mui/material";
import NoDataIcon from "@mui/icons-material/ErrorOutline";
// components
import SelectInput from "../../../components/inputs/SelectInput";
import HeatmapChart from "../../../components/charts/HeatmapChart";
import TableChart from "../../../components/charts/TableChart";
import DateRangeInput from "../../../components/inputs/DateRangeInput";
import PageLoader from "../../../components/PageLoader";
import {
  IconButtonStyled,
  IconStyled,
} from "../../observation/ListObservations/styledComponents";
import HelpIcon from "../../../components/HelpIcon";
//  API
import {
  getQualityDisciplineRelevanceRootCauseApi,
  getQualityLocationRelevanceRootCauseApi,
  getRelevanceTableApi,
} from "../../../api/qualityDashboard";
import { getDisciplineFilters } from "../utils";
// ----------------------------------------------------------------------

const cardStyling = {
  height: 400,
  padding: "16px ",
  borderRadius: "6px",
};

const loaderStyling = {
  height: "90%",
  display: "flex",
  alignItems: "center",
};

export default function FeedbackBehavior({
  companyId,
  locationOptions = [],
  unitOptions = [],
  state,
}) {
  const yearToDate = new Date(new Date().getFullYear(), 0, 1);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [disciplineFilter, setDisciplineFilter] = useState("all");
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [locationFilter, setLocationFilter] = useState("all");
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [unitFilter, setUnitFilter] = useState("all");
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [obsType, setObsType] = useState("Unsafe");
  const [dateFilter, setDateFilter] = useState({
    start: state.alertStartDate
      ? subDays(new Date(state.alertStartDate), 31)
      : yearToDate,
    end: state.alertStartDate
      ? new Date(state.alertStartDate)
      : moment().diff(yearToDate, "days") < 31
      ? addDays(yearToDate, 31)
      : new Date(),
  });
  const [disciplineRelevanceData, setDisciplineRelevanceData] = useState([]);
  const [locationRelevanceData, setLocationRelevanceData] = useState([]);
  const [filterParams, setFilterParams] = useState({
    directorateId: state.directorateId,
    disciplineId: "all",
    locationId: "all",
    startDate: state.alertStartDate
      ? subDays(new Date(state.alertStartDate), 31)
      : yearToDate,
    endDate: state.alertStartDate
      ? new Date(state.alertStartDate)
      : moment().diff(yearToDate, "days") < 31
      ? addDays(yearToDate, 31)
      : new Date(),
  });
  const [pageFilters, setPageFilters] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const [tableInfo, setTableInfo] = useState({ totalPages: 1, totalCount: 10 });
  const [tableLoading, setTableLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [discDataLoaded, setDiscDataLoaded] = useState(false);
  const [locDataLoaded, setLocDataLoaded] = useState(false);

  const tableColumns = [
    {
      headerName: t("table.obsCardNo"),
      field: "observationId",
    },
    {
      headerName: t("table.location"),
      field: "locationName",
    },
    {
      headerName: t("table.discipline"),
      field: "disciplineName",
    },
    {
      headerName: t("table.suggestedRootCause"),
      field: "suggestedRootCause",
    },
    {
      headerName: t("table.feedback"),
      field: "feedback",
    },
    {
      headerName: t("table.relevanceScore"),
      field: "relevanceScore",
    },
  ];

  const renderFilters = () => (
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.locationTitle")}
          disabled={loading || locationDropdown.length < 1}
          value={locationFilter}
          handler={(e) => setLocationFilter(e.target.value)}
          options={locationDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.unitTitle")}
          disabled={loading || unitDropdown.length < 1}
          value={unitFilter}
          handler={(e) => setUnitFilter(e.target.value)}
          options={unitDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.disciplineTitle")}
          disabled={loading || disciplineDropdown.length < 1}
          value={disciplineFilter}
          handler={(e) => setDisciplineFilter(e.target.value)}
          options={disciplineDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.observations")}
          disabled={loading}
          value={obsType}
          handler={(e) => setObsType(e.target.value)}
          options={safeUnsafeOptions}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <DateRangeInput
          disabled={loading}
          label={t("observations.viewObservations.filters.dateRangeButton")}
          startDate={dateFilter.start}
          endDate={dateFilter.end}
          handler={(start, end) => setDateFilter({ start, end })}
        />
      </Grid>
      <Grid item xs={1} sm={1}>
        <Tooltip title={t("general.applyFilters")}>
          <IconButtonStyled
            aria-label={t("general.applyFilters")}
            onClick={submitFilters}
          >
            <IconStyled />
          </IconButtonStyled>
        </Tooltip>
      </Grid>
    </Grid>
  );

  const renderNoData = () => (
    <Stack
      spacing={1}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <NoDataIcon />
      <Typography variant="body1">{t("general.noData")}</Typography>
    </Stack>
  );

  const renderHeatmapCharts = () => (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Card style={cardStyling}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {t("dashboards.implementationDashboards.disciplineRelevance")}
            </Typography>
            <HelpIcon
              text={t(
                "dashboards.tooltips.contractor.feedbackRootCause.disciplineHeatmap"
              )}
            />
          </Stack>

          {!discDataLoaded ? (
            <div style={loaderStyling}>
              <PageLoader />
            </div>
          ) : disciplineRelevanceData.length > 0 ? (
            <HeatmapChart
              height="100%"
              chartData={disciplineRelevanceData}
              chartOptions={heatMapOptions}
            />
          ) : (
            renderNoData()
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} style={{ paddingLeft: "12px" }}>
        <Card style={cardStyling}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {t("dashboards.implementationDashboards.locationRelevance")}
            </Typography>
            <HelpIcon
              text={t(
                "dashboards.tooltips.contractor.feedbackRootCause.locationHeatmap"
              )}
            />
          </Stack>

          {!locDataLoaded ? (
            <div style={loaderStyling}>
              <PageLoader />
            </div>
          ) : locationRelevanceData.length > 0 ? (
            <HeatmapChart
              height="100%"
              chartData={locationRelevanceData}
              chartOptions={heatMapOptions}
            />
          ) : (
            renderNoData()
          )}
        </Card>
      </Grid>
    </Grid>
  );

  const renderRelevanceTable = () => (
    <Card style={{ ...cardStyling, height: "auto" }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {t("dashboards.implementationDashboards.relevanceTable")}
        </Typography>
        <HelpIcon
          text={t("dashboards.tooltips.contractor.feedbackRootCause.table")}
        />
      </Stack>

      {tableLoading || loading ? (
        <div style={loaderStyling}>
          <PageLoader />
        </div>
      ) : (
        <TableChart
          columns={tableColumns}
          rows={rows}
          filterParams={pageFilters}
          setFilterParams={setPageFilters}
          tableInfo={tableInfo}
          loading={tableLoading}
        />
      )}
    </Card>
  );

  const getDisciplineRelevanceHeatmap = async () => {
    try {
      setLoading(true);
      const rawData = await getQualityDisciplineRelevanceRootCauseApi({
        ...filterParams,
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
        startDate: moment(filterParams.startDate).startOf("day").toISOString(),
        endDate: moment(filterParams.endDate).endOf("day").toISOString(),
        companyId,
        obsType: obsType === "all" ? null : obsType,
      });
      setDisciplineRelevanceData(rawData);
      setDiscDataLoaded(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getLocationRelevanceHeatmap = async () => {
    try {
      setLoading(true);
      const rawData = await getQualityLocationRelevanceRootCauseApi({
        ...filterParams,
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
        startDate: moment(filterParams.startDate).startOf("day").toISOString(),
        endDate: moment(filterParams.endDate).endOf("day").toISOString(),
        companyId,
        obsType: obsType === "all" ? null : obsType,
      });
      setLocationRelevanceData(rawData);
      setLocDataLoaded(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getTableData = async (startDate, endDate) => {
    try {
      setTableLoading(true);
      const data = await getRelevanceTableApi({
        ...pageFilters,
        type: "suggestedRootCause",
        directorateId: state.directorateId,
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        locationId: locationFilter === "all" ? null : locationFilter,
        startDate: moment(startDate).startOf("day").toISOString(),
        endDate: moment(endDate).endOf("day").toISOString(),
        companyId,
        obsType: obsType === "all" ? null : obsType,
      });
      const finalRows = data.data
        ? data.data.map((row) => {
            return {
              ...row,
              relevanceScore: row.relevanceScore
                ? `${(row.relevanceScore * 100).toFixed(0)}%`
                : t("general.noData"),
            };
          })
        : [];
      setRows(finalRows);
      setTableInfo({
        totalPages: data.totalPages,
        totalCount: data.totalItems,
      });
      setTableLoading(false);
    } catch (err) {
      setTableLoading(false);
    }
  };

  const submitFilters = () => {
    getTableData(dateFilter.start, dateFilter.end);
    setFilterParams({
      ...filterParams,
      disciplineId: disciplineFilter,
      locationId: locationFilter,
      startDate: dateFilter.start,
      endDate: dateFilter.end,
      obsType: obsType === "all" ? null : obsType,
    });
  };

  useEffect(() => {
    setLocationDropdown(locationOptions);
    setUnitDropdown(unitOptions);
  }, [locationOptions, unitOptions]);

  useEffect(() => {
    getDisciplineRelevanceHeatmap();
    getLocationRelevanceHeatmap();
  }, [filterParams]);

  useEffect(() => {
    getTableData(filterParams.startDate, filterParams.endDate);
  }, [pageFilters]);

  useEffect(() => {
    if (companyId && locationFilter && locationFilter !== "all") {
      setDisciplineFilter("all");
      getDisciplineFilters({
        setLoading,
        companyFilter: companyId,
        locationFilter,
        unitFilter,
        setDisciplineDropdown,
      });
    }
  }, [companyId, locationFilter]);

  return (
    <Stack spacing={2} direction="column">
      {renderFilters()}
      {renderHeatmapCharts()}
      {renderRelevanceTable()}
    </Stack>
  );
}
