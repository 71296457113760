import "./styles.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { safeUnsafeOptions } from "./constants";
import { percentFormatter } from "../../../utils/general";
// material
import { Grid, Stack, Card, Typography, Tooltip, Box } from "@mui/material";
import NoDataIcon from "@mui/icons-material/ErrorOutline";
// components
import SelectInput from "../../../components/inputs/SelectInput";
import HeatmapChart from "../../../components/charts/HeatmapChart";
import PageLoader from "../../../components/PageLoader";
import PopperBox from "../../../components/PopperBox";
import HelpIcon from "../../../components/HelpIcon";
import {
  IconButtonStyled,
  IconStyled,
  MoreIconStyled,
} from "../../observation/ListObservations/styledComponents";
import AiLegend from "../../../components/AiLegend";
//  API
import {
  getQualityBreakdownCompaniesApi,
  getQualityHeatmapCompaniesApi,
} from "../../../api/qualityDashboard";
import {
  getCompanyFilters,
  getLocationsAndUnitsFilters,
  getDisciplineFilters,
  returnAIColor,
  heatmapColors,
} from "../utils";
// ----------------------------------------------------------------------

const cardStyling = {
  height: 600,
  padding: "16px ",
  borderRadius: "6px",
};

const loaderStyling = {
  height: "90%",
  display: "flex",
  alignItems: "center",
};

const breakdownCardStyling = {
  borderRadius: "6px",
  border: "1px solid #E4E4E4",
  padding: "8px",
};

export default function DirectorateQualityView({
  directorateId,
  directorateName,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [companyFilter, setCompanyFilter] = useState("all");
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [disciplineFilter, setDisciplineFilter] = useState("all");
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [locationFilter, setLocationFilter] = useState("all");
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [unitFilter, setUnitFilter] = useState("all");
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [obsType, setObsType] = useState("Unsafe");
  const [qualityScoreData, setQualityScoreData] = useState([]);
  const [qualityBreakdownData, setQualityBreakdownData] = useState(null);
  const [companyNames, setCompanyNames] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState(null);

  const heatMapOptions = {
    chart: {
      fontFamily: "Cairo, sans-serif",
      events: {
        click: (event, chartContext, config) => {
          if (
            event.toElement?.nextSibling?.innerHTML &&
            event.target.parentNode.classList.contains(
              "quality-score-heatmap-yaxis-label"
            )
          ) {
            const targetName = event.toElement?.nextSibling?.innerHTML;
            const finalText = targetName.replace(/&amp;/g, "&");
            const targetInList = companyNames.find(
              (cObj) => cObj.name === finalText
            );
            if (targetInList) {
              navigate(
                `/dashboard/company-dashboard/quality/${targetInList.id}`,
                {
                  state: {
                    companyName: finalText,
                    directorateId,
                    directorateName,
                  },
                }
              );
            }
          }
        },
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        colorScale: {
          ranges: heatmapColors,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => (val ? `${val.toFixed(0)}%` : ""),
      style: {
        fontSize: "14px",
      },
    },
    tooltip: {
      y: {
        formatter: (val) => (val ? `${val.toFixed(0)}%` : ""),
      },
    },
    xaxis: {
      type: "category",
      position: "top",
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: true,
    },
    legend: {
      show: true,
      position: "bottom",
      showForSingleSeries: true,
      offsetY: -10,
      markers: {
        width: 8,
        height: 8,
        offsetY: -1,
      },
    },
    yaxis: {
      labels: {
        style: {
          cssClass: "quality-score-heatmap-yaxis-label",
        },
      },
    },
  };

  const renderFilters = () => (
    <Grid container spacing={1} sx={{ mt: 1 }} alignItems="flex-end">
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.companyTitle")}
          disabled={loading || companyDropdown.length < 1}
          value={companyFilter}
          handler={(e) => setCompanyFilter(e.target.value)}
          options={companyDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.locationTitle")}
          disabled={loading || locationDropdown.length < 1}
          value={locationFilter}
          handler={(e) => setLocationFilter(e.target.value)}
          options={locationDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.unitTitle")}
          disabled={loading || unitDropdown.length < 1}
          value={unitFilter}
          handler={(e) => setUnitFilter(e.target.value)}
          options={unitDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.disciplineTitle")}
          disabled={loading || disciplineDropdown.length < 1}
          value={disciplineFilter}
          handler={(e) => setDisciplineFilter(e.target.value)}
          options={disciplineDropdown}
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <Stack spacing={1} direction="row">
          <Tooltip title={t("general.moreFilters")}>
            <IconButtonStyled
              aria-label={"More filters"}
              onClick={(e) => setPopperAnchor(e.currentTarget)}
            >
              <MoreIconStyled />
            </IconButtonStyled>
          </Tooltip>
          <Tooltip title={t("general.applyFilters")}>
            <IconButtonStyled
              aria-label={"Apply filters"}
              onClick={submitFilters}
            >
              <IconStyled />
            </IconButtonStyled>
          </Tooltip>
        </Stack>
      </Grid>
    </Grid>
  );

  const renderMoreFilters = () => (
    <PopperBox
      filters
      disabled={loading}
      popperAnchor={popperAnchor}
      minWidth="20vw"
      content={
        <SelectInput
          allOption
          label={t("observations.viewObservations.filters.observations")}
          disabled={loading}
          value={obsType}
          handler={(e) => setObsType(e.target.value)}
          options={safeUnsafeOptions}
        />
      }
      handlePopperClose={() => setPopperAnchor(null)}
    />
  );

  const renderNoData = () => (
    <Stack
      spacing={1}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <NoDataIcon />
      <Typography variant="body1">{t("general.noData")}</Typography>
    </Stack>
  );

  const renderQualityScoreChart = () => (
    <Card style={{ ...cardStyling, height: 700 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {t("dashboards.implementationDashboards.qualityScoreAnnual")}
        </Typography>
        <HelpIcon text={t("dashboards.tooltips.directorate.qualityHeatmap")} />
      </Stack>
      {!dataLoaded ? (
        <div style={loaderStyling}>
          <PageLoader />
        </div>
      ) : companyNames.length > 0 ? (
        <HeatmapChart
          height="99%"
          chartData={qualityScoreData}
          chartOptions={heatMapOptions}
        />
      ) : (
        renderNoData()
      )}
    </Card>
  );

  const renderQualityBreakdownChart = () => (
    <Card style={{ ...cardStyling, height: "100%" }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">
          {t("dashboards.implementationDashboards.qualityScoreBreakdown")}
        </Typography>
        <HelpIcon
          text={t("dashboards.tooltips.directorate.qualityBreakdown")}
        />
      </Stack>
      {qualityBreakdownData ? (
        <Grid container spacing={2} mt={0.5}>
          {Object.keys(qualityBreakdownData).map((k) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              key={`quality-breakdown-${k}-${qualityBreakdownData[k]}`}
            >
              <Box style={breakdownCardStyling}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h7">{k}</Typography>
                  <HelpIcon
                    text={t(
                      `dashboards.tooltips.directorate.${k.replace(/\s/g, "")}`
                    )}
                  />
                </Stack>
                <Typography
                  mt={1}
                  variant="h3"
                  style={{
                    color: returnAIColor(
                      parseFloat(qualityBreakdownData[k]).toFixed(2) * 100 || 0
                    ),
                  }}
                >
                  {percentFormatter(qualityBreakdownData[k]) || "No data"}
                </Typography>
              </Box>
            </Grid>
          ))}
          <Grid item xs={12}>
            <AiLegend />
          </Grid>
        </Grid>
      ) : (
        <div style={{ ...loaderStyling, height: 120 }}>
          <PageLoader />
        </div>
      )}
    </Card>
  );

  const getQualityHeatmapData = async () => {
    try {
      setLoading(true);
      const rawData = await getQualityHeatmapCompaniesApi({
        directorateId,
        locationId: locationFilter === "all" ? null : locationFilter,
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        companyId: companyFilter === "all" ? null : companyFilter,
        obsType: obsType === "all" ? null : obsType,
      });
      setQualityScoreData(rawData);
      const cNames = rawData.map((c) => {
        return {
          name: c.name,
          id: c.id,
        };
      });
      setCompanyNames(cNames);
      setDataLoaded(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const getQualityBreakdownData = async () => {
    try {
      setLoading(true);
      const rawData = await getQualityBreakdownCompaniesApi({
        directorateId,
        locationId: locationFilter === "all" ? null : locationFilter,
        disciplineId: disciplineFilter === "all" ? null : disciplineFilter,
        companyId: companyFilter === "all" ? null : companyFilter,
        obsType: obsType === "all" ? null : obsType,
      });

      setQualityBreakdownData(rawData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitFilters = () => {
    getQualityHeatmapData();
    getQualityBreakdownData();
  };

  useEffect(() => {
    if (directorateId) {
      setCompanyFilter("all");
      getCompanyFilters({
        setLoading,
        directorateFilter: directorateId,
        setCompanyDropdown,
      });
    }
    getQualityHeatmapData();
    getQualityBreakdownData();
  }, []);

  useEffect(() => {
    if (companyFilter && companyFilter !== "all") {
      setLocationFilter("all");
      getLocationsAndUnitsFilters({
        setLoading,
        companyFilter,
        setLocationDropdown,
        setUnitDropdown,
      });
    }
  }, [companyFilter]);

  useEffect(() => {
    if (
      companyFilter &&
      companyFilter !== "all" &&
      locationFilter &&
      locationFilter !== "all"
    ) {
      setDisciplineFilter("all");
      getDisciplineFilters({
        setLoading,
        companyFilter,
        locationFilter,
        unitFilter,
        setDisciplineDropdown,
      });
    }
  }, [companyFilter, locationFilter]);

  return (
    <Stack spacing={2} direction="column">
      {renderFilters()}
      {renderQualityScoreChart()}
      {renderQualityBreakdownChart()}
      {renderMoreFilters()}
    </Stack>
  );
}
