import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";

SecondaryButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.object,
  style: PropTypes.object,
  alignment: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  compact: PropTypes.bool,
};

const ButtonStyled = styled(Button)(({ theme, hasicon, isred }) => ({
  background: isred ? theme.palette.primary.red : theme.palette.primary.grey,
  fontFamily: "Cairo, sans-serif",
  color: theme.palette.primary.white,
  textTransform: "none",
  height: "43px",
  fontWeight: "fontWeightRegular",
  boxShadow: "none",
  borderRadius: "10px",
  "&:hover": {
    background: isred
      ? theme.palette.primary.hoverRed
      : theme.palette.primary.hoverGrey,
  },
  paddingLeft: "16px",
}));

const CompactButtonStyled = styled(Button)(({ theme, isRed }) => ({
  background: isRed ? theme.palette.primary.red : theme.palette.primary.grey,
  borderRadius: "4px",
  padding: "8px 12px",
  height: "28px",
  "&:hover": {
    background: isRed
      ? theme.palette.primary.hoverRed
      : theme.palette.primary.hoverGrey,
  },
  boxShadow: "none",
}));

export default function SecondaryButton({
  icon,
  label,
  style,
  alignment,
  disabled,
  onClick,
  isRed = false,
  compact = false,
  ...props
}) {
  if (compact) {
    return (
      <CompactButtonStyled
        startIcon={icon ? icon : null}
        variant="contained"
        disabled={disabled}
        fullWidth
        onClick={onClick}
        isRed={isRed}
        {...props}
      >
        <Typography variant="caption">{label}</Typography>
      </CompactButtonStyled>
    );
  } else {
    return (
      <ButtonStyled
        isred={isRed || ""}
        startIcon={icon || undefined}
        hasicon={icon ? "true" : ""}
        variant="contained"
        disabled={disabled}
        onClick={onClick}
        fullWidth
        style={{
          ...style,
          justifyContent: alignment
            ? alignment === "left"
              ? "flex-start"
              : "center"
            : "center",
        }}
        {...props}
      >
        <Typography variant="body1" style={{ marginLeft: icon ? "8px" : 0 }}>
          {label}
        </Typography>
      </ButtonStyled>
    );
  }
}
