import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { displayToast } from "../../utils/general";
import { DEFAULT_ACTIVITY_DURATION_IN_DAYS } from "../../utils/constants";
// material
import { Typography, Grid, Stack, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
// components
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import DateInput from "../../components/inputs/DateInput";
import TextInput from "../../components/inputs/TextInput";
import SelectInput from "../../../src/components/inputs/SelectInput";
import InfoBox from "../../components/InfoBox";
import GanttChart from "../../components/charts/GanttChart";
// api
import { createImplementation } from "../../api/implementation";
import { getActivityTypesApi } from "../../api/activities";

const addDefaults = {
  open: false,
  activityName: "",
  stub: "",
  parentActivityId: "",
  startDate: new Date(),
  endDate: new Date(),
  weightage: null,
};

export default function PhaseDates({ planId, planDetails, goBack }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  // Quarter Day, Half Day, Day, Week, Month
  const [viewMode, setViewMode] = useState("Week");
  const [addDetails, setAddDetails] = useState(addDefaults);
  const [activityTypes, setActivityTypes] = useState([]);
  const [planActivities, setPlanActivities] = useState([]);

  const viewModes = [
    { value: "Day", label: t("calendar.day") },
    { value: "Week", label: t("calendar.week") },
    // { value: "Month", label: t("calendar.month") },
    { value: "Half Day", label: t("calendar.halfDay") },
    { value: "Quarter Day", label: t("calendar.quarterDay") },
  ];

  const getDefaultActivities = (allActivities) => {
    const finalActivities = [];
    const today = new Date();
    allActivities.forEach((row) => {
      if (DEFAULT_ACTIVITY_DURATION_IN_DAYS[row.stub]) {
        const start = moment(today)
          .add(DEFAULT_ACTIVITY_DURATION_IN_DAYS[row.stub].startsIn, "days")
          .toDate();
        const end = moment(today)
          .add(DEFAULT_ACTIVITY_DURATION_IN_DAYS[row.stub].startsIn, "days")
          .add(DEFAULT_ACTIVITY_DURATION_IN_DAYS[row.stub].duration, "days")
          .toDate();

        //  Hardcoded removal of focal points activity
        if (row.stub !== "IdentifyFocalPoints") {
          finalActivities.push({
            name: t(`planActivityNamesEnglish.${row.stub}`),
            id: row.stub,
            start,
            end,
            progress: 100,
            // dependencies: "",
            days: DEFAULT_ACTIVITY_DURATION_IN_DAYS[row.stub].duration,
          });
        }
      }
    });
    finalActivities.sort((a, b) => {
      if (moment(a.start).isAfter(moment(b.start))) {
        return 1;
      } else {
        return -1;
      }
    });
    return finalActivities;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.createLoading")
    );

    try {
      const body = { ...planDetails, startDate, activities: planActivities };
      const { data } = await createImplementation(body);
      await navigate(
        `/implementations/details/${data.planId}/${data.companyId}`
      );
      displayToast(
        setLoading,
        "success",
        t("implementations.createSuccess"),
        activeToast
      );
    } catch (err) {
      setLoading(false);
      displayToast(setLoading, "error", err, activeToast);
      console.log(err);
    }

    // TODO add dynamic id
  };

  const handleDateChange = (value, index) => {
    const updatedTasks = planActivities;
    const targetTask = updatedTasks[index];
    const updatedTaskItem = {
      ...targetTask,
      end: moment(targetTask.end)
        .add(parseInt(value), "days")
        .format("YYYY-MM-DD"),
      days: parseInt(value),
    };

    updatedTasks[index] = updatedTaskItem;
    setPlanActivities(updatedTasks);
  };

  const handleGanttDateChange = (task) => {
    setLoadingChart(true);
    const newPlan = [...planActivities];
    const index = newPlan.findIndex((activity) => activity.id === task.id);
    const newDuration = moment(task.end).diff(moment(task.start), "days");
    if (index >= 0) {
      newPlan[index].start = task.start;
      newPlan[index].end = task.end;
      newPlan[index].days = newDuration || 1;
      setPlanActivities(newPlan);
    }
    setLoadingChart(false);
  };

  const handleAllStartDateChange = (newStart) => {
    //  Find the difference in days between the original start date and the new one and add that number to the individual activity dates
    const rawDiff = newStart.diff(startDate, "days");
    let newDiff;
    if (Math.sign(rawDiff) < 0) {
      newDiff = rawDiff;
    } else {
      newDiff = rawDiff + 1;
    }
    const updatedActivities = planActivities.map((a) => {
      return {
        ...a,
        start: moment(a.start).add(newDiff, "days").toDate(),
        end: moment(a.end).add(newDiff, "days").toDate(),
      };
    });
    setPlanActivities(updatedActivities);
    setStartDate(newStart);
  };

  //  TODO: Remove training flag when it is removed from the backend
  const getAllActivityTypes = async () => {
    try {
      setLoading(true);
      const typesResponse = await getActivityTypesApi();
      const types = typesResponse
        .filter(
          (activity) =>
            !activity.isDefaultActivity && activity.stub !== "Training"
        )
        .map((type) => {
          return {
            label: type.name,
            value: type.stub,
          };
        });
      setPlanActivities(getDefaultActivities(typesResponse));
      setActivityTypes(types);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const submitCustomActivity = () => {
    try {
      setLoading(true);
      const newPlan = [...planActivities];
      if (addDetails.startDate >= addDetails.endDate) {
        throw t("errors.dateError");
      }
      const payload = {
        name: addDetails.activityName,
        id: addDetails.stub,
        start: moment(addDetails.startDate).toDate(),
        end: moment(addDetails.endDate).toDate(),
        days:
          moment(addDetails.endDate).diff(
            moment(addDetails.startDate),
            "days"
          ) + 1,
        progress: 100,
      };
      newPlan.push(payload);
      setPlanActivities(newPlan);
      displayToast(setLoading, "success", t("implementations.customSuccess"));
      setAddDetails({ ...addDetails, open: false });
      setLoading(false);
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "pureError", err);
      setLoadingChart(false);
    }
  };

  useEffect(() => {
    getAllActivityTypes();
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        md={9}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Typography>{t("implementations.activityInput")}</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <SecondaryButton
          icon={<AddCircleIcon />}
          label={t("implementations.addActivity")}
          onClick={() => setAddDetails({ ...addDefaults, open: true })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateInput
          label={t("implementations.startDate")}
          date={startDate}
          disabled={loading}
          handler={(newDate) => handleAllStartDateChange(newDate)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          value={viewMode}
          handler={(e) => setViewMode(e.target.value)}
          options={viewModes}
          label={t("implementations.viewMode")}
        />
      </Grid>
      <Grid item xs={12}>
        <GanttChart
          tasks={planActivities}
          onDateChange={handleGanttDateChange}
          viewMode={viewMode}
        />
      </Grid>
      {planActivities && !loadingChart
        ? planActivities.map((t, index) => (
            <Grid item xs={12} md={3}>
              <Stack>
                <Typography variant="h6">{t.name}</Typography>
                <Typography variant="body">{`${moment(t.start).format(
                  "L"
                )} - ${moment(t.end).format("L")}`}</Typography>
                <TextInput
                  disabled={true}
                  type="number"
                  value={t.days}
                  handler={(event) =>
                    handleDateChange(event.target.value, index)
                  }
                />
              </Stack>
            </Grid>
          ))
        : t("general.loading")}
      <Grid item xs={12}>
        <PrimaryButton
          icon={<CheckIcon />}
          alignment="left"
          label={t("implementations.submit")}
          onClick={handleSubmit}
        />
      </Grid>
      <InfoBox
        disabled={loading || !addDetails.stub}
        open={addDetails.open}
        title={t("implementations.addActivity")}
        content={
          <Stack spacing={1}>
            <TextInput
              disabled={loading}
              label={t("implementations.addActivityName")}
              placeholder={t("implementations.addActivityNamePlaceholder")}
              value={addDetails.activityName}
              handler={(e) =>
                setAddDetails({ ...addDetails, activityName: e.target.value })
              }
            />
            <DateInput
              disabled={loading}
              label={t("implementations.startDate")}
              date={addDetails.startDate}
              handler={(newDate) =>
                setAddDetails({ ...addDetails, startDate: newDate })
              }
            />
            <DateInput
              disabled={loading}
              label={t("implementations.endDate")}
              date={addDetails.endDate}
              handler={(newDate) =>
                setAddDetails({ ...addDetails, endDate: newDate })
              }
            />
            <SelectInput
              label={t("Activity.activityType")}
              options={activityTypes}
              value={addDetails.stub}
              disabled={loading}
              handler={(e) =>
                setAddDetails({ ...addDetails, stub: e.target.value })
              }
            />
          </Stack>
        }
        handleClose={() => setAddDetails(addDefaults)}
        buttonLabel={t("implementations.addCustom")}
        handleAction={submitCustomActivity}
      />
    </>
  );
}
