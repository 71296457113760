import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Popover, Box, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import ClosePopperIcon from "@mui/icons-material/CancelOutlined";

PopperBox.propTypes = {
  disabled: PropTypes.bool,
  content: PropTypes.node,
  popperAnchor: PropTypes.node.isRequired,
  handlePopperClose: PropTypes.func,
  minWidth: PropTypes.string,
  filters: PropTypes.bool,
  overflowV: PropTypes.bool,
};

const CloseIconStyled = styled(ClosePopperIcon)(({ theme }) => ({
  height: "18px",
  width: "18px",
  color: theme.palette.primary.red,
  cursor: "pointer",
}));

const PopperContent = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.white,
  boxShadow: " 0px 1px 9px rgba(248, 147, 31, 0.22)",
  border: `1px solid #ECECEC`,
  padding: "12px",
  borderRadius: "6px",
}));

export default function PopperBox({
  disabled,
  content,
  popperAnchor,
  handlePopperClose,
  minWidth,
  overflowV,
  filters,
}) {
  const { t } = useTranslation();
  const open = Boolean(popperAnchor);
  const popperId = open ? "popperbox-input-popper-id" : undefined;

  return (
    <Popover
      id={popperId}
      PaperProps={{
        style: {
          ...(minWidth ? { minWidth } : {}),
          ...(overflowV ? { overflow: "visible" } : {}),
        },
      }}
      open={open}
      anchorEl={popperAnchor}
      onClose={handlePopperClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      {filters ? (
        <PopperContent>
          <Stack spacing={1}>
            <Stack justifyContent="space-between" direction="row">
              <Typography variant="caption">
                {t("general.moreFilters").toUpperCase()}
              </Typography>
              <CloseIconStyled onClick={handlePopperClose} />
            </Stack>
            {content}
          </Stack>
        </PopperContent>
      ) : (
        <PopperContent>{content}</PopperContent>
      )}
    </Popover>
  );
}
