import { Step, StepLabel, Stepper } from "@mui/material";
import PropTypes from "prop-types";

StepperLine.propTypes = {
  activeStyle: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  style: PropTypes.object,
};

export default function StepperLine({ steps, activeStep, style }) {
  return (
    <Stepper activeStep={activeStep} style={{ ...style }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
