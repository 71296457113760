import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Stack, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Page from "../components/Page";
import HeaderBackButton from "../components/buttons/HeaderBackButton";

const StyledList = styled("ul")(() => ({
  paddingLeft: "50px",
  fontWeight: "bold",
}));

const LinkStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.hyperlinkBlue,
  textShadow: "none",
  cursor: "pointer",
  textDecoration: "underline",
  display: "inline",
}));

export default function PrivacyPolicy() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleNavigate = (link) => {
    navigate("/exitPage", {
      state: {
        exitUrl: link,
      },
    });
  };

  return (
    <Page title={t("general.privacy")}>
      <Container>
        <Stack spacing={2} pb={5}>
          <Box sx={{ pt: 5 }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {state?.back && <HeaderBackButton />}
              <Typography variant="h3">PDO Privacy Policy</Typography>
            </div>
          </Box>
          <Typography variant="body1">
            Thank you for visiting our web site. Petroleum Development Oman LLC
            (PDO) respects the privacy of everyone who visits this website. As a
            result we would like to inform you how we use personal information
            collected at this website and recommend that you read this privacy
            policy. By using the website, you will be treated as having accepted
            the practices described in this privacy policy.
          </Typography>
          <Stack spacing={1}>
            <Typography variant="h5plain" mt="3vh">
              1. Who is responsible for the personal information collected on
              this site?
            </Typography>
            <Typography variant="body1">
              Your personal data is controlled ultimately by Petroleum
              Development Oman LLC (PDO), a limited liability company
              incorporated under the laws of the Sultanate of Oman by Royal
              Decree, having its Head Office in Mina Al Fahal and having
              Commercial Registration number 1/09999/0.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5plain" mt="3vh">
              2. Purposes of the collection of your data
            </Typography>
            <Typography variant="body1">
              One of the purposes of our website is to inform you of who we are
              and what we do. We may collect and use personal information
              (including name, address, telephone number and email) to better
              provide you with the required services or information. We would
              therefore use your personal information in order to:
            </Typography>
            <StyledList style={{ paddingLeft: "50px" }}>
              <li>respond to queries or requests submitted by you</li>
              <li>process applications submitted by you</li>
              <li>
                administer or otherwise carry out our obligations in relation to
                any agreement you have with us
              </li>
              <li>anticipate and resolve problems</li>
              <li>maintain internal record keeping</li>
            </StyledList>
            <Typography variant="body1">
              Except as set out in this privacy policy, we will not disclose any
              personally identifiable information without your permission unless
              we are legally entitled or required to do so. We may share
              information with governmental agencies or other companies
              assisting us in fraud prevention or investigation. We may do so
              when: (1) permitted or required by law; or, (2) trying to protect
              against or prevent actual or potential fraud or unauthorized
              transactions; or, (3) investigating fraud which has already taken
              place; or, (4) we believe that such action is necessary to protect
              and/or defend our rights, property or personal safety and those of
              others. The information is not provided to these companies for
              marketing purposes.
            </Typography>
            <Typography variant="body1">
              Please be assured that we will not use your information for any of
              the purposes if you have indicated that you do not wish us to use
              your information in this way when submitting the information or at
              a later stage.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5plain" mt="3vh">
              3. Collection of non-personal information
            </Typography>
            <Typography variant="body1">
              We may automatically collect non-personal information about you
              such as the type of internet browsers you use or the website from
              which you linked to our website. We may also aggregate details
              which you have submitted to the site (for example, your age and
              the town where you live). You cannot be identified from this
              information and it is only used to assist us in providing an
              effective service on this web site. We may from time to time
              supply third parties with this non-personal or aggregated data for
              uses in connection with this website.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5plain" mt="3vh">
              4. Data Security
            </Typography>
            <Typography variant="body1">
              We are committed to ensuring that your information is secure. Only
              authorized employees, agents and contractors (who have agreed to
              keep information secure and confidential) have access to this
              information. We have implemented technology and policies with the
              objective of safeguarding and securing the information we collect
              online.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5plain" mt="3vh">
              5. Links to Other Websites
            </Typography>
            <Typography variant="body1">
              The privacy practices detailed in this privacy policy are for this
              website only. On our website, we may have links to other websites.
              If you link to other websites, please review the privacy policies
              posted at those sites. We cannot be responsible for the privacy of
              any information which you provide whilst visiting such websites as
              such sites are subject to their different terms of use and privacy
              policies as provided in their website.
            </Typography>
            <Typography variant="body1">
              We recommend that you check the policy of each website you visit
              to better understand your rights and obligations especially when
              you are submitting any type of content on those third party
              website. Please contact the owner or operator of such website if
              you have any concerns or questions.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5plain" mt="3vh">
              6. Cookies policy
            </Typography>
            <StyledList style={{ paddingLeft: "50px" }}>
              <li>What is a cookie?</li>
            </StyledList>
            <Typography variant="body1">
              Cookies are small data files that your browser places on your
              computer or device. Cookies help your browser navigate a website
              and the cookies themselves cannot collect any information stored
              on your computer or your files.
            </Typography>
            <Typography variant="body1">
              When a server uses a web browser to read cookies they can help a
              website deliver a more user-friendly service. To protect your
              privacy, your browser only gives a website access to the cookies
              it has already sent to you.
            </Typography>
            <StyledList style={{ paddingLeft: "50px" }}>
              <li>Why do we use cookies?</li>
            </StyledList>
            <Typography variant="body1">
              We use cookies to learn more about the way you interact with our
              content and help us to improve your experience when visiting our
              website.
            </Typography>
            <Typography variant="body1">
              Cookies remember the type of browser you use and which additional
              browser software you have installed. They also remember your
              preferences, such as language and region, which remain as your
              default settings when you revisit the website. Cookies also allow
              you to rate pages and fill in comment forms.
            </Typography>
            <Typography variant="body1">
              Some of the cookies we use are session cookies and only last until
              you close your browser, others are persistent cookies which are
              stored on your computer for longer.
            </Typography>
            <StyledList style={{ paddingLeft: "50px" }}>
              <li>How are third party cookies used?</li>
            </StyledList>
            <Typography variant="body1">
              For some of the functions within our websites we may use third
              party suppliers. These videos or links (and any other content from
              third party suppliers) may contain third party cookies and you may
              wish to consult the policies of these third party websites for
              information regarding their use of cookies.
            </Typography>
            <StyledList style={{ paddingLeft: "50px" }}>
              <li>How do I reject and delete cookies?</li>
            </StyledList>
            <Typography variant="body1">
              We will not use cookies to collect personally identifiable
              information about you. However, should you wish to do so, you can
              choose to reject or block the cookies set by us or the websites of
              any third party suppliers by changing your browser settings – see
              the Help function within your browser for further details. Please
              note that most browsers automatically accept cookies so if you do
              not wish cookies to be used you may need to actively delete or
              block the cookies.
            </Typography>
            <Typography variant="body1">
              You can also visit{" "}
              <LinkStyle
                onClick={() => handleNavigate("http://www.allaboutcookies.org")}
              >
                www.allaboutcookies.org
              </LinkStyle>{" "}
              for details on how to delete or reject cookies and for further
              information on cookies generally. For information on the use of
              cookies in mobile phone browsers and for details on how to reject
              or delete such cookies, please refer to your handset manual.
            </Typography>
            <Typography variant="body1">
              Note, however, that if you reject the use of cookies you will
              still be able to visit our websites but some of the functions may
              not work correctly.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5plain" mt="3vh">
              7. Changes to this policy
            </Typography>
            <Typography variant="body1">
              From time to time we may make changes to this privacy policy. If
              we make any change to this privacy policy and the way in which we
              use your personal data we will post these changes on this page.
              You are encouraged to review the privacy policy whenever you visit
              the website.
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5plain" mt="3vh">
              8. How you can contact us
            </Typography>
            <Typography variant="body1">
              If you would like to contact PDO regarding this privacy policy,
              you may contact us using the information below:
            </Typography>
            <Typography variant="body1">
              By e-mail: webmaster@pdo.co.om
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Page>
  );
}
