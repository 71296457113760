import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { displayToast } from "../../utils/general";
// material
import { Grid, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// components
import PrimaryButton from "../../components/buttons/PrimaryButton";
import TextInput from "../../components/inputs/TextInput";
import SelectInput from "../../components/inputs/SelectInput";
import MultiSelectInput from "../../components/inputs/MultiSelectInput";
import InfoBox from "../../components/InfoBox";
//  api
import { getImplementationDirectoratesApi } from "../../api/directorate";
import { getLocationsForImplementationApi } from "../../api/location";
import { getUnitsForImplementationApi } from "../../api/unit";
import { getCompaniesUsingDirectorateApi } from "../../api/company";

// ----------------------------------------------------------------------

const addDefault = {
  companyName: "",
  companyId: "",
  directorateIds: [],
  directorateId: "",
  locationIds: [],
  unitIds: [],
  endDate: new Date(),
  name: "",
};

export default function ActivityInformation({
  setTabValue,
  setPlanDetails,
  preloadObject,
  setPreloadObject,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [firstRun, setFirstRun] = useState(true);
  const [addDetails, setAddDetails] = useState(addDefault);
  const [directorateDropdown, setDirectorateDropdown] = useState([]);
  const [locationDropdown, setLocationDropdown] = useState([]);
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [confirmationPopup, setConfirmationPopup] = useState({
    open: false,
  });

  const getCompanies = async (directorateId) => {
    setLoading(true);
    try {
      const companyData = await getCompaniesUsingDirectorateApi(directorateId);
      const companyList = companyData.map((c) => {
        return {
          label: c.name,
          value: c.id,
          name: c.name,
        };
      });
      setCompanyDropdown(companyList);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getDirectorates = async () => {
    setLoading(true);
    try {
      const directorateData = await getImplementationDirectoratesApi();
      const directoratelist = directorateData.map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      setDirectorateDropdown(directoratelist);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getLocationsByCompany = async (companyId, directorateId) => {
    setLoading(true);
    try {
      const locationData = await getLocationsForImplementationApi(
        companyId,
        directorateId
      );
      const locationList = locationData.map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      setLocationDropdown(locationList);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const renderConfirmationPopup = () => (
    <InfoBox
      compact
      disabled={loading}
      open={confirmationPopup.open}
      title={t(`implementations.confirmNext`)}
      content={
        <Stack>
          <Typography variant="h6">
            {t(`implementations.confirmNextMessage`)}
          </Typography>
        </Stack>
      }
      buttonLabel={t(`implementations.next`)}
      handleClose={() =>
        setConfirmationPopup({ ...confirmationPopup, open: false })
      }
      handleAction={submitCreateImplementation}
    />
  );

  const confirmNext = async () => {
    try {
      if (
        addDetails.locationIds.length === 0 &&
        addDetails.unitIds.length === 0
      ) {
        throw t("validation.implementationLocationOrUnit");
      }

      if (
        !addDetails.name ||
        !addDetails.companyId ||
        !addDetails.directorateId
      ) {
        throw t("validation.createImplementation");
      }
      setConfirmationPopup({ ...confirmationPopup, open: true });
    } catch (err) {
      displayToast(setLoading, "error", err);
      console.log(err);
    }
  };

  const submitCreateImplementation = async () => {
    try {
      setLoading(true);

      const finalLocations = addDetails.locationIds.map((l) => l.value);
      const finalUnits = addDetails.unitIds.map((u) => u.value);

      const body = {
        companyName: "",
        directorateIds: [addDetails.directorateId],
        locationIds: finalLocations,
        unitIds: finalUnits,
        endDate: new Date(),
        name: addDetails.name,
        companyId: addDetails.companyId,
      };

      setPlanDetails({ ...body });
      setPreloadObject(addDetails);
      setTabValue(1);

      setLoading(false);
    } catch (err) {
      displayToast(setLoading, "error", err);
      console.log(err);
    }
  };

  const getUnits = async (companyId, directorateId) => {
    setLoading(true);
    try {
      const unitData = await getUnitsForImplementationApi(
        companyId,
        directorateId
      );
      const unitList = unitData.map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      if (!firstRun) {
        setAddDetails({ ...addDetails, unitIds: [] });
      }
      setUnitDropdown(unitList);
      setLoading(false);
      setFirstRun(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDirectorates();
  }, []);

  useEffect(() => {
    if (addDetails.directorateId) {
      if (!firstRun) {
        setAddDetails({ ...addDetails, locationIds: [], unitIds: [] });
      }
      getCompanies(addDetails.directorateId);
    }
  }, [addDetails.directorateId]);

  useEffect(() => {
    const { companyId, directorateId } = addDetails;
    if (!firstRun) {
      setUnitDropdown([]);
    }
    if (companyId) {
      if (!firstRun) {
        setAddDetails({ ...addDetails, locationIds: [] });
      }
      getLocationsByCompany(companyId, directorateId);
    }
    if (directorateId && companyId) {
      getUnits(companyId, directorateId);
    }
  }, [addDetails.companyId, addDetails.directorateId]);

  useEffect(() => {
    if (preloadObject) {
      setAddDetails(preloadObject);
    }
  }, [preloadObject]);

  return (
    <>
      <Grid item xs={12} md={12}>
        <TextInput
          disabled={loading}
          value={addDetails.name}
          label={t("implementations.inputImplementationName")}
          handler={(e) =>
            setAddDetails({ ...addDetails, name: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          label={t("implementations.directoratesSelectText")}
          disabled={loading}
          options={directorateDropdown}
          handler={(e) =>
            setAddDetails({ ...addDetails, directorateId: e.target.value })
          }
          value={addDetails.directorateId}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput
          label={t("implementations.companyInput")}
          value={addDetails.companyId}
          disabled={loading || companyDropdown.length < 1}
          handler={(e) =>
            setAddDetails({ ...addDetails, companyId: e.target.value })
          }
          options={companyDropdown}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelectInput
          label={t("implementations.addLocations")}
          disabled={loading || locationDropdown.length < 1}
          options={locationDropdown}
          handler={(newValue) =>
            setAddDetails({ ...addDetails, locationIds: newValue })
          }
          values={addDetails.locationIds}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MultiSelectInput
          label={t("implementations.addUnits")}
          disabled={loading || unitDropdown.length < 1}
          options={unitDropdown}
          handler={(newValue) =>
            setAddDetails({ ...addDetails, unitIds: newValue })
          }
          values={addDetails.unitIds}
        />
      </Grid>
      <Grid item xs={12}>
        <PrimaryButton
          label={t("implementations.next")}
          alignment="left"
          onClick={confirmNext}
          disabled={loading || !addDetails.name}
        />
      </Grid>
      {renderConfirmationPopup()}
    </>
  );
}
