import React, { useState, useEffect } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../calendar.css";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate} from "react-router-dom";
//  Material UI
import { Grid, Container, Button, Dialog } from "@mui/material";
//  Components
import HeaderBackButton from "../../../../components/buttons/HeaderBackButton";
import PageLoader from "../../../../components/PageLoader";
import Page from "../../../../components/Page";
import { styled, useTheme } from "@mui/material/styles";
import ScheduleTraining from "./ScheduleTraining";
import InfoBox from "../../../../components/InfoBox";
import SecondaryButton from "../../../../components/buttons/SecondaryButton";
//  API
import { getTrainingPlans } from "../../../../api/implementation";
import { displayToast } from "../../../../utils/general";

const localizer = momentLocalizer(moment);
const detailsDefault = {
  open: true,
  id: "",
  title: "",
  dateType: "",
  startDate: "",
  endDate: "",
  siteName: "",
};

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.buttonOrange,
  padding: "20px",
  height: "50px",
  color: theme.palette.primary.black,
  fontWeight: "fontWeightSemibold",
  fontSize: "14px",
  textTransform: "none",
  borderRadius: "10px",
  border: `1px solid ${theme.palette.primary.orange}`,
  boxShadow: "none",
  backdropFilter: "blur(20px)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverOrange,
  },
}));

export const TrainingCalender = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { activityId, planId, activityTypeStub, activityTypeId, companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const handleEventSelect = (event) => {
    navigate(
      `/implementations/training-schedule/${planId}/${companyId}/${activityId}/${activityTypeStub}/${activityTypeId}/${event.id}`
    );
  };

  const handleSelect = ({ start, end }) => {
    setStartDate(moment(start).startOf());
    setEndDate(moment(end).startOf().subtract(1, "days"));
    setDialogOpen(true);
  };

  const handleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const getAllTrainingPlans = async () => {
    try {
      setLoading(true);
      let query = `planId=${planId}`;
      let tempPlans = await getTrainingPlans(query);
      let tempEvents = tempPlans?.result?.map((plan, index) => {
        return {
          id: plan.id,
          title: plan.description,
          allDay: true,
          start: moment(plan.startDate)._d,
          end: moment(plan.endDate),
          concluded: plan.concluded || false
        };
      });
      setLoading(false);
      setEvents(tempEvents);
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        t("training.errors.calenderError"),
        "errorCalenderComponent"
      );
    }
  };

  const handleSuccess = async () => {
    setDialogOpen(false);
    getAllTrainingPlans();
  };

  useEffect(() => {
    getAllTrainingPlans();
  }, []);

  return !loading ? (
    <>
            <Calendar
              selectable
              views={["month"]}
              events={events}
              localizer={localizer}
              onSelectEvent={(event) => handleEventSelect(event)}
              // onSelectSlot={handleSelect}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
              popup
              eventPropGetter={(event, start, end, isSelected) => {
                const style = {
                  backgroundColor: event.concluded ? theme.palette.primary.green : theme.palette.primary.orange,
                };
                return {
                  style: style,
                };
              }}
            />
      <InfoBox
        overflowV
        handleClose={() => setDialogOpen(false)}
        disabled={false}
        open={dialogOpen}
        title={t("trainingSchedule.scheduleTraining")}
        content={
          <ScheduleTraining
            startDate={startDate}
            endDate={endDate}
            onSuccess={() => {
              handleSuccess();
            }}
          />
        }
        // minWidth="1000px"
      />
    </>
  ) : (
    <PageLoader />
  );
};
