import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { Link, Stack, Grid, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
//  components
import TextInput from "../../inputs/TextInput";
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";
import AlertBox from "../../AlertBox";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import useAuth from "../../../context/useAuth";
//MSAL Auth
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../api/config.json";

// ----------------------------------------------------------------------

export default function LoginForm({ handleRecoveryPassword }) {
  const { login, error, loading } = useAuth();
  const query = useLocation().search;
  const theme = useTheme();

  const expiry = new URLSearchParams(query).get("expiry");
  const { t } = useTranslation();
  const { instance } = useMsal();
  const [azureLoading, setAzureLoading] = useState(loading);
  const [azureError, setAzureError] = useState(error);

  let password;
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password,
  });

  const handleFormEntry = (e, type) => {
    switch (type) {
      case "enter":
        if (e.code === "Enter") {
          login(loginDetails.email, loginDetails.password);
        }
        break;

      case "email":
        setLoginDetails({ ...loginDetails, email: e.target.value });
        break;

      case "password":
        setLoginDetails({ ...loginDetails, password: e.target.value });
        break;

      default:
        console.log("Please enter a valid type.");
    }
  };

  const handleAzureLogin = async () => {
    try {
      setAzureLoading(true);
      await instance.loginRedirect(loginRequest);
    } catch (err) {
      if (err.errors) {
        setAzureError(err.errors[0]);
      } else {
        setAzureError(err);
      }
    }
  };

  return (
    <>
      <Stack spacing={1}>
        <Grid container spacing={1} direction="row"></Grid>

        <Grid container spacing={1} direction="row">
          <Grid item xs={1} />
          <Grid item xs={11}>
            <Box
              component="img"
              src="/static/ihtimam_pdo_logo.svg"
              sx={{ width: "100%", height: 180 }}
              style={{
                backgroundColor: theme.palette.primary.white,
                borderRadius: "26px",
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row">
          <Grid item xs={1} />
          <Grid item xs={11}>
            <AlertBox
              open={expiry ? true : false}
              type="error"
              message={t("errors.session")}
            />
            <AlertBox
              open={azureError ? true : false}
              type="error"
              message={azureError}
            />
            <AlertBox
              open={error ? true : false}
              type="error"
              message={error}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row">
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <PersonIcon sx={{ fill: theme.palette.primary.white }} />
          </Grid>
          <Grid item xs={11}>
            <TextInput
              placeholder={t(`login.enterEmailPhoneNumber`)}
              value={loginDetails.email}
              disabled={loading}
              handler={(e) => handleFormEntry(e, "email")}
              keyPressHandler={(e) => handleFormEntry(e, "enter")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row">
          <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
            <LockIcon sx={{ fill: theme.palette.primary.white }} />
          </Grid>
          <Grid item xs={11}>
            <TextInput
              placeholder={t(`login.enterPassword`)}
              type="password"
              value={loginDetails.password}
              disabled={loading}
              handler={(e) => handleFormEntry(e, "password")}
              keyPressHandler={(e) => handleFormEntry(e, "enter")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row">
          <Grid item xs={1} />
          <Grid item xs={11}>
            <Link
              component={RouterLink}
              onClick={(event) => handleRecoveryPassword(true)}
              variant="subtitle2"
              color={theme.palette.primary.buttonOrange}
              to="#"
            >
              {t(`login.forgotPassword`)}
            </Link>
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row">
          <Grid item xs={1} />
          <Grid item xs={11}>
            <PrimaryButton
              label={t(`login.signIn`)}
              alignment="left"
              disabled={azureLoading}
              onClick={() => login(loginDetails.email, loginDetails.password)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row">
          <Grid item xs={1} />
          <Grid item xs={11}>
            <SecondaryButton
              icon={<ArrowForwardOutlinedIcon />}
              label={t(`login.azureLogin`)}
              alignment="left"
              disabled={azureLoading}
              onClick={() => handleAzureLogin()}
            />
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
