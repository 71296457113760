import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useTranslation } from "react-i18next";
//  Material UI
import { Typography, Box, Container} from "@mui/material";
//  Components
import HeaderBackButton from "../../../../components/buttons/HeaderBackButton";
import Page from "../../../../components/Page";
import TrainingDetails from "./TrainingDetails";
//  API

function TrainingManage(){
  const { t } = useTranslation();

  return (
    <Page title={t("trainingSchedule.manageTraining")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">{t("trainingSchedule.manageTraining")}</Typography>
          </div>
        </Box>
        <Box>
          <TrainingDetails/>
        </Box>
      </Container>
    </Page>
  );
};

export default TrainingManage
