import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "gantt-task-react/dist/index.css";
import { Gantt } from "gantt-task-react";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import "./gantt-custom.css";

GanttChart.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
  onDateChange: PropTypes.func,
  viewMode: PropTypes.string,
};

export default function GanttChart({ tasks, onDateChange, viewMode }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [ganttTasks, setGanttTasks] = useState([]);

  useEffect(() => {
    if (tasks) {
      setGanttTasks(tasks);
    }
  }, [tasks]);

  if (ganttTasks && ganttTasks.length > 0) {
    return (
      <Gantt
        tasks={ganttTasks}
        viewMode={viewMode}
        onDateChange={(task) => onDateChange(task)}
        listCellWidth={""}
        barProgressSelectedColor={theme.palette.primary.orange}
        barProgressColor={theme.palette.primary.orange}
        TooltipContent={(task) => {
          return (
            <div className="gantt-tooltip-card">
              <div className="gantt-tooltip-bold-text">
                {task.task.name} :{" "}
                {moment(task.task.start).format("DD-MM-YYYY")} -{" "}
                {moment(task.task.end).format("DD-MM-YYYY")}
              </div>
              <div>{t("general.duration", { days: task.task.days })}</div>
            </div>
          );
        }}
      />
    );
  } else {
    return t("general.loading");
  }
}
