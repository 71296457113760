import React from "react";
import { useTranslation } from "react-i18next";
import { Select, Box, Stack, Typography, MenuItem } from "@mui/material";
import { InfoOutlined, ArrowDropDownCircleOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import HelpText from "../HelpText";

const SelectStyle = styled(Select)(({ theme }) => ({
  "&.Mui-disabled": {
    svg: {
      fill: "#9A9A9A",
    },
  },
  ".MuiOutlinedInput-input": {
    padding: "18px 14px",
    background:
      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
    border: "1px solid #C2C2C2",
    borderRadius: "10px",
    "&.Mui-disabled": {
      boxShadow: "none",
    },
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  ".MuiSelect-select": {
    "&.Mui-disabled": {
      border: "1px solid #E9E9E9",
      background: "#F1F1F1",
    },
    "&:hover": {
      border: `1px solid black`,
    },
    "&:focus": {
      borderColor: theme.palette.primary.buttonOrange,
      boxShadow: `inset 0px 0px 0px 2px ${theme.palette.primary.buttonOrange}`,
    },
  },
}));

const hoverColor = "rgba(248, 147, 31, 0.16)";

const MenuItemStyle = styled(MenuItem)({
  "&.Mui-selected": {
    backgroundColor: hoverColor,
    "&:hover": {
      backgroundColor: hoverColor,
    },
  },
  "&:hover": {
    backgroundColor: hoverColor,
  },
});

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

const DropdownIconStyle = styled(ArrowDropDownCircleOutlined)(({ theme }) => ({
  fill: theme.palette.primary.orange,
}));

const SelectInput = ({
  label,
  helpText,
  disabled,
  value,
  handler,
  options,
  optional,
  allOption,
  style,
  required = false,
  stackProps = { paddingBottom: "4px" },
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Box>
      <Stack
        direction="row"
        style={{ ...stackProps }}
        justifyContent="space-between"
        alignItems="center"
      >
        <div style={{ display: "flex" }}>
          <Typography variant="h6">{label}</Typography>
          {required && (
            <Typography variant="h6" color="error">
              *{" "}
            </Typography>
          )}
        </div>
        {helpText && (
          <HelpText title={helpText}>
            <IconStyle />
          </HelpText>
        )}
      </Stack>
      <SelectStyle
        variant="outlined"
        placeholder={label}
        fullWidth
        disabled={disabled}
        IconComponent={DropdownIconStyle}
        value={value}
        onChange={handler}
        SelectDisplayProps={{
          style: {
            ...style,
            paddingTop: "11px",
            paddingBottom: "11px",
          },
        }}
      >
        {optional && (
          <MenuItemStyle value={null} key={`selectInputOption-none`}>
            {t("general.none")}
          </MenuItemStyle>
        )}

        {allOption && (
          <MenuItemStyle value={"all"} key={`selectInputOption-all`}>
            {t("general.all")}
          </MenuItemStyle>
        )}
        {options &&
          options.map((o, i) => (
            <MenuItemStyle
              value={o.value}
              key={`selectInputOption-${i}-${o.label}`}
            >
              {currentLanguage.includes("en")
                ? o.label
                : o.labelAR
                ? o.labelAR
                : o.label}
            </MenuItemStyle>
          ))}
      </SelectStyle>
    </Box>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.object),
  handler: PropTypes.func,
  optional: PropTypes.bool,
  style: PropTypes.object,
  allOption: PropTypes.bool,
  stackProps: PropTypes.object,
};

export default SelectInput;
