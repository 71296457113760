import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { TextField, Typography, Box, Stack, IconButton } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
// This was causing a break in filters , MUI recently changed this
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker, PickersDay } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HelpText from "../HelpText";
import ClickAwayListener from '@mui/material/ClickAwayListener';


DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  handler: PropTypes.func,
  ukForm: PropTypes.bool,
  stackProps: PropTypes.object,
  disableFuture: PropTypes.bool,
};

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  height: "43px",
  width: "43px",
  borderRadius: "10px",
  background: theme.palette.primary.grey,
  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverGrey,
  },
  "&:disabled": {
    backgroundColor: theme.palette.primary.disabledRed,
    boxShadow: "none",
  },
}));

const IconCalendarStyled = styled(EventNoteIcon)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const TextFieldStyle = styled(TextField)({
  ".MuiOutlinedInput-input": {
    height: "10px",
    background:
      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
    borderRadius: "10px",
    "&.Mui-disabled": {
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-root": {
    fieldset: {
      border: "1px solid #C2C2C2",
    },
    paddingRight: 0,
    "&.Mui-disabled": {
      background: "#F1F1F1",
    },
  },
});

const DatePickerStyled = styled(DatePicker)({
  "&.MuiInputBase-adornedEnd": {
    display: "none",
  },
});

const PickersDayStyled = styled(PickersDay)(({ theme }) => ({
  "&.MuiPickersDay-today": {
    background: "none",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.orange,
    "&:hover": {
      backgroundColor: theme.palette.primary.hoverOrange,
    },
  },
}));

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

export default function DateInput({
  label,
  helpText,
  date,
  handler,
  disabled,
  monthView = false,
  ukForm = true,
  required = false,
  stackProps = { pb: 1 },
  disableFuture = false,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const minDate = moment("2017/01/01");

  const renderPickerDay = (date, selectedDates, pickersDayProps) => {
    return <PickersDayStyled {...pickersDayProps} />;
  };

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ ...stackProps }}
        justifyContent="space-between"
        alignItems="center"
      >
        <div style={{ display: "flex" }}>
          <Typography variant="h6">{label}</Typography>
          {required && (
            <Typography variant="h6" color="error">
              *{" "}
            </Typography>
          )}
        </div>
        {helpText && (
          <HelpText title={helpText}>
            <IconStyle />
          </HelpText>
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        <ClickAwayListener onClickAway={()=>{
          setOpen(false)
        }} mouseEvent="onMouseDown">
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePickerStyled
                disableFuture={disableFuture}
                value={date}
                onChange={handler}
                InputAdornmentProps={{ sx: { display: "none" } }}
                renderInput={(params) => <TextFieldStyle fullWidth {...params} />}
                renderDay={renderPickerDay}
                open={open}
                disableCloseOnSelect={false}
                onAccept={() => setOpen(false)}
                disabled={disabled}
                inputFormat={
                  monthView ? "MMM yyyy" : ukForm ? "DD/MM/yyyy" : "MM/DD/yyyy"
                }
                views={monthView ? ["year", "month"] : ["year", "month", "day"]}
                minDate={minDate}
              />
            </LocalizationProvider>
          </div>
        </ClickAwayListener>
        <HelpText title={t("general.selectNewDate")}>
          <IconButtonStyle
            aria-label={"Date selection button"}
            onClick={() => setOpen(!open)}
            disabled={disabled}
          >
            <IconCalendarStyled />
          </IconButtonStyle>
        </HelpText>
      </Stack>
    </Box>
  );
}
