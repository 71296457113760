import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

CircularStatus.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
};

export default function CircularStatus({ label, value }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex", justifyContent: "center"}}>
      <CircularProgress
        variant="determinate"
        thickness={8}
        size={70}
        value={value}
        color="success"
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h8" component="div" color="text.secondary">
          {label}
        </Typography>
      </Box>
    </Box>
  );
}
