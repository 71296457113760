import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../../components/inputs/TextInput";
import { checkBetweenRange } from "../../../utils/general";

function SafeScore(props) {
  const { t } = useTranslation();
  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
        <Typography style={{ color: "#f8931f" }} variant="h5">
          {t("kpi.safeScoreConfig")} :
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextInput
          label={t("kpi.percentageWeightage")}
          placeholder={t("general.percentage")}
          type={"number"}
          value={props?.data?.paramWeight}
          disabled
        />
      </Grid>
    </Grid>
  );
}

export default SafeScore;
