import axios from "axios";
import { baseURL, loginRequest } from "./config.json";
import apiHelper from "./apiHelper";
import { msalInstance } from "../index";
// import { loginRequest } from "./config.json";

const getToken = async () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent(loginRequest);

  return response.accessToken;
};

export const loginApi = (email, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${baseURL}/api/users/login`, {
        emailAddress: email,
        password,
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCurrentUserApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/users/me`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const authenticateUser = async () => {
  const accessToken = await getToken();
  console.log("Authenticating user...");
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${baseURL}/api/azure/authenticate`, {
        accessToken: accessToken,
        fcmToken: "",
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const renewTokenApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/keepAlive`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(
          err.response
            ? err.response.data.errorMessage
            : "Error getting the current user."
        );
      });
  });
};
