import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { displayToast } from "../../utils/general";
import useAuth from "../../context/useAuth";
import { jsPDF } from "jspdf";
import toast from "react-hot-toast";
import html2canvas from "html2canvas";
// material
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  Container,
  Typography,
  Stack,
  Rating,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
// components
import Page from "../../components/Page";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import ProvideAppreciationIcon from "@mui/icons-material/EmojiEvents";
import StatusIcon from "@mui/icons-material/PendingActionsOutlined";
import ObservationTypeIcon from "@mui/icons-material/AssignmentLateOutlined";
import LocationIcon from "@mui/icons-material/FmdGoodOutlined";
import UnitIcon from "@mui/icons-material/PinDropOutlined";
import CompanyIcon from "@mui/icons-material/BusinessOutlined";
import DisciplineIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import ReturnToObserverIcon from "@mui/icons-material/KeyboardReturnOutlined";
import MarkAsIncidentIcon from "@mui/icons-material/AssignmentLate";
import DownloadIcon from "@mui/icons-material/SystemUpdateAlt";
import ApproveIcon from "@mui/icons-material/LoginOutlined";
import EditIcon from "@mui/icons-material/Edit";
import CompleteIcon from "@mui/icons-material/Verified";
import RejectIcon from "@mui/icons-material/ThumbDown";
import FormDivider from "../../components/FormDivider";
import BehaviorInput from "../../components/inputs/BehaviorInput";
import AttachmentViewer from "../../components/AttachmentViewer";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import InfoBox from "../../components/InfoBox";
import TextInput from "../../components/inputs/TextInput";
import FileUploader from "../../components/inputs/FileUploader";
import UnsafeConditionInput from "../../components/inputs/UnsafeConditionInput";
import Timeline from "../../components/Timeline";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
//  api
import {
  getObservationDetailsApi,
  returnToObserverApi,
  completeAndCloseApi,
  rejectObservationApi,
  trackObservationApi,
  markAsIncidentApi,
  provideAppreciationApi,
} from "../../api/observation";
import { getAttachmentsApi } from "../../api/attachments";
import { getObservationPermissionsApi } from "../../api/acl";
import { searchUsersApi } from "../../api/users";
import RadioSet from "../../components/buttons/RadioSet";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PageLoader from "../../components/PageLoader";

const returnDetailsDefault = {
  open: false,
  comment: "",
  attachments: [],
};

const appreciationDetailsDefault = {
  open: false,
  comment: "",
  appreciation: 0,
  lessonLearnt: "",
  helpText: "",
  attachments: [],
  rootCauseId: "",
};

const validationChecks = {
  assignAction: [
    "OBSERVATION SUBMITTED",
    "OBSERVATION RESUBMITTED",
    "RETURNED TO SUPERVISOR",
    "ACTION PERFORMED REJECTED",
  ],
  showAppreciation: ["CLOSED BECAUSE SAFE", "OBSERVATION RESUBMITTED"],
  returnToObserver: [
    "OBSERVATION SUBMITTED",
    "CLOSED BECAUSE SAFE",
    "OBSERVATION RESUBMITTED",
  ],
  markAsIncident: ["OBSERVATION SUBMITTED", "OBSERVATION RESUBMITTED"],
  closeNoAction: ["OBSERVATION SUBMITTED", "OBSERVATION RESUBMITTED"],
  closeWithAction: ["OBSERVATION SUBMITTED", "OBSERVATION RESUBMITTED"],
  reject: ["OBSERVATION SUBMITTED", "OBSERVATION RESUBMITTED"],
  edit: [
    "OBSERVATION SUBMITTED",
    "RETURNED TO OBSERVER",
    "RETURNED TO SUPERVISOR",
    "OBSERVATION RESUBMITTED",
    "CLOSED BECAUSE SAFE",
  ],
};

const observationRoleDefault = {
  permitted: false,
  role: [],
};

const timelineStepDefault = {
  activeStep: "",
  steps: [
    {
      label: "OBSERVATION SUBMITTED",
      icon: "observation_submitted",
      comment: "Example comment.",
    },
  ],
};

export default function ObservationDetails() {
  const { id } = useParams();
  const { userId, access } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const timeout = useRef();
  const pagesRef = useRef([]);
  const [observationRole, setObservationRole] = useState(
    observationRoleDefault
  );
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [details, setDetails] = useState(null);
  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const [rowDetails, setRowDetails] = useState([]);
  const [timelineSteps, setTimelineSteps] = useState(timelineStepDefault);
  const [criticalRowDetails, setCriticalRowDetails] = useState([]);
  const [otherRowDetails, setOtherRowDetails] = useState([]);
  const [returnDetails, setReturnDetails] = useState(returnDetailsDefault);
  const [completeDetails, setCompleteDetails] = useState(
    appreciationDetailsDefault
  );
  const [appreciationDetails, setAppreciationDetails] = useState(
    appreciationDetailsDefault
  );
  const [rejectDetails, setRejectDetails] = useState(returnDetailsDefault);
  const [incidentDetails, setIncidentDetails] = useState(returnDetailsDefault);
  const [userDropdown, setUserDropdown] = useState([]);

  const [shareLessonWith, setShareLessonWith] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const renderActionDetails = () => (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
          {details.actions[0].generatedActionId}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          variant="h6"
          style={{
            color: theme.palette.primary.red,
            textShadow: "none",
          }}
        >
          {t(`observations.observationDetails.actionToPerform`)}
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: "#7D7D7D",
            textShadow: "none",
          }}
        >
          {details.actions[0].actionRequired}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          variant="h6"
          style={{
            textShadow: "none",
          }}
        >
          {t(`observations.observationDetails.priority`)}
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: "#7D7D7D",
            textShadow: "none",
          }}
        >
          {details.actions[0].priority}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{
            textShadow: "none",
          }}
        >
          {t(`observations.observationDetails.targetDate`)}
        </Typography>
        <Typography
          variant="h6"
          style={{
            color: "#7D7D7D",
            textShadow: "none",
          }}
        >
          {moment(details.actions[0].targetDate).format("DD/MM/YY  hh:mm a")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );

  const renderButtonSet = () => {
    if (
      details.status === "CLOSED BECAUSE SAFE" ||
      (details.status === "OBSERVATION RESUBMITTED" && details.safeObservation)
    ) {
      return (
        //  -> Safe observation - Return to Observer and Provide Appreciation and an edit observation for verifiers
        <>
          <Grid item xs={12}>
            {!details.appreciation && (
              <Stack direction="row" spacing={6}>
                <PrimaryButton
                  disabled={
                    loading ||
                    !validationChecks.returnToObserver.includes(details.status)
                  }
                  alignment="left"
                  label={t(`observations.observationDetails.returnToObserver`)}
                  icon={<ReturnToObserverIcon />}
                  onClick={() =>
                    setReturnDetails({ ...returnDetails, open: true })
                  }
                />
                <PrimaryButton
                  disabled={
                    loading ||
                    !validationChecks.showAppreciation.includes(details.status)
                  }
                  alignment="left"
                  label={t(
                    `observations.observationDetails.provideAppreciation`
                  )}
                  onClick={() =>
                    setAppreciationDetails({
                      ...appreciationDetails,
                      open: true,
                    })
                  }
                  icon={<ProvideAppreciationIcon />}
                />
              </Stack>
            )}
          </Grid>
          <Grid item xs={12}>
            <PrimaryButton
              disabled={
                loading ||
                !validationChecks.edit.includes(details.status) ||
                (details.observer?.id !== userId &&
                  details.submittedBy !== userId)
              }
              alignment="left"
              label={t(`observations.observationDetails.editObservation`)}
              icon={<EditIcon />}
              onClick={() => navigate(`/observations/edit/${id}`)}
            />
          </Grid>
        </>
      );
    } else {
      return (
        //  Regular observation
        <>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <PrimaryButton
                disabled={
                  loading ||
                  !validationChecks.returnToObserver.includes(details.status)
                }
                alignment="left"
                label={t(`observations.observationDetails.returnToObserver`)}
                icon={<ReturnToObserverIcon />}
                onClick={() =>
                  setReturnDetails({ ...returnDetails, open: true })
                }
              />
              <PrimaryButton
                disabled={
                  loading ||
                  !validationChecks.assignAction.includes(details.status)
                }
                alignment="left"
                label={t(`observations.observationDetails.approveAndAssign`)}
                onClick={() => navigate(`/observations/assign/${id}`)}
                icon={<ApproveIcon />}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <PrimaryButton
                disabled={
                  loading ||
                  !validationChecks.edit.includes(details.status) ||
                  (details.observer?.id !== userId &&
                    details.submittedBy !== userId)
                }
                alignment="left"
                label={t(`observations.observationDetails.editObservation`)}
                icon={<EditIcon />}
                onClick={() => navigate(`/observations/edit/${id}`)}
              />
              <PrimaryButton
                disabled={
                  loading ||
                  !validationChecks.closeNoAction.includes(details.status)
                }
                alignment="left"
                icon={<CompleteIcon />}
                label={t(
                  `observations.observationDetails.completeAndCloseWithoutAction`
                )}
                onClick={() =>
                  setCompleteDetails({
                    ...completeDetails,
                    open: true,
                  })
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <PrimaryButton
                disabled={
                  loading ||
                  !validationChecks.markAsIncident.includes(details.status)
                }
                alignment="left"
                icon={<MarkAsIncidentIcon />}
                label={t(`observations.observationDetails.markAsIncident`)}
                onClick={() =>
                  setIncidentDetails({
                    ...incidentDetails,
                    open: true,
                  })
                }
              />
              <PrimaryButton
                isred
                disabled={
                  loading || !validationChecks.reject.includes(details.status)
                }
                icon={<RejectIcon />}
                alignment="left"
                label={t(`observations.observationDetails.rejectObservation`)}
                onClick={() =>
                  setRejectDetails({ ...rejectDetails, open: true })
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <SecondaryButton
              alignment="left"
              disabled={loading}
              onClick={submitDownload}
              label={t("observations.observationDetails.downloadObsCard")}
              icon={<DownloadIcon />}
            />
          </Grid>
        </>
      );
    }
  };

  const validatePermission = (obsDetails, permissionsData) => {
    //  IF SUPER ADMIN NEGATE VERIFIER PERMISSIONS
    if (access.includes("SUPER_ADMIN")) {
      return false;
    } else if (
      obsDetails.isEscalated &&
      ["HSE-MANAGER", "PDO_HSE_MANAGER"].some((el) =>
        permissionsData.role.includes(el)
      )
    ) {
      return true;
    } else if (
      !obsDetails.isEscalated &&
      ["VERIFIER", "PDO_VERIFIER"].some((el) =>
        permissionsData.role.includes(el)
      )
    ) {
      return true;
    } else if (
      obsDetails.status === "CLOSED BECAUSE SAFE" &&
      ["VERIFIER", "PDO_VERIFIER"].some((el) =>
        permissionsData.role.includes(el)
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const handleRatingHover = (h) => {
    const finalValue = parseFloat(h);
    let finalText = "";

    if (finalValue === 1) {
      finalText = t("observations.observationDetails.hoverStar1");
    } else if (finalValue === 2) {
      finalText = t("observations.observationDetails.hoverStar2");
    } else if (finalValue === 3) {
      finalText = t("observations.observationDetails.hoverStar3");
    } else if (finalValue === 4) {
      finalText = t("observations.observationDetails.hoverStar4");
    } else if (finalValue === 5) {
      finalText = t("observations.observationDetails.hoverStar5");
    } else {
      finalText = "";
    }
    setAppreciationDetails({ ...appreciationDetails, helpText: finalText });
  };

  const returnRatingText = (v) => {
    const finalValue = v;
    let finalText = "";

    if (finalValue === 1) {
      finalText = t("observations.observationDetails.hoverStar1");
    } else if (finalValue === 2) {
      finalText = t("observations.observationDetails.hoverStar2");
    } else if (finalValue === 3) {
      finalText = t("observations.observationDetails.hoverStar3");
    } else if (finalValue === 4) {
      finalText = t("observations.observationDetails.hoverStar4");
    } else if (finalValue === 5) {
      finalText = t("observations.observationDetails.hoverStar5");
    } else {
      finalText = "";
    }
    return finalText;
  };

  const submitReturnToObserver = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("observations.observationDetails.returnLoading")
    );
    try {
      await returnToObserverApi(returnDetails, id);
      displayToast(
        setLoading,
        "successLoading",
        t("observations.observationDetails.returnSuccess"),
        activeToast
      );
      navigate("/observations/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitCompleteAndClose = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("observations.observationDetails.closeLoading")
    );
    try {
      if (
        completeDetails.appreciationRequired &&
        !completeDetails.appreciation
      ) {
        throw t("actions.assignToParty.appreciationError");
      }
      let tempUserIds = [];
      appreciationDetails?.userIds?.length > 0 &&
        appreciationDetails.userIds.forEach((entry) =>
          tempUserIds.push(parseInt(entry.id))
        );

      await completeAndCloseApi(
        {
          ...completeDetails,
          lessonDescription: appreciationDetails?.lessonSummary,
          lessonLearnt: appreciationDetails?.lessonLearnt,
          userIds: [...tempUserIds],
          shareLessonWith,
          sendEmail,
          companyId: details.companyId,
          unitId: details.unitId,
        },
        id
      );
      displayToast(
        setLoading,
        "successLoading",
        t("observations.observationDetails.closeSuccess"),
        activeToast
      );
      navigate("/observations/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitRejectObservation = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("observations.observationDetails.rejectLoading")
    );
    try {
      await rejectObservationApi(rejectDetails, id);
      displayToast(
        setLoading,
        "successLoading",
        t("observations.observationDetails.rejectSuccess"),
        activeToast
      );
      navigate("/observations/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitIncidentDetails = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("observations.observationDetails.incidentLoading")
    );
    try {
      await markAsIncidentApi(incidentDetails, id);
      displayToast(
        setLoading,
        "successLoading",
        t("observations.observationDetails.incidentSuccess"),
        activeToast
      );
      navigate("/observations/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitProvideAppreciation = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("observations.observationDetails.appreciateLoading")
    );
    try {
      if (appreciationDetails.appreciation < 1) {
        throw t("observations.observationDetails.appreciationRequired");
      }
      await provideAppreciationApi({ ...appreciationDetails }, id);
      displayToast(
        setLoading,
        "successLoading",
        t("observations.observationDetails.appreciateSuccess"),
        activeToast
      );
      navigate("/observations/list");
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitDownload = () => {
    try {
      toast.remove();
      setLoading(true);
      toast.loading(t("reporting.downloading"), {
        duration: Infinity,
      });

      setTimeout(async () => {
        const clientWidth = pagesRef.current[0].clientWidth;
        const clientHeight = pagesRef.current[0].clientHeight;

        const pdf = new jsPDF("p", "px", [clientWidth, clientHeight], true);
        const width = pdf.internal.pageSize.getWidth();
        const height = pdf.internal.pageSize.getHeight();

        let ahtml2canvas = await html2canvas(pagesRef.current[0], {
          scale: window.devicePixelRatio,
        });

        let imgData = ahtml2canvas.toDataURL("image/png");

        // Calculate dimensions to fit the entire content
        const aspectRatio = ahtml2canvas.width / ahtml2canvas.height;

        let newWidth = clientWidth * 0.9;

        // Adjust the width if it exceeds the available space
        if (newWidth > width) {
          newWidth = width * 0.9;
        }

        const newHeight = newWidth / aspectRatio;

        pdf.addImage(
          imgData,
          "PNG",
          width * 0.05,
          0,
          newWidth,
          newHeight,
          undefined,
          "FAST"
        );

        pdf.save(
          `${details.observationId}_${moment().format("DD_MM_YYYY")}.pdf`
        );
        setLoading(false);
        toast.remove();
        toast.success(t("reporting.downloadSuccess"));
      }, 1000);
    } catch (err) {
      toast.remove();
      toast.error(t("reporting.downloadError"));
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleGetDetails = async () => {
      setPageLoading(true);
      try {
        const behaviorRows = [];
        const criticalRows = [];
        const otherRows = [];
        const attachmentIds = [];
        const finalSteps = [];

        const detailsData = await getObservationDetailsApi(id);
        setDetails(detailsData);

        const timelineDetailsRaw = await trackObservationApi(id);
        let activeStep = 0;

        timelineDetailsRaw.forEach((d, index) => {
          if (d.eventName === detailsData.status) {
            activeStep = index;
          } else if (d.eventName === "APPRECIATION PROVIDED") {
            activeStep += 1;
          }

          finalSteps.push({
            icon: d.icon,
            label: d.eventName,
            comment: d.comment,
            role: d.role,
            name: d.name,
            attachments: d.attachments,
            createdAt: d.createdAt,
          });
        });
        setTimelineSteps({
          activeStep,
          steps: finalSteps,
        });

        const observationPermissionsData = await getObservationPermissionsApi(
          id
        );
        setObservationRole(observationPermissionsData);

        if (detailsData.observationType === "BEHAVIOR") {
          detailsData.behaviors &&
            detailsData.behaviors.forEach((d) => {
              if (d.ObservationBehaviors.behaviorType === "CRITICAL") {
                criticalRows.push({
                  name: d.name,
                  label: d.name,
                  labelAR: d.nameAR,
                  safe: d.ObservationBehaviors.safe,
                  unsafe: d.ObservationBehaviors.unsafe,
                  lsr: d.ObservationBehaviors?.LSRTitle || "",
                });
              } else {
                otherRows.push({
                  name: d.name,
                  label: d.name,
                  labelAR: d.nameAR,
                  safe: d.ObservationBehaviors.safe,
                  unsafe: d.ObservationBehaviors.unsafe,
                });
              }
            });
        } else if (detailsData.observationType === "CONDITION") {
          detailsData.conditions &&
            detailsData.conditions.forEach((c) => {
              behaviorRows.push({
                label: c.unsafeCondition.name,
                value: c.unsafeCondition.id,
                labelAR: c.unsafeCondition.nameAR,
              });
            });
        }

        detailsData.attachments.forEach((a) => {
          attachmentIds.push(a.id);
        });

        if (attachmentIds && attachmentIds.length > 0) {
          const attachmentsData = await getAttachmentsApi(attachmentIds);
          setAttachmentLinks(attachmentsData);
        }

        setRowDetails(behaviorRows);
        setCriticalRowDetails(criticalRows);
        setOtherRowDetails(otherRows);
        setPageLoading(false);
      } catch (err) {
        setPageLoading(false);
        console.log(err);
      }
    };

    handleGetDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Page title="Observation details">
      <Container maxWidth="xl">
        {details ? (
          <>
            <Box sx={{ pb: 2 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <HeaderBackButton />
                <Typography variant="h4">{t(`observations.title`)}</Typography>
              </div>
            </Box>
            <div ref={(el) => (pagesRef.current[0] = el)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Typography variant="h5">{details.observationId}</Typography>
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "12px",
                      color: "#7D7D7D",
                      textShadow: "none",
                    }}
                  >
                    {t("observations.observationDetails.submittedByText", {
                      name: ` ${details.observer?.firstName ?? ""} ${
                        details.observer?.lastName ?? ""
                      } `,
                      date: moment(details.observedAt).format(
                        "DD/MM/YY  hh:mm a"
                      ),
                    })}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "1px",
                      color: "#7D7D7D",
                      textShadow: "none",
                    }}
                  >
                    {details.status === "RETURNED TO SUPERVISOR" &&
                    details.actions.length > 0 &&
                    details.actions[details.actions.length - 1].status ===
                      "RETURNED"
                      ? `${t(`observations.observationDetails.returnedBy`)}
                  ${`${
                    details?.actions[details.actions.length - 1]?.ActionParty
                      ?.firstName
                  } ${
                    details?.actions[details.actions.length - 1]?.ActionParty
                      ?.lastName
                  }`}
                  at ${moment(
                    details.actions[details.actions.length - 1].updatedAt
                  ).format("DD/MM/YY  hh:mm a")}`
                      : ""}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "1px",
                      color: "#7D7D7D",
                      textShadow: "none",
                    }}
                  >
                    {(details.status === "ACTION ASSIGNED" ||
                      details.status === "ACTION RE-ASSIGNED") &&
                    details.actions.length > 0 &&
                    details.actions[details.actions.length - 1].status ===
                      "PENDING"
                      ? `${
                          details.status === "ACTION ASSIGNED"
                            ? t(`observations.observationDetails.assignedTo`)
                            : t(`observations.observationDetails.reAssignedTo`)
                        }
                  ${`${
                    details?.actions[details.actions.length - 1]?.ActionParty
                      ?.firstName
                  } ${
                    details?.actions[details.actions.length - 1]?.ActionParty
                      ?.lastName
                  }`}
                  at ${moment(
                    details.actions[details.actions.length - 1].updatedAt
                  ).format("DD/MM/YY  hh:mm a")}`
                      : ""}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      marginTop: "1px",
                      color: "#7D7D7D",
                      textShadow: "none",
                    }}
                  >
                    {details.status === "OBSERVATION CLOSED" &&
                    details.actions.length > 0 &&
                    details.actions[details.actions.length - 1].status ===
                      "APPROVED"
                      ? `${t(`observations.observationDetails.completedBy`)}
                  ${`${
                    details?.actions[details.actions.length - 1]?.ActionParty
                      ?.firstName
                  } ${
                    details?.actions[details.actions.length - 1]?.ActionParty
                      ?.lastName
                  }`}
                  at ${moment(
                    details.actions[details.actions.length - 1].updatedAt
                  ).format("DD/MM/YY  hh:mm a")}`
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography
                    variant="h6"
                    style={{
                      color: !details?.safeObservation
                        ? theme.palette.primary.red
                        : theme.palette.primary.green,
                    }}
                  >
                    {!details?.safeObservation
                      ? t(`observations.observationDetails.unsafeObservation`)
                      : t(`observations.observationDetails.safeObservation`)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: "6px" }}>
                <Grid item xs={12}>
                  <FormDivider />
                </Grid>
                <Grid item xs={12}>
                  <Timeline
                    steps={timelineSteps.steps}
                    activeStep={timelineSteps.activeStep}
                    attachmentLinks={attachmentLinks}
                  />
                </Grid>
                {details.actions &&
                  details.actions.length > 0 &&
                  renderActionDetails()}
                <Grid item xs={12} md={6}>
                  <Stack direction="row" alignItems="center">
                    <StatusIcon style={{ color: "#7D7D7D" }} />
                    <Stack direction="column" sx={{ ml: 2 }}>
                      <Typography
                        variant="h6"
                        style={{ color: "#7D7D7D", textShadow: "none" }}
                      >
                        {`${t(`observations.observationDetails.status`)}`}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ color: "#4C4C4E", textShadow: "none" }}
                      >
                        {t(details.status, { ns: "backend" })}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" alignItems="center">
                    <ObservationTypeIcon style={{ color: "#7D7D7D" }} />
                    <Stack direction="column" sx={{ ml: 2 }}>
                      <Typography
                        variant="h6"
                        style={{ color: "#7D7D7D", textShadow: "none" }}
                      >
                        {t(`observations.observationDetails.type`)}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ color: "#4C4C4E", textShadow: "none" }}
                      >
                        {details.observationType}, {details.observationSubType}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" alignItems="center">
                    <LocationIcon style={{ color: "#7D7D7D" }} />
                    <Stack direction="column" sx={{ ml: 2 }}>
                      <Typography
                        variant="h6"
                        style={{ color: "#7D7D7D", textShadow: "none" }}
                      >
                        {t(`observations.observationDetails.location`)}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ color: "#4C4C4E", textShadow: "none" }}
                      >
                        {details.location.name}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" alignItems="center">
                    <UnitIcon style={{ color: "#7D7D7D" }} />
                    <Stack direction="column" sx={{ ml: 2 }}>
                      <Typography
                        variant="h6"
                        style={{ color: "#7D7D7D", textShadow: "none" }}
                      >
                        {t(`observations.observationDetails.unit`)}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ color: "#4C4C4E", textShadow: "none" }}
                      >
                        {details.unit?.name || "-"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack direction="row" alignItems="center">
                    <CompanyIcon style={{ color: "#7D7D7D" }} />
                    <Stack direction="column" sx={{ ml: 2 }}>
                      <Typography
                        variant="h6"
                        style={{ color: "#7D7D7D", textShadow: "none" }}
                      >
                        {t(`observations.observationDetails.companyDepartment`)}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ color: "#4C4C4E", textShadow: "none" }}
                      >
                        {details.company.name}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                {details.discipline && (
                  <Grid item xs={12} md={6}>
                    <Stack direction="row" alignItems="center">
                      <DisciplineIcon style={{ color: "#7D7D7D" }} />
                      <Stack direction="column" sx={{ ml: 2 }}>
                        <Typography
                          variant="h6"
                          style={{ color: "#7D7D7D", textShadow: "none" }}
                        >
                          {t(`observations.observationDetails.discipline`)}
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ color: "#4C4C4E", textShadow: "none" }}
                        >
                          {details.discipline.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormDivider />
                </Grid>
                {details.observationType === "BEHAVIOR" &&
                  details.behaviors && (
                    <>
                      <Grid container sx={{ pl: "40px", pr: "40px", mt: 1 }}>
                        <Grid xs={12} sx={{ mb: 1 }}>
                          <Typography variant="h5">
                            {t(
                              `observations.observationDetails.observedCriticalBehaviors`
                            )}
                          </Typography>
                        </Grid>
                        <Grid xs={12} sx={{ mb: 1 }}>
                          <BehaviorInput
                            disabled={loading}
                            behaviors={criticalRowDetails}
                            mandatory
                            displayLSRs={false}
                            displayOnly
                          />
                        </Grid>
                        <Grid xs={12} sx={{ mb: 1 }}>
                          <Typography variant="h5">
                            {t(
                              `observations.observationDetails.observedOtherBehaviors`
                            )}
                          </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <BehaviorInput
                            disabled={loading}
                            behaviors={otherRowDetails}
                            mandatory
                            displayOnly
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormDivider />
                      </Grid>
                    </>
                  )}
                {details.observationType === "CONDITION" &&
                  details.conditions && (
                    <>
                      <Grid container sx={{ pl: "40px", pr: "40px", mt: 1 }}>
                        <Grid xs={12}>
                          <Typography variant="h5">
                            {t(
                              `observations.observationDetails.unsafeConditions`
                            )}
                          </Typography>
                        </Grid>
                        <Grid xs={12}>
                          <UnsafeConditionInput
                            disabled={loading}
                            unsafeConditions={rowDetails}
                            displayOnly
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FormDivider />
                      </Grid>
                    </>
                  )}
                <Grid item xs={12}>
                  <Stack
                    spacing={2}
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                      border: "1px solid #C2C2C2",
                      padding: "18px",
                      borderRadius: "4px",
                    }}
                  >
                    <Grid item xs={12} md={12}>
                      <Stack direction="column">
                        <Typography variant="h6" style={{ textShadow: "none" }}>
                          {t(`observations.observationDetails.feedback`)}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: theme.palette.primary.grey }}
                        >
                          {details.feedback}
                        </Typography>
                      </Stack>
                    </Grid>
                    {details.rootCause &&
                      details?.observationType === "BEHAVIOR" && (
                        <Grid item xs={12} md={12}>
                          <Stack direction="column">
                            <Typography
                              variant="h6"
                              style={{ textShadow: "none" }}
                            >
                              {t(`observations.observationApp.rootCauseSafe`)}
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ color: theme.palette.primary.grey }}
                            >
                              {details.rootCause}
                            </Typography>
                          </Stack>
                        </Grid>
                      )}
                    <Grid item xs={12} md={12}>
                      <Stack direction="column">
                        <Typography variant="h6" style={{ textShadow: "none" }}>
                          {t(`observations.observationDetails.actionTaken`)}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: theme.palette.primary.grey }}
                        >
                          {details.actionToPrevent}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack direction="column">
                        <Typography variant="h6" style={{ textShadow: "none" }}>
                          {t(`observations.observationDetails.suggestion`)}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: theme.palette.primary.grey }}
                        >
                          {details.suggestions}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <FormDivider />
                </Grid>
                <Grid item xs={12}>
                  <AttachmentViewer
                    disabled={loading}
                    attachments={details.attachments}
                    attachmentLinks={attachmentLinks}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormDivider />
                </Grid>
              </Grid>
            </div>
            {/* Button Controls */}
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {details?.appreciation ? (
                <>
                  <Grid item xs={12}>
                    <Stack spacing={2}>
                      <Typography variant="h6">
                        {t("observations.observationDetails.appreciation")}
                      </Typography>
                      {details.appreciation ? (
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="flex-end"
                        >
                          <Rating
                            disabled
                            name="appreciation-rating-details-display"
                            defaultValue={0}
                            precision={1}
                            value={details.appreciation}
                          />
                          <Typography variant="body1">
                            {returnRatingText(details.appreciation)}
                          </Typography>
                        </Stack>
                      ) : (
                        <Typography variant="body1">
                          {t("observations.observationDetails.noAppreciation")}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <FormDivider />
                  </Grid>
                </>
              ) : (
                ""
              )}
              {validatePermission(details, observationRole) ? (
                renderButtonSet()
              ) : (
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <PrimaryButton
                      disabled={
                        loading ||
                        !validationChecks.edit.includes(details.status) ||
                        (details.observer?.id !== userId &&
                          details.submittedBy !== userId)
                      }
                      alignment="left"
                      label={t(
                        `observations.observationDetails.editObservation`
                      )}
                      icon={<EditIcon />}
                      onClick={() => navigate(`/observations/edit/${id}`)}
                    />
                    <SecondaryButton
                      alignment="left"
                      disabled={loading}
                      onClick={submitDownload}
                      label={t(
                        "observations.observationDetails.downloadObsCard"
                      )}
                      icon={<DownloadIcon />}
                    />
                  </Stack>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <div
            style={{
              height: "70vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PageLoader />
          </div>
        )}
      </Container>
      <InfoBox
        compact
        disabled={loading || !returnDetails.comment}
        open={returnDetails.open}
        title={t(`observations.observationDetails.returnToObserver`)}
        content={
          <Stack spacing={3}>
            <TextInput
              disabled={loading}
              label={t(`observations.observationDetails.comment`)}
              value={returnDetails.comment}
              handler={(e) =>
                setReturnDetails({
                  ...returnDetails,
                  comment: e.target.value,
                })
              }
            />
            <FileUploader
              files={returnDetails.attachments}
              setFiles={(files) =>
                setReturnDetails({ ...returnDetails, attachments: files })
              }
              disabled={loading}
            />
          </Stack>
        }
        buttonLabel={t(`observations.observationDetails.returnToObserver`)}
        handleClose={() => setReturnDetails(returnDetailsDefault)}
        handleAction={() => submitReturnToObserver()}
      />
      <InfoBox
        compact
        disabled={loading}
        open={appreciationDetails.open}
        title={t(`observations.observationDetails.provideAppreciation`)}
        content={
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <TextInput
                disabled={loading}
                label={t(`observations.observationDetails.comment`)}
                value={appreciationDetails.comment}
                handler={(e) =>
                  setAppreciationDetails({
                    ...appreciationDetails,
                    comment: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label={t("observations.observationDetails.summaryLesson")}
                value={appreciationDetails.lessonLearnt}
                disabled={loading}
                handler={(e) =>
                  setAppreciationDetails({
                    ...appreciationDetails,
                    lessonLearnt: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack spacing={2}>
                <Typography variant="h6">
                  {`${t("observations.observationDetails.appreciation")}*`}
                </Typography>
                <Rating
                  name="appreciation-rating"
                  defaultValue={0}
                  precision={1}
                  value={appreciationDetails.appreciation}
                  onChangeActive={(e, h) => handleRatingHover(h)}
                  onChange={(e) =>
                    setAppreciationDetails({
                      ...appreciationDetails,
                      appreciation: parseFloat(e.target.value),
                    })
                  }
                />
                <Typography variant="body1">
                  {appreciationDetails.helpText ||
                    t("observations.observationDetails.starHover")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <FileUploader
                files={appreciationDetails.attachments}
                setFiles={(files) =>
                  setAppreciationDetails({
                    ...appreciationDetails,
                    attachments: files,
                  })
                }
                disabled={loading}
              />
            </Grid>
          </Grid>
        }
        buttonLabel={t(`observations.observationDetails.provideAppreciation`)}
        handleClose={() => setAppreciationDetails(appreciationDetailsDefault)}
        handleAction={() => submitProvideAppreciation()}
      />
      <InfoBox
        compact
        minWidth="65vw"
        disabled={loading || !completeDetails.comment}
        open={completeDetails.open}
        title={t(
          `observations.observationDetails.completeAndCloseWithoutAction`
        )}
        content={
          <Stack spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t("observations.observationDetails.lessonsAppreciation")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" spacing={8}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={completeDetails.appreciationRequired}
                      name={"appreciationRequired"}
                      onChange={() =>
                        setCompleteDetails({
                          ...completeDetails,
                          appreciation: 0,
                          helpText: "",
                          appreciationRequired:
                            !completeDetails.appreciationRequired,
                        })
                      }
                      sx={{
                        color: theme.palette.primary.orange,
                        "&.Mui-checked": {
                          color: theme.palette.primary.orange,
                        },
                        "&:hover": {
                          backgroundColor: "rgba(248,147,31,0.08)",
                        },
                      }}
                    />
                  }
                  disabled={loading}
                  label={t(
                    "observations.observationDetails.appreciationRequired"
                  )}
                />
                <Rating
                  name="appreciation-rating"
                  defaultValue={0}
                  precision={1}
                  disabled={!completeDetails.appreciationRequired}
                  value={completeDetails.appreciation}
                  onChangeActive={(e, h) => handleRatingHover(h)}
                  onChange={(e) =>
                    setCompleteDetails({
                      ...completeDetails,
                      appreciation: parseFloat(e.target.value),
                    })
                  }
                />
                <Typography variant="body1">
                  {appreciationDetails.helpText ||
                    t("observations.observationDetails.starHover")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="column" spacing={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={appreciationDetails.lessonLearnt}
                      name={"lessonLearnt"}
                      onChange={() =>
                        setAppreciationDetails({
                          ...appreciationDetails,
                          lessonLearnt: !appreciationDetails.lessonLearnt,
                        })
                      }
                      sx={{
                        color: theme.palette.primary.orange,
                        "&.Mui-checked": {
                          color: theme.palette.primary.orange,
                        },
                        "&:hover": {
                          backgroundColor: "rgba(248,147,31,0.08)",
                        },
                      }}
                    />
                  }
                  disabled={loading}
                  label={t("observations.observationDetails.lessonLearnt")}
                />
                {appreciationDetails.lessonLearnt && (
                  <>
                    <TextInput
                      label={t("observations.observationDetails.summaryLesson")}
                      value={appreciationDetails.lessonSummary}
                      disabled={loading || !appreciationDetails.lessonLearnt}
                      handler={(e) =>
                        setAppreciationDetails({
                          ...appreciationDetails,
                          lessonSummary: e.target.value,
                        })
                      }
                    />
                    <RadioSet
                      label={t("observations.observationDetails.shareLesson")}
                      disabled={loading}
                      value={shareLessonWith}
                      handleChange={(e, v) => {
                        setShareLessonWith(v);
                      }}
                      options={
                        details.unit
                          ? [
                              {
                                label: t("profile.company"),
                                value: "Company",
                              },
                              {
                                label: t("profile.unit"),
                                value: "Unit",
                              },
                              {
                                label: t("general.selectedIndividuals"),
                                value: "Users",
                              },
                            ]
                          : [
                              {
                                label: t("profile.company"),
                                value: "Company",
                              },
                              {
                                label: t("general.selectedIndividuals"),
                                value: "Users",
                              },
                            ]
                      }
                    />

                    {shareLessonWith === "Users" && (
                      <AutocompleteInput
                        multiple
                        handler={(e, v) =>
                          v.length > 0
                            ? setAppreciationDetails({
                                ...appreciationDetails,
                                userIds: v,
                              })
                            : setAppreciationDetails({
                                ...appreciationDetails,
                                userIds: [],
                              })
                        }
                        keyPressHandler={handleSearchUser}
                        label={t("observations.observationDetails.shareLesson")}
                        loading={searchLoading}
                        disabled={loading}
                        options={userDropdown}
                      />
                    )}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendEmail}
                          name={"actionPerformed"}
                          onChange={() => {
                            setSendEmail(!sendEmail);
                          }}
                          sx={{
                            color: theme.palette.primary.orange,
                            "&.Mui-checked": {
                              color: theme.palette.primary.orange,
                            },
                            "&:hover": {
                              backgroundColor: "rgba(248,147,31,0.08)",
                            },
                          }}
                        />
                      }
                      disabled={loading}
                      label={t("observations.observationDetails.sendEmail")}
                    />
                  </>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <FormDivider />
            </Grid>
            <TextInput
              disabled={loading}
              label={t(`observations.observationApp.rootCause`)}
              value={completeDetails.comment}
              handler={(e) =>
                setCompleteDetails({
                  ...completeDetails,
                  comment: e.target.value,
                })
              }
            />
            <FileUploader
              files={completeDetails.attachments}
              setFiles={(files) =>
                setCompleteDetails({ ...completeDetails, attachments: files })
              }
              disabled={loading}
            />
          </Stack>
        }
        buttonLabel={t(`observations.observationDetails.closeWithoutAction`)}
        handleClose={() => setCompleteDetails(returnDetailsDefault)}
        handleAction={() => submitCompleteAndClose()}
      />
      <InfoBox
        compact
        disabled={loading || !rejectDetails.comment}
        open={rejectDetails.open}
        title={t(`observations.observationDetails.rejectObservation`)}
        content={
          <Stack spacing={3}>
            <TextInput
              disabled={loading}
              label={t(`observations.observationDetails.whyReject`)}
              value={rejectDetails.comment}
              handler={(e) =>
                setRejectDetails({
                  ...rejectDetails,
                  comment: e.target.value,
                })
              }
            />
            <FileUploader
              files={rejectDetails.attachments}
              setFiles={(files) =>
                setRejectDetails({ ...rejectDetails, attachments: files })
              }
              disabled={loading}
            />
          </Stack>
        }
        buttonLabel={t(`observations.observationDetails.rejectObservation`)}
        handleClose={() => setRejectDetails(returnDetailsDefault)}
        handleAction={() => submitRejectObservation()}
      />
      <InfoBox
        compact
        disabled={loading || !incidentDetails.comment}
        open={incidentDetails.open}
        title={t(`observations.observationDetails.markAsIncident`)}
        content={
          <Stack spacing={3}>
            <TextInput
              disabled={loading}
              label={t(`observations.observationDetails.comment`)}
              value={incidentDetails.comment}
              handler={(e) =>
                setIncidentDetails({
                  ...incidentDetails,
                  comment: e.target.value,
                })
              }
            />
            <FileUploader
              files={incidentDetails.attachments}
              setFiles={(files) =>
                setIncidentDetails({ ...incidentDetails, attachments: files })
              }
              disabled={loading}
            />
          </Stack>
        }
        buttonLabel={t(`observations.observationDetails.markAsIncident`)}
        handleClose={() => setIncidentDetails(returnDetailsDefault)}
        handleAction={() => submitIncidentDetails()}
      />
    </Page>
  );
}
