import React, { createContext, useContext, useMemo, useState } from "react";

const StoreContext = createContext({
  store: {},
});

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState({});

  const updateStore = (obj) => {
    setStore({ ...store, ...obj });
  };

  const memoedValue = useMemo(
    () => ({
      store,
      updateStore,
    }),
    [store]
  );

  return (
    <StoreContext.Provider value={memoedValue}>
      {children}
    </StoreContext.Provider>
  );
};

export default function useStore() {
  return useContext(StoreContext);
}
