import useAuth from "../context/useAuth";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

AccessControl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  accessName: PropTypes.string,
  accessNames: PropTypes.object,
  isRoute: PropTypes.bool,
};

export default function AccessControl({
  children,
  accessName,
  accessNames,
  isRoute = false,
}) {
  const { access } = useAuth();
  try {
    if (accessName) {
      if (accessName === "NONE") {
        return children;
      } else if (access.includes(accessName)) {
        return children;
      } else {
        return isRoute ? <Navigate to="/404" replace /> : null;
      }
    } else if (accessNames) {
      const accessArray = accessNames.features;
      if (accessNames.type === "OR") {
        const found = access.some((a) => accessArray.includes(a));
        if (found) {
          return children;
        } else {
          return isRoute ? <Navigate to="/404" replace /> : null;
        }
      } else if (accessNames.type === "AND") {
        const found = access.every((a) => accessArray.includes(a));
        if (found) {
          return children;
        } else {
          return isRoute ? <Navigate to="/404" replace /> : null;
        }
      }
    }
  } catch (err) {
    return isRoute ? <Navigate to="/404" replace /> : null;
  }
}
