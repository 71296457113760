import apiHelper from "./apiHelper";

export const getObservationPermissionsApi = (observationId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/${observationId}/permission`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getActionPermissionsApi = (observationId, actionId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/${observationId}/action/${actionId}/permission`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
