import apiHelper from "./apiHelper";

export const getRootCausesApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/rootCauses?view=tree`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
