import { useState } from "react";
import { useTranslation } from "react-i18next";

// material
import { Box, Grid, Container, Typography } from "@mui/material";

// components
import Page from "../../components/Page";
import ActivityInformation from "./ActivityInformation";
import PhaseDates from "./PhaseDates";
import StepperLine from "../../components/StepperLine";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";

export default function CreateImplementation() {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [planId, setPlanId] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);
  const [preloadObject, setPreloadObject] = useState(null);

  return (
    <Page title={t("sideBar.createImplementation")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {tabValue === 1 && (
              <HeaderBackButton backFunction={() => setTabValue(0)} />
            )}
            <Typography variant="h4">
              {t("implementations.createImplementation")}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <StepperLine
              activeStep={tabValue}
              style={{ margin: "14px 0" }}
              steps={[
                t("implementations.tabs.activityInformation"),
                t("implementations.tabs.defineDates"),
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          {tabValue === 0 && (
            <ActivityInformation
              setTabValue={setTabValue}
              setPlanDetails={setPlanDetails}
              planDetails={planDetails}
              setPreloadObject={setPreloadObject}
              preloadObject={preloadObject}
            />
          )}
          {tabValue === 1 && (
            <PhaseDates
              planId={planId}
              planDetails={planDetails}
              goBack={() => setTabValue(0)}
            />
          )}
        </Grid>
      </Container>
    </Page>
  );
}
