import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TextField, Typography, Box, Stack, IconButton } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { styled } from "@mui/material/styles";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { InfoOutlined } from "@mui/icons-material";
import HelpText from "../HelpText";

DateTimeInput.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  handler: PropTypes.func,
  maxDate: PropTypes.bool,
  maxDateTime: PropTypes.bool,
};

const TextFieldStyled = styled(TextField)({
  ".MuiOutlinedInput-input": {
    height: "10px",
    background:
      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
    borderRadius: "10px",
    "&.Mui-disabled": {
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-root": {
    fieldset: {
      border: "1px solid #C2C2C2",
    },
    paddingRight: 0,
    "&.Mui-focused fieldset": {
      border: "1px solid #C2C2C2",
    },
    "&.Mui-disabled": {
      background: "#F1F1F1",
    },
  },
});

const IconCalendarStyled = styled(EventNoteIcon)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  height: "43px",
  width: "43px",
  borderRadius: "10px",
  background: theme.palette.primary.grey,
  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverGrey,
  },
  "&:disabled": {
    backgroundColor: theme.palette.primary.disabledRed,
    boxShadow: "none",
  },
}));

export default function DateTimeInput({
  label,
  helpText,
  date,
  handler,
  disabled,
  maxDate = false,
  maxDateTime = false,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const minDate = new Date("2017-01-01");

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ pb: 1 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">{label}</Typography>
        {helpText && (
          <HelpText title={helpText}>
            <IconStyle />
          </HelpText>
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            value={date}
            onChange={handler}
            InputAdornmentProps={{ sx: { display: "none" } }}
            renderInput={(params) => <TextFieldStyled fullWidth {...params} />}
            open={open}
            disableCloseOnSelect={false}
            onAccept={() => setOpen(false)}
            disabled={disabled}
            inputFormat={"dd/MM/yyyy hh:mm aa"}
            maxDate={maxDate ? new Date() : null}
            minDate={minDate}
            maxDateTime={maxDateTime ? new Date() : null}
          />
        </LocalizationProvider>
        <HelpText title={t("general.selectNewDate")}>
          <IconButtonStyle
            aria-label={"Date selection button"}
            onClick={() => setOpen(!open)}
            disabled={disabled}
          >
            <IconCalendarStyled />
          </IconButtonStyle>
        </HelpText>
      </Stack>
    </Box>
  );
}
