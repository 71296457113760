export const columnDefaults = {
  observation_feedback: [
    { field: "buName", headerName: "Business Unit", width: 200 },
    { field: "feedbackCards", headerName: "Feedback Cards", width: 200 },
    {
      field: "feedbackCardsPercent",
      headerName: "Feedback Cards %",
      width: 200,
    },
    { field: "total", headerName: "Total", width: 200 },
  ],
  obsFeedbackFilters: [
    { field: "filter", headerName: "Filter", width: 200 },
    { field: "value", headerName: "Value", width: 200 },
  ],
  observer_type: [
    { field: "buName", headerName: "Business Unit", width: 200 },
    { field: "observerType", headerName: "Observer Type", width: 200 },
    { field: "total", headerName: "Total", width: 200 },
  ],
  percent_knowledge_reports: [
    { field: "numEmployees", headerName: "# Employees", width: 200 },
    { field: "knowledgeEmp", headerName: "Knowledge Employees", width: 200 },
    { field: "company", headerName: "Contractor", width: 200 },
    {
      field: "percentage",
      headerName: "Percentage %",
      width: 200,
      renderCell: (params) =>
        params.row.percentage ? `${params.row.percentage}%` : `0%`,
    },
  ],
  behavioral_trend: [
    { field: "behaviorName", headerName: "Behavior Name", width: 180 },
    { field: "businessUnit", headerName: "Business Unit", width: 180 },
    {
      field: "percentage",
      headerName: "Percentage",
      width: 180,
      renderCell: (params) =>
        params.row.percentage ? `${params.row.percentage}%` : `0%`,
    },
    { field: "safe", headerName: "Safe", width: 200 },
    { field: "unsafe", headerName: "Unsafe", width: 250 },
  ],
  observation_summary: [
    { field: "BusinessUnit", headerName: "Business Unit", width: 200 },
    { field: "cardStatus", headerName: "Card Status", width: 200 },
    { field: "Safe/Unsafe", headerName: "Safe/Unsafe", width: 200 },
    { field: "Total", headerName: "Total", width: 200 },
  ],
  complete_observation: [
    { field: "behaviorName", headerName: "Behavior Name", width: 200 },
    { field: "behaviorType", headerName: "Behavior Type", width: 200 },
    { field: "businessUnit", headerName: "Business Unit", width: 200 },
    { field: "safe", headerName: "Safe", width: 200 },
    { field: "unsafe", headerName: "Unsafe", width: 200 },
  ],
  unit_performance: [
    { field: "behavior", headerName: "Behavior", width: 200 },
    { field: "businessUnit", headerName: "Business Unit", width: 200 },
    {
      field: "percentage",
      headerName: "Percentage",
      width: 200,
      renderCell: (params) =>
        params.row.percentage ? `${params.row.percentage}%` : `0%`,
    },
    { field: "safe", headerName: "Safe", width: 200 },
    { field: "unsafe", headerName: "Unsafe", width: 200 },
  ],
  action_close_out_reports: [
    { field: "BusinessUnit", headerName: "Business Unit", width: 200 },
    { field: "Pending", headerName: "Pending", width: 100 },
    { field: "Closed", headerName: "Closed", width: 100 },
    { field: "Overdue", headerName: "Overdue", width: 100 },
    { field: "intervalStartDate", headerName: "Interval Date", width: 200 },
  ],
  root_cause_against_observation: [
    { field: "BusinessUnit", headerName: "Business Unit", width: 200 },
    { field: "RootCause", headerName: "Root Cause", width: 100 },
    { field: "Total", headerName: "Total", width: 100 },
    {
      field: "IntervalStartDate",
      headerName: "Interval Date",
      width: 200,
    },
  ],
  root_cause_trend: [
    { field: "BusinessUnit", headerName: "Business Unit", width: 200 },
    { field: "RootCause", headerName: "Root Cause", width: 100 },
    { field: "Total", headerName: "Total", width: 100 },
    {
      field: "IntervalStartDate",
      headerName: "Interval Date",
      width: 200,
    },
  ],
  behavior_linked_lsr_report: [
    { field: "BusinessUnit", headerName: "Business Unit", width: 200 },
    { field: "behavior", headerName: "Behavior", width: 100 },
    { field: "Total", headerName: "Total", width: 100 },
    {
      field: "IntervalStartDate",
      headerName: "Interval Date",
      width: 200,
    },
  ],
  total_lsr_report: [
    { field: "BusinessUnit", headerName: "Business Unit", width: 200 },
    { field: "LSR", headerName: "LSR", width: 100 },
    { field: "NonLSR", headerName: "Non LSR", width: 100 },
    {
      field: "LSR Percentage",
      headerName: "LSR Percentage",
      width: 100,
      renderCell: (params) =>
        params.row["LSR Percentage"]
          ? `${params.row["LSR Percentage"]}%`
          : `0%`,
    },
    { field: "Total", headerName: "Total", width: 100 },
    {
      field: "IntervalStartDate",
      headerName: "Interval Date",
      width: 200,
    },
  ],
  lsr_violation_report: [
    { field: "BusinessUnit", headerName: "Business Unit", width: 200 },
    { field: "LSR", headerName: "LSR", width: 100 },
    { field: "Total", headerName: "Total", width: 100 },
    {
      field: "IntervalStartDate",
      headerName: "Interval Date",
      width: 200,
    },
  ],
  lsr_type_classification_report: [
    { field: "BusinessUnit", headerName: "Business Unit", width: 200 },
    { field: "LSR", headerName: "LSR", width: 100 },
    { field: "atRisk", headerName: "At Risk", width: 100 },
    { field: "safe", headerName: "Safe", width: 100 },
    {
      field: "intervalStartDate",
      headerName: "Interval Date",
      width: 200,
    },
  ],
  ihtimam_total_performance: [
    { field: "Behavior", headerName: "Behavior", width: 400 },
    { field: "safe", headerName: "Safe", width: 100 },
    { field: "unsafe", headerName: "Unsafe", width: 100 },
    {
      field: "percentage",
      headerName: "Percentage",
      width: 100,
      renderCell: (params) =>
        params.row.percentage ? `${params.row.percentage}%` : `0%`,
    },
  ],
};
