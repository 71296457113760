import React from "react";
import PropTypes from "prop-types";
import { styled, useTheme } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";

AiAlert.propTypes = {
  labelComponent: PropTypes.node.isRequired,
  mt: PropTypes.number,
  handler: PropTypes.func,
  sx: PropTypes.object,
};

const StyledStack = styled(Stack)(({ theme, clickable }) => ({
  border: `1px solid ${theme.palette.primary.orange}`,
  borderRadius: "4px",
  padding: "12px",
  background: "#FFF1E0",
  cursor: clickable ? "pointer" : "default",
}));

const StyledIcon = styled(InfoIcon)(({ theme }) => ({
  fill: theme.palette.primary.orange,
  height: "18px",
  width: "18px",
}));

export default function AiAlert({ labelComponent, mt = 0, handler, sx = {} }) {
  return (
    <StyledStack
      spacing={1}
      direction="row"
      sx={{ ...sx, mt }}
      onClick={handler}
      clickable={handler ? true : false}
    >
      <StyledIcon />
      {labelComponent}
    </StyledStack>
  );
}
