import apiHelper from "./apiHelper";

export const getUnitsApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/units`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getUnitsTiedApi = (directorateId, locationId, companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/units/directorate/${directorateId}/company/${companyId}/location/${locationId}`
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getUnitsForImplementationApi = (companyId, directorateId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/units/availableForImplementation?companyId=${companyId}&directorateId=${directorateId}`
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
