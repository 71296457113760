import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loginApi, getCurrentUserApi } from "../api/auth";
import { string, object } from "yup";
import jwtDecode from "jwt-decode";
import PageLoader from "../components/PageLoader";
import i18next from "i18next";
import { getTranslations } from "../api/locale";

const AuthContext = createContext({
  token: "",
  channel: "",
  loading: false,
  access: [],
  error: "",
  userId: "",
  companyId: "",
  profileUpdate: 0,
  login: null,
  msalLogin: null,
  logout: null,
  handleSetError: null,
});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState("");
  const [channel, setChannel] = useState("");
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState("");
  const [error, setError] = useState("");
  const [access, setAccess] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [profileUpdate, setProfileUpdate] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const loginSchema = object({
    email: string().email().required().label("Email"),
    password: string().required().label("Password"),
  });

  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  useEffect(() => {
    const setupAuth = async () => {
      try {
        const currentToken = localStorage.getItem("token");
        if (currentToken) {
          setToken(currentToken);
          const userDetails = await getCurrentUserApi();
          setUserId(userDetails.id);
          if (userDetails.access) {
            const accessKeys = Object.keys(userDetails.access);
            setAccess(accessKeys);
          }
          if (userDetails.company?.id) {
            setCompanyId(userDetails.company.id);
          }
          if (userDetails.notificationConfig) {
            setChannel(userDetails.notificationConfig.notificationChannel);
          }
          //  Add translations
          const backendTranslations = await getTranslations();
          if (backendTranslations) {
            i18next.addResourceBundle(
              "en",
              "backend",
              backendTranslations["en"]
            );
            i18next.addResourceBundle(
              "ar",
              "backend",
              backendTranslations["ar"]
            );
          }
          setLoadingInitial(false);
        } else {
          setLoadingInitial(false);
        }
      } catch (err) {
        if (error.response) {
          localStorage.removeItem("token");
          localStorage.removeItem("sso");
          navigate("/login");
          setLoadingInitial(false);
        }
      }
    };

    setupAuth();
  }, []);

  const login = async (email, password) => {
    setLoading(true);
    try {
      const isValid = await loginSchema.validate({ email, password });
      if (isValid) {
        const res = await loginApi(email, password);

        if (location.pathname === "/login" && res.passResetLink) {
          navigate(res.passResetLink);
        } else {
          await localStorage.setItem("token", res.token);
          setUserId(res.userId);
          setToken(res.token);

          //  Add translations
          const backendTranslations = await getTranslations();
          if (backendTranslations) {
            i18next.addResourceBundle(
              "en",
              "backend",
              backendTranslations["en"]
            );
            i18next.addResourceBundle(
              "ar",
              "backend",
              backendTranslations["ar"]
            );
          }

          if (res.token) {
            const { companyId } = jwtDecode(res.token);
            setCompanyId(companyId);
          }
          if (res.notificationConfig) {
            setChannel(res.notificationConfig.notificationChannel);
          }
          if (res.access) {
            const accessKeys = Object.keys(res.access);
            setAccess(accessKeys);
          }
          if (
            location.pathname !== "/login" &&
            location.pathname !== "/resetPassword" &&
            location.pathname !== "/changePassword" &&
            location.pathname !== "/validateOtp"
          ) {
            navigate(location.pathname);
          } else if (location.pathname === "/resetPassword") {
            navigate("/tasks/list");
          } else if (location.pathname === "/changePassword") {
            navigate("/tasks/list");
          } else {
            navigate("/tasks/list");
          }
        }
      }
    } catch (err) {
      if (err.errors) {
        setError(err.errors[0]);
      } else {
        setError(err);
      }
    }
    setLoading(false);
  };

  const msalLogin = async (res) => {
    setLoading(true);
    try {
      await localStorage.setItem("token", res.token);
      localStorage.setItem("sso", true);
      setUserId(res.userId);
      setToken(res.token);
      if (res.token) {
        const { companyId } = jwtDecode(res.token);
        setCompanyId(companyId);

        //  Add translations
        const backendTranslations = await getTranslations();
        if (backendTranslations) {
          i18next.addResourceBundle("en", "backend", backendTranslations["en"]);
          i18next.addResourceBundle("ar", "backend", backendTranslations["ar"]);
        }
      }
      if (res.notificationConfig) {
        setChannel(res.notificationConfig.notificationChannel);
      }
      if (res.access) {
        const accessKeys = Object.keys(res.access);
        setAccess(accessKeys);
      }
      navigate("/tasks/list");
    } catch (err) {
      if (err.errors) {
        setError(err.errors[0]);
      } else {
        setError(err);
      }
    }
    setLoading(false);
  };

  const logout = () => {
    localStorage.clear();
    setToken(null);
    setChannel("");
    navigate("/login");
  };

  const profileUpdated = () => {
    const updated = profileUpdate + 1;
    setProfileUpdate(updated);
  };

  const handleSetError = (err) => {
    setError(err);
  };

  const memoedValue = useMemo(
    () => ({
      token,
      loading,
      error,
      channel,
      access,
      userId,
      companyId,
      profileUpdate,
      login,
      logout,
      msalLogin,
      profileUpdated,
      handleSetError,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      channel,
      token,
      loading,
      error,
      access,
      userId,
      companyId,
      profileUpdate,
      handleSetError,
    ]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {loadingInitial ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PageLoader />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
