import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
//  Material UI
import { Grid, Container, Button, Typography } from "@mui/material";
//  Components
import PageLoader from "../../../../components/PageLoader";
import DefaultTable from "../../../../components/tables/DefaultTable";
//  API
import { getNotTrainingPlanUsersByImplementation } from "../../../../api/implementation";
import InfoBox from "../../../../components/InfoBox";
import MarkComplete from "./MarkComplete";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";

const filterParamDefaults = {
  startDate: null,
  disciplineId: null,
  pageSize: 10,
  pageNo: 1,
  type: "ALL",
};

export const UserNotTrained = () => {
  const { t } = useTranslation();
  const { planId, companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [filterParams, setFilterParams] = useState(filterParamDefaults);
  const [trainingRows, setTrainingRows] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogUser, setDialogUser] = useState(null);
  const handleMarkOnSuccess = async () => {
    getNotTrainedUsers();
    setDialogOpen(false);
  };

  const trainingColumns = [
    // { field: "id", headerName: "Table Id", width: 70 },
    { field: "name", headerName: t("training.tableHeaders.name"), width: 250 },
    {
      field: "action",
      headerName: t("training.tableHeaders.action"),
      width: 200,
      renderCell: (notTrainedArray) => {
        return (
          <>
            {notTrainedArray.row.action ? (
              <PrimaryButton
                label={t("training.alreadyMarked")}
                onClick={() => {}}
                disabled={true}
              />
            ) : (
              <PrimaryButton
                label={t("training.markCompleted")}
                onClick={() => {
                  setDialogUser(notTrainedArray.row.id);
                  setDialogOpen(true);
                }}
                disabled={false}
              />
            )}
          </>
        );
      },
    },
  ];

  const getNotTrainedUsers = async () => {
    setLoading(true);
    let tempUsers = [];
    let query = `page=${filterParams.pageNo}&pageSize=${filterParams.pageSize}&companyId=${companyId}`;
    let users = await getNotTrainingPlanUsersByImplementation(query);
    users?.nonTrainedUsers?.rows?.forEach((user) => {
      tempUsers.push({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        action: user.trainingCompletedAt ? true : false,
      });
    });
    setTrainingRows(tempUsers);
    setTableInfo({
      totalCount: users?.nonTrainedUsers?.count,
      totalPages: Math.ceil(
        parseFloat(users?.nonTrainedUsers?.count / filterParams.pageSize)
      ),
    });
    setLoading(false);
  };
  useEffect(() => {
    getNotTrainedUsers();
  }, [filterParams]);

  return (
    <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} style={{ marginTop: "20px" }}>
            <Typography variant="h5">{t("training.userNotTrained")}</Typography>
          </Grid>
          <Grid
            style={{ paddingTop: "50px", paddingLeft: "60px" }}
            container
            spacing={5}
          >
            <DefaultTable
              rows={trainingRows}
              columns={trainingColumns}
              loading={loading}
              filterParams={filterParams}
              setFilterParams={(e)=>{
                if(!(e.page=== filterParams.page) || !(e.pageSize===filterParams.pageSize)){
                  setFilterParams(e)
                }
              }}
              tableInfo={tableInfo}
            />
          </Grid>
          <InfoBox
            overflowV
            handleClose={() => setDialogOpen(false)}
            disabled={false}
            open={dialogOpen}
            title={t('training.markUserTrained')}
            content={
              <MarkComplete
                userId={dialogUser}
                onSuccess={() => {
                  handleMarkOnSuccess();
                }}
              />
            }
          />
        </Grid>
    </>
  )
};
