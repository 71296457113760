import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import { authenticateUser } from "../api/auth";
import useAuth from "../context/useAuth";
import { useMsal } from "@azure/msal-react";

const loaderContainer = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  width: "100vw",
};
export default function RedirectPage() {
  const { msalLogin, handleSetError } = useAuth();
  const { inProgress } = useMsal();
  const navigate = useNavigate();

  const handleRedirect = async () => {
    try {
      const authResponse = await authenticateUser();
      await msalLogin(authResponse);
    } catch (err) {
      handleSetError(err);
      navigate("/login");
    }
  };

  useEffect(() => {
    if (inProgress === "none") {
      handleRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress]);

  return (
    <div style={loaderContainer}>
      <PageLoader />
    </div>
  );
}
