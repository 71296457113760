import React from "react";
import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import HelpText from "../HelpText";
import { ReactComponent as ColorPalette } from "../icons/color_palette.svg";
import { SketchPicker } from 'react-color'
import { useState } from "react";
import { useOutsideAlerter } from "../../utils/hooks";
import { useRef } from "react";

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '10px'
}));

const Swatch = styled('div')(({ color }) => ({
  width: '47px',
  height: '34px',
  borderRadius: '8px',
  backgroundColor: color
}))

const SwatchAndHashContainer = styled('div')(({ theme }) => ({
  width: '163px',
  height: '50px',
  borderRadius: '10px',
  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)',
  boxShadow: '0px 2px 9px 0px rgba(0, 0, 0, 0.11)',
  backdropFilter: 'blur(10px)',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  // position: 'relative'
}))

const IconContainer = styled('div')(({ theme }) => ({
  width: '50px',
  height: '50px',
  borderRadius: '10px',
  background: '#424242',
  boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  // isolation : 'isolate'
}))

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

const ColorPicker = ({
  label,
  helpText,
  value,
  onChangeComplete,
  onChange,
  icon,
  labelColor,
  ...props
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const pickerRef = useRef(null);

  useOutsideAlerter(()=>{
    setDisplayColorPicker(false)
  }, pickerRef)

  return (
    <Box>
      {label && (
        <Stack
          direction="row"
          style={{ paddingBottom: "4px" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" style={{ color: labelColor }}>
            <div style={{ display: "flex" }}>
              {icon}
              {label}
            </div>
          </Typography>
          {helpText && (
            <HelpText title={helpText}>
              <IconStyle />
            </HelpText>
          )}
        </Stack>
      )}
      <Container>
        <SwatchAndHashContainer>
          <Swatch color={value} />
          <Typography color={"#424242"} fontSize={"18px"} fontWeight={"400"} fontFamily={"Cairo"}>{value}</Typography>
        </SwatchAndHashContainer>
        <IconContainer onClick={() => { setDisplayColorPicker(true) }}>
          <ColorPalette />
        </IconContainer>
        {displayColorPicker && <div style={{ position: 'absolute'}}>
          <div style={{ position: 'fixed', zIndex: "99999", display: 'flex'}}>
            <div style={{width: '230px', height: '0px'}}></div>
            <div ref={pickerRef}>
              <SketchPicker color={value} onChangeComplete={onChangeComplete} />
            </div>
          </div>
        </div>}
      </Container>
    </Box>
  );
};

ColorPicker.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.string,
  onChangeComplete: PropTypes.func,
  onChange: PropTypes.func,
  labelColor: PropTypes.string,
};

export default ColorPicker;
