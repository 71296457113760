import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { getDisciplinesApi } from "../../../api/disciplines";
import { useParams } from "react-router-dom";
import { displayToast } from "../../../utils/general";
import { useTheme, styled } from "@mui/material/styles";
import {
  Grid,
  Stack,
  Typography,
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Tooltip,
} from "@mui/material";
import Page from "../../../components/Page";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import InfoBox from "../../../components/InfoBox";
import SelectInput from "../../../components/inputs/SelectInput";
import MultiSelectInput from "../../../components/inputs/MultiSelectInput";
import TabsBar from "../../../components/navigation/TabsBar";
import DefaultTable from "./../../../components/tables/DefaultTable";
import ActivityTable from "../../../components/tables/ActivityTable";
import LtiList from "../LTI/LtiList";
import {
  AddCircle,
  ArrowDropDownCircleOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import AutocompleteInput from "../../../components/inputs/AutocompleteInput";
import {
  getPlanLocationsApi,
  getPlanUnitsApi,
  getVerifiersAndManagersApi,
  updateVerifiersApi,
} from "../../../api/implementation";
import {
  getBehaviorsApi,
  getBehaviorsByImplementationId,
  retireCriticialBehaviorByCompany,
  updateCriticalBehaviorsByImplementationId,
} from "../../../api/behaviors";
import { manageActivityApi } from "../../../api/activities";
import { searchUsersApi } from "../../../api/users";
import { getSpecialResponsibilitiesApi } from "../../../api/roles";
import PageLoader from "../../../components/PageLoader";

const RRow = styled("div")(({ theme, active, disabled }) => ({
  cursor: "pointer",
  minHeight: "50px",
  color: disabled
    ? theme.palette.primary.disabledFont
    : theme.palette.primary.black,
  background: disabled
    ? theme.palette.primary.disabled
    : "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
  boxShadow: disabled ? "none" : " 0px 4px 9px rgba(0, 0, 0, 0.11)",
  borderRadius: "6px",
  border: active
    ? `2px solid ${theme.palette.primary.hoverOrange}`
    : `1px solid #F1F3F4`,
  padding: "12px 0",
}));

const BRow = styled("div")(() => ({
  minHeight: "50px",
  borderRadius: "6px",
  border: `1px solid #E0E0E0`,
  padding: "12px",
  marginBottom: "6px",
}));

const RetiredTag = styled("div")(({ theme }) => ({
  borderRadius: "8px",
  background: theme.palette.primary.red,
  color: theme.palette.primary.white,
  fontWeight: "bold",
  fontSize: "12px",
  padding: "4px 7px",
  textTransform: "uppercase",
  marginLeft: "8px",
}));

const AccordionCard = styled(Accordion)(({ theme, borderColor, disabled }) => ({
  color: disabled
    ? theme.palette.primary.disabledFont
    : theme.palette.primary.black,
  background: disabled
    ? theme.palette.primary.disabled
    : "linear-gradient(108.46deg, rgba(255, 255, 255, 0.33) 0%, rgba(255, 255, 255, 0.33) 100%)",
  boxShadow: disabled ? "none" : " 0px 4px 9px rgba(0, 0, 0, 0.11)",
  border: `1px solid #F1F3F4`,
  ":before": {
    backgroundColor: theme.palette.primary.white,
  },
}));

const DropdownIconStyle = styled(ArrowDropDownCircleOutlined)(({ theme }) => ({
  fill: theme.palette.primary.orange,
}));

const editButtonStyles = {
  height: "30px",
  width: "80%",
  marginRight: "5px",
};

const addModalDefault = {
  open: false,
  disciplineId: "",
  behaviorId: {},
  locationsAndUnits: [],
};

const editModalDefault = {
  open: false,
  disciplineId: "",
  titleName: "",
  companyId: "",
  locationId: "",
  unitId: "",
  behaviorsList: [],
  currentBehaviors: [],
  behaviorIdsToRemove: [],
  behaviorIdsToAdd: [],
};

const verifierModalDefault = {
  open: false,
  type: "add",
  users: [],
  verifierNames: "",
  disciplineId: null,
  locationId: null,
  unitId: null,
  disciplineName: "",
  locationName: "",
  unitName: "",
  usersToAdd: [],
  usersToRemove: [],
};

export const IdentifyCriticalBehaviors = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const timeout = useRef();
  const theme = useTheme();
  const { stub, planId, companyId } = useParams();
  const [tab, setTab] = useState("disciplines");
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [disciplinesLoading, setDisciplinesLoading] = useState(true);
  const [verifierResponsibilityId, setVerifierResponsibilityId] = useState("");
  const [disciplines, setDisciplines] = useState([]);
  const [behaviorsDropdown, setBehaviorsDropdown] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [savedDisciplines, setSavedDisciplines] = useState([]);
  const [addModal, setAddModal] = useState(addModalDefault);
  const [editModal, setEditModal] = useState(editModalDefault);
  const [locationsAndUnits, setLocationsAndUnits] = useState([]);
  const [selectedBehaviorId, setSelectedBehaviorId] = useState("");
  const [filterParams, setFilterParams] = useState({
    pageSize: 10,
    pageNo: 1,
  });
  const [tableInfo, setTableInfo] = useState({ totalPages: 1, totalCount: 0 });
  const [rows, setRows] = useState([]);
  const [verifierModal, setVerifierModal] = useState(verifierModalDefault);
  const [loadingRetire, setLoadingRetire] = useState(false);

  const [deleteDetails, setDeleteDetails] = useState({
    open: false,
  });
  const [retireDetails, setRetireDetails] = useState({
    open: false,
    id: "",
    retire: false,
    label: "",
  });

  const columns = [
    { field: "disciplineName", headerName: t("table.discipline"), width: 30 },
    { field: "locationName", headerName: t("table.location"), width: 20 },
    { field: "unitName", headerName: t("table.unit"), width: 20 },
    { field: "verifierNames", headerName: t("table.verifier"), width: 30 },
    {
      field: "actions",
      headerName: t("table.actions"),
      width: 15,
      alignItems: "center",
      renderCell: (params) => (
        <SecondaryButton
          disabled={loading}
          label={t("general.edit")}
          alignment="center"
          style={{ height: "35px" }}
          onClick={() => {
            setVerifierModal({
              open: true,
              disciplineId: params.row.disciplineId,
              locationId: params.row.locationId,
              unitId: params.row.unitId,
              users: params.row.userIds,
              disciplineName: params.row.disciplineName,
              locationName: params.row.locationName,
              unitName: params.row.unitName,
              usersToAdd: [],
              usersToRemove: [],
            });
          }}
        />
      ),
    },
  ];

  const renderDisciplinesPage = () => (
    <Stack spacing={2}>
      {disciplinesLoading ? (
        <div
          style={{
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PageLoader />
        </div>
      ) : savedDisciplines.length === 0 ? (
        <>
          <Typography fontStyle={"bold"}>
            {t("implementations.noDisciplinesAdded")}
          </Typography>
          <Typography>
            {t("implementations.addDisciplineAndCriticalBehavior")}
          </Typography>
        </>
      ) : (
        <>
          <Typography fontStyle={"bold"} sx={{ pb: "8px" }}>
            {t("implementations.viewDisciplines.title")}
          </Typography>
          {renderDisciplineTree(savedDisciplines)}
        </>
      )}
      <PrimaryButton
        label={t("implementations.addDiscipline")}
        icon={<AddCircle />}
        onClick={() => {
          setAddModal({ open: true });
        }}
      />
    </Stack>
  );

  const renderManagersView = () => (
    <>
      <ActivityTable
        rows={rows}
        columns={columns}
        loading={loading}
        tableInfo={tableInfo}
      />
    </>
  );

  const renderLTIsView = () => (
    <>
      <LtiList />
    </>
  );

  const renderRetireModal = () => (
    <InfoBox
      disabled={loading}
      open={retireDetails.open}
      title={retireDetails.label}
      content={
        <Stack>
          <Typography variant="h6" style={{ color: theme.palette.primary.red }}>
            {t(`retirement.confirmation`)}
          </Typography>
        </Stack>
      }
      buttonLabel={retireDetails.label}
      handleClose={() => setRetireDetails({ ...retireDetails, open: false })}
      handleAction={() => {
        retireBehavior(
          retireDetails.id,
          retireDetails.retire,
          retireDetails.discipline,
          retireDetails.location,
          retireDetails.unit
        );
      }}
    />
  );

  const renderDisciplineTree = (disciplineList) => {
    return disciplineList.map((disciplineData, dIndex) => (
      <AccordionCard elevation={0} key={`discipline-card-${dIndex}`}>
        <AccordionSummary
          expandIcon={<DropdownIconStyle />}
          aria-controls="disciplineDropdown-arrow"
          id={`disciplineTree-d-${dIndex}`}
        >
          <Typography variant={"h6"}>
            {disciplineData.disciplineName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {disciplineData.behaviorsByLocations.map((location, lIndex) => (
            <AccordionCard elevation={0}>
              <AccordionSummary
                expandIcon={<DropdownIconStyle />}
                aria-controls="disciplineDropdown-arrow"
                id={`disciplineTree-l-${lIndex}`}
              >
                <Grid container>
                  <Grid item xs={12} md={11}>
                    <Typography variant={"h7"}>
                      {location.locationName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <SecondaryButton
                      label={t("implementations.viewDisciplines.edit")}
                      disabled={loading}
                      onClick={() =>
                        handleOpenEdit(
                          location,
                          disciplineData.disciplineId,
                          null
                        )
                      }
                      style={editButtonStyles}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {location.behaviors.map((b, bIndex) =>
                  b.retiredAt ? (
                    <BRow
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <div>{`${bIndex + 1}. ${b.name}`}</div>
                        <RetiredTag>{t("retirement.retired")}</RetiredTag>
                      </div>
                      <div>
                        <SecondaryButton
                          label={t("retirement.unretire")}
                          disabled={loadingRetire}
                          onClick={() => {
                            // retireBehavior(b.id, false);
                            setRetireDetails({
                              open: true,
                              id: b.id,
                              retire: false,
                              label: t("retirement.unretire"),
                              discipline: disciplineData.disciplineId,
                              location: location.locationId,
                            });
                          }}
                          style={{ ...editButtonStyles, width: "100px" }}
                        />
                      </div>
                    </BRow>
                  ) : (
                    <BRow
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>{`${bIndex + 1}. ${b.name}`}</div>
                      {b.retiredAt ? (
                        <PrimaryButton
                          style={{ width: "120px" }}
                          disabled={loadingRetire}
                          label={t("retirement.retire")}
                          icon={<DoNotDisturbOnOutlinedIcon />}
                          onClick={() => {
                            // retireBehavior(b.id, false);
                            setRetireDetails({
                              open: true,
                              id: b.id,
                              retire: false,
                              label: t("retirement.retire"),
                              discipline: disciplineData.disciplineId,
                              location: location.locationId,
                            });
                          }}
                        />
                      ) : (
                        <SecondaryButton
                          isRed
                          label={t("retirement.retire")}
                          disabled={loadingRetire}
                          onClick={() => {
                            // retireBehavior(b.id, true);
                            setRetireDetails({
                              open: true,
                              id: b.id,
                              retire: true,
                              label: t("retirement.retire"),
                              discipline: disciplineData.disciplineId,
                              location: location.locationId,
                            });
                          }}
                          style={{ ...editButtonStyles, width: "100px" }}
                        />
                      )}
                    </BRow>
                  )
                )}
                {location.behaviorsByUnits.map((bu, buIndex) => (
                  <AccordionCard elevation={0} sx={{ mt: 1 }}>
                    <AccordionSummary
                      expandIcon={<DropdownIconStyle />}
                      aria-controls="unitBehavior-arrow"
                      id={`disciplineTree-u-${buIndex}`}
                    >
                      <Grid container>
                        <Grid item xs={12} md={11}>
                          <Typography variant={"h7"}>{bu.unitName}</Typography>
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <SecondaryButton
                            label={t("implementations.viewDisciplines.edit")}
                            disabled={loading}
                            onClick={() =>
                              handleOpenEdit(
                                location,
                                disciplineData.disciplineId,
                                bu
                              )
                            }
                            style={editButtonStyles}
                          />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      {bu.behaviors.map((b, bIndex) =>
                        b.retiredAt ? (
                          <BRow
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ display: "flex" }}>
                              <div>{`${bIndex + 1}. ${b.name}`}</div>
                              <RetiredTag>{t("retirement.retired")}</RetiredTag>
                            </div>
                            <div>
                              <SecondaryButton
                                label={t("retirement.unretire")}
                                disabled={loadingRetire}
                                onClick={() => {
                                  // retireBehavior(b.id, false);
                                  setRetireDetails({
                                    open: true,
                                    id: b.id,
                                    retire: false,
                                    label: t("retirement.unretire"),
                                    discipline: disciplineData.disciplineId,
                                    location: location.locationId,
                                    unit: bu.unitId,
                                  });
                                }}
                                style={{ ...editButtonStyles, width: "100px" }}
                              />
                            </div>
                          </BRow>
                        ) : (
                          <BRow
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>{`${bIndex + 1}. ${b.name}`}</div>
                            {b.retiredAt ? (
                              <PrimaryButton
                                style={{ width: "120px" }}
                                disabled={loadingRetire}
                                label={t("retirement.retire")}
                                icon={<DoNotDisturbOnOutlinedIcon />}
                                onClick={() => {
                                  // retireBehavior(b.id, false);
                                  setRetireDetails({
                                    open: true,
                                    id: b.id,
                                    retire: false,
                                    label: t("retirement.retire"),
                                    discipline: disciplineData.disciplineId,
                                    location: location.locationId,
                                    unit: bu.unitId,
                                  });
                                }}
                              />
                            ) : (
                              <SecondaryButton
                                isRed
                                label={t("retirement.retire")}
                                disabled={loadingRetire}
                                onClick={() => {
                                  // retireBehavior(b.id, true);
                                  setRetireDetails({
                                    open: true,
                                    id: b.id,
                                    retire: true,
                                    label: t("retirement.retire"),
                                    discipline: disciplineData.disciplineId,
                                    location: location.locationId,
                                    unit: bu.unitId,
                                  });
                                }}
                                style={{ ...editButtonStyles, width: "100px" }}
                              />
                            )}
                          </BRow>
                        )
                      )}
                    </AccordionDetails>
                  </AccordionCard>
                ))}
              </AccordionDetails>
            </AccordionCard>
          ))}
        </AccordionDetails>
      </AccordionCard>
    ));
  };

  const renderAddView = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ pt: 0 }}>
        <Typography variant="body1">
          {t(`implementations.addDescription`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AutocompleteInput
          handler={(e, v) =>
            v
              ? setAddModal({ ...addModal, disciplineId: v.value })
              : setBehaviorsDropdown([])
          }
          value={addModal.disciplineId}
          label={t("implementations.addDisciplines.step1")}
          placeholder={t("implementations.addDisciplines.step1Placeholder")}
          options={disciplines}
        />
        {/* <SelectInput
          label={t("implementations.addDisciplines.step1")}
          value={addModal.disciplineId}
          disabled={loading}
          handler={(e) =>
            setAddModal({ ...addModal, disciplineId: e.target.value })
          }
          options={disciplines}
        /> */}
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
          {t("implementations.addDisciplines.step2")}
        </Typography>
        <Stack
          spacing={2}
          style={{
            border: `1px solid ${theme.palette.primary.lighter}`,
            padding: "8px",
            borderRadius: "8px",
            minHeight: "40vh",
          }}
        >
          {behaviorsDropdown.length > 0 ? (
            behaviorsDropdown.map((b) => (
              <RRow disabled={loading}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Checkbox
                      checked={addModal.behaviorId[b.value]}
                      onChange={(event) =>
                        setAddModal({
                          ...addModal,
                          behaviorId: {
                            ...addModal.behaviorId,
                            [b.value]: event.target.checked,
                          },
                        })
                      }
                      disabled={loading}
                      sx={{
                        color: theme.palette.primary.orange,
                        "&.Mui-checked": {
                          color: theme.palette.primary.orange,
                        },
                        "&:hover": {
                          backgroundColor: "rgba(248,147,31,0.08)",
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    style={{
                      ...(!b.lsrTitle
                        ? { display: "flex", alignItems: "center" }
                        : {}),
                      paddingLeft: "18px",
                    }}
                  >
                    <Typography variant="body1">{b.label}</Typography>
                    {b.lsrTitle && (
                      <p
                        style={{
                          fontWeight: "lighter",
                          fontSize: "12px",
                        }}
                      >
                        {b.lsrTitle}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </RRow>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">
                {t("implementations.selectDisciplineFirst")}
              </Typography>
            </div>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <>
          <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
            {t("implementations.addDisciplines.step3")}
          </Typography>
          <Stack
            justifyContent="space-between"
            style={{
              border: `1px solid ${theme.palette.primary.lighter}`,
              padding: "12px",
              borderRadius: "8px",
              minHeight: "40vh",
            }}
          >
            <MultiSelectInput
              disabled={loading}
              options={locationsAndUnits}
              handler={(newValue) =>
                setAddModal({ ...addModal, locationsAndUnits: newValue })
              }
              values={addModal.locationsAndUnits}
            />
          </Stack>
        </>
      </Grid>
    </Grid>
  );

  const renderEditView = () => (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ pt: 0 }}>
        <Typography variant="body1">
          {t(`implementations.viewDisciplines.editDescription`)}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <AutocompleteInput
          value={selectedBehaviorId}
          handler={(e, v) => handleAddBehavior(v)}
          options={behaviorsDropdown}
          disabled={
            loading || (behaviorsDropdown && behaviorsDropdown.length === 0)
          }
          label={t("implementations.viewDisciplines.criticalBehaviors")}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="column" spacing={2}>
          {editModal.currentBehaviors.length > 0 ? (
            editModal.currentBehaviors.map((b) => (
              <BRow style={{ marginBottom: 0 }}>
                <Stack justifyContent="space-between" direction="row">
                  <Typography variant="body1">{b.name}</Typography>
                  <DeleteOutline
                    style={{
                      color: theme.palette.primary.red,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setDeleteDetails({
                        ...deleteDetails,
                        open: true,
                        behavior: b,
                      })
                    }
                  />
                </Stack>
              </BRow>
            ))
          ) : (
            <BRow style={{ height: "300px" }}>
              {t("implementations.viewDisciplines.noRows")}
            </BRow>
          )}
        </Stack>
      </Grid>
    </Grid>
  );

  const renderDeleteModal = () => (
    <Typography variant="body1" style={{ color: theme.palette.primary.red }}>
      {t(`implementations.deleteDisciplineBehaviorWarningMessage`)}
    </Typography>
  );

  const renderEditVerifiers = () => (
    <Stack direction="column" spacing={2}>
      <Typography variant="h8">{`${t("implementations.companyBehaviors", {
        disciplineName: verifierModal.disciplineName,
        locationName: verifierModal.locationName,
      })} ${
        verifierModal.unitId ? ` (${verifierModal.unitName}):` : ":"
      } `}</Typography>
      {verifierModal.users.length > 0 ? (
        verifierModal.users.map((u) => (
          <BRow style={{ marginBottom: 0 }}>
            <Stack justifyContent="space-between" direction="row">
              <Typography variant="body1">{u.userLabel}</Typography>
              <DeleteOutline
                style={{
                  color: theme.palette.primary.red,
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveVerifier(u)}
              />
            </Stack>
          </BRow>
        ))
      ) : (
        <BRow style={{ height: "50px" }}>
          {t("implementations.noVerifiers")}
        </BRow>
      )}
      <AutocompleteInput
        handler={(e, v) => handleAddVerifier(v)}
        keyPressHandler={handleSearchUser}
        label={t("implementations.selectVerifier")}
        loading={searchLoading}
        disabled={loading}
        options={userDropdown}
        disablePortal={false}
      />
    </Stack>
  );

  const handleAddVerifier = (v) => {
    if (v) {
      const currentUsers = [...verifierModal.users];
      const currentToBeAdded = [...verifierModal.usersToAdd];
      const currentToBeRemoved = [...verifierModal.usersToRemove];
      const isThere = currentUsers.find((u) => u.userId === v.id);
      if (isThere) {
        displayToast(null, "pureError", t("implementations.verifierExists"));
      } else {
        currentUsers.push({ userId: v.id, userLabel: v.label });
        //  If user is there in the remove list then no need to add it to the add list
        if (currentToBeRemoved.includes(v.id)) {
          const usersToRemove = currentToBeRemoved.filter((id) => id !== v.id);
          setVerifierModal({
            ...verifierModal,
            users: currentUsers,
            usersToRemove,
          });
        } else {
          currentToBeAdded.push(v.id);
          setVerifierModal({
            ...verifierModal,
            users: currentUsers,
            usersToAdd: currentToBeAdded,
          });
        }
      }
    }
  };

  const handleRemoveVerifier = (u) => {
    const currentUsers = verifierModal.users.filter(
      (user) => user.userId !== u.userId
    );
    const currentUsersToAdd = [...verifierModal.usersToAdd];
    const currentUsersToRemove = [...verifierModal.usersToRemove];
    if (currentUsersToAdd.includes(u.userId)) {
      const usersToAdd = currentUsersToAdd.filter((id) => id !== u.userId);
      setVerifierModal({ ...verifierModal, users: currentUsers, usersToAdd });
    } else {
      currentUsersToRemove.push(u.userId);
      setVerifierModal({
        ...verifierModal,
        users: currentUsers,
        usersToRemove: currentUsersToRemove,
      });
    }
  };

  const handleOpenEdit = async (locationDetails, disciplineId, unitDetails) => {
    try {
      setLoading(true);
      //  Set dropdown values manually
      const finalDropdown = [];
      const existingIds = unitDetails
        ? unitDetails.behaviors.map((b) => b.id)
        : locationDetails.behaviors.map((b) => b.id);

      const response = await getBehaviorsApi(disciplineId, "CRITICAL");
      //  Remove the existing behaviors from the dropdown
      response.forEach((opt) => {
        if (!existingIds.includes(opt.id)) {
          finalDropdown.push({ label: opt.name, value: opt.id });
        }
      });

      setBehaviorsDropdown(finalDropdown);
      setEditModal({
        open: true,
        disciplineId,
        titleName: unitDetails
          ? unitDetails.unitName
          : locationDetails.locationName,
        companyId,
        locationId: locationDetails.locationId,
        behaviorsList: unitDetails
          ? unitDetails.behaviors
          : locationDetails.behaviors,
        currentBehaviors: unitDetails
          ? unitDetails.behaviors
          : locationDetails.behaviors,
        behaviorIdsToAdd: [],
        behaviorIdsToRemove: [],
        ...(unitDetails ? { unitId: unitDetails.unitId } : {}),
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleGetCriticalBehaviors = async (disciplineId) => {
    try {
      setLoading(true);
      const response = await getBehaviorsApi(disciplineId, "CRITICAL");
      const behaviors = response.map((opt) => {
        return {
          label: opt.name,
          value: opt.id,
          lsrTitle: opt.LSR ? `LSR: ${opt.LSR.title}` : "",
          lsrAr: opt.LSR ? `LSR: ${opt.LSR.titleAr}` : "",
        };
      });

      setBehaviorsDropdown(behaviors);
      setLoading(false);
      return behaviors;
    } catch (err) {
      console.log(err);
      setLoading(false);
      return [];
    }
  };

  const handleAddBehavior = (v) => {
    if (v) {
      //  Add it to list of current behaviors
      const updatedBehaviors = [...editModal.currentBehaviors];
      updatedBehaviors.push({
        id: v.value,
        name: v.label,
      });
      setEditModal({ ...editModal, currentBehaviors: updatedBehaviors });

      //  Remove it from behaviors dropdown
      const updatedDropdown = _.filter([...behaviorsDropdown], (b) => {
        return b.value !== v.value;
      });
      setBehaviorsDropdown(updatedDropdown);
    }

    // Clear selected value
    setSelectedBehaviorId("");
  };

  //  No need to process final delete here, we will do it on submission
  const handleRemoveBehavior = () => {
    const row = deleteDetails.behavior;
    //  Remove the selected behavior from list
    const updatedBehaviors = _.filter([...editModal.currentBehaviors], (b) => {
      return b.id !== row.id;
    });

    //  Add it back to the dropdown
    const updatedDropdown = behaviorsDropdown;
    updatedDropdown.push({ label: row.name, value: row.id });

    //  Set updated values
    setEditModal({
      ...editModal,
      currentBehaviors: updatedBehaviors,
    });
    setBehaviorsDropdown(updatedDropdown);
    setDeleteDetails({ open: false });
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1, companyId, true);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const getBehaviors = async () => {
    try {
      setLoading(true);
      setDisciplinesLoading(true);
      const behaviorList = await getBehaviorsByImplementationId(planId);
      setSavedDisciplines(behaviorList);

      //  Get and parse information for the verifiers table
      const data = await getVerifiersAndManagersApi(planId, companyId);
      const parsedVerifiers = [];

      data.forEach((v) => {
        const disciplineId = v.discipline ? v.discipline.id : null;
        const disciplineName = v.discipline ? v.discipline.name : null;
        const disciplineNameAr = v.discipline
          ? v.discipline.disciplineAR
          : null;
        const locationId = v.location ? v.location.id : null;
        const locationName = v.location ? v.location.name : null;
        const unitId = v.unit ? v.unit.id : null;
        const unitName = v.unit ? v.unit.name : null;

        const userObj = v.user
          ? {
              ...v.user,
              userId: v.userId,
              userLabel: `${v.user.firstName} ${v.user.lastName ?? ""} (${
                v.user.emailAddress || "No email"
              })`,
            }
          : null;
        const verifierName = v.user
          ? `${v.user.firstName} ${v.user.lastName} (${
              v.user.emailAddress || "No email"
            })`
          : null;

        const foundIndex = parsedVerifiers.findIndex(
          (foundVerifier) =>
            foundVerifier.disciplineId === disciplineId &&
            foundVerifier.locationId === locationId &&
            foundVerifier.unitId === unitId
        );

        if (foundIndex >= 0) {
          //  Need to push into userIds too
          const newObj = {
            disciplineId,
            disciplineName,
            disciplineNameAr,
            locationId,
            locationName,
            unitId,
            unitName,
            userIds: [...parsedVerifiers[foundIndex].userIds, userObj],
            verifierNames:
              parsedVerifiers[foundIndex].verifierNames + ", " + verifierName,
          };

          parsedVerifiers[foundIndex] = newObj;
        } else {
          parsedVerifiers.push({
            disciplineId,
            disciplineName,
            disciplineNameAr,
            locationId,
            locationName,
            unitId,
            unitName,
            userIds: [userObj],
            verifierNames: verifierName,
          });
        }
      });

      const finalRows = [];
      //  Add existing combinations
      behaviorList.forEach((d) => {
        d.behaviorsByLocations.forEach((l) => {
          //  Check if discipline, location and unit null combination exists already, if it does not, add it
          const found = parsedVerifiers.find((comb) => {
            return (
              comb.disciplineId === d.disciplineId &&
              comb.locationId === l.locationId &&
              !comb.unitId
            );
          });

          finalRows.push({
            id: `${d.disciplineId}-${l.locationId}-0`,
            disciplineId: d.disciplineId,
            locationId: l.locationId,
            unitId: null,
            disciplineName: d.disciplineName,
            locationName: l.locationName,
            unitName: t("implementations.notFound.unit"),
            verifierNames: found
              ? found.verifierNames
              : t("implementations.notFound.verifier"),
            userIds: found ? found.userIds : [],
          });

          //  For each unit
          if (l.behaviorsByUnits.length > 0) {
            l.behaviorsByUnits.forEach((u) => {
              const unitFind = parsedVerifiers.find((comb) => {
                return (
                  comb.disciplineId === d.disciplineId &&
                  comb.locationId === l.locationId &&
                  comb.unitId &&
                  comb.unitId === u.unitId
                );
              });

              finalRows.push({
                id: `${d.disciplineId}-${l.locationId}-${u.unitId}`,
                disciplineId: d.disciplineId,
                locationId: l.locationId,
                unitId: u.unitId,
                disciplineName: d.disciplineName,
                locationName: l.locationName,
                unitName: u.unitName,
                verifierNames: unitFind
                  ? unitFind.verifierNames
                  : t("implementations.notFound.verifier"),
                userIds: unitFind ? unitFind.userIds : [],
              });
            });
          }
        });
      });

      setTableInfo({ totalPages: 1, totalCount: finalRows.length });
      setRows(finalRows);
      setLoading(false);
      setDisciplinesLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setDisciplinesLoading(false);
    }
  };

  const getDisciplines = async () => {
    try {
      setLoading(true);
      const disciplinesFetched = await getDisciplinesApi({}, "CRITICAL");
      let options = disciplinesFetched.data.map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });

      setDisciplines(options);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getLocationsAndUnits = async () => {
    setLoading(true);
    try {
      const locationData = await getPlanLocationsApi(planId);
      const unitsData = await getPlanUnitsApi(planId);

      const locationOptions = [];
      const locationKeys = [];

      const groupedUnits = unitsData.reduce((acc, unit) => {
        if (acc[unit.location.name]) {
          acc[unit.location.name] = {
            label: unit.location.name,
            options: [...acc[unit.location.name].options],
          };
        } else {
          acc[unit.location.name] = {
            label: unit.location.name,
            options: [],
          };
          locationKeys.push(unit.location.name);
        }
        acc[unit.location.name].options.push({
          label: unit.name,
          value: unit.id,
          isLocation: false,
          locationId: unit.location.id,
        });

        return acc;
      }, {});

      locationData.forEach((l) => {
        locationOptions.push({
          label: l.name,
          value: l.id,
          type: "Locations",
          isLocation: true,
        });
      });

      const finalDropdown = [
        {
          label: "Locations",
          options: locationOptions,
        },
      ];

      Object.keys(groupedUnits).forEach((locName) =>
        finalDropdown.push(groupedUnits[locName])
      );

      setLocationsAndUnits(finalDropdown);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getImplementationData = () => {
    getBehaviors();
    getLocationsAndUnits();
    getDisciplines();
  };

  const submitNewDiscipline = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.addDisciplines.addLoading")
    );
    try {
      if (!addModal.behaviorId || Object.keys(addModal.behaviorId).length < 1) {
        throw t("implementations.addDisciplines.behaviorError");
      }
      if (
        !addModal.locationsAndUnits ||
        addModal.locationsAndUnits.length < 1
      ) {
        throw t("implementations.addDisciplines.locationError");
      }
      const finalLocations = [];
      addModal.locationsAndUnits.forEach((lu) => {
        if (lu.isLocation) {
          finalLocations.push({
            id: lu.value,
            type: "LOCATION",
            locationId: lu.value,
          });
        } else {
          finalLocations.push({
            id: lu.value,
            type: "UNIT",
            locationId: lu.locationId,
          });
        }
      });

      const finalBehaviorIds = [];

      Object.keys(addModal.behaviorId).forEach((bId) => {
        if (addModal.behaviorId[bId]) {
          finalBehaviorIds.push({
            id: parseInt(bId),
            locations: finalLocations,
          });
        }
      });

      const payload = {
        activityTypeStub: stub,
        planId: planId,
        companyId: companyId,
        disciplineId: addModal.disciplineId,
        behaviorId: finalBehaviorIds,
      };

      await manageActivityApi(payload);
      getBehaviors();
      setAddModal(addModalDefault);
      setBehaviorsDropdown([]);

      displayToast(
        setLoading,
        "success",
        t("implementations.addDisciplines.addSuccessful"),
        activeToast
      );
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const submitSaveEdit = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.viewDisciplines.editLoading")
    );
    try {
      const currentBehaviors = editModal.currentBehaviors;
      const editedBehaviors = editModal.behaviorsList;

      // If behaviors are in behaviorsList and not in current behaviors then add them
      const toAdd = _.differenceBy(currentBehaviors, editedBehaviors, "id").map(
        (o) => o.id
      );

      //  If behaviors are not in behaviorsList and in current behaviors then remove them
      const toRemove = _.differenceBy(
        editedBehaviors,
        currentBehaviors,
        "id"
      ).map((o) => o.id);

      const payload = {
        disciplineId: editModal.disciplineId,
        locationId: editModal.locationId,
        companyId: editModal.companyId,
        unitId: editModal.unitId,
        behaviorIdsToRemove: toRemove,
        behaviorIdsToAdd: toAdd,
      };

      await updateCriticalBehaviorsByImplementationId(planId, payload);

      setBehaviorsDropdown([]);
      getImplementationData();
      setEditModal(editModalDefault);
      displayToast(
        setLoading,
        "success",
        t("implementations.viewDisciplines.editSuccess"),
        activeToast
      );
    } catch (err) {
      console.log(err);
      setEditModal(editModalDefault);
      setBehaviorsDropdown([]);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  const submitUpdateVerifiers = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.updateVerifiersLoading")
    );
    try {
      const payload = {
        companyId,
        accessType: "VERIFIER",
        disciplineId: verifierModal.disciplineId,
        locationId: verifierModal.locationId,
        unitId: verifierModal.unitId,
        responsibilitiesRemoved: verifierModal.usersToRemove.map((id) => {
          return { userId: id };
        }),
        responsibilitiesAdded: verifierModal.usersToAdd.map((id) => {
          return { userId: id };
        }),
      };

      setVerifierModal(verifierModalDefault);
      await updateVerifiersApi(planId, payload);
      getImplementationData();
      displayToast(
        setLoading,
        "success",
        t("implementations.updateVerifiersSuccess"),
        activeToast
      );
    } catch (err) {
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  const retireBehavior = async (
    behaviorId,
    retire,
    discipline,
    location,
    unit
  ) => {
    try {
      let requestRetire = await retireCriticialBehaviorByCompany(
        companyId,
        behaviorId,
        { retire, discipline, location, unit }
      );
      if (requestRetire) {
        getBehaviors();
      }
      setLoadingRetire(false);
      setRetireDetails({ ...retireDetails, open: false });
    } catch (e) {
      displayToast(setLoadingRetire, "error", e, "retireError");
    }
  };

  useEffect(() => {
    const getSpecialR = async () => {
      try {
        const data = await getSpecialResponsibilitiesApi();
        if (data.length > 0) {
          setVerifierResponsibilityId(data[0].id);
        }
      } catch (err) {
        console.log("Unable to get special responsibility.");
      }
    };
    getSpecialR();
    getImplementationData();
  }, [planId]);

  useEffect(() => {
    setAddModal({ ...addModal, behaviorId: {} });
    if (addModal.disciplineId) {
      handleGetCriticalBehaviors(addModal.disciplineId);
    }
  }, [addModal.disciplineId]);

  return (
    <Page
      title={t("implementationsList.identifyDisciplinesAndCriticalBehaviors")}
    >
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {t("implementationsList.identifyDisciplinesAndCriticalBehaviors")}
            </Typography>
          </div>
        </Box>
        <TabsBar
          value={tab}
          handler={(event, value) => setTab(value)}
          style={{ marginBottom: "14px" }}
          tabs={[
            {
              label: t("implementations.tabs.disciplines"),
              value: 0,
              key: "disciplines",
            },
            {
              label: t("implementations.tabs.managers"),
              value: 0,
              key: "managers",
            },
            {
              label: t("implementations.tabs.behaviorLeading"),
              value: 0,
              key: "lti",
            },
          ]}
        />
        {tab === "disciplines" && renderDisciplinesPage()}
        {tab === "managers" && renderManagersView()}
        {tab === "lti" && renderLTIsView()}
      </Container>
      <InfoBox
        disabled={loading}
        minWidth="70vw"
        open={addModal.open}
        title={t("implementationsList.identifyDisciplinesAndCriticalBehaviors")}
        content={renderAddView()}
        handleClose={() => {
          setAddModal(addModalDefault);
          setBehaviorsDropdown([]);
        }}
        buttonLabel={t("implementations.addADiscipline")}
        handleAction={submitNewDiscipline}
      />
      <InfoBox
        compact
        disabled={loading}
        minWidth="60vw"
        minHeight="70vh"
        open={editModal.open}
        title={`${t("implementations.viewDisciplines.editTitle")} ${
          editModal.titleName
        }`}
        content={renderEditView()}
        handleClose={() => {
          setEditModal(editModalDefault);
          setBehaviorsDropdown([]);
        }}
        buttonLabel={t("implementations.viewDisciplines.editSave")}
        handleAction={submitSaveEdit}
      />
      <InfoBox
        disabled={loading}
        open={verifierModal.open}
        title={t("implementations.editVerifiers")}
        content={renderEditVerifiers()}
        handleClose={() => {
          setVerifierModal(verifierModalDefault);
        }}
        buttonLabel={t("general.saveChanges")}
        handleAction={submitUpdateVerifiers}
      />
      <InfoBox
        disabled={loading}
        minWidth="40vw"
        open={deleteDetails.open}
        title={t("implementations.deleteDisciplineBehavior")}
        content={renderDeleteModal()}
        handleClose={() => {
          setDeleteDetails({ open: false });
        }}
        buttonLabel={t("general.delete")}
        handleAction={handleRemoveBehavior}
      />
      {renderRetireModal()}
    </Page>
  );
};
