import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import io from "socket.io-client";
import toast from "react-hot-toast";
import useAuth from "../../context/useAuth";
import { useNavigate } from "react-router-dom";
// MUI
import { styled } from "@mui/material/styles";
import SpeakerIcon from "@mui/icons-material/Campaign";
// COMPONENTS
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import MobileNavbar from "./MobileNavbar";
//  API
import { socketURL } from "../../api/config.json";
import { getNotificationsApi } from "../../api/notifications";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { channel } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [socket, setSocket] = useState(null);
  const [notificationsData, setNotificationsData] = useState({});

  const handleGetNotifications = async () => {
    try {
      const notifData = await getNotificationsApi();
      setNotificationsData(notifData);
    } catch (err) {
      console.log(err);
    }
  };
  const handleClearNotifications = () => {
    setNotificationsData({
      notifications: [],
      totalUnRead: 0,
      totalObservationUnRead: 0,
      totalActionUnread: 0,
      totalAlertsUnRead: 0,
    });
  };

  const renderNotifToast = (messageObj) => {
    toast(
      (t) => (
        <span
        // onClick={() => navigate(messageObj.clickEndpoint)}
        >
          {messageObj.filledMessage}
        </span>
      ),
      {
        icon: <SpeakerIcon style={{ color: "#F8931F" }} />,
      }
    );

    handleGetNotifications();
  };

  useEffect(() => {
    const newSocket = io(socketURL, {
      path: "/ws",
      transports: ["polling"],
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  useEffect(() => {
    const messageListener = (message) => {
      renderNotifToast(message);
    };
    if (socket && channel) {
      socket.on(channel, messageListener);
    }
    return () => {
      if (socket && channel) {
        socket.off(channel, messageListener);
      }
    };
  }, [socket, channel]);

  useEffect(() => {
    handleGetNotifications();
  }, []);

  return (
    <>
      <RootStyle>
        <DashboardNavbar
          onOpenSidebar={() => setOpen(true)}
          notificationsData={notificationsData}
          clearData={handleClearNotifications}
        />
        <DashboardSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
      <MobileNavbar
        notificationsData={notificationsData}
        clearData={handleClearNotifications}
      />
    </>
  );
}
