import apiHelper from "./apiHelper";
import { generateFormData } from "../utils/general";

//  Type can be one of:
// ['PENDING', 'COMPLETED', 'APPROVED', 'REJECTED', 'RETURNED', 'RE-ASSIGNED']
export const getAssignedActionsApi = ({
  type = "ALL",
  pageSize,
  pageNo,
  startTargetDate,
  endTargetDate,
  searchTerm = null,
  sort,
}) => {
  return new Promise((resolve, reject) => {
    let sortString = null;
    if (sort) {
      const otherSort = { ...sort };
      switch (otherSort.field) {
        case "generatedActionId":
          otherSort.field = "id";
          break;
        case "observationDate":
          otherSort.field = "observedAt";
          break;
        default:
          break;
      }
      sortString = `${otherSort.field}+${otherSort.sort}`;
    }
    apiHelper
      .get(`/api/users/me/actions${sortString ? `?sort=${sortString}` : ``}`, {
        params: {
          pageSize,
          pageNo,
          type,
          startTargetDate,
          endTargetDate,
          textSearch: searchTerm,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getActionDetailsApi = (observationId, actionId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/${observationId}/action/${actionId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const returnToSupervisorApi = (body, observationId, actionId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(
        `/api/observations/${observationId}/action/${actionId}/returnToSupervisor`,
        bodyFormData
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const completeActionApi = (body, observationId, actionId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(
        `/api/observations/${observationId}/action/${actionId}/markComplete`,
        bodyFormData
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const reassignActionApi = (body, observationId, actionId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(
        `/api/observations/${observationId}/action/${actionId}/reAssign`,
        bodyFormData
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};

export const approveActionApi = (observationId, actionId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/observations/${observationId}/action/${actionId}/approve`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const rejectActionApi = (body, observationId, actionId) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(
        `/api/observations/${observationId}/action/${actionId}/reject`,
        bodyFormData
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.data.errorMessage);
        } else if (err.request) {
          reject("backendError");
        } else {
          reject("fileEditError");
        }
      });
  });
};
