import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { displayToast } from "../../../utils/general";
//  Material UI
import { Grid, Typography, Box, Container } from "@mui/material";
//  Components
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import PageLoader from "../../../components/PageLoader";
import Page from "../../../components/Page";
import AutocompleteInput from "../../../components/inputs/AutocompleteInput";
//  API
import {
  getActivityByIdApi,
  manageActivityApi,
  implementationContractorsApi,
} from "../../../api/activities";
import TextInput from "../../../components/inputs/TextInput";
import FileUploader from "../../../components/inputs/FileUploader";
import AttachmentViewer from "../../../components/AttachmentViewer";
import { getAttachmentsApi } from "../../../api/attachments";

export const KickOffMeeting = () => {
  const { t } = useTranslation();
  const { planId, activityId, activityTypeStub, activityTypeId, companyId } =
    useParams();
  const [loading, setLoading] = useState(false);
  const [activityDetail, setActivityDetail] = useState({});
  const [users, setUsers] = useState([]);
  const [files, setFiles] = useState([]);
  const [preloadedAttachments, setPreloadedAttachments] = useState([]);
  const [attachmentLinks, setAttachmentLinks] = useState([]);
  const activityStub = activityTypeStub;

  const getCurrentActivity = async () => {
    try {
      setLoading(true);
      // const userData = await getUsers();
      const activity = await getActivityByIdApi(activityId, activityStub);
      const {
        minutes,
        agenda,
        RolloutPlanMeetings,
        attachments,
        noShows,
        attendees,
      } = activity;

      const attachmentIds = [];
      if (attachments) {
        attachments.forEach((a) => {
          attachmentIds.push(a.id);
        });
      }

      if (attachmentIds && attachmentIds.length > 0) {
        const attachmentsData = await getAttachmentsApi(attachmentIds);
        setAttachmentLinks(attachmentsData);
      }

      setActivityDetail({
        minutes,
        agenda,
        attendees: attendees,
        noShows: noShows,
      });
      setPreloadedAttachments(attachments || []);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.detailsUpdating")
    );

    try {
      const { minutes, attendees, agenda, noShows = "" } = activityDetail;
      // const finalAttendees = attendeeIds.map((a) => a.value);
      // const finalNoShows = noShowIds.map((n) => n.value);
      const body = {
        minutes,
        attendees,
        noShows,
        agenda,
        activityId,
        activityTypeStub,
        planId: planId,
        activityTypeId,
        attachments: files,
      };

      await manageActivityApi(body);
      displayToast(
        setLoading,
        "success",
        t("implementations.detailsUpdated"),
        activeToast
      );
      getCurrentActivity();
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  const getUsers = async () => {
    const usersData = await implementationContractorsApi({
      planId,
      companyId,
    });
    const users = usersData.data.map((u) => {
      return {
        label: `${u.firstName} ${u.lastName ?? ''}`,
        value: u.id,
      };
    });
    setUsers(users);
    return users;
  };

  useEffect(() => {
    getCurrentActivity();
  }, []);

  return !loading && users ? (
    <Page title={t("implementationsList.kickOff")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {t("implementationsList.kickOffMeetingDialogTitle")}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              disabled={loading}
              label={t("implementationsList.minutes")}
              placeholder={t("implementationsList.enterMinutesInput")}
              handler={(e) =>
                setActivityDetail({
                  ...activityDetail,
                  minutes: e.target.value,
                })
              }
              value={activityDetail.minutes}
              multiline
              rows={4}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <TextInput
              disabled={loading}
              label={t("implementationsList.agenda")}
              placeholder={t("implementationsList.agendaInput")}
              handler={(e) =>
                setActivityDetail({
                  ...activityDetail,
                  agenda: e.target.value,
                })
              }
              value={activityDetail.agenda}
              multiline
              rows={4}
            />
          </Grid> */}

          <Grid item xs={12}>
            <TextInput
              disabled={loading}
              label={t("implementationsList.attendeesInputText")}
              placeholder={t("implementationsList.attendeesPlaceholder")}
              handler={(e) =>
                setActivityDetail({
                  ...activityDetail,
                  attendees: e.target.value,
                })
              }
              value={activityDetail.attendees}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              disabled={loading}
              label={t("implementationsList.noShowInputText")}
              placeholder={t("implementationsList.noShowPlaceholder")}
              handler={(e) =>
                setActivityDetail({
                  ...activityDetail,
                  noShows: e.target.value,
                })
              }
              value={activityDetail.noShows}
              multiline
              rows={4}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <AutocompleteInput
              multiple
              value={activityDetail.attendeeIds}
              handler={(e, v) =>
                setActivityDetail({
                  ...activityDetail,
                  attendeeIds: v ? v : [],
                })
              }
              options={users}
              placeholder={t("implementationsList.attendeesPlaceholder")}
              label={t("implementationsList.attendeesInputText")}
              disabled={loading || users.length < 1}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteInput
              multiple
              value={activityDetail.noShowIds}
              handler={(e, v) =>
                setActivityDetail({
                  ...activityDetail,
                  noShowIds: v ? v : [],
                })
              }
              options={users}
              placeholder={t("implementationsList.noShowPlaceholder")}
              label={t("implementationsList.noShowInputText")}
              disabled={loading || users.length < 1}
            />
          </Grid> */}
          <Grid item xs={12}>
            <AttachmentViewer
              disabled={loading}
              attachments={preloadedAttachments || []}
              attachmentLinks={attachmentLinks}
              customLabel={t("general.viewAttachments")}
            />
          </Grid>
          <Grid item xs={12}>
            <FileUploader
              files={files}
              setFiles={setFiles}
              labelText={t("implementationsList.attachments")}
            />
          </Grid>
          <Grid item xs={12}>
            <PrimaryButton
              disabled={
                loading || !activityDetail.minutes || !activityDetail.attendees
              }
              alignment="left"
              label={t("general.saveChanges")}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  ) : (
    <PageLoader />
  );
};
