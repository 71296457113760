import React, { useRef } from "react";
import MultiSelectInput from "./MultiSelectInput";

export default function MultiSelectWithAll(props) {
  const valueRef = useRef(props.values);
  valueRef.current = props.values;

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "All",
  };

  const isSelectAllSelected = () =>
    valueRef.current.length > 0 &&
    valueRef.current.length === props.options.length;

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.values;

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option.value === selectAllOption.value) {
      props.handler(props.options, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      props.handler([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      props.handler(newValue || [], actionMeta);
    }
  };

  return (
    <MultiSelectInput
      isOptionSelected={isOptionSelected}
      options={getOptions()}
      values={getValue()}
      handler={onChange}
      required={props.required}
      label={props.label}
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  );
}
