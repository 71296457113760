import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { startCase } from "lodash";
//  MUI
import { useTheme } from "@mui/material/styles";
import { Box, Container, Grid, Typography, Stack } from "@mui/material";
import SecondaryButton from "../../../src/components/buttons/SecondaryButton";
import PostAddIcon from "@mui/icons-material/PostAdd";
import TableSearch from "../../components/inputs/TableSearch";
import TabsBar from "../../components/navigation/TabsBar";
import InfoBox from "../../components/InfoBox";
import RadioSet from "../../components/buttons/RadioSet";
import AllIcon from "@mui/icons-material/MoreHorizOutlined";
import VideoIcon from "@mui/icons-material/OndemandVideoOutlined";
import AudioIcon from "@mui/icons-material/MusicNoteOutlined";
import DocumentIcon from "@mui/icons-material/DescriptionOutlined";
import WeblinkIcon from "@mui/icons-material/InsertLinkOutlined";
//  Components
import Page from "../../components/Page";
import DefaultTable from "../../components/tables/DefaultTable";
import { displayToast } from "../../utils/general";
import TextInput from "../../components/inputs/TextInput";
import SelectInput from "../../components/inputs/SelectInput";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import AccessControl from "../../components/AccessControl";
//  API
import { getMyKnowledgeApi } from "../../api/knowledge";
import { createDocument } from "../../api/knowledge";
import { searchUsersApi } from "../../api/users";
import { filterModalDefaults } from "./defaults";

const addDefaults = {
  open: false,
  name: "",
  description: "",
  link: "",
  fileType: "",
};

export default function ListKnowledge() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const timeout = useRef();
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState("newAdditions");
  const [radioValue, setRadioValue] = useState("filterType");
  const [search, setSearch] = useState("");
  const [triggerFlag, setTriggerFlag] = useState(false);
  const [KnowledgeRows, setKnowledgeRows] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterModal, setFilterModal] = useState(filterModalDefaults);
  const [addDetails, setAddDetails] = useState(addDefaults);
  const [emailList, setEmailList] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [userDropdown, setUserDropdown] = useState([]);
  const [filterParams, setFilterParams] = useState({
    pageNo: 1,
    pageSize: 10,
    fileType: "",
    order: "newAdditions",
    searchTerm: "",
  });
  const [tabs, setTabs] = useState([]);

  const iconStyle = {
    height: 18,
    width: 18,
    color: theme.palette.primary.hoverGrey,
  };

  const tasksColumnDefaults = [
    { field: "id", headerName: t("table.id"), width: 100, sortable: false },
    { field: "name", headerName: t("table.name"), width: 300, sortable: false },
    {
      field: "fileType",
      headerName: t("table.fileType"),
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Stack spacing={1} direction="row">
          {params.row.trueType === "audio" ? (
            <AudioIcon sx={iconStyle} />
          ) : params.row.trueType === "video" ? (
            <VideoIcon sx={iconStyle} />
          ) : params.row.trueType === "document" ? (
            <DocumentIcon sx={iconStyle} />
          ) : (
            <WeblinkIcon sx={iconStyle} />
          )}
          <Typography style={{ fontSize: "14px" }}>
            {t(`knowledge.dropdown.${params.row.trueType}`)}
          </Typography>
        </Stack>
      ),
    },
    // { field: "description", headerName: "Description", width: 400 },
    // { field: "link", headerName: "Link", width: 300 },
    {
      field: "viewCount",
      headerName: t("table.viewCount"),
      width: 100,
      sortable: false,
    },
    {
      field: "knowledgeDate",
      headerName: t("table.knowledgeDate"),
      width: 150,
      sortable: false,
    },
    {
      field: "uploadedBy",
      headerName: t("table.uploadedBy"),
      width: 200,
      sortable: false,
    },
  ];

  const knowledgeTypes = [
    {
      label: t("knowledge.dropdown.video"),
      value: "video",
    },
    {
      label: t("knowledge.dropdown.audio"),
      value: "audio",
    },
    {
      label: t("knowledge.dropdown.document"),
      value: "document",
    },
    {
      label: t("knowledge.dropdown.webLink"),
      value: "webLink",
    },
  ];

  const handleTabChange = async (event, value) => {
    await setTabValue(value);
    await setFilterParams({ ...filterParams, order: value });
    setSearch("");
  };

  const getKnowledgeList = async () => {
    try {
      setLoading(true);

      const knowledgeData = await getMyKnowledgeApi(filterParams);
      const parsedData = knowledgeData.data.map((knowledge) => {
        return {
          id: knowledge.id,
          fileType: startCase(knowledge.fileType),
          trueType: knowledge.fileType,
          name: knowledge.name,
          // description: knowledge.description,
          // link: knowledge.link,
          viewCount: knowledge.viewCount,
          knowledgeDate: moment(knowledge.createdAt).format("DD/MM/YY hh:mm a"),
          uploadedBy: `${knowledge?.createdByUser?.firstName ?? ""} ${
            knowledge?.createdByUser?.lastName ?? ""
          }`,
        };
      });
      setKnowledgeRows(parsedData);

      setTableInfo({
        totalCount: knowledgeData.totalItems,
        totalPages: knowledgeData.totalPages,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "error", err);
    }
  };

  useEffect(() => {
    getKnowledgeList();
  }, [filterParams]);

  const submitSearch = () => {
    setFilterParams({ ...filterParams, pageNo: 1, searchTerm: search });
    setTriggerFlag(true);
  };

  const handleFilterChange = (event, value) => {
    setFilterParams({ ...filterParams, pageNo: 1, fileType: value });
  };

  const submitAddDocument = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("knowledge.addDetails.addDocumentLoading")
    );
    try {
      const userIds = emailList.map((e) => e.id);
      const payload = { ...addDetails, userIds: userIds };
      delete payload.open;
      await createDocument(payload);
      setAddDetails(addDefaults);
      getKnowledgeList();
      displayToast(
        setLoading,
        "success",
        t("knowledge.addDetails.addDocumentSuccess")
      );
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const renderAddModal = () => (
    <InfoBox
      disabled={
        loading ||
        !addDetails.name ||
        !addDetails.fileType ||
        !addDetails.link ||
        !addDetails.description
      }
      open={addDetails.open}
      minWidth="60vw"
      title={t("knowledge.addDetails.addNewDocument")}
      content={
        <Stack spacing={2}>
          <TextInput
            disabled={loading}
            label={t("knowledge.addDetails.addDocumentName")}
            placeholder={t("knowledge.addDetails.addDocumentNamePlaceholder")}
            value={addDetails.name}
            handler={(e) =>
              setAddDetails({ ...addDetails, name: e.target.value })
            }
          />
          <TextInput
            disabled={loading}
            label={`${t("knowledge.addDetails.addDocumentDescription")}*`}
            placeholder={t(
              "knowledge.addDetails.addDocumentDescriptionPlaceholder"
            )}
            value={addDetails.description}
            handler={(e) =>
              setAddDetails({ ...addDetails, description: e.target.value })
            }
          />
          <TextInput
            disabled={loading}
            label={t("knowledge.addDetails.addDocumentLink")}
            placeholder={t("knowledge.addDetails.addDocumentLinkPlaceholder")}
            value={addDetails.link}
            handler={(e) =>
              setAddDetails({ ...addDetails, link: e.target.value })
            }
          />
          <SelectInput
            label={t("knowledge.addDetails.selectFileType")}
            options={knowledgeTypes}
            value={addDetails.fileType}
            disabled={loading}
            handler={(e) =>
              setAddDetails({ ...addDetails, fileType: e.target.value })
            }
          />
          <AutocompleteInput
            multiple
            value={emailList}
            handler={(e, v) => setEmailList(v)}
            keyPressHandler={handleSearchUser}
            label={t("knowledge.addDetails.selectEmail")}
            placeholder={t("knowledge.addDetails.selectEmailPlaceholder")}
            loading={searchLoading}
            disabled={loading}
            options={userDropdown}
          />
        </Stack>
      }
      handleClose={() => setAddDetails(addDefaults)}
      buttonLabel={t("knowledge.addDetails.addDocument")}
      handleAction={submitAddDocument}
    />
  );

  useEffect(() => {
    setTabs([
      {
        label: t("knowledge.tabs.newAdditions"),
        value: 0,
        key: "newAdditions",
      },
      {
        label: t("knowledge.tabs.mostViewed"),
        value: 0,
        key: "mostViewed",
      },
    ]);
  }, [i18n.language]);

  return (
    <Page title={t("knowledge.title")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div>
            <Typography variant="h4">{t("knowledge.title")}</Typography>
          </div>
        </Box>
        <Grid container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={10}>
              <TabsBar value={tabValue} handler={handleTabChange} tabs={tabs} />
            </Grid>
            <AccessControl accessName="KNW_BASE_CU">
              <Grid item xs={12} md={2}>
                <SecondaryButton
                  icon={<PostAddIcon />}
                  label={t("knowledge.addDetails.addNewDocument")}
                  alignment={"left"}
                  onClick={() => setAddDetails({ ...addDefaults, open: true })}
                  disabled={loading}
                />
              </Grid>
            </AccessControl>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            style={{ paddingTop: "20px" }}
          >
            <TableSearch
              searchTerm={search}
              handler={(e) => setSearch(e.target.value)}
              disabled={loading}
              submitSearch={submitSearch}
              clearable={filterParams.searchTerm}
              clearHandler={() => {
                setSearch("");
                setFilterParams({
                  ...filterParams,
                  pageNo: 1,
                  searchTerm: "",
                });
                setTriggerFlag(true);
              }}
            />
            <RadioSet
              disabled={loading}
              label=""
              value={filterModal[radioValue]}
              handleChange={handleFilterChange}
              options={[
                {
                  label: t(`knowledge.filters.allKnowledges`),
                  value: "",
                },
                {
                  label: (
                    <Stack spacing={1} direction="row">
                      <AudioIcon />
                      <Typography style={{ fontSize: "14px" }}>
                        {t(`knowledge.filters.allAudios`)}
                      </Typography>
                    </Stack>
                  ),
                  value: "audio",
                },
                {
                  label: (
                    <Stack spacing={1} direction="row">
                      <VideoIcon />
                      <Typography style={{ fontSize: "14px" }}>
                        {t(`knowledge.filters.allVideos`)}
                      </Typography>
                    </Stack>
                  ),
                  value: "video",
                },
                {
                  label: (
                    <Stack spacing={1} direction="row">
                      <DocumentIcon />
                      <Typography style={{ fontSize: "14px" }}>
                        {t(`knowledge.filters.allDocuments`)}
                      </Typography>
                    </Stack>
                  ),
                  value: "document",
                },
                {
                  label: (
                    <Stack spacing={1} direction="row">
                      <WeblinkIcon />
                      <Typography style={{ fontSize: "14px" }}>
                        {t(`knowledge.filters.allWebLinks`)}
                      </Typography>
                    </Stack>
                  ),
                  value: "webLink",
                },
              ]}
            />
          </Stack>
        </Grid>
        {renderAddModal()}
        <Grid container spacing={2} style={{ paddingTop: "20px" }}>
          <Grid item xs={12}>
            <DefaultTable
              rows={KnowledgeRows}
              columns={tasksColumnDefaults}
              loading={loading}
              tableInfo={tableInfo}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              minHeight="75vh"
              topTextContent={
                <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
                  {filterParams.searchTerm && (
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.grey,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.searchResults")} "${
                      filterParams.searchTerm
                    }" `}</Typography>
                  )}
                  <Typography
                    variant="body2"
                    style={{
                      color: theme.palette.primary.orange,
                      fontWeight: "bold",
                    }}
                  >{`${t("table.totalListed")} ${
                    tableInfo.totalCount
                  } `}</Typography>
                </Stack>
              }
              onRowClick={(r) => {
                navigate(`/library/details/${r.row.id}`);
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
