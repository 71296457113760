import apiHelper from "./apiHelper";

export const getOptions = (id) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiReports/options?companyId=${id}`)
      .then((res) => {
        resolve(res.data?.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompanyOptions = (id) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiReports/companyOptions?companyId=${id}`)
      .then((res) => {
        resolve(res.data?.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getConfigurations = (value, companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/kpiReports/configurations?value=${value}&companyId=${companyId}`
      )
      .then((res) => {
        resolve(res.data?.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const saveConfiguration = (value, companyId, body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(
        `/api/kpiReports/configurations?value=${value}&companyId=${companyId}`,
        body
      )
      .then((res) => {
        resolve(res.data?.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getKpiWeightsApi = (companyId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/kpiReports/weights`, {
        params: {
          companyId,
        },
      })
      .then((res) => {
        resolve(res.data?.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const updateKpiWeightsApi = (body) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/kpiReports/weights`, body)
      .then((res) => {
        resolve(res.data?.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
