import apiHelper from "./apiHelper";

export const getLatestReviewApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/sustainabilityReview/latest`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const saveResponseApi = (payload) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/sustainabilityReview/saveResponse`, payload)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getStatsApi = (planId, revision) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/sustainabilityReview/implementation/${planId}/basicStats`, {
        params: {
          revision,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const assignReviewApi = (payload) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/sustainabilityReview/assign`, payload)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getTextResponsesApi = (implementationId, questionId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/sustainabilityReview/implementation/${implementationId}/question/${questionId}`
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getSubmissionsApi = ({ planId, pageSize, pageNo }) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/sustainabilityReview/implementation/${planId}/responses`, {
        params: {
          pageSize,
          pageNo,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getSingleResponseApi = ({ planId, submissionId }) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/sustainabilityReview/implementation/${planId}/responses/${submissionId}`
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getRevisionsApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/sustainabilityReview/revisions`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
