import { percentFormatter } from "../../../utils/general";

export const reasonTagDropdown = [
  {
    label: "Empty Cell",
    value: "Empty Cell",
  },
  {
    label: "High Similarity",
    value: "High Similarity",
  },
  {
    label: "Vague Phrases",
    value: "Vague Phrases",
  },
  {
    label: "Common Phrases",
    value: "Common Phrases",
  },
  {
    label: "No Tag",
    value: "No Tag",
  },
];

const heatmapColors = [
  {
    from: 0,
    to: 0,
    color: "#858585",
    name: "No data",
  },
  {
    from: 1,
    to: 65,
    color: "#DC143C",
    name: "Low: Below 65%",
  },
  {
    from: 65,
    to: 85,
    color: "#F2994A",
    name: "Moderate: 65% - 85%",
  },
  {
    from: 85,
    to: 100,
    color: "#1bc262",
    name: "High: Above 85%",
  },
];

export const exampleMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

export const exampleDisciplines = [
  "Catering",
  "Housekeeping",
  "Driving",
  "Metal Fabrication",
  "Electrical",
  "Construction",
  "Plumbing",
  "Mechanical Engineering",
  "Environment",
  "Casing Yard",
  "Carpentry",
  "Lifting",
];

export const exampleLocations = [
  "Lekhwair",
  "Bahja",
  "Qarn Alam",
  "Fahud",
  "Haima",
  "Harweel",
];

export const exampleTopics = [
  "LSR_SUSPENDED",
  "LSR_SEATBELT",
  "LSR_WORKING",
  "LSR_PTW",
  "LSR_PTW2",
  "LSR_MOBILE_PHONE & SPEED_LIMIT",
  "LSR_ISOLATION",
  "LSR_SMOKING",
  "LSR_SEAT_BELT",
];

export const generateLineData = () => {
  return exampleMonths.map((m) => {
    return Math.random().toFixed(2);
  });
};

export const lineChartOptions = {
  series: [
    {
      name: "Quality Score",
      data: generateLineData(),
    },
  ],
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  title: {
    show: false,
  },
  xaxis: {
    categories: exampleMonths,
  },
  yaxis: {
    labels: {
      formatter: percentFormatter,
    },
    max: 1,
  },
};

export const heatMapOptions = {
  chart: {
    fontFamily: "Cairo, sans-serif",
  },
  plotOptions: {
    heatmap: {
      enableShades: false,
      colorScale: {
        ranges: heatmapColors,
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter: (val) => (val ? `${val.toFixed(0)}%` : ""),
    style: {
      fontSize: "14px",
    },
  },
  tooltip: {
    y: {
      formatter: (val) => (val ? `${val.toFixed(0)}%` : ""),
    },
  },
  xaxis: {
    type: "category",
    position: "top",
  },
  axisTicks: {
    show: false,
  },
  labels: {
    show: true,
  },
  legend: {
    show: true,
    position: "bottom",
    showForSingleSeries: true,
    offsetY: -10,
    markers: {
      width: 8,
      height: 8,
      offsetY: -1,
    },
  },
  yaxis: {
    labels: {
      maxWidth: 60,
    },
  },
};

export const lowEffortHeatmapOptions = {
  chart: {
    fontFamily: "Cairo, sans-serif",
  },
  plotOptions: {
    heatmap: {
      enableShades: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 65,
            color: "#DC143C",
            name: "Low: Below 65%",
          },
          {
            from: 65,
            to: 85,
            color: "#F2994A",
            name: "Moderate: 65% - 85%",
          },
          {
            from: 85,
            to: 100,
            color: "#1bc262",
            name: "High: Above 85%",
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter: (val) => `${val.toFixed(0)}%`,
  },
  xaxis: {
    type: "category",
    position: "top",
  },
  axisTicks: {
    show: false,
  },
  labels: {
    show: true,
  },
  legend: {
    show: true,
    position: "bottom",
    showForSingleSeries: true,
    offsetY: -10,
    markers: {
      width: 8,
      height: 8,
      offsetY: -1,
    },
  },
  tooltip: {
    y: {
      formatter: (val) => (val ? `${val.toFixed(0)}%` : ""),
    },
  },
  yaxis: {
    labels: {
      maxWidth: 60,
    },
  },
};

//  DEPRECATED
export const _DEPRECATED_stackedBarOptions = {
  chart: {
    type: "bar",
    stacked: true,
    fontFamily: "Cairo, sans-serif",
  },
  colors: [
    "#B0B0B0",
    "#FF0000",
    "#FF8A00",
    "#FFC700",
    "#7BAD11",
    "#38D600",
    "#38D600",
  ],
  legend: {},
  dataLabels: { enabled: false, dropShadow: { enabled: false } },
  xaxis: {
    categories: exampleMonths,
  },
  yaxis: {
    max: 100,
    title: {
      text: "% Breakdown of appreciation",
    },
    labels: {
      formatter: (val) => {
        return val ? `${val.toFixed(0)}%` : undefined;
      },
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "30%",
    },
  },
};

export const bubbleChartOptions = {
  chart: {
    height: 350,
    type: "bubble",
  },
  colors: ["#0081A7", "#00AFB9", "#F0CEA0", "#F3A712", "#534D41"],
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 0.8,
  },
  xaxis: {
    tickAmount: 12,
    type: "category",
  },
  yaxis: {
    max: 70,
  },
  tooltip: {
    x: {
      formatter: function (val) {
        return `Safe Score: ${val}%`;
      },
    },
    z: {
      title: "Total safe cards:",
    },
  },
};

export const barChartOptions = {
  colors: ["#43A1D7"],
  legend: {
    show: false,
    floating: true,
    horizontalAlign: "center",
  },
  dataLabels: {
    enabled: false,
    dropShadow: { enabled: false },
  },
  xaxis: {
    categories: exampleTopics,
  },
  plotOptions: {
    bar: {
      distributed: true,
      columnWidth: "30%",
    },
  },
};

export const generateBubbleData = () => {
  return [
    {
      name: "Bubble1",
      data: [
        [
          new Date().getTime(),
          Math.floor(Math.random() * (100 - 0 + 1)),
          Math.floor(Math.random() * (100 - 0 + 1)),
        ],
      ],
    },
    {
      name: "Bubble2",
      data: [
        [
          new Date().getTime(),
          Math.floor(Math.random() * (100 - 0 + 1)),
          Math.floor(Math.random() * (100 - 0 + 1)),
        ],
      ],
    },
    {
      name: "Bubble3",
      data: [
        [
          new Date().getTime(),
          Math.floor(Math.random() * (100 - 0 + 1)),
          Math.floor(Math.random() * (100 - 0 + 1)),
        ],
      ],
    },
    {
      name: "Bubble4",
      data: [
        [
          new Date().getTime(),
          Math.floor(Math.random() * (100 - 0 + 1)),
          Math.floor(Math.random() * (100 - 0 + 1)),
        ],
      ],
    },
  ];
};

export const generateDisciplineHeatmap = () => {
  return exampleDisciplines.map((c) => {
    return {
      name: c,
      data: exampleMonths.map((m) => {
        return {
          x: m,
          y: Math.random().toFixed(2),
        };
      }),
    };
  });
};

export const generateLocationHeatmap = () => {
  return exampleLocations.map((c) => {
    return {
      name: c,
      data: exampleMonths.map((m) => {
        return {
          x: m,
          y: Math.random().toFixed(2),
        };
      }),
    };
  });
};

export const generateStackedBar = () => {
  return [
    {
      name: "1 star",
      data: [3, 47, 39, 49, 58, 89],
    },
    {
      name: "2 star",
      data: [42, 3, 13, 1, 8, 7],
    },
    {
      name: "3 star",
      data: [29, 48, 44, 42, 20, 1],
    },
    {
      name: "4 star",
      data: [21, 1, 3, 6, 9, 2],
    },
    {
      name: "5 star",
      data: [5, 1, 1, 2, 5, 1],
    },
  ];
};

export const relevanceRows = [
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
  {
    id: "OBS-2021-285830",
    behaviorName:
      "Banksman is available and using 3m rule during forklift operation",
    feedback:
      "I have visited the casing yard for spot-check. the place was in very good house keeping...",
    relevanceScore: `${Math.random().toFixed(2)}%`,
  },
];

export const graphColors = [
  "#ffa229",
  "#ee9225",
  "#dd8221",
  "#cc721d",
  "#bd6319",
  "#ac5315",
  "#9a4110",
  "#8a310c",
  "#792108",
  "#6c1405",
  "#580000",
];
