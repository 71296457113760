import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { pxToRem, responsiveFontSizes } from "../../theme/typography";
import { useTranslation } from "react-i18next";

const IconButtonStyle = styled(ArrowBackIcon)(({ color }) => ({
  cursor: "pointer",
  fontSize: pxToRem(24),
  marginRight: "16px",
  color,
  ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
}));

const HeaderBackButton = ({ backFunction, color }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Tooltip title={t("general.back")}>
      <IconButtonStyle
        color={color}
        onClick={() => (backFunction ? backFunction() : navigate(-1))}
      />
    </Tooltip>
  );
};

HeaderBackButton.propTypes = {
  backFunction: PropTypes.func,
  color: PropTypes.string,
};

export default HeaderBackButton;
