import * as React from "react";
import Divider from "@mui/material/Divider";

const FormDivider = () => {
  return (
    <Divider
      variant="middle"
      sx={{
        margin: 0,
        borderColor: "#F7D5AE",
        borderBottomWidth: "medium",
        borderRadius: "10px",
      }}
    />
  );
};

export default FormDivider;
