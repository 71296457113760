/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { observationDetailsDefault } from "./ObservationApp/defaults";
import { displayToast } from "../../utils/general";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

// MUI
import { Grid, Container, Box } from "@mui/material";
import { Typography } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Summarize";
//  Components
import SelectInput from "../../components/inputs/SelectInput";
import Page from "../../components/Page";
import ObservationCardTemplate from "./ObservationCardTemplate";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
//  API
import { getLocationsForObsApi } from "../../api/location";
import {
  getCompaniesUsingLocationApi,
  getCompanyDetailsApi,
} from "../../api/company";
import { getUnitsTiedApi } from "../../api/unit";
import { getCompanyDisciplinesApi } from "../../api/disciplines";
import { getObsCriticalBehaviorsApi } from "../../api/behaviors";
import { getAttachmentsApi } from "../../api/attachments";

export default function ObservationCard() {
  const { t } = useTranslation();
  const today = moment().format("DD/MM/YYYY");
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [locationsDropdown, setLocationsDropdown] = useState([]);
  const [directorateDropdown, setDirectorateDropdown] = useState([]);
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [disciplineDropdown, setDisciplineDropdown] = useState([]);
  const [unitDropdown, setUnitDropdown] = useState([]);
  const [logoUrl, setLogoUrl] = useState("");
  const [observationDetails, setObservationDetails] = useState(
    observationDetailsDefault
  );
  const pagesRef = useRef([]);

  const getLocations = async () => {
    try {
      setPreview(false);
      setLoading(true);
      const locationData = await getLocationsForObsApi();
      const locationOptions = locationData.map((c) => {
        return {
          label: c.name,
          value: c.id,
        };
      });

      setObservationDetails({
        ...observationDetails,
        location: "",
        company: "",
        unit: "",
        discipline: "",
        directorate: "",
      });

      setLocationsDropdown(locationOptions);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return [];
    }
  };

  const getCompanies = async () => {
    try {
      setPreview(false);
      setLoading(true);
      const companyData = await await getCompaniesUsingLocationApi(
        observationDetails.location
      );
      const companyOptions = companyData.map((c) => {
        return {
          label: c.name,
          value: c.id,
          directorates: c.directorates,
        };
      });
      setCompanyDropdown(companyOptions);

      setDirectorateDropdown([]);
      setUnitDropdown([]);
      setDisciplineDropdown([]);
      setObservationDetails({
        ...observationDetails,
        company: "",
        unit: "",
        discipline: "",
        directorate: "",
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getDirectorates = () => {
    setPreview(false);
    const companyId = observationDetails.company;
    const targetCompany = companyDropdown.find((c) => c.value === companyId);
    let finalDArray = [];
    if (targetCompany && targetCompany.directorates) {
      finalDArray = targetCompany.directorates.map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });

      setDirectorateDropdown(finalDArray);
    }

    setUnitDropdown([]);
    setObservationDetails({
      ...observationDetails,
      unit: "",
      discipline: "",
    });
  };

  const getUnits = async () => {
    try {
      setObservationDetails({
        ...observationDetails,
        unit: "",
        discipline: "",
      });
      setPreview(false);
      setLoading(true);
      const unitData = await getUnitsTiedApi(
        observationDetails.directorate,
        observationDetails.location,
        observationDetails.company
      );
      const unitOptions = unitData.map((u) => {
        return {
          label: u.name,
          value: u.id,
        };
      });
      setUnitDropdown(unitOptions);
      setLoading(false);
    } catch (err) {}
  };

  const getDisciplines = async () => {
    try {
      setPreview(false);
      setLoading(true);
      setObservationDetails({
        ...observationDetails,
        discipline: "",
      });

      const disciplineData = await getCompanyDisciplinesApi(
        observationDetails.directorate,
        observationDetails.company,
        observationDetails.location,
        observationDetails.unit
      );

      const disciplineOptions = disciplineData.map((d) => {
        return {
          label: d.name,
          value: d.id,
          labelAR: d.disciplineAR,
        };
      });

      setDisciplineDropdown(disciplineOptions);
      setLoading(false);
    } catch (err) {}
  };

  const handleShowPreview = async () => {
    try {
      setLoading(true);
      if (!observationDetails.location) {
        throw t(`errorHandling.locationError`);
      }
      if (!observationDetails.company) {
        throw t(`errorHandling.companyError`);
      }
      if (!observationDetails.directorate) {
        throw t(`errorHandling.directorateError`);
      }
      if (!observationDetails.discipline) {
        throw t(`errorHandling.disciplineError`);
      }

      const companyDetails = await getCompanyDetailsApi(
        observationDetails.company
      );
      if (companyDetails.attachment) {
        const url = await getAttachmentsApi([companyDetails.attachment.id]);
        if (url && url.length > 0) {
          setLogoUrl(url[0].url);
        }
      }

      const criticalBehaviorsData = await getObsCriticalBehaviorsApi(
        observationDetails.company,
        observationDetails.discipline,
        observationDetails.location,
        observationDetails.unit,
        observationDetails.directorate
      );

      const criticalBehaviorsListed = criticalBehaviorsData.map((b) => {
        return {
          id: b.id,
          label: b.name,
          labelAR: b.nameAR,
        };
      });

      setObservationDetails({
        ...observationDetails,
        discipline: observationDetails.discipline,
        criticalBehaviors: criticalBehaviorsListed,
        otherBehaviors: [],
      });
      setPreview(true);
      setLoading(false);
    } catch (err) {
      displayToast(setLoading, "pureError", err);
      console.log(err);
    }
  };

  const submitDownload = async () => {
    const activeToast = displayToast(
      setDownloadLoading,
      "loading",
      t("observations.observationDetails.downloadingObsCard")
    );

    setLoading(true);
    try {
      const pdf = new jsPDF("p", "pt", "a4", true);
      const width = pdf.internal.pageSize.getWidth();
      const w = width - width * 0.1;
      const height = pdf.internal.pageSize.getHeight();
      const h = height - height * 0.1;

      for (let index = 0; index < pagesRef.current.length; index++) {
        const canvasRef = await html2canvas(pagesRef.current[index], {
          scale: window.devicePixelRatio, // Adjust the scale for better Retina rendering
          useCORS: true,
        });

        const imgData = canvasRef.toDataURL("image/png");

        pdf.addImage(imgData, "PNG", 30, 130, w, h * 0.7);

        if (index + 1 !== pagesRef.current.length) {
          pdf.addPage();
        } else {
          pdf.save(`ObservationCard_${moment().format("DD_MM_YYYY")}.pdf`);
        }
      }

      displayToast(
        setDownloadLoading,
        "success",
        t("observations.observationDetails.cardDownloaded"),
        activeToast
      );
      setLoading(false);
    } catch (err) {
      displayToast(setLoading, "error", t("errors.backendError"), activeToast);
      setLoading(false);
    }
  };

  //  On component mount
  useEffect(() => {
    getLocations();
  }, []);

  //  On location dropdown change
  useEffect(() => {
    if (observationDetails.location) {
      getCompanies();
    }
  }, [observationDetails.location]);

  //  On location or company change
  useEffect(() => {
    if (observationDetails.location && observationDetails.company) {
      getDirectorates();
    }
  }, [observationDetails.location, observationDetails.company]);

  //  On directorate change
  useEffect(() => {
    if (observationDetails.directorate) {
      getUnits();
      getDisciplines();
    }
  }, [observationDetails.directorate]);

  //  On discipline change
  useEffect(() => {
    setPreview(false);
  }, [observationDetails.discipline]);

  //  On unit change
  useEffect(() => {
    if (observationDetails.unit !== "") {
      getDisciplines();
    }
  }, [observationDetails.unit]);

  return (
    <Page title={t("observations.observationCardPage.observationCardTitle")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">
              {t("observations.observationCardPage.observationCardTitle")}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SelectInput
              label={t("observations.observationCardPage.step1")}
              value={observationDetails.location}
              disabled={loading || locationsDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  location: e.target.value,
                  company: observationDetailsDefault.company,
                  directorate: observationDetailsDefault.directorate,
                  unit: observationDetailsDefault.unit,
                  discipline: observationDetailsDefault.discipline,
                })
              }
              options={locationsDropdown}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              label={t("observations.observationCardPage.step2")}
              value={observationDetails.company}
              disabled={loading || companyDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  company: e.target.value,
                  directorate: observationDetailsDefault.directorate,
                  unit: observationDetailsDefault.unit,
                  discipline: observationDetailsDefault.discipline,
                })
              }
              options={companyDropdown}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              label={t("observations.observationCardPage.step2a")}
              value={observationDetails.directorate}
              disabled={loading || directorateDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  directorate: e.target.value,
                  unit: observationDetailsDefault.unit,
                  discipline: observationDetailsDefault.discipline,
                })
              }
              options={directorateDropdown}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              optional
              label={t("observations.observationCardPage.step3")}
              value={observationDetails.unit}
              disabled={loading || unitDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  unit: e.target.value,
                  discipline: observationDetailsDefault.discipline,
                })
              }
              options={unitDropdown}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <SelectInput
              label={t("observations.observationCardPage.step4")}
              value={observationDetails.discipline}
              disabled={loading || disciplineDropdown.length < 1}
              handler={(e) =>
                setObservationDetails({
                  ...observationDetails,
                  discipline: e.target.value,
                })
              }
              options={disciplineDropdown}
            />
          </Grid>
          <Grid item xs={12}>
            <SecondaryButton
              disabled={loading}
              icon={<PreviewIcon />}
              alignment="left"
              label={t("observations.observationCardPage.preview")}
              onClick={handleShowPreview}
            />
          </Grid>
          {preview && (
            <>
              <Grid item xs={12}>
                <div>
                  <ObservationCardTemplate
                    observationDetails={observationDetails}
                    disciplineDropdown={disciplineDropdown}
                    logoUrl={logoUrl}
                    pagesRef={pagesRef}
                    today={today}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <PrimaryButton
                  disabled={loading}
                  icon={<PreviewIcon />}
                  alignment="left"
                  label={t("observations.observationCardPage.print")}
                  onClick={submitDownload}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
