import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { InfoOutlined, ArrowDropDownCircleOutlined } from "@mui/icons-material";
import HelpText from "../HelpText";

MultiSelectInput.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  handler: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
};

const primaryOrange = "rgba(248, 147, 31, 0.6)";
const hoverColor = "rgba(248, 147, 31, 0.16)";

const multiStyling = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    background: isDisabled
      ? "#F1F1F1"
      : "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
    border: isDisabled ? "1px solid #E9E9E9" : "1px solid #C2C2C2",
    borderRadius: "10px",
    height: "46px",
    overflow: "auto",
    whiteSpace: "noWrap",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? hoverColor
        : undefined,
      color: isDisabled ? "#ccc" : isSelected ? "red" : "black",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : hoverColor
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: primaryOrange,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#1C1C1C",
    fontSize: "0.9rem",
    borderRadius: "10px",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    cursor: "pointer",
    ":hover": {
      backgroundColor: data.color,
      color: "#1C1C1C",
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  indicatorContainer: (styles) => ({
    ...styles,
    background: primaryOrange,
  }),
};

const DropdownIconStyle = styled(ArrowDropDownCircleOutlined)(({ theme }) => ({
  marginRight: "8px",
}));

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

export default function MultiSelectInput({
  label,
  disabled,
  options,
  helpText,
  handler,
  values,
  placeholder,
  isMulti = true,
  required = false,
  isOptionSelected
}) {
  const { t } = useTranslation();
  const DropdownIndicator = (props) => {
    return (
      <DropdownIconStyle
        style={{ fill: disabled ? "#9A9A9A" : primaryOrange }}
      />
    );
  };

  return (
    <Box>
      <Stack
        direction="row"
        style={{ paddingBottom: "4px" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <div style={{ display: "flex" }}>
          <Typography variant="h6">{label}</Typography>
          {required && (
            <Typography variant="h6" color="error">
              *{" "}
            </Typography>
          )}
        </div>
        {helpText && (
          <HelpText title={helpText}>
            <IconStyle />
          </HelpText>
        )}
      </Stack>

      <Select
        placeholder={placeholder || t("reporting.selectPlaceholder")}
        closeMenuOnSelect={false}
        components={{ DropdownIndicator }}
        // defaultValue={[colourOptions[4], colourOptions[5]]}
        isMulti={isMulti}
        isDisabled={disabled}
        options={options}
        styles={multiStyling}
        onChange={handler}
        value={values}
        noOptionsMessage={() => t("general.noOptions")}
        isOptionSelected={isOptionSelected}
      />
    </Box>
  );
}
