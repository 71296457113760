import apiHelper from "./apiHelper";

export const askQuestionApi = (question) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`/api/chatbot/askQuestion`, {
        question,
        type: "web",
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
