import apiHelper from "./apiHelper";

export const getDisciplinesApi = (filterParams, type) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/disciplines`, {
        params: {
          pageSize: filterParams?.pageSize || 9999,
          pageNo: filterParams?.pageNo || 1,
          ...(type ? { type } : {}),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getObsDisciplinesApi = (filterParams, type) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/disciplines`, {
        params: {
          pageSize: filterParams?.pageSize || 9999,
          pageNo: filterParams?.pageNo || 1,
          ...(type ? { type } : {}),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

// export const getOtherDisciplinesApi = () => {
//   return new Promise((resolve, reject) => {
//     apiHelper
//       .get(`/api/disciplines`, {
//         params: {
//           pageSize: 9999,
//           pageNo: 1,
//           type: "OTHER",
//         },
//       })
//       .then((res) => {
//         resolve(res.data.data);
//       })
//       .catch((err) => {
//         reject(err.response.data.errorMessage);
//       });
//   });
// };

export const getObsOtherDisciplinesApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/observations/disciplines`, {
        params: {
          pageSize: 9999,
          pageNo: 1,
          type: "OTHER",
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompanyDisciplinesApi = (
  directorateId,
  companyId,
  locationId,
  unitId
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/disciplines/company`, {
        params: {
          pageSize: 999,
          pageNo: 1,
          directorateId,
          companyId,
          locationId,
          ...(unitId ? { unitId } : {}),
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
