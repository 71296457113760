import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useAuth from "../../context/useAuth";
// material
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Toolbar,
} from "@mui/material";
import { alpha, styled, useTheme } from "@mui/material/styles";
import ObservationIcon from "@mui/icons-material/HistoryEdu";
import LogoutIcon from "@mui/icons-material/PowerSettingsNew";
import LanguageIcon from "@mui/icons-material/Language";
// components
import { MHidden } from "../../components/@material-extend";
import NotificationsPopover from "./NotificationsPopover";
import Chatbot from "./Chatbot";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    display: "none",
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

MobileNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function MobileNavbar({ notificationsData, clearData }) {
  const theme = useTheme();
  const { logout } = useAuth();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const changeLanguage = () => {
    if (i18n.language.includes("en")) {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
  };

  const renderBottomNavigation = () => (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation>
        <BottomNavigationAction
          icon={<LanguageIcon />}
          onClick={changeLanguage}
        />
        <Chatbot isMobile />
        <BottomNavigationAction
          icon={<ObservationIcon />}
          onClick={() => navigate("/observations/submit")}
        />
        <NotificationsPopover
          parentNotifications={notificationsData}
          clearData={clearData}
          isMobile
        />
        <BottomNavigationAction icon={<LogoutIcon />} onClick={logout} />
      </BottomNavigation>
    </Paper>
  );

  return (
    <ToolbarStyle>
      {/* MOBILE */}
      <MHidden width="lgUp">{renderBottomNavigation()}</MHidden>
    </ToolbarStyle>
  );
}
