import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import FormDivider from "../../../components/FormDivider";
import TextInput from "../../../components/inputs/TextInput";
import { checkBetweenRange } from "../../../utils/general";

function NumberObservations(props) {
  const { t } = useTranslation();
  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
        <Typography style={{ color: "#f8931f" }} variant="h5">
          {t("kpi.numObsConfig")} :
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextInput
          label={t("kpi.numObsNumSupervisors")}
          placeholder={t("general.count")}
          type={"number"}
          value={props?.data?.param1}
          handler={(e) => {
            let temp = parseFloat(e.target.value);
            // if (temp >= 100) temp = 100;
            if (temp < 0) temp = 0;
            props?.setData({
              ...props.data,
              param1: temp ?? 0,
            });
          }}
          disabled={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <TextInput
          label={t("kpi.numObsNumCards")}
          placeholder={t("general.count")}
          type={"number"}
          value={props?.data?.param2}
          handler={(e) => {
            let temp = parseFloat(e.target.value);
            // if (temp >= 100) temp = 100;
            if (temp < 0) temp = 0;
            props?.setData({
              ...props.data,
              param2: temp ?? 0,
            });
          }}
          disabled={false}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6} md={6}>
        <TextInput
          label={t("kpi.percentageWeightage")}
          placeholder={t("general.percentage")}
          type={"number"}
          value={props?.data?.paramWeight}
          disabled
        />
      </Grid> */}
      {/* <Grid item xs={12} sm={12} md={12}>
        <FormDivider />
      </Grid> */}
    </Grid>
  );
}

export default NumberObservations;
