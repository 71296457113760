import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import {
  getTraineeUser,
  getTrainingPlans,
  OptForDataByTaskId,
  optForTaskSubmit,
} from "../../api/implementation";
import moment from "moment";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PageLoader from "../../components/PageLoader";

function TrainingOptFor() {
  const { t } = useTranslation();
  const { taskId, trainingPlanId, trainingUserId } = useParams();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [data, setData] = useState({});

  const handleSubmitTask = async (value) => {
    let tempObject = {
      userStatus: value,
      traineeUserId: trainingUserId,
      taskId: taskId,
    };
    let submitTask = await optForTaskSubmit(tempObject);
    if (submitTask) {
      getTrainingData();
    }
  };
  const getTrainingData = async () => {
    setLoading(true);
    try {
      let query = `taskId=${taskId}`;
      let getTraining = await OptForDataByTaskId(query);
      setData(getTraining);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrainingData();
    // getUsersByCompanyId();
  }, []);

  return !loading ? (
    <>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">{"Task list"}</Typography>
          </div>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Stack
              spacing={4}
              style={{
                background:
                  "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
                border: "1px solid #C2C2C2",
                padding: "18px",
                borderRadius: "4px",
              }}
            >
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t(`training.trainingType`)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {data?.TraineeUsers?.TrainingPlan?.TrainingType?.name}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {t(`training.trainingPeriod`)}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {`${moment(
                      data?.TraineeUsers?.TrainingPlan?.startDate
                    ).format("DD/MM/YYYY")} to ${moment(
                      data?.TraineeUsers?.TrainingPlan?.endDate
                    ).format("DD/MM/YYYY")}`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {"Training Applied by"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {`${data?.TraineeUsers?.User?.firstName} ${data?.TraineeUsers?.User?.lastName}`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column">
                  <Typography variant="h6" style={{ textShadow: "none" }}>
                    {"Training Applied on"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{ color: theme.palette.primary.grey }}
                  >
                    {moment(data?.TraineeUsers?.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          {(data?.TraineeUsers?.userStatus === "OptedFor" || data?.TraineeUsers?.userStatus === "pending" ) && (
              <>
              <Grid item xs={12} md={6}>
                <SecondaryButton
                  icon={<NotInterestedIcon />}
                  label={"Reject training request"}
                  alignment="left"
                  disabled={false}
                  onClick={() => handleSubmitTask("Rejected")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PrimaryButton
                  disabled={false}
                  alignment="left"
                  label={"Accept training request"}
                  icon={<CheckCircleOutlineIcon />}
                  onClick={() => handleSubmitTask("Accepted")}
                />
              </Grid>
            </>
            )}
        </Grid>
      </Container>
    </>
  ) : (
    <PageLoader />
  );
}

export default TrainingOptFor;
