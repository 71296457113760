import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import PageLoader from "../PageLoader";

StackedBarChart.propTypes = {
  chartOptions: PropTypes.object,
  chartData: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
  data: PropTypes.object,
};

export default function StackedBarChart(props) {
  const { t } = useTranslation();
  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (props.chartData && props.chartOptions) {
      setChartOptions(props.chartOptions);
      setChartData(props.chartData);
    }
  }, [props.chartOptions, props.chartData]);

  useEffect(() => {
    if (props.data && props?.demo === false) {
      let tempChartOptions = {
        chart: {
          type: "bar",
          // height: 350,
          stacked: true,
        },
        stroke: { colors: ["#FEFEFE"] },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: { enabled: false, dropShadow: { enabled: false } },
        xaxis: {
          categories: props?.data?.labels.map((entry) => {
            if (entry === null) return "Not Defined";
            return entry;
          }),
          labels: {
            trim: true,
            minHeight: 120,
            hideOverlappingLabels: false,
            showDuplicates: true,
          },
        },
      };
      let tempChartData = props?.data?.values.map((entry) => {
        if (entry === null) return 0;
        return entry;
      });
      setChartOptions(tempChartOptions);
      setChartData(tempChartData);
    } else if (props.chartOptions && props.chartData && !props.data) {
      setChartOptions(props.chartOptions);
      setChartData(props.chartData);
    } else if (props.blankLoading && !props.demo) {
    } else {
      let tempChartOptions = {
        chart: {
          type: "bar",
          height: 600,
          stacked: true,
        },
        stroke: { colors: ["#FEFEFE"] },
        dataLabels: { enabled: false, dropShadow: { enabled: false } },
        // xaxis: {
        //   // categories: ['Label 1','Label 2','Label3'],
        //   labels: {
        //     trim: true,
        //     minHeight: 120,
        //     hideOverlappingLabels: false,
        //     showDuplicates: true,
        //   }
        // },
      };
      let tempChartData = [
        {
          name: "EXAMPLE A",
          data: [44, 55, 41, 67, 22, 43],
        },
        {
          name: "EXAMPLE B",
          data: [13, 23, 20, 8, 13, 27],
        },
        {
          name: "EXAMPLE C",
          data: [11, 17, 15, 15, 21, 14],
        },
        {
          name: "EXAMPLE D",
          data: [21, 7, 25, 13, 22, 8],
        },
      ];
      setChartOptions(tempChartOptions);
      setChartData(tempChartData);
    }
  }, []);


  useEffect(()=>{
    if (props.data && props?.demo === false) {
      let tempChartOptions = {
        chart: {
          type: "bar",
          // height: 350,
          stacked: true,
        },
        stroke: { colors: ["#FEFEFE"] },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: { enabled: false, dropShadow: { enabled: false } },
        xaxis: {
          categories: props?.data?.labels.map((entry) => {
            if (entry === null) return "Not Defined";
            return entry;
          }),
          labels: {
            trim: true,
            minHeight: 120,
            hideOverlappingLabels: false,
            showDuplicates: true,
          },
        },
      };
      let tempChartData = props?.data?.values.map((entry) => {
        if (entry === null) return 0;
        return entry;
      });
      setChartOptions(tempChartOptions);
      setChartData(tempChartData);
    }
  }, [props.data])

  return (
    <>
      {!props?.demo ? (
        <>
          {chartOptions !== null ? (
            <div
              style={{
                height: props.height || "88%",
                width: "100%",
                direction: "ltr",
              }}
            >
              <ReactApexChart
                type="bar"
                options={chartOptions}
                series={chartData}
                height={props.height || "88%"}
                width={"100%"}
                stacked="true"
              />
            </div>
          ) : (
            <PageLoader />
          )}
        </>
      ) : (
        <>
          {chartOptions !== null ? (
            <div
              style={{
                height: props.height || "88%",
                width: "100%",
                direction: "ltr",
              }}
            >
              <ReactApexChart
                type="bar"
                options={chartOptions}
                series={chartData}
                height={props.height || "88%"}
                width={"100%"}
                stacked="true"
              />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
