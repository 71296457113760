import { accessList } from "../../utils/acl";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import MyTasksIcon from "@mui/icons-material/TaskOutlined";
import KnowledgeIcon from "@mui/icons-material/ManageSearch";
import ReportingIcon from "@mui/icons-material/SummarizeOutlined";
import BookIcon from "@mui/icons-material/Book";
import GavelIcon from "@mui/icons-material/Gavel";

// ----------------------------------------------------------------------

const iconStyle = {
  width: 22,
  height: 22,
};

const sidebarConfig = [
  {
    title: "sideBar.myTasks",
    path: "/tasks/list",
    icon: <MyTasksIcon style={iconStyle} />,
    ...accessList.myTasks,
  },
  {
    title: "sideBar.dashboards",
    path: "/dashboard/app",
    icon: <DashboardIcon sx={iconStyle} />,
    children: [
      {
        title: "sideBar.aiDashboard",
        path: "/dashboard/aiDashboards",
        ...accessList.aiDashboards,
      },
      {
        title: "sideBar.kpis",
        path: "/dashboard/kpis",
        ...accessList.kpis,
      },
      {
        title: "sideBar.observationsDashboard",
        path: "/dashboard/app/observations",
        ...accessList.observationDashboard,
      },
    ],
    ...accessList.dashboards,
  },
  {
    title: "sideBar.knowledgeBase",
    path: "/library/list",
    icon: <KnowledgeIcon style={iconStyle} />,
    ...accessList.library,
  },
  {
    title: "sideBar.observations",
    path: "/observations",
    icon: <HistoryEduIcon sx={iconStyle} />,
    children: [
      {
        title: "sideBar.viewObservations",
        path: "/observations/list",
        ...accessList.viewObservations,
      },
      {
        title: "sideBar.submitObservation",
        path: "/observations/submit",
        ...accessList.submitObservation,
      },
      {
        title: "sideBar.bulkUploadObservation",
        path: "/observations/bulk",
        ...accessList.bulkUploadObservation,
      },
      {
        title: "sideBar.observationCards",
        path: "/observations/card",
        ...accessList.observationCards,
      },
    ],
    ...accessList.observations,
  },
  {
    title: "sideBar.actions",
    path: "/actions/list",
    icon: <GavelIcon sx={iconStyle} />,
    ...accessList.actions,
  },
  {
    title: "sideBar.implementations",
    path: "/implementations",
    icon: <CoPresentIcon sx={iconStyle} />,
    children: [
      {
        title: "sideBar.viewImplementations",
        path: "/implementations/list",
        ...accessList.viewImplementations,
      },
      {
        title: "sideBar.createImplementation",
        path: "/implementations/new",
        ...accessList.createImplementation,
      },
    ],
    ...accessList.implementations,
  },
  {
    title: "sideBar.reporting",
    path: "/reports/create",
    icon: <ReportingIcon style={iconStyle} />,
    children: [
      {
        title: "reporting.createReport",
        path: "/reports/create",
        ...accessList.createReport,
      },
      {
        title: "reporting.reportbuilder",
        path: "/reports/creator",
        ...accessList.progressReports,
      },
      {
        title: "reporting.myReports",
        path: "/reports/scheduled",
        ...accessList.scheduleReport,
      },
    ],
    ...accessList.reporting,
  },
  {
    title: "sideBar.training",
    path: "/training/list",
    icon: <BookIcon style={iconStyle} />,
    ...accessList.training,
  },
];

export default sidebarConfig;
