import apiHelper from "./apiHelper";

export const getSpecialResponsibilitiesApi = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/roles/responsibilities/special`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
