import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import PageLoader from "../PageLoader";

LineChart.propTypes = {
  chartOptions: PropTypes.object,
  chartData: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
};

export default function LineChart(props) {
  const { t } = useTranslation();
  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (props.chartOptions) {
      setChartOptions(props.chartOptions);
      if (props.chartOptions.series) {
        setChartData(props.chartOptions.series);
      }
    }
  }, [props.chartOptions]);

  return (
    <>
      {!props?.demo ? (
        <>
          {chartOptions !== null ? (
            <div
              style={{
                height: props.height || "88%",
                width: "100%",
                direction: "ltr",
              }}
            >
              <ReactApexChart
                type="line"
                options={chartOptions}
                series={chartData}
                height={props.height || "88%"}
                width={"100%"}
              />
            </div>
          ) : (
            <PageLoader />
          )}
        </>
      ) : (
        <>
          {chartOptions !== null ? (
            <div
              style={{
                height: props.height || "88%",
                width: "100%",
                direction: "ltr",
              }}
            >
              <ReactApexChart
                type="line"
                options={chartOptions}
                series={chartData}
                height={props.height || "88%"}
                width={"100%"}
              />
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
