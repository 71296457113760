import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DefaultTable from "../../../components/tables/DefaultTable";
import { getReportScheduleWithoutType } from "../../../api/reports";
import PageLoader from "../../../components/PageLoader";
import { displayToast } from "../../../utils/general";
import moment from "moment";

// const constantReportNames = {
//     observation_feedback: "Observation Feedback",
//     observer_type: "Observer Type",
//     observation_summary: "Observation Summary",
//     complete_observation: "Complete Observation",
//     behavioral_trend: "Behavioral Trend",
//     unit_performance: "Unit Performance",
//     percent_knowledge_reports: "% Knowledge Reports"
// }

// const constantIntervals = {
//     daily: "Daily",
//     weekly: "Weekly",
//     monthly: "Monthly",
//     quarterly: "Quarterly",

// }

function ScheduleList() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const constantReportNames = {
    observation_feedback: t(
      `reporting.types.reportSubTypesDefault.observationFeedback`
    ),
    observer_type: t(`reporting.types.reportSubTypesDefault.observerType`),
    observation_summary: t(
      `reporting.types.reportSubTypesDefault.observationSummary`
    ),
    complete_observation: t(
      `reporting.types.reportSubTypesDefault.completeObservation`
    ),
    behavioral_trend: t(
      `reporting.types.reportSubTypesDefault.behavioralTrend`
    ),
    unit_performance: t(
      `reporting.types.reportSubTypesDefault.unitPerformance`
    ),
    percent_knowledge_reports: t(
      `reporting.types.reportSubTypesDefault.reportPercentKnowledge`
    ),
  };

  const constantIntervals = {
    daily: t(`reporting.timeIntervals.daily`),
    weekly: t(`reporting.timeIntervals.weekly`),
    monthly: t(`reporting.timeIntervals.monthly`),
    quarterly: t(`reporting.timeIntervals.quarterly`),
  };

  const [loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterParams, setFilterParams] = useState({
    pageNo: 1,
    pageSize: 10,
    type: "ALL",
  });

  const scheduleColumns = [
    {
      field: "id",
      headerName: t(`reporting.scheduleColumns.id`),
      width: 100,
      sortable: false,
    },
    {
      field: "reportType",
      headerName: t(`reporting.scheduleColumns.reportType`),
      width: 250,
      sortable: false,
    },
    {
      field: "timeInterval",
      headerName: t(`reporting.scheduleColumns.timeInterval`),
      width: 150,
      sortable: false,
    },
    {
      field: "startInterval",
      headerName: t(`reporting.scheduleColumns.startInterval`),
      width: 250,
      sortable: false,
    },
    {
      field: "nextDeliveryDate",
      headerName: t(`reporting.scheduleColumns.nextDeliveryDate`),
      width: 250,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: t(`reporting.scheduleColumns.createdAt`),
      width: 200,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: t(`reporting.scheduleColumns.modifiedAt`),
      width: 200,
      sortable: false,
    },
  ];

  const getSchedulesFunction = async () => {
    setLoading(true);
    try {
      let tempSchedules = await getReportScheduleWithoutType(
        filterParams?.page,
        filterParams.pageSize
      );

      tempSchedules?.rows?.forEach((entry) => {
        entry.reportType = constantReportNames[entry.reportType];
        entry.timeInterval = constantIntervals[entry.timeInterval];
        entry.startInterval = moment(entry.startInterval).format("MM/DD/YYYY");
        entry.nextDeliveryDate = moment(entry.nextDeliveryDate).format(
          "MM/DD/YYYY"
        );
        entry.createdAt = moment(entry.createdAt).format("MM/DD/YYYY");
        entry.updatedAt = moment(entry.updatedAt).format("MM/DD/YYYY");
      });

      setSchedules(tempSchedules?.rows);
      setTableInfo({
        totalCount: tempSchedules.count,
        totalPages: parseInt(tempSchedules.count / filterParams.pageSize) + 1,
      });
      setLoading(false);
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        "Error retrieving schedule list*",
        "errorLoading"
      );
    }
  };

  useEffect(() => {
    getSchedulesFunction();
  }, [filterParams]);

  return (
    <>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4">{t(`reporting.scheduleTitle`)}</Typography>
          </div>
        </Box>
        <Grid
          style={{ paddingTop: "50px", paddingLeft: "45px" }}
          container
          spacing={4}
        >
          <DefaultTable
            rows={schedules}
            columns={scheduleColumns}
            loading={loading}
            filterParams={filterParams}
            setFilterParams={(e) => {
              if (
                !(e.page === filterParams.page) ||
                !(e.pageSize === filterParams.pageSize)
              ) {
                setFilterParams(e);
              }
            }}
            tableInfo={tableInfo}
            onRowClick={(p) => {
              console.log("ShowNavigation", p.row.id);
              navigate(`/reports/details/${p.row.id}`);
            }}
          />
        </Grid>
      </Container>
    </>
  );
}

export default ScheduleList;
