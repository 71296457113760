import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { displayToast } from "../../utils/general";
//  material
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Grid,
  Container,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  Rating,
} from "@mui/material";
//   components
import Page from "../../components/Page";
import HeaderBackButton from "../../components/buttons/HeaderBackButton";
import SelectInput from "../../components/inputs/SelectInput";
import FormDivider from "../../components/FormDivider";
import TextInput from "../../components/inputs/TextInput";
import DateInput from "../../components/inputs/DateInput";
import FileUploader from "../../components/inputs/FileUploader";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import RadioSet from "../../components/buttons/RadioSet";
import PageLoader from "../../components/PageLoader";
//  api
import {
  getObservationDetailsApi,
  assignObservationApi,
} from "../../api/observation";
import { getRootCausesApi } from "../../api/rootCauses";
import { searchUsersApi } from "../../api/users";

const actionDetailsDefault = {
  actionRequired: "",
  actionParty: "",
  approver: "",
  targetDate: new Date(),
  rootCauseId: 1,
  priority: "LOW",
  comment: "",
  actionPerformed: false,
};

const appreciationDetailsDefault = {
  appreciationRequired: false,
  appreciation: 0,
  lessonLearnt: false,
  lessonSummary: "",
  shareLessonWith: null,
  helpText: "",
};

export default function AssignToParty() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const timeout = useRef();

  const [loading, setLoading] = useState(true);
  const [acknowledged, setAcknowledged] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [userDropdown, setUserDropdown] = useState([]);
  const [details, setDetails] = useState(null);
  const [rootCauses, setRootCauses] = useState(null);
  const [mainRoots, setMainRoots] = useState([]);
  const [subRoots, setSubRoots] = useState([]);

  const [selectedMainRoot, setSelectedMainRoot] = useState("");
  const [selectedSubRoot, setSelectedSubRoot] = useState(null);
  const [appreciationDetails, setAppreciationDetails] = useState(
    appreciationDetailsDefault
  );
  const [actionDetails, setActionDetails] = useState(actionDetailsDefault);
  const [files, setFiles] = useState([]);

  const [shareLessonWith, setShareLessonWith] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  const handlePriorityChange = (event) => {
    let finalTargetDate = moment().toDate();
    const prioritySelected = event.target.value;

    if (prioritySelected === "LOW") {
      finalTargetDate = moment().add(1, "month").toDate();
    } else if (prioritySelected === "MEDIUM") {
      finalTargetDate = moment().add(1, "week").toDate();
    } else {
      finalTargetDate = moment().add(1, "day").toDate();
    }
    //  Set priority and target date update based on priority
    setActionDetails({
      ...actionDetails,
      priority: event.target.value,
      targetDate: finalTargetDate,
    });
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1, undefined, true);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const handleRatingHover = (h) => {
    const finalValue = parseFloat(h);
    let finalText = "";

    if (finalValue === 1) {
      finalText = t("observations.observationDetails.hoverText.one");
    } else if (finalValue === 2) {
      finalText = t("observations.observationDetails.hoverText.two");
    } else if (finalValue === 3) {
      finalText = t("observations.observationDetails.hoverText.three");
    } else if (finalValue === 4) {
      finalText = t("observations.observationDetails.hoverText.four");
    } else if (finalValue === 5) {
      finalText = t("observations.observationDetails.hoverText.five");
    } else {
      finalText = "";
    }
    setAppreciationDetails({ ...appreciationDetails, helpText: finalText });
  };

  const submitAssignObservation = async () => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("observations.observationDetails.assignLoading")
    );

    try {
      if (!selectedMainRoot) {
        throw t("actions.assignToParty.mainRootError");
      }

      if (!selectedSubRoot) {
        throw t("actions.assignToParty.subRootError");
      }

      if (
        appreciationDetails.lessonLearnt &&
        shareLessonWith === "Users" &&
        !appreciationDetails.userIds
      ) {
        throw t("actions.assignToParty.shareWithUserError");
      }

      if (
        appreciationDetails.appreciationRequired &&
        appreciationDetails.appreciation === 0
      ) {
        throw t("actions.assignToParty.appreciationError");
      }

      if (!actionDetails.actionRequired) {
        throw t("actions.assignToParty.actionRequiredError");
      }

      if (!actionDetails.actionParty) {
        throw t("actions.assignToParty.actionPartyError");
      }

      if (!acknowledged) {
        throw t("actions.assignToParty.acknowledgedError");
      }

      let tempUserIds = [];
      appreciationDetails?.userIds?.length > 0 &&
        appreciationDetails.userIds.forEach((entry) =>
          tempUserIds.push(parseInt(entry.id))
        );
      const payload = {
        actionRequired: actionDetails.actionRequired,
        actionParty: actionDetails.actionParty,
        approver: actionDetails.approver,
        targetDate: moment(actionDetails.targetDate).format("YYYY-MM-DD"),
        appreciation: appreciationDetails.appreciation,
        lessonLearnt: appreciationDetails.lessonLearnt,
        rootCauseId: selectedSubRoot?.value,
        attachments: files,
        priority: actionDetails.priority,
        comment: actionDetails.comment,
        shareLessonWith,
        companyId: details.companyId,
        unitId: details.unitId,
        lessonDescription: appreciationDetails.lessonSummary
          ? appreciationDetails.lessonSummary
          : null,
        userIds: [...tempUserIds],
        sendEmail,
        actionPerformed: actionDetails.actionPerformed,
      };
      await assignObservationApi(payload, id);
      displayToast(
        setLoading,
        "successLoading",
        t("observations.observationDetails.assignSuccess"),
        activeToast
      );
      setTimeout(() => {
        navigate("/observations/list");
      }, 2000);
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    }
  };

  //    Get initial data
  useEffect(() => {
    const handleGetDetails = async () => {
      setLoading(true);
      try {
        const detailsData = await getObservationDetailsApi(id);
        setDetails(detailsData);

        const rootCauseData = await getRootCausesApi();
        //  Put root cause data into dropdowns.
        setRootCauses(rootCauseData);
        const primaryList = Object.keys(rootCauseData);
        const firstDropdown = primaryList.map((mr) => {
          return {
            label: mr,
            value: mr,
          };
        });
        setMainRoots(firstDropdown);
        if (primaryList.length > 0) {
          const secondDropdown = rootCauseData[primaryList[0]].map((sc) => {
            return {
              label: sc.subCategory,
              value: sc.id,
            };
          });
          setSubRoots(secondDropdown);
        }

        setLoading(false);
      } catch (err) {
        displayToast(setLoading, "error", err);
        console.log(err);
      }
    };
    handleGetDetails();
  }, [id]);

  //  Change sub root options on main root change
  useEffect(() => {
    if (selectedMainRoot && rootCauses) {
      setSelectedSubRoot(null);
      const secondDropdown = rootCauses[selectedMainRoot].map((sc) => {
        return {
          label: sc.subCategory,
          value: sc.id,
        };
      });
      setSubRoots(secondDropdown);
    }
  }, [selectedMainRoot, rootCauses]);

  return (
    <Page title={t("observations.observationDetails.assignTitle")}>
      <Container maxWidth="xl">
        {details ? (
          <>
            <Box sx={{ pb: 2 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <HeaderBackButton />
                <Typography variant="h4">
                  {t("observations.observationDetails.assignTitle")}
                </Typography>
              </div>
            </Box>
            <Grid item xs={12}>
              <Typography variant="h5">{details.observationId}</Typography>
              <Typography
                variant="h6"
                style={{
                  marginTop: "12px",
                  marginBottom: "12px",
                  color: "#7D7D7D",
                  textShadow: "none",
                }}
              >
                Submitted by{" "}
                {/* {`${details.observer.firstName} ${details.observer.lastName} (${details.observerType})`}{" "} */}
                {`${details.observer.firstName} ${details.observer.lastName}`}{" "}
                on {moment(details.observedAt).format("DD/MM/YY  hh:mm")}
              </Typography>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <AutocompleteInput
                  handler={(e, v) =>
                    v && v.label
                      ? setSelectedMainRoot(v.label)
                      : setSelectedMainRoot(null)
                  }
                  label={t("observations.observationDetails.rootCause")}
                  disabled={loading}
                  options={mainRoots}
                  value={
                    selectedMainRoot
                      ? {
                          label: selectedMainRoot,
                          value: selectedMainRoot,
                        }
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <AutocompleteInput
                  handler={(e, v) =>
                    v ? setSelectedSubRoot(v) : setSelectedSubRoot(null)
                  }
                  label={t("observations.observationDetails.rootSub")}
                  disabled={loading}
                  options={subRoots}
                  value={selectedSubRoot}
                />
              </Grid>
              <Grid item xs={12}>
                <FormDivider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t("observations.observationDetails.lessonsAppreciation")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={8}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={appreciationDetails.appreciationRequired}
                        name={"appreciationRequired"}
                        onChange={() =>
                          setAppreciationDetails({
                            ...appreciationDetails,
                            appreciation: 0,
                            helpText: "",
                            appreciationRequired:
                              !appreciationDetails.appreciationRequired,
                          })
                        }
                        sx={{
                          color: theme.palette.primary.orange,
                          "&.Mui-checked": {
                            color: theme.palette.primary.orange,
                          },
                          "&:hover": {
                            backgroundColor: "rgba(248,147,31,0.08)",
                          },
                        }}
                      />
                    }
                    disabled={loading}
                    label={t(
                      "observations.observationDetails.appreciationRequired"
                    )}
                  />
                  <Rating
                    name="appreciation-rating"
                    defaultValue={0}
                    precision={1}
                    disabled={!appreciationDetails.appreciationRequired}
                    value={appreciationDetails.appreciation}
                    onChangeActive={(e, h) => handleRatingHover(h)}
                    onChange={(e) =>
                      setAppreciationDetails({
                        ...appreciationDetails,
                        appreciation: parseFloat(e.target.value),
                      })
                    }
                  />
                  <Typography variant="body1">
                    {appreciationDetails.helpText}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="column" spacing={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={appreciationDetails.lessonLearnt}
                        name={"lessonLearnt"}
                        onChange={() => {
                          setShareLessonWith(false);
                          setAppreciationDetails({
                            ...appreciationDetails,
                            lessonLearnt: !appreciationDetails.lessonLearnt,
                          });
                        }}
                        sx={{
                          color: theme.palette.primary.orange,
                          "&.Mui-checked": {
                            color: theme.palette.primary.orange,
                          },
                          "&:hover": {
                            backgroundColor: "rgba(248,147,31,0.08)",
                          },
                        }}
                      />
                    }
                    disabled={loading}
                    label={t("observations.observationDetails.lessonLearnt")}
                  />
                  {appreciationDetails.lessonLearnt && (
                    <>
                      <TextInput
                        label={t(
                          "observations.observationDetails.summaryLesson"
                        )}
                        value={appreciationDetails.lessonSummary}
                        disabled={loading || !appreciationDetails.lessonLearnt}
                        handler={(e) =>
                          setAppreciationDetails({
                            ...appreciationDetails,
                            lessonSummary: e.target.value,
                          })
                        }
                      />
                      <RadioSet
                        label={t("observations.observationDetails.shareLesson")}
                        disabled={loading}
                        value={shareLessonWith}
                        handleChange={(e, v) => {
                          setShareLessonWith(v);
                        }}
                        options={[
                          {
                            label: t("profile.company"),
                            value: "Company",
                          },
                          {
                            label: t("profile.unit"),
                            value: "Unit",
                          },
                          {
                            label: t("general.selectedIndividuals"),
                            value: "Users",
                          },
                        ]}
                      />

                      {shareLessonWith === "Users" && (
                        <AutocompleteInput
                          multiple
                          handler={(e, v) =>
                            v.length > 0
                              ? setAppreciationDetails({
                                  ...appreciationDetails,
                                  userIds: v,
                                })
                              : setAppreciationDetails({
                                  ...appreciationDetails,
                                  userIds: [],
                                })
                          }
                          keyPressHandler={handleSearchUser}
                          label={t(
                            "observations.observationDetails.shareLesson"
                          )}
                          loading={searchLoading}
                          disabled={loading}
                          options={userDropdown}
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendEmail}
                            name={"actionPerformed"}
                            onChange={() => {
                              setSendEmail(!sendEmail);
                            }}
                            sx={{
                              color: theme.palette.primary.orange,
                              "&.Mui-checked": {
                                color: theme.palette.primary.orange,
                              },
                              "&:hover": {
                                backgroundColor: "rgba(248,147,31,0.08)",
                              },
                            }}
                          />
                        }
                        disabled={loading}
                        label={t("observations.observationDetails.sendEmail")}
                      />
                    </>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <FormDivider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t("observations.observationDetails.actionItem")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="column" spacing={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={actionDetails.actionPerformed}
                        name={"actionPerformed"}
                        onChange={() => {
                          setActionDetails({
                            ...actionDetails,
                            actionPerformed: !actionDetails.actionPerformed,
                          });
                        }}
                        sx={{
                          color: theme.palette.primary.orange,
                          "&.Mui-checked": {
                            color: theme.palette.primary.orange,
                          },
                          "&:hover": {
                            backgroundColor: "rgba(248,147,31,0.08)",
                          },
                        }}
                      />
                    }
                    disabled={loading}
                    label={t("observations.observationDetails.actionPerformed")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  label={
                    actionDetails.actionPerformed
                      ? t(`observations.observationDetails.actionIsPerformed`)
                      : t("observations.observationDetails.actionToPerform")
                  }
                  value={actionDetails.actionRequired}
                  disabled={loading}
                  handler={(e) => {
                    setActionDetails({
                      ...actionDetails,
                      actionRequired: e.target.value,
                    });
                    setAcknowledged(actionDetails.actionPerformed);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <AutocompleteInput
                  handler={(e, v) =>
                    v && v.id
                      ? setActionDetails({
                          ...actionDetails,
                          actionParty: v.id,
                          approver: v.id,
                        })
                      : setActionDetails({
                          ...actionDetails,
                          actionParty: "",
                          approver: "",
                        })
                  }
                  keyPressHandler={handleSearchUser}
                  label={t("observations.observationDetails.actionParty")}
                  loading={searchLoading}
                  disabled={loading}
                  options={userDropdown}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <SelectInput
                  label={t("actions.assignToParty.priority")}
                  value={actionDetails.priority}
                  disabled={loading}
                  handler={handlePriorityChange}
                  helpText={t("actions.assignToParty.priorityHelpText")}
                  options={[
                    {
                      label: t("actions.assignToParty.lowPriority"),
                      value: "LOW",
                    },
                    {
                      label: t("actions.assignToParty.mediumPriority"),
                      value: "MEDIUM",
                    },
                    {
                      label: t("actions.assignToParty.highPriority"),
                      value: "HIGH",
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <DateInput
                  label={
                    actionDetails.actionPerformed
                      ? t(`observations.observationDetails.actionPerformedDate`)
                      : t("observations.observationDetails.targetDate")
                  }
                  date={actionDetails.targetDate}
                  disabled={loading}
                  handler={(newDate) => {
                    setActionDetails({
                      ...actionDetails,
                      targetDate: newDate,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput
                  label={t("observations.observationDetails.assignToComment")}
                  value={actionDetails.comment}
                  disabled={loading}
                  handler={(e) =>
                    setActionDetails({
                      ...actionDetails,
                      comment: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <FileUploader
                  files={files}
                  setFiles={setFiles}
                  disabled={loading}
                />
              </Grid>
              {!actionDetails.actionPerformed ? (
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={acknowledged}
                        name={"appreciationRequired"}
                        onChange={() => setAcknowledged(!acknowledged)}
                        sx={{
                          color: theme.palette.primary.orange,
                          "&.Mui-checked": {
                            color: theme.palette.primary.orange,
                          },
                          "&:hover": {
                            backgroundColor: "rgba(248,147,31,0.08)",
                          },
                        }}
                      />
                    }
                    disabled={loading || actionDetails.actionPerformed}
                    label={t("observations.observationDetails.acknowledgement")}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <PrimaryButton
                  label={t("general.submit")}
                  alignment="left"
                  onClick={submitAssignObservation}
                  disabled={
                    loading ||
                    !acknowledged ||
                    !selectedMainRoot ||
                    !selectedSubRoot ||
                    !actionDetails.actionParty ||
                    !actionDetails.actionRequired
                  }
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <PageLoader />
        )}
      </Container>
    </Page>
  );
}
