import PropTypes from "prop-types";
import React, { useState, useRef, useMemo } from "react";
import moment from "moment";
import toast from "react-hot-toast";
import { jsPDF } from "jspdf";
import { toPng } from "html-to-image";
import { useTranslation } from "react-i18next";
import { parseChartData } from "./utilsReport";
import "./reporting.css";
//  Components
import {
  Grid,
  Container,
  Typography,
  Stack,
  Box,
  Tooltip,
  Menu,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import Page from "../../components/Page";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import LineChart from "../../components/charts/LineChart";
import StackedBarChart from "../../components/charts/StackedBarChart";
import BarChart from "../../components/charts/BarChart";
import TableChart from "../../components/charts/TableChart";
//  Chart Options
import {
  stackedBarOptions,
  barChartOptions,
  lineChartOptions,
} from "./chartDefaults";
//  Table Options
import { columnDefaults } from "./tableDefaults";
import { displayToast, toTitleCase } from "../../utils/general";
import InfoBox from "../../components/InfoBox";
import SelectInput from "../../components/inputs/SelectInput";
import DateInput from "../../components/inputs/DateInput";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
import { searchUsersApi } from "../../api/users";
import { DeleteOutline } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import { setReportSchedule } from "../../api/reports";
import ScheduleReportIcon from "@mui/icons-material/EventRepeat";
import DownloadReportIcon from "@mui/icons-material/SystemUpdateAltOutlined";
import { utils, writeFile } from "xlsx";

ViewReport.propTypes = {
  chartInfo: PropTypes.object.isRequired,
};

const scheduleModalDefaults = {
  open: false,
  type: "h2",
  text: "Schedule Report",
};

const reportDetailsModalDefaults = {
  open: false,
  type: "h2",
  text: "Report Details",
};

const verifierModalDefault = {
  open: false,
  type: "add",
  users: [],
  verifierNames: "",
  disciplineId: null,
  locationId: null,
  unitId: null,
  disciplineName: "",
  locationName: "",
  unitName: "",
};

const BRow = styled("div")(() => ({
  minHeight: "50px",
  borderRadius: "6px",
  border: `1px solid #E0E0E0`,
  padding: "12px",
  marginBottom: "6px",
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
}));

const blackListedColumns = [
  "Action Assigned - Safe",
  "Marked As Near Miss - Safe",
  "Observation Closed Without Action - Safe",
  "Pending - Safe",
  "Rejected - Safe",
];

export default function ViewReport({
  companyNumber,
  chartInfo,
  reportType,
  fromDate,
  toDate,
  businessUnits,
  directorates,
  locations,
  units,
  disciplines,
  contractorCompanies,
  includeActionItem,
  includeChild,
  cardOwner,
  timeIntervalCreate = {},
  behaviours,
  behaviourTypes,
  observationLinkedTo,
  subType,
  lsrType,
  rootCause,
  groupBy,
  observerType,
  byPassedNonBypassed,
  cardStatus,
}) {
  const { t } = useTranslation();
  const timeout = useRef();
  const theme = useTheme();
  const today = moment().format("DD-MM-YY");
  const pagesRef = useRef([]);
  const [loading, setLoading] = useState(true);
  const [scheduleModal, setScheduleModal] = useState(scheduleModalDefaults);
  const [reportDetailsModal, setReportDetailsModal] = useState({
    ...reportDetailsModalDefaults,
    text: chartInfo.title,
  });
  const [scheduleDate, setScheduleDate] = useState(moment());
  const [timeInterval, setTimeInterval] = useState([
    { label: "Daily", value: "daily" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Quarterly", value: "quarterly" },
  ]);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState("daily");
  const [userDropdown, setUserDropdown] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [verifierModal, setVerifierModal] = useState(verifierModalDefault);
  const [confirmModal, setConfirmModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadModal, setDownloadModal] = useState(false);

  //  Disabled til MVP2
  // useEffect(() => {
  //   getReportSchedule(reportType)
  //     .then((res) => {
  //       // console.log(res)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // });

  const toastLoaded = (message) => {
    toast.remove();
    toast.success(message);
    setLoading(false);
  };

  const handleDownloadMenuClose = () => {
    setAnchorEl(null);
  };

  const returnGroupByText = () => {
    const groupedValue = groupBy?.value || "";
    switch (groupedValue) {
      case "directorate":
        const dNames = directorates.map((d) => d.label);
        return dNames.join(", ");

      case "company":
        const cNames = contractorCompanies.map((c) => c.label);
        return cNames.join(", ");

      case "location":
        const lNames = locations.map((l) => l.label);
        return lNames.join(",");

      case "unit":
        const uNames = units.map((u) => u.label);
        return uNames.join(", ");

      case "discipline":
        const discNames = disciplines.map((d) => d.label);
        return discNames.join(", ");

      default:
        return "Not found.";
    }
  };

  const renderHeader = () => (
    <Grid item xs={12} style={{ height: "10%", paddingTop: "16px" }}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            component="img"
            src="/static/ihtimam_pdo_logo.svg"
            style={{ height: "80px", width: "180px", marginRight: "20px" }}
          />
          <Stack>
            <Typography variant="body2">
              From {fromDate} to {toDate}
            </Typography>
            <Typography variant="body2">{`Grouped By: ${
              groupBy.label ? toTitleCase(groupBy.label) : "Not found."
            }`}</Typography>
            <Typography variant="body2">{`${toTitleCase(
              groupBy.label
            )}(s): ${returnGroupByText()}`}</Typography>
          </Stack>
        </Stack>
        <Typography variant="h5" pt={2}>
          {chartInfo.title}
        </Typography>
      </Stack>
    </Grid>
  );

  const renderFooter = (index, total) => (
    <Grid item xs={12}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ height: "50%" }}
      >
        <Typography variant="body2">
          {companyNumber} on {moment(new Date()).format("MM-DD-YYYY H:mm")}
        </Typography>
        <Typography variant="body2">Petroleum Development Oman</Typography>
        <Typography variant="body2">{`Page ${
          index + 1
        } of ${total}`}</Typography>
      </Stack>
    </Grid>
  );

  const renderChart = ({ type, chartOptions, chartData }) => {
    let chart;
    switch (type) {
      case "stackedBar":
        chart = (
          <StackedBarChart
            height="95%"
            chartData={chartData}
            chartOptions={chartOptions}
          />
        );
        break;

      case "barChart":
        chart = (
          <BarChart
            height="100%"
            blankLoading={true}
            chartData={chartData}
            chartOptions={chartOptions}
          />
        );
        break;

      case "lineChart":
        chart = (
          <LineChart
            height="100%"
            blankLoading={true}
            chartData={chartData}
            chartOptions={chartOptions}
          />
        );
        break;
      default:
        chart = "Unknown chart type";
        break;
    }

    return (
      <Grid item xs={12} style={{ height: "100%" }}>
        {chart}
      </Grid>
    );
  };

  const reportDataChunks = (array, chunk_size) => {
    const array_chunks = (array, chunk_size) =>
      Array(Math.ceil(array.length / chunk_size))
        .fill()
        .map((_, index) => index * chunk_size)
        .map((begin) => array.slice(begin, begin + chunk_size));
    const chunkedRows = array_chunks(array, chunk_size);
    return { arrayLength: chunkedRows.length, data: chunkedRows };
  };

  //  Please refer to columnDefaults and make sure to add there when adding a new supported report
  const renderTable = (data, customColumns) => {
    if (data && data?.length > 0 && chartInfo.type) {
      const tableRows = data;
      return (
        <div style={{ border: "1px solid #1C1C1C" }}>
          <TableChart
            columns={
              customColumns ? customColumns : columnDefaults[chartInfo.type]
            }
            rows={tableRows}
            filterParams={{ pageSize: 9999999999, pageNo: 1 }}
            tableInfo={{ totalPages: 1, totalCount: 10 }}
            hidePagination
            customFontSize="16px"
          />
        </div>
      );
    } else {
      return (
        <Grid item xs={12}>
          Unable to get table data
        </Grid>
      );
    }
  };

  const reportPage = (
    children,
    indexOfPage,
    totalNumberOfPages,
    numberOfChartsInPage
  ) => {
    const singleChart = () => {
      return (
        <Grid item xs={12} style={{ height: "60%" }}>
          {children}
        </Grid>
      );
    };

    const multiChart = () => {
      return children.map((child, index) => {
        return (
          <Grid
            item
            xs={12}
            style={{
              height: `calc(100vh / ${numberOfChartsInPage})`,
              width: "100%",
            }}
            key={index}
          >
            {child}
          </Grid>
        );
      });
    };
    return (
      <Grid
        container
        ref={(el) => (pagesRef.current[indexOfPage] = el)}
        style={{ pageBreakAfter: "always", height: "297mm" }}
      >
        {renderHeader()}
        {Array.isArray(children) ? multiChart() : singleChart()}
        {renderFooter(indexOfPage, totalNumberOfPages)}
      </Grid>
    );
  };

  const renderReport = useMemo(() => {
    if (!chartInfo && !chartInfo.type && !chartInfo.tableData) {
      return "No chart data.";
    } else {
      console.log("rendering report...");

      //  Generating table data
      const tableRows = chartInfo?.tableData;
      //  Decrease second param below if character count is too long
      let pageSize = 15;
      if (chartInfo.type === "behavioral_trend") {
        pageSize = 10;
      }
      const tableChunkData = reportDataChunks(tableRows, pageSize);
      switch (chartInfo.type) {
        case "observation_feedback":
          const ofCategories = chartInfo?.chartData?.categories;
          const ofData = chartInfo?.chartData?.data;
          const ofName = chartInfo?.chartData?.name;
          return (
            <>
              {reportPage(
                renderChart({
                  type: "stackedBar",
                  chartOptions: {
                    ...stackedBarOptions,
                    chart: {
                      ...stackedBarOptions.chart,
                      animations: {
                        enabled: false,
                      },
                    },
                    title: {
                      text: ofName,
                      align: "center",
                      floating: true,
                    },
                    xaxis: {
                      ...stackedBarOptions.xaxis,
                      categories: ofCategories,
                    },
                  },
                  chartData: ofData,
                }),
                0,
                1 + tableChunkData.arrayLength,
                1
              )}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      { field: "buName", headerName: groupBy, width: 200 },
                      {
                        field: "feedbackCards",
                        headerName: "Feedback Cards",
                        width: 200,
                      },
                      {
                        field: "feedbackCardsPercent",
                        headerName: "Feedback Cards %",
                        width: 200,
                      },
                      { field: "total", headerName: "Total", width: 200 },
                    ]),
                    index + 1,
                    1 + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        case "total_observation_report":
          return "WIP";

        case "observer_type":
          const otCategories = chartInfo?.chartData?.categories;
          const otData = chartInfo?.chartData?.data;
          const otGroups = chartInfo?.chartData?.groups;
          const otName = chartInfo?.chartData?.name;
          return (
            <>
              {reportPage(
                renderChart({
                  type: "barChart",
                  chartOptions: {
                    ...barChartOptions,
                    chart: {
                      ...barChartOptions.chart,
                      animations: {
                        enabled: false,
                      },
                    },
                    colors: [
                      "#ffd700",
                      "#1e90ff",
                      "#008000",
                      ...barChartOptions.colors,
                    ],
                    title: {
                      text: otName,
                      align: "center",
                      floating: true,
                    },
                    xaxis: {
                      ...barChartOptions.xaxis,
                      categories: otCategories,
                      group: {
                        style: {
                          fontSize: "10px",
                          fontWeight: 700,
                        },
                        // groups: otGroups,
                      },
                    },
                    yaxis: {
                      ...barChartOptions.yaxis,
                      forceNiceScale: true,
                      floating: false,
                      decimalsInFloat: 0,
                      title: {
                        text: "Count",
                      },
                    },
                    plotOptions: {
                      bar: {
                        distributed: false,
                        columnWidth: "40%",
                        dataLabels: {
                          position: "top",
                        },
                      },
                    },
                  },
                  chartData: otData,
                }),
                0,
                1 + tableChunkData.arrayLength,
                1
              )}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk),
                    index + 1,
                    1 + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        case "observation_summary":
          const osChartData = chartInfo?.chartData || [];

          return (
            <>
              {osChartData &&
                osChartData.length > 0 &&
                osChartData.map((chrt, chrtIndex) => {
                  const chrtName = chrt.name;
                  const chrtCategories = [];
                  const chrtData = chrt.data;
                  const chrtSeries = [];
                  const chrtGrouping = [];
                  let colLength = 0;

                  const dataArr = [];
                  chrt.categories.forEach((cat, index) => {
                    if (!blackListedColumns.includes(cat)) {
                      dataArr.push({
                        x: cat,
                        y: chrtData[0].data[index],
                      });
                      chrtCategories.push(cat);
                    }
                  });

                  chrtSeries.push({
                    name: "Count",
                    data: dataArr,
                  });

                  if (chrt.groups) {
                    chrt.groups.forEach((group, index) => {
                      if (index === 0) {
                        colLength = group.cols;
                      }
                      chrtGrouping.push({
                        ...group,
                        cols: group.cols,
                      });
                    });
                  }

                  return reportPage(
                    renderChart({
                      type: "barChart",
                      chartOptions: {
                        ...barChartOptions,
                        chart: {
                          ...barChartOptions.chart,
                          animations: {
                            enabled: false,
                          },
                        },
                        title: {
                          text: chrtName,
                          align: "center",
                          floating: true,
                        },
                        colors: [
                          function ({ seriesIndex }) {
                            if (
                              seriesIndex % colLength === 0 ||
                              seriesIndex === 0
                            ) {
                              return "#32cd32";
                            } else {
                              return "#ff0000";
                            }
                          },
                        ],
                        plotOptions: {
                          bar: {
                            distributed: true,
                            dataLabels: {
                              position: "top",
                            },
                          },
                          dataLabels: {
                            enabled: true,
                            offsetY: 50,
                            offsetX: 30,
                          },
                        },
                        legend: {
                          show: false,
                        },
                        xaxis: {
                          ...barChartOptions.xaxis,
                          labels: {
                            rotate: -90,
                            trim: false,
                            minHeight: chrtGrouping ? 500 : 240,
                          },
                          ...(chrtGrouping
                            ? {
                                group: {
                                  style: {
                                    fontSize: "10px",
                                    fontWeight: 700,
                                    cssClass: "groupXLabels",
                                  },
                                  groups: chrtGrouping,
                                },
                                axisTicks: {
                                  show:
                                    chrtGrouping && chrtGrouping.length <= 1
                                      ? false
                                      : true,
                                },
                              }
                            : { categories: chrtCategories }),
                        },
                        yaxis: {
                          ...barChartOptions.yaxis,
                          forceNiceScale: true,
                          floating: false,
                          decimalsInFloat: 0,
                          title: {
                            text: "Count",
                          },
                        },
                      },
                      chartData: chrtSeries,
                    }),
                    chrtIndex,
                    osChartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "BusinessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      {
                        field: "cardStatus",
                        headerName: "Card Status",
                        width: 200,
                      },
                      {
                        field: "Safe/Unsafe",
                        headerName: "Safe/Unsafe",
                        width: 200,
                      },
                      { field: "Total", headerName: "Total", width: 200 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "intervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    osChartData.length + index,
                    osChartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        //   Converted Complete Observation to key value object per chart instead of array
        case "complete_observation":
          let coPageCount = 0;
          const coCardCountData = chartInfo?.chartData?.cardCount || null;
          const coObsCategorization =
            chartInfo?.chartData?.observationCategorization || null;
          const coSafe = chartInfo?.chartData?.safe || null;
          const coSafeBehaviors = chartInfo?.chartData?.safeBehaviors || [];
          const coSafeUnsafe = chartInfo?.chartData?.safeunsafe || null;
          const coUnsafe = chartInfo?.chartData?.unsafe || null;
          const coUnsafeBehaviors = chartInfo?.chartData?.unsafeBehaviors || [];
          const coUnsafeOtherBehaviors =
            chartInfo?.chartData?.unsafeOtherBehaviors || [];

          const totalChartPages =
            6 +
            coSafeBehaviors.length +
            coUnsafeBehaviors.length +
            coUnsafeOtherBehaviors.length;

          return (
            <>
              {/* Card Count */}
              {coCardCountData &&
                reportPage(
                  renderChart({
                    type: "barChart",
                    chartOptions: {
                      ...barChartOptions,
                      chart: {
                        ...barChartOptions.chart,
                        animations: {
                          enabled: false,
                        },
                      },
                      colors: ["#418cf0"],
                      legend: {
                        show: false,
                      },
                      title: {
                        text: coCardCountData.name,
                        align: "center",
                        floating: true,
                      },
                      xaxis: {
                        ...barChartOptions.xaxis,
                        categories: coCardCountData.categories,
                        ...(coCardCountData.groups && {
                          labels: {
                            rotateAlways: true,
                            rotate: -90,
                            trim: true,
                            minHeight: 260,
                          },
                          group: {
                            style: {
                              fontSize: "10px",
                              fontWeight: 700,
                              cssClass: "groupXLabels",
                            },
                            groups: coCardCountData.groups,
                          },
                          axisTicks: {
                            show: true,
                          },
                        }),
                      },
                      yaxis: {
                        ...barChartOptions.yaxis,
                        forceNiceScale: true,
                        floating: false,
                        decimalsInFloat: 0,
                        title: {
                          text: "Count",
                        },
                      },
                    },
                    chartData: coCardCountData.data,
                  }),
                  coPageCount++,
                  totalChartPages + tableChunkData.arrayLength - 1,
                  1
                )}
              {/* Observation Categorization */}
              {coObsCategorization &&
                reportPage(
                  renderChart({
                    type: "barChart",
                    chartOptions: {
                      ...barChartOptions,
                      chart: {
                        ...barChartOptions.chart,
                        stacked: true,
                        animations: {
                          enabled: false,
                        },
                      },
                      colors: ["#EDE15B", "#bdcf32", "#FF5454"],
                      legend: {
                        show: true,
                      },
                      title: {
                        text: coObsCategorization.name,
                        align: "center",
                        floating: true,
                      },
                      xaxis: {
                        ...barChartOptions.xaxis,
                        categories: coObsCategorization.categories,
                        ...(coObsCategorization.groups && {
                          labels: {
                            rotateAlways: true,
                            rotate: -90,
                            trim: true,
                            minHeight: 260,
                          },
                          group: {
                            style: {
                              fontSize: "10px",
                              fontWeight: 700,
                              cssClass: "groupXLabels",
                            },
                            groups: coObsCategorization.groups,
                          },
                          axisTicks: {
                            show: true,
                          },
                        }),
                      },
                      yaxis: {
                        ...barChartOptions.yaxis,
                        forceNiceScale: true,
                        floating: false,
                        decimalsInFloat: 0,
                        title: {
                          text: "Count",
                        },
                      },
                      plotOptions: {
                        bar: {
                          ...barChartOptions.plotOptions.bar,
                          columnWidth: "40%",
                          distributed: false,
                          dataLabels: {
                            position: "center",
                            hideOverflowingLabels: false,
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        dropShadow: { enabled: false },
                        style: {
                          colors: ["#1C1C1C"],
                        },
                        textAnchor: "middle",
                      },
                    },
                    chartData: coObsCategorization.data,
                  }),
                  coPageCount++,
                  totalChartPages + tableChunkData.arrayLength - 1,
                  1
                )}
              {/* Safe */}
              {coSafe &&
                reportPage(
                  renderChart({
                    type: "barChart",
                    chartOptions: {
                      ...barChartOptions,
                      chart: {
                        ...barChartOptions.chart,
                        stacked: true,
                        animations: {
                          enabled: false,
                        },
                      },
                      colors: ["#32cd32", "#386641"],
                      legend: {
                        show: true,
                      },
                      title: {
                        text: coSafe.name,
                        align: "center",
                        floating: true,
                      },
                      xaxis: {
                        ...barChartOptions.xaxis,
                        categories: coSafe.categories,
                        ...(coSafe.groups && {
                          labels: {
                            rotateAlways: true,
                            rotate: -90,
                            trim: true,
                            minHeight: 260,
                          },
                          group: {
                            style: {
                              fontSize: "10px",
                              fontWeight: 700,
                              cssClass: "groupXLabels",
                            },
                            groups: coSafe.groups,
                          },
                          axisTicks: {
                            show: true,
                          },
                        }),
                      },
                      yaxis: {
                        ...barChartOptions.yaxis,
                        forceNiceScale: true,
                        floating: false,
                        decimalsInFloat: 0,
                        title: {
                          text: "Count",
                        },
                      },
                      plotOptions: {
                        bar: {
                          ...barChartOptions.plotOptions.bar,
                          distributed: false,
                          columnWidth: "40%",
                          dataLabels: {
                            position: "center",
                            hideOverflowingLabels: false,
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        dropShadow: { enabled: false },
                        style: {
                          colors: ["#1C1C1C"],
                        },
                        textAnchor: "middle",
                      },
                    },
                    chartData: coSafe.data,
                  }),
                  coPageCount++,
                  totalChartPages + tableChunkData.arrayLength - 1,
                  1
                )}
              {/* Safe Behaviors */}
              {coSafeBehaviors &&
                coSafeBehaviors.length > 0 &&
                coSafeBehaviors.map((chrt, index) => {
                  const chrtName = chrt.name;
                  const chrtCategories = chrt.categories;
                  const chrtData = chrt.data;
                  const chrtGrouping = chrt.groups;
                  let chrtSeries = [];
                  if (chrtGrouping) {
                    chrtSeries = chrtData.map((chart) => ({
                      name: chart.name,
                      data: chrtCategories.map((cat, index) => ({
                        x: cat,
                        y: chart.data[index],
                      })),
                    }));
                  }

                  return reportPage(
                    chrtGrouping
                      ? renderChart({
                          type: "barChart",
                          chartOptions: {
                            chart: {
                              type: "bar",
                              stacked: true,
                              animations: {
                                enabled: false,
                              },
                            },
                            legend: {
                              show: true,
                            },
                            colors: ["#32cd32", "#386641"],
                            title: {
                              text: chrtName,
                              align: "center",
                              floating: true,
                            },
                            xaxis: {
                              ...barChartOptions.xaxis,
                              labels: {
                                // ...barChartOptions.xaxis.labels,
                                rotateAlways: true,
                                rotate: -90,
                                trim: true,
                                minHeight: 500,
                              },
                              group: {
                                style: {
                                  fontSize: "10px",
                                  fontWeight: 700,
                                  cssClass: "groupXLabels",
                                },
                                groups: chrtGrouping,
                              },
                              axisTicks: {
                                show:
                                  chrtGrouping && chrtGrouping.length <= 1
                                    ? false
                                    : true,
                              },
                            },
                            yaxis: {
                              ...barChartOptions.yaxis,
                              forceNiceScale: true,
                              floating: false,
                              decimalsInFloat: 0,
                              title: {
                                text: "Count",
                              },
                            },
                            plotOptions: {
                              bar: {
                                dataLabels: {
                                  hideOverflowingLabels: false,
                                },
                              },
                            },
                            dataLabels: {
                              enabled: true,
                              offsetY: 10,
                              style: {
                                colors: ["#1C1C1C"],
                              },
                              formatter: function (val, opts) {
                                return val || "";
                              },
                            },
                          },
                          chartData: chrtSeries,
                        })
                      : renderChart({
                          type: "barChart",
                          chartOptions: {
                            chart: {
                              type: "bar",
                              stacked: true,
                            },
                            title: {
                              text: chrtName,
                              align: "center",
                              floating: true,
                            },
                            legend: {
                              show: true,
                            },
                            colors: ["#32cd32", "#386641"],
                            xaxis: {
                              ...barChartOptions.xaxis,
                              categories: chrtCategories,
                              labels: {
                                // ...barChartOptions.xaxis.labels,
                                rotateAlways: true,
                                rotate: -90,
                                trim: true,
                                minHeight: 500,
                                fontSize: "10px",
                              },
                            },
                            yaxis: {
                              ...barChartOptions.yaxis,
                              forceNiceScale: false,
                              floating: false,
                              decimalsInFloat: 0,
                              title: {
                                text: "Count",
                              },
                            },
                            plotOptions: {
                              bar: {
                                dataLabels: {
                                  hideOverflowingLabels: false,
                                  position: "top",
                                },
                              },
                            },
                            dataLabels: {
                              enabled: true,
                              offsetY: 10,
                              style: {
                                colors: ["#1C1C1C"],
                              },
                              formatter: function (val, opts) {
                                return val || "";
                              },
                            },
                          },
                          chartData: chrtData,
                        }),
                    coPageCount++,
                    totalChartPages + tableChunkData.arrayLength - 1,
                    1
                  );
                })}
              {/* Safe Unsafe */}
              {coSafeUnsafe &&
                reportPage(
                  renderChart({
                    type: "barChart",
                    chartOptions: {
                      ...barChartOptions,
                      chart: {
                        ...barChartOptions.chart,
                        stacked: true,
                        animations: {
                          enabled: false,
                        },
                      },
                      legend: {
                        show: true,
                      },
                      colors: ["#32cd32", "#ff0000"],
                      title: {
                        text: coSafeUnsafe.name,
                        align: "center",
                        floating: true,
                      },
                      xaxis: {
                        ...barChartOptions.xaxis,
                        categories: coSafeUnsafe.categories,
                        ...(coSafeUnsafe.groups && {
                          labels: {
                            rotateAlways: true,
                            rotate: -90,
                            trim: true,
                            minHeight: 260,
                          },
                          group: {
                            style: {
                              fontSize: "10px",
                              fontWeight: 700,
                              cssClass: "groupXLabels",
                            },
                            groups: coSafeUnsafe.groups,
                          },
                          axisTicks: {
                            show: true,
                          },
                        }),
                      },
                      yaxis: {
                        ...barChartOptions.yaxis,
                        forceNiceScale: true,
                        floating: false,
                        decimalsInFloat: 0,
                        title: {
                          text: "Count",
                        },
                      },
                      plotOptions: {
                        bar: {
                          ...barChartOptions.plotOptions.bar,
                          distributed: false,
                          columnWidth: "40%",
                          dataLabels: {
                            position: "center",
                            hideOverflowingLabels: false,
                          },
                        },
                      },
                      dataLabels: {
                        enabled: true,
                        dropShadow: { enabled: false },
                        style: {
                          colors: ["#1C1C1C"],
                        },
                        textAnchor: "middle",
                      },
                    },
                    chartData: coSafeUnsafe.data,
                  }),
                  coPageCount++,
                  totalChartPages + tableChunkData.arrayLength - 1,
                  1
                )}
              {/* Unsafe */}
              {coUnsafe &&
                reportPage(
                  renderChart({
                    type: "barChart",
                    chartOptions: {
                      ...barChartOptions,
                      chart: {
                        ...barChartOptions.chart,
                        animations: {
                          enabled: false,
                        },
                      },
                      colors: ["#ff0000", "#ad0000"],
                      title: {
                        text: coUnsafe.name,
                        align: "center",
                        floating: true,
                      },
                      xaxis: {
                        ...barChartOptions.xaxis,
                        categories: coUnsafe.categories,
                        ...(coUnsafe.groups && {
                          labels: {
                            rotateAlways: true,
                            rotate: -90,
                            trim: true,
                            minHeight: 260,
                          },
                          group: {
                            style: {
                              fontSize: "10px",
                              fontWeight: 700,
                              cssClass: "groupXLabels",
                            },
                            groups: coUnsafe.groups,
                          },
                          axisTicks: {
                            show: true,
                          },
                        }),
                      },
                      yaxis: {
                        ...barChartOptions.yaxis,
                        forceNiceScale: true,
                        floating: false,
                        decimalsInFloat: 0,
                        title: {
                          text: "Count",
                        },
                      },
                      plotOptions: {
                        bar: {
                          ...barChartOptions.plotOptions.bar,
                          distributed: false,
                          columnWidth: "40%",
                          dataLabels: {
                            position: "top",
                          },
                        },
                      },
                    },
                    chartData: coUnsafe.data,
                  }),
                  coPageCount++,
                  totalChartPages + tableChunkData.arrayLength - 1,
                  1
                )}
              {/* Unsafe Behaviors */}
              {coUnsafeBehaviors &&
                coUnsafeBehaviors.length > 0 &&
                coUnsafeBehaviors.map((chrt, index) => {
                  const chrtName = chrt.name;
                  const chrtCategories = chrt.categories;
                  const chrtData = chrt.data;
                  const chrtGrouping = chrt.groups;
                  const chrtSeries = [];
                  if (chrtGrouping) {
                    const dataArr = chrtCategories.map((cat, index) => {
                      return {
                        x: cat,
                        y: chrtData[0].data[index],
                      };
                    });

                    chrtSeries.push({
                      name: "Count",
                      data: dataArr,
                    });
                  }

                  return reportPage(
                    chrtGrouping
                      ? renderChart({
                          type: "barChart",
                          chartOptions: {
                            ...barChartOptions,
                            chart: {
                              ...barChartOptions.chart,
                              animations: {
                                enabled: false,
                              },
                            },
                            legend: {
                              show: true,
                            },
                            colors: ["#ff0000"],
                            title: {
                              text: chrtName,
                              align: "center",
                              floating: true,
                            },
                            xaxis: {
                              ...barChartOptions.xaxis,
                              labels: {
                                // ...barChartOptions.xaxis.labels,
                                rotateAlways: true,
                                rotate: -90,
                                trim: true,
                                minHeight: 500,
                              },
                              group: {
                                style: {
                                  fontSize: "10px",
                                  fontWeight: 700,
                                  cssClass: "groupXLabels",
                                },
                                groups: chrtGrouping,
                              },
                              axisTicks: {
                                show:
                                  chrtGrouping && chrtGrouping.length <= 1
                                    ? false
                                    : true,
                              },
                            },
                            yaxis: {
                              ...barChartOptions.yaxis,
                              forceNiceScale: true,
                              floating: false,
                              decimalsInFloat: 0,
                              title: {
                                text: "Count",
                              },
                            },
                            plotOptions: {
                              bar: {
                                dataLabels: {
                                  hideOverflowingLabels: false,
                                  position: "top",
                                },
                              },
                            },
                            dataLabels: {
                              enabled: true,
                              style: {
                                colors: ["#1C1C1C"],
                              },
                              formatter: function (val, opts) {
                                return val || "";
                              },
                            },
                          },
                          chartData: chrtSeries,
                        })
                      : renderChart({
                          type: "barChart",
                          chartOptions: {
                            ...barChartOptions,
                            chart: {
                              ...barChartOptions.chart,
                              animations: {
                                enabled: false,
                              },
                            },
                            title: {
                              text: chrtName,
                              align: "center",
                              floating: true,
                            },
                            legend: {
                              show: false,
                            },
                            colors: ["#ff0000"],
                            plotOptions: {
                              bar: {
                                distributed: false,
                                dataLabels: {
                                  position: "top",
                                },
                              },
                            },
                            xaxis: {
                              ...barChartOptions.xaxis,
                              categories: chrtCategories,
                              labels: {
                                // ...barChartOptions.xaxis.labels,
                                rotateAlways: true,
                                rotate: -90,
                                trim: true,
                                minHeight: 500,
                                fontSize: "10px",
                              },
                            },
                            yaxis: {
                              ...barChartOptions.yaxis,
                              forceNiceScale: false,
                              floating: false,
                              decimalsInFloat: 0,
                              title: {
                                text: "Count",
                              },
                            },
                          },
                          chartData: chrtData,
                        }),
                    coPageCount++,
                    totalChartPages + tableChunkData.arrayLength - 1,
                    1
                  );
                })}
              {/* Unsafe Other Behaviors */}
              {coUnsafeOtherBehaviors &&
                coUnsafeOtherBehaviors.length > 0 &&
                coUnsafeOtherBehaviors.map((chrt, index) => {
                  const chrtName = chrt.name;
                  const chrtCategories = chrt.categories;
                  const chrtData = chrt.data;
                  const chrtGrouping = chrt.groups;
                  const chrtSeries = [];
                  if (chrtGrouping) {
                    const dataArr = chrtCategories.map((cat, index) => {
                      return {
                        x: cat,
                        y: chrtData[0].data[index],
                      };
                    });

                    chrtSeries.push({
                      name: "Count",
                      data: dataArr,
                    });
                  }
                  return reportPage(
                    chrtGrouping
                      ? renderChart({
                          type: "barChart",
                          chartOptions: {
                            ...barChartOptions,
                            chart: {
                              ...barChartOptions.chart,
                              animations: {
                                enabled: false,
                              },
                            },
                            legend: {
                              show: false,
                            },
                            colors: ["#ad0000"],
                            title: {
                              text: chrtName,
                              align: "center",
                              floating: true,
                            },
                            xaxis: {
                              ...barChartOptions.xaxis,
                              labels: {
                                // ...barChartOptions.xaxis.labels,
                                rotateAlways: true,
                                rotate: -90,
                                trim: true,
                                minHeight: 500,
                              },
                              group: {
                                style: {
                                  fontSize: "10px",
                                  fontWeight: 700,
                                  cssClass: "groupXLabels",
                                },
                                groups: chrtGrouping,
                              },
                              axisTicks: {
                                show:
                                  chrtGrouping && chrtGrouping.length <= 1
                                    ? false
                                    : true,
                              },
                            },
                            yaxis: {
                              ...barChartOptions.yaxis,
                              forceNiceScale: true,
                              floating: false,
                              decimalsInFloat: 0,
                              title: {
                                text: "Count",
                              },
                            },
                          },
                          chartData: chrtSeries,
                        })
                      : renderChart({
                          type: "barChart",
                          chartOptions: {
                            ...barChartOptions,
                            chart: {
                              ...barChartOptions.chart,
                              animations: {
                                enabled: false,
                              },
                            },
                            title: {
                              text: chrtName,
                              align: "center",
                              floating: true,
                            },
                            legend: {
                              show: false,
                            },
                            colors: ["#ad0000"],
                            plotOptions: {
                              bar: {
                                distributed: true,
                                dataLabels: {
                                  position: "top",
                                },
                              },
                            },
                            xaxis: {
                              ...barChartOptions.xaxis,
                              categories: chrtCategories,
                              labels: {
                                // ...barChartOptions.xaxis.labels,
                                rotateAlways: true,
                                rotate: -90,
                                trim: true,
                                minHeight: 500,
                                fontSize: "10px",
                              },
                            },
                            yaxis: {
                              ...barChartOptions.yaxis,
                              forceNiceScale: false,
                              floating: false,
                              decimalsInFloat: 0,
                              title: {
                                text: "Count",
                              },
                            },
                          },
                          chartData: chrtData,
                        }),
                    coPageCount++,
                    totalChartPages + tableChunkData.arrayLength - 1,
                    1
                  );
                })}

              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "behaviorName",
                        headerName: "Behavior Name",
                        width: 200,
                      },
                      {
                        field: "behaviorType",
                        headerName: "Behavior Type",
                        width: 200,
                      },
                      {
                        field: "businessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      { field: "safe", headerName: "Safe", width: 200 },
                      { field: "unsafe", headerName: "Unsafe", width: 200 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "intervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    coPageCount++,
                    totalChartPages + tableChunkData.arrayLength - 1,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        case "observation_target":
          return "WIP";

        case "behavioral_trend":
          return (
            <>
              {chartInfo.chartData &&
                chartInfo.chartData.length > 0 &&
                chartInfo.chartData.map((chrt, chrtIndex) => {
                  const btName = chrt.name;
                  const btInfo = parseChartData(chrt.type, chrt);
                  let btCategories = btInfo[0];
                  let btData = btInfo[1];

                  return reportPage(
                    renderChart({
                      type: "lineChart",
                      chartOptions: {
                        ...lineChartOptions,
                        chart: {
                          ...lineChartOptions.chart,
                          animations: {
                            enabled: false,
                          },
                        },
                        colors: [
                          "#418cf0",
                          "#ea5545",
                          "#f46a9b",
                          "#ef9b20",
                          "#edbf33",
                          "#ede15b",
                          "#bdcf32",
                          "#87bc45",
                          "#27aeef",
                          "#b33dc6",
                        ],
                        title: {
                          text: btName,
                          align: "center",
                          floating: true,
                        },
                        xaxis: {
                          ...lineChartOptions.xaxis,
                          categories: btCategories,
                          group: {
                            style: {
                              fontSize: "10px",
                              fontWeight: 700,
                            },
                          },
                        },
                        yaxis: {
                          ...lineChartOptions.yaxis,
                          forceNiceScale: true,
                          floating: false,
                          decimalsInFloat: 0,
                          title: {
                            text: "Percentage %",
                          },
                          labels: {
                            formatter: (val) => `${val}%`,
                          },
                        },
                      },
                      chartData: btData,
                    }),
                    chrtIndex,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "behaviorName",
                        headerName: "Behavior Name",
                        width: 180,
                      },
                      {
                        field: "businessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 180,
                      },
                      {
                        field: "percentage",
                        headerName: "Percentage",
                        width: 180,
                        renderCell: (params) =>
                          params.row.percentage
                            ? `${params.row.percentage}%`
                            : `0%`,
                      },
                      { field: "safe", headerName: "Safe", width: 200 },
                      { field: "unsafe", headerName: "Unsafe", width: 250 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "intervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    chartInfo.chartData.length + index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        case "unit_performance":
          const upCategories = chartInfo?.chartData?.categories;
          const upData = chartInfo?.chartData?.data;
          const upGroups = chartInfo?.chartData?.groups;
          const upName = chartInfo?.chartData?.name;
          return (
            <>
              {reportPage(
                renderChart({
                  type: "barChart",
                  chartOptions: {
                    ...barChartOptions,
                    chart: {
                      ...barChartOptions.chart,
                      animations: {
                        enabled: false,
                      },
                    },
                    colors: [
                      "#418cf0",
                      "#ea5545",
                      "#f46a9b",
                      "#ef9b20",
                      "#edbf33",
                      "#ede15b",
                      "#bdcf32",
                      "#87bc45",
                      "#27aeef",
                      "#b33dc6",
                    ],
                    title: {
                      text: upName,
                      align: "center",
                      floating: true,
                    },
                    xaxis: {
                      ...barChartOptions.xaxis,
                      categories: upCategories,
                    },
                    yaxis: {
                      ...lineChartOptions.yaxis,
                      forceNiceScale: true,
                      floating: false,
                      decimalsInFloat: 0,
                      title: {
                        text: "Percentage %",
                      },
                      label: {
                        formatter: (val) => `${val}%`,
                      },
                    },
                    plotOptions: {
                      bar: {
                        distributed: false,
                        columnWidth: "40%",
                        dataLabels: {
                          position: "top", // top, center, bottom
                        },
                      },
                    },
                  },
                  chartData: upData,
                }),
                0,
                1 + tableChunkData.arrayLength,
                1
              )}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      { field: "behavior", headerName: "Behavior", width: 200 },
                      {
                        field: "businessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      {
                        field: "percentage",
                        headerName: "Percentage",
                        width: 200,
                        renderCell: (params) =>
                          params.row.percentage
                            ? `${params.row.percentage}%`
                            : `0%`,
                      },
                      { field: "safe", headerName: "Safe", width: 200 },
                      { field: "unsafe", headerName: "Unsafe", width: 200 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "intervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    index + 1,
                    1 + tableChunkData.arrayLength,
                    1
                  );
                })}

              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        case "percent_knowledge_reports":
          // const pKCategories = chartInfo?.chartData?.categories;
          const pKCategories = [];
          const pkValues = [];
          const pKData = chartInfo?.chartData?.data;
          const pKName = chartInfo?.chartData?.name;
          pKData.forEach((data) => {
            pKCategories.push(data.x);
            pkValues.push(data.y);
          });
          const acutalData = [
            {
              name: "% Percentage",
              data: pkValues,
            },
          ];

          const PkGroups = chartInfo?.chartData?.groups;

          return (
            <>
              {reportPage(
                renderChart({
                  type: "barChart",
                  chartOptions: {
                    ...barChartOptions,
                    chart: {
                      ...barChartOptions.chart,
                      animations: {
                        enabled: false,
                      },
                    },
                    title: {
                      text: pKName,
                      align: "center",
                      floating: true,
                    },
                    xaxis: {
                      ...barChartOptions.xaxis,
                      categories: pKCategories,
                    },
                    yaxis: {
                      ...barChartOptions.yaxis,
                      decimalsInFloat: 0,
                      title: {
                        text: "Percentage %",
                      },
                      labels: {
                        formatter: (val) => `${val}%`,
                      },
                    },
                  },
                  chartData: acutalData,
                }),
                0,
                1 + tableChunkData.arrayLength,
                1
              )}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk),
                    index + 1,
                    1 + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        case "ihtimam_total_performance":
          const itpCharts = chartInfo?.chartData || [];
          const itpChartGroups =
            chartInfo?.chartData[itpCharts.length - 1].groups || false;

          return (
            <>
              {itpCharts.map((chrt, index) => {
                switch (chrt.type) {
                  //  Total Cards
                  case "totalCards":
                    const itpTotalCardsName = chrt?.name || "";
                    const itpTotalCardsCats = chrt?.categories || [];
                    const itpTotalCardsData = chrt?.data || "";

                    return reportPage(
                      renderChart({
                        type: "barChart",
                        chartOptions: {
                          ...barChartOptions,
                          chart: {
                            ...barChartOptions.chart,
                            animations: {
                              enabled: false,
                            },
                          },
                          title: {
                            text: itpTotalCardsName,
                            align: "center",
                            floating: true,
                          },
                          colors: ["#418cf0"],
                          plotOptions: {
                            bar: {
                              distributed: false,
                              dataLabels: {
                                position: "top",
                              },
                            },
                          },
                          xaxis: {
                            ...barChartOptions.xaxis,
                            categories: itpTotalCardsCats,
                          },
                          yaxis: {
                            ...barChartOptions.yaxis,
                            forceNiceScale: true,
                            floating: false,
                            decimalsInFloat: 0,
                            title: {
                              text: "Card Count",
                            },
                          },
                        },
                        chartData: itpTotalCardsData,
                      }),
                      index,
                      chartInfo.chartData.length + tableChunkData.arrayLength,
                      1
                    );

                  // Safe / Unsafe
                  case "safeUnsafeCards":
                    const itpSafeUnsafeName = chrt?.name || "";
                    const itpSafeUnsafeCats = chrt?.categories || [];
                    const itpSafeUnsafeData = chrt?.data || "";

                    return reportPage(
                      renderChart({
                        type: "barChart",
                        chartOptions: {
                          ...barChartOptions,
                          chart: {
                            ...barChartOptions.chart,
                            animations: {
                              enabled: false,
                            },
                          },
                          title: {
                            text: itpSafeUnsafeName,
                            align: "center",
                            floating: true,
                          },
                          colors: ["#07b254", "#ff0000"],
                          plotOptions: {
                            bar: {
                              distributed: itpChartGroups ? false : true,
                              dataLabels: {
                                position: "top",
                              },
                            },
                          },
                          xaxis: {
                            ...barChartOptions.xaxis,
                            categories: itpSafeUnsafeCats,
                          },
                          yaxis: {
                            ...barChartOptions.yaxis,
                            forceNiceScale: true,
                            floating: false,
                            decimalsInFloat: 0,
                            title: {
                              text: "Count",
                            },
                          },
                        },
                        chartData: itpSafeUnsafeData,
                      }),
                      index,
                      chartInfo.chartData.length + tableChunkData.arrayLength,
                      1
                    );

                  case "feedback":
                    const itpObsFeedbackName = chrt.name || "";
                    const itpObsFeedbackCats = chrt.categories || [];
                    const itpObsFeedbackData = chrt.data || "";

                    return reportPage(
                      renderChart({
                        type: "barChart",
                        chartOptions: {
                          ...barChartOptions,
                          chart: {
                            ...barChartOptions.chart,
                            animations: {
                              enabled: false,
                            },
                          },
                          title: {
                            text: itpObsFeedbackName,
                            align: "center",
                            floating: true,
                          },
                          colors: ["#ff7322", "#418cf0"],
                          plotOptions: {
                            bar: {
                              distributed:
                                itpObsFeedbackCats[0] === "Feedback"
                                  ? true
                                  : false,
                              dataLabels: {
                                position: "top",
                              },
                            },
                          },
                          xaxis: {
                            ...barChartOptions.xaxis,
                            categories: itpObsFeedbackCats,
                          },
                          yaxis: {
                            ...barChartOptions.yaxis,
                            forceNiceScale: true,
                            floating: false,
                            decimalsInFloat: 0,
                            title: {
                              text: "Count",
                            },
                          },
                        },
                        chartData: itpObsFeedbackData,
                      }),
                      index,
                      chartInfo.chartData.length + tableChunkData.arrayLength,
                      1
                    );

                  case "actionCloseouts":
                    const itpActionCloseOutName = chrt?.name || "";
                    const itpActionCloseOutCats = chrt?.categories || [];
                    const itpActionCloseOutData = chrt?.data || "";

                    return reportPage(
                      renderChart({
                        type: "barChart",
                        chartOptions: {
                          ...barChartOptions,
                          chart: {
                            ...barChartOptions.chart,
                            animations: {
                              enabled: false,
                            },
                          },
                          title: {
                            text: itpActionCloseOutName,
                            align: "center",
                            floating: true,
                          },
                          colors: ["#FFBF00", "#32cd32", "#ff0000"],
                          plotOptions: {
                            bar: {
                              distributed: itpChartGroups ? false : true,
                              dataLabels: {
                                position: "top",
                              },
                            },
                          },
                          xaxis: {
                            ...barChartOptions.xaxis,
                            categories: itpActionCloseOutCats,
                          },
                          yaxis: {
                            ...barChartOptions.yaxis,
                            forceNiceScale: true,
                            floating: false,
                            decimalsInFloat: 0,
                            title: {
                              text: "Count",
                            },
                          },
                        },
                        chartData: itpActionCloseOutData,
                      }),
                      index,
                      chartInfo.chartData.length + tableChunkData.arrayLength,
                      1
                    );

                  case "safeScore":
                    const itpSafeScoreName = chrt.name || "";
                    const itpSafeScoreCats = chrt.categories || [];
                    const itpSafeScoreData = chrt.data || "";
                    let safeScoreLevelsName = Object.keys(chrt?.levels);
                    let safeScoreLevelsValue = Object.values(chrt?.levels);
                    const itpChartGrouping = chrt?.groups;
                    const itpSafeScoreSeries = [];
                    if (itpChartGrouping) {
                      const dataArr = itpSafeScoreCats.map((cat, index) => {
                        return {
                          x: cat,
                          y: itpSafeScoreData[0].data[index],
                        };
                      });

                      itpSafeScoreSeries.push({
                        name: "Percentage",
                        data: dataArr,
                      });
                    }

                    const annotations = {
                      yaxis: [
                        {
                          y: safeScoreLevelsValue[0],
                          borderColor: "#00E396",
                          label: {
                            borderColor: "#00E396",
                            style: {
                              color: "#1C1C1C",
                              background: "#00E396",
                              height: "20px",
                            },
                            text: safeScoreLevelsName[0],
                          },
                        },
                        {
                          y: safeScoreLevelsValue[1],
                          borderColor: "#FEB019",
                          label: {
                            borderColor: "#FEB019",
                            style: {
                              color: "#1C1C1C",
                              background: "#FEB019",
                            },
                            text: safeScoreLevelsName[1],
                          },
                        },
                        {
                          y: safeScoreLevelsValue[2],
                          borderColor: "#FF4560",
                          label: {
                            borderColor: "#FF4560",
                            style: {
                              color: "#1C1C1C",
                              background: "#FF4560",
                            },
                            text: safeScoreLevelsName[2],
                          },
                        },
                      ],
                    };
                    return reportPage(
                      itpChartGrouping
                        ? renderChart({
                            type: "barChart",
                            chartOptions: {
                              ...barChartOptions,
                              chart: {
                                ...barChartOptions.chart,
                                animations: {
                                  enabled: false,
                                },
                              },
                              annotations,
                              colors: ["#418cf0"],
                              legend: {
                                show: false,
                              },
                              title: {
                                text: itpSafeScoreName,
                                align: "center",
                                floating: true,
                              },
                              xaxis: {
                                ...barChartOptions.xaxis,
                                labels: {
                                  // ...barChartOptions.xaxis.labels,
                                  rotateAlways: true,
                                  rotate: -90,
                                  trim: true,
                                  minHeight: 500,
                                },
                                group: {
                                  style: {
                                    fontSize: "10px",
                                    fontWeight: 700,
                                    cssClass: "groupXLabels",
                                  },
                                  groups: itpChartGrouping,
                                },
                              },
                              yaxis: {
                                ...barChartOptions.yaxis,
                                forceNiceScale: true,
                                floating: false,
                                decimalsInFloat: 0,
                                title: {
                                  text: "Count",
                                },
                              },
                            },
                            chartData: itpSafeScoreSeries,
                          })
                        : renderChart({
                            type: "barChart",
                            chartOptions: {
                              ...barChartOptions,
                              chart: {
                                ...barChartOptions.chart,
                                animations: {
                                  enabled: false,
                                },
                              },
                              annotations: annotations,
                              title: {
                                text: itpSafeScoreName,
                                align: "center",
                                floating: true,
                              },
                              legend: {
                                show: false,
                              },
                              colors: ["#418cf0"],
                              plotOptions: {
                                bar: {
                                  distributed: false,
                                  dataLabels: {
                                    position: "top",
                                  },
                                },
                              },
                              xaxis: {
                                ...barChartOptions.xaxis,
                                categories: itpSafeScoreCats,
                                labels: {
                                  // ...barChartOptions.xaxis.labels,
                                  rotateAlways: true,
                                  rotate: -90,
                                  trim: true,
                                  minHeight: 500,
                                  fontSize: "10px",
                                },
                              },
                              yaxis: {
                                ...barChartOptions.yaxis,
                                forceNiceScale: false,
                                floating: false,
                                decimalsInFloat: 0,
                                title: {
                                  text: "Count",
                                },
                              },
                            },
                            chartData: itpSafeScoreData,
                            ...(itpChartGrouping
                              ? {
                                  group: {
                                    style: {
                                      fontSize: "10px",
                                      fontWeight: 700,
                                    },
                                    groups: itpChartGrouping,
                                  },
                                }
                              : {}),
                          }),
                      index,
                      chartInfo.chartData.length + tableChunkData.arrayLength,
                      1
                    );

                  default:
                    return null;
                }
              })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      { field: "Behavior", headerName: "Behavior", width: 400 },
                      { field: "safe", headerName: "Safe", width: 100 },
                      { field: "unsafe", headerName: "Unsafe", width: 100 },
                      {
                        field: "percentage",
                        headerName: "Percentage",
                        width: 100,
                        renderCell: (params) =>
                          params.row.percentage
                            ? `${params.row.percentage}%`
                            : `0%`,
                      },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "intervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    chartInfo.chartData.length + index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        case "action_close_out_reports":
          return (
            <>
              {chartInfo.chartData &&
                chartInfo.chartData.length > 0 &&
                chartInfo.chartData.map((chrt, index) => {
                  const acName = chrt.name;
                  const acInfo = parseChartData(chrt.type, chrt);
                  let acCategories = acInfo[0];
                  let acData = acInfo[1];

                  return reportPage(
                    renderChart({
                      type: "barChart",
                      chartOptions: {
                        ...barChartOptions,
                        chart: {
                          ...barChartOptions.chart,
                          animations: {
                            enabled: false,
                          },
                        },
                        title: {
                          text: acName,
                          align: "center",
                          floating: true,
                        },
                        colors: ["#FFBF00", "#32cd32", "#ff0000"],
                        plotOptions: {
                          bar: {
                            distributed:
                              chrt.type === "interval" ? false : true,
                            dataLabels: {
                              position: "top",
                            },
                          },
                        },
                        xaxis: {
                          ...barChartOptions.xaxis,
                          categories: acCategories,
                        },
                        yaxis: {
                          ...barChartOptions.yaxis,
                          forceNiceScale: true,
                          floating: false,
                          decimalsInFloat: 0,
                          title: {
                            text: "Count",
                          },
                        },
                      },
                      chartData: acData,
                    }),
                    index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "BusinessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      { field: "Pending", headerName: "Pending", width: 100 },
                      { field: "Closed", headerName: "Closed", width: 100 },
                      { field: "Overdue", headerName: "Overdue", width: 100 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "intervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    chartInfo.chartData.length + index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        case "total_lsr_report":
          return (
            <>
              {chartInfo.chartData &&
                chartInfo.chartData.length > 0 &&
                chartInfo.chartData.map((chrt, index) => {
                  const tlName = chrt.name;
                  const tlInfo = parseChartData(chrt.type, chrt);

                  let tlCategories = tlInfo[0];
                  let tlData = tlInfo[1];

                  return reportPage(
                    renderChart({
                      type: "barChart",
                      chartOptions: {
                        ...barChartOptions,
                        chart: {
                          ...barChartOptions.chart,
                          animations: {
                            enabled: false,
                          },
                        },
                        title: {
                          text: tlName,
                          align: "center",
                          floating: true,
                        },
                        colors: ["#ff0000", "#32cd32"],
                        plotOptions: {
                          bar: {
                            distributed: false,
                            dataLabels: {
                              position: "top",
                            },
                          },
                        },
                        xaxis: {
                          ...barChartOptions.xaxis,
                          categories: tlCategories,
                        },
                        yaxis: {
                          ...barChartOptions.yaxis,
                          forceNiceScale: true,
                          floating: false,
                          decimalsInFloat: 0,
                          title: {
                            text: "Count",
                          },
                        },
                      },
                      chartData: tlData,
                    }),
                    index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "BusinessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      { field: "LSR", headerName: "LSR", width: 100 },
                      { field: "NonLSR", headerName: "Non LSR", width: 100 },
                      {
                        field: "LSR Percentage",
                        headerName: "LSR Percentage",
                        width: 100,
                        renderCell: (params) =>
                          params.row["LSR Percentage"]
                            ? `${params.row["LSR Percentage"]}%`
                            : `0%`,
                      },
                      { field: "Total", headerName: "Total", width: 100 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "IntervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    chartInfo.chartData.length + index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}
            </>
          );

        case "lsr_violation_report":
          return (
            <>
              {chartInfo.chartData &&
                chartInfo.chartData.length > 0 &&
                chartInfo.chartData.map((chrt, index) => {
                  const lvName = chrt.name;
                  const lvInfo = parseChartData(chrt.type, chrt);

                  let lvCategories = lvInfo[0];
                  let lvData = lvInfo[1];

                  return reportPage(
                    renderChart({
                      type: "barChart",
                      chartOptions: {
                        ...barChartOptions,
                        chart: {
                          ...barChartOptions.chart,
                          animations: {
                            enabled: false,
                          },
                        },
                        title: {
                          text: lvName,
                          align: "center",
                          floating: true,
                        },
                        colors: [
                          "#87bc45",
                          "#27aeef",
                          "#edbf33",
                          "#f46a9b",
                          "#ef9b20",
                          "#ede15b",
                          "#bdcf32",
                          "#b33dc6",
                          "#32cd32",
                          "#ff0000",
                          "#ea5545",
                        ],
                        plotOptions: {
                          bar: {
                            distributed:
                              chrt.type === "interval" ? false : true,
                            dataLabels: {
                              position: "top",
                            },
                          },
                        },
                        xaxis: {
                          ...barChartOptions.xaxis,
                          labels: {
                            trim: true,
                            rotate: -90,
                            minHeight:
                              chrt.type === "interval"
                                ? undefined
                                : lvCategories &&
                                  lvCategories.length > 0 &&
                                  lvCategories[0] === "Count"
                                ? undefined
                                : 400,
                          },
                          categories: lvCategories,
                        },
                        yaxis: {
                          ...barChartOptions.yaxis,
                          forceNiceScale: true,
                          floating: false,
                          decimalsInFloat: 0,
                          title: {
                            text: "Count",
                          },
                        },
                      },
                      chartData: lvData,
                    }),
                    index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "BusinessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      { field: "LSR", headerName: "LSR", width: 100 },
                      { field: "Total", headerName: "Total", width: 100 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "IntervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    chartInfo.chartData.length + index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}{" "}
            </>
          );

        case "behavior_linked_lsr_report":
          return (
            <>
              {chartInfo.chartData &&
                chartInfo.chartData.length > 0 &&
                chartInfo.chartData.map((chrt, index) => {
                  const bllName = chrt.name;
                  const bllInfo = parseChartData(chrt.type, chrt);

                  let bllCategories = bllInfo[0];
                  let bllData = bllInfo[1];

                  return reportPage(
                    renderChart({
                      type: "barChart",
                      chartOptions: {
                        ...barChartOptions,
                        chart: {
                          ...barChartOptions.chart,
                          animations: {
                            enabled: false,
                          },
                        },
                        title: {
                          text: bllName,
                          align: "center",
                          floating: true,
                        },
                        colors: [
                          "#32cd32",
                          "#ff0000",
                          "#ea5545",
                          "#f46a9b",
                          "#ef9b20",
                          "#edbf33",
                          "#ede15b",
                          "#bdcf32",
                          "#87bc45",
                          "#27aeef",
                          "#b33dc6",
                        ],
                        legend: {
                          show: chrt.type === "interval" ? true : false,
                        },
                        plotOptions: {
                          dataLabels: {
                            enabled: false,
                            // position: 'top', // top, center, bottom
                          },
                          bar: {
                            distributed:
                              chrt.type === "interval" ? false : true,
                            dataLabels: {
                              position: "top",
                            },
                          },
                        },
                        xaxis: {
                          ...barChartOptions.xaxis,
                          labels: {
                            trim: true,
                            rotate: -90,
                            minHeight:
                              chrt.type === "interval" ? undefined : 400,
                          },
                          categories: bllCategories,
                        },
                        yaxis: {
                          ...barChartOptions.yaxis,
                          forceNiceScale: true,
                          floating: false,
                          decimalsInFloat: 0,
                          title: {
                            text: "Count",
                          },
                        },
                      },
                      chartData: bllData,
                    }),
                    index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "BusinessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      { field: "Behavior", headerName: "Behavior", width: 100 },
                      { field: "Total", headerName: "Total", width: 100 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "IntervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    chartInfo.chartData.length + index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}{" "}
            </>
          );

        case "lsr_per_bu":
          return "WIP";

        case "lsr_type_classification_report":
          return (
            <>
              {chartInfo.chartData &&
                chartInfo.chartData.length > 0 &&
                chartInfo.chartData.map((chrt, index) => {
                  const chrtName = chrt.name;
                  const chrtCategories = chrt.categories;
                  const chrtData = chrt.data;
                  const chrtGrouping = chrt.groups;
                  const chrtSeries = [];
                  if (chrtGrouping) {
                    const safeArr = [];
                    const unsafeArr = [];
                    chrtCategories.forEach((cat, index) => {
                      safeArr.push({
                        x: cat,
                        y: chrtData[0].data[index],
                      });
                      unsafeArr.push({
                        x: cat,
                        y: chrtData[1].data[index],
                      });
                    });

                    chrtSeries.push({
                      name: "Safe Behavior - LSR",
                      data: safeArr,
                    });
                    chrtSeries.push({
                      name: "At Risk Behavior - LSR",
                      data: unsafeArr,
                    });
                  }

                  return reportPage(
                    renderChart({
                      type: "barChart",
                      chartOptions: {
                        ...barChartOptions,
                        chart: {
                          ...barChartOptions.chart,
                          animations: {
                            enabled: false,
                          },
                        },
                        title: {
                          text: chrtName,
                          align: "center",
                          floating: true,
                        },
                        colors: [
                          "#32cd32",
                          "#ff0000",
                          "#ea5545",
                          "#f46a9b",
                          "#ef9b20",
                          "#edbf33",
                          "#ede15b",
                          "#bdcf32",
                          "#87bc45",
                          "#27aeef",
                          "#b33dc6",
                        ],
                        plotOptions: {
                          bar: {
                            distributed: false,
                            dataLabels: {
                              position: "top",
                            },
                          },
                        },
                        xaxis: {
                          ...barChartOptions.xaxis,
                          labels: {
                            rotate: -90,
                            trim: true,
                            minHeight: chrtGrouping
                              ? 400
                              : chrtCategories &&
                                chrtCategories.length > 0 &&
                                chrtCategories[0] === "Count"
                              ? undefined
                              : 240,
                            formatter: function (val) {
                              try {
                                // trim the label to 2 words and add "..." at the end
                                const v = val.split(" ").slice(0, 5).join(" ");
                                return v.length < val.length ? v + "..." : v;
                              } catch (err) {
                                return val;
                              }
                            },
                          },
                          ...(chrtGrouping
                            ? {
                                group: {
                                  style: {
                                    fontSize: "10px",
                                    fontWeight: 700,
                                    cssClass: "groupXLabels",
                                  },
                                  groups: chrtGrouping,
                                },
                                axisTicks: {
                                  show:
                                    chrtGrouping && chrtGrouping.length <= 1
                                      ? false
                                      : true,
                                },
                              }
                            : { categories: chrtCategories }),
                        },
                        yaxis: {
                          ...barChartOptions.yaxis,
                          forceNiceScale: true,
                          floating: false,
                          decimalsInFloat: 0,
                          title: {
                            text: "Count",
                          },
                        },
                      },
                      chartData: chrtGrouping ? chrtSeries : chrtData,
                    }),
                    index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "BusinessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      { field: "LSR", headerName: "LSR", width: 100 },
                      { field: "atRisk", headerName: "At Risk", width: 100 },
                      { field: "safe", headerName: "Safe", width: 100 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "intervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    chartInfo.chartData.length + index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}{" "}
            </>
          );

        case "root_cause_against_observation":
          return (
            <>
              {chartInfo.chartData &&
                chartInfo.chartData.length > 0 &&
                chartInfo.chartData.map((chrt, index) => {
                  const rcaoName = chrt.name;
                  const rcaoInfo = parseChartData(chrt.type, chrt);

                  let rcaoCategories = rcaoInfo[0];
                  let rcaoData = rcaoInfo[1];

                  return reportPage(
                    renderChart({
                      type: "barChart",
                      chartOptions: {
                        ...barChartOptions,
                        chart: {
                          ...barChartOptions.chart,
                          animations: {
                            enabled: false,
                          },
                        },
                        title: {
                          text: rcaoName,
                          align: "center",
                          floating: true,
                        },
                        colors: [
                          "#ede15b",
                          "#bdcf32",
                          "#87bc45",
                          "#27aeef",
                          "#b33dc6",
                          "#32cd32",
                          "#ff0000",
                          "#ea5545",
                          "#f46a9b",
                          "#ef9b20",
                          "#edbf33",
                        ],
                        plotOptions: {
                          bar: {
                            distributed:
                              chrt.type === "interval" ? false : true,
                            dataLabels: {
                              position: "top",
                            },
                          },
                        },
                        xaxis: {
                          ...barChartOptions.xaxis,
                          categories: rcaoCategories,
                        },
                        yaxis: {
                          ...barChartOptions.yaxis,
                          forceNiceScale: true,
                          floating: false,
                          decimalsInFloat: 0,
                          title: {
                            text: "Count",
                          },
                        },
                      },
                      chartData: rcaoData,
                    }),
                    index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "BusinessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      {
                        field: "RootCause",
                        headerName: "Root Cause",
                        width: 100,
                      },
                      { field: "Total", headerName: "Total", width: 100 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "IntervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    chartInfo.chartData.length + index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}{" "}
            </>
          );

        case "root_cause_trend":
          return (
            <>
              {chartInfo.chartData &&
                chartInfo.chartData.length > 0 &&
                chartInfo.chartData.map((chrt, index) => {
                  const rctName = chrt.name;
                  const rctInfo = parseChartData(chrt.type, chrt);

                  let rctCategories = rctInfo[0];
                  let rctData = rctInfo[1];

                  return reportPage(
                    renderChart({
                      type: "barChart",
                      chartOptions: {
                        ...barChartOptions,
                        chart: {
                          ...barChartOptions.chart,
                          animations: {
                            enabled: false,
                          },
                        },
                        title: {
                          text: rctName,
                          align: "center",
                          floating: true,
                        },
                        colors: undefined,
                        plotOptions: {
                          bar: {
                            distributed:
                              chrt.type === "interval" ? false : true,
                            dataLabels: {
                              position: "top",
                            },
                          },
                        },
                        xaxis: {
                          ...barChartOptions.xaxis,
                          categories: rctCategories,
                        },
                        yaxis: {
                          ...barChartOptions.yaxis,
                          forceNiceScale: true,
                          floating: false,
                          decimalsInFloat: 0,
                          title: {
                            text: "Count",
                          },
                        },
                      },
                      chartData: rctData,
                    }),
                    index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {tableChunkData?.arrayLength > 0 &&
                tableChunkData?.data?.map((chunk, index) => {
                  return reportPage(
                    renderTable(chunk, [
                      {
                        field: "BusinessUnit",
                        headerName: toTitleCase(groupBy.value),
                        width: 200,
                      },
                      {
                        field: "RootCause",
                        headerName: "Root Cause",
                        width: 100,
                      },
                      { field: "Total", headerName: "Total", width: 100 },
                      ...(timeIntervalCreate.value
                        ? [
                            {
                              field: "IntervalStartDate",
                              headerName: "Interval Start Date",
                              width: 200,
                            },
                          ]
                        : []),
                    ]),
                    chartInfo.chartData.length + index,
                    chartInfo.chartData.length + tableChunkData.arrayLength,
                    1
                  );
                })}
              {toastLoaded(t("reporting.reportGenerated"))}{" "}
            </>
          );

        case "root_cause_with_behaviour":
          return "WIP";

        case "card_count":
          return "WIP";

        case "participation_percentage":
          return "WIP";

        case "employee_card_count":
          return "WIP";

        default:
          return "Invalid chart type.";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartInfo]);

  const submitDownloadPDF = async () => {
    try {
      handleDownloadMenuClose();
      toast.remove();
      setLoading(true);
      toast.loading(t("reporting.downloading"), {
        duration: Infinity,
      });
      const pdf = new jsPDF("l", "pt", "a4", true);
      const width = pdf.internal.pageSize.getWidth();
      const w = width - width * 0.1;
      const height = pdf.internal.pageSize.getHeight();
      const h = height - height * 0.1;
      setDownloadModal(true);
      for (let index = 0; index < pagesRef.current.length; index++) {
        const imgData = await toPng(pagesRef.current[index]);
        // pdf.addImage(imgData, "JPEG", width * 0.05, 0, w, h);
        pdf.addImage(imgData, "PNG", width * 0.04, 0, w, h * 1.2, "", "FAST");
        setDownloadProgress(
          (((index + 1) / pagesRef.current.length) * 100).toFixed(0)
        );
        if (index + 1 !== pagesRef.current.length) {
          pdf.addPage();
        } else {
          pdf.save(`${chartInfo.title}_${today}.pdf`);
          setLoading(false);
          toast.remove();
          toast.success(t("reporting.downloadSuccess"));
          setDownloadModal(false);
          setDownloadProgress(0);
        }
      }
    } catch (err) {
      console.log(err);
      toast.remove();
      toast.error(t("reporting.downloadError"));
      setDownloadModal(false);
      setLoading(false);
      setDownloadProgress(0);
    }
  };

  const submitDownloadXLSX = () => {
    handleDownloadMenuClose();
    toast.remove();
    setLoading(true);
    toast.loading(t("reporting.downloading"), {
      duration: Infinity,
    });
    try {
      let keyMap = {
        buName: toTitleCase(groupBy.value),
        BusinessUnit: toTitleCase(groupBy.value),
        businessUnit: toTitleCase(groupBy.value),
      };
      let newTableData = chartInfo.tableData.map((entry) =>
        Object.keys(entry).reduce((cumulative, key) => {
          if (!keyMap[key]) {
            const resultantKey = key.replace(/([A-Z])/g, " $1");
            keyMap[key] =
              resultantKey.charAt(0).toUpperCase() + resultantKey.slice(1);
          }
          cumulative[keyMap[key]] = entry[key];
          return cumulative;
        }, {})
      );
      const ws = utils.json_to_sheet(newTableData);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, `${chartInfo.title}`);
      writeFile(wb, `${chartInfo.title}_${today}.xlsx`);
      setLoading(false);
      toast.remove();
      toast.success(t("reporting.downloadSuccess"));
    } catch (err) {
      toast.remove();
      toast.error(t("reporting.downloadError"));
      setLoading(false);
    }
  };

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const handleAddVerifier = (v) => {
    if (v) {
      const currentUsers = [...verifierModal.users];
      const isThere = currentUsers.find((u) => u.userId === v.id);
      if (isThere) {
        displayToast(null, "pureError", t("reporting.verifierExists"));
      } else {
        currentUsers.push({ userId: v.id, userLabel: v.label });
        setVerifierModal({ ...verifierModal, users: currentUsers });
      }
    }
  };

  const handleRemoveVerifier = (u) => {
    const currentUsers = verifierModal.users.filter(
      (user) => user.userId !== u.userId
    );
    setVerifierModal({ ...verifierModal, users: currentUsers });
  };

  const renderEditVerifiers = () => (
    <Stack direction="column" spacing={2}>
      <Typography variant="h6">{`Currently assigned users for ${chartInfo.title}`}</Typography>
      {verifierModal.users.length > 0 ? (
        verifierModal.users.map((u) => (
          <BRow style={{ marginBottom: 0 }}>
            <Stack justifyContent="space-between" direction="row">
              <Typography variant="body1">{u.userLabel}</Typography>
              <DeleteOutline
                style={{
                  color: theme.palette.primary.red,
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveVerifier(u)}
              />
            </Stack>
          </BRow>
        ))
      ) : (
        <BRow style={{ height: "50px" }}>{t("reporting.noVerifiers")}</BRow>
      )}
      <AutocompleteInput
        handler={(e, v) => handleAddVerifier(v)}
        keyPressHandler={handleSearchUser}
        label={t("reporting.selectVerifier")}
        loading={searchLoading}
        disabled={false}
        options={userDropdown}
        disablePortal={false}
      />
    </Stack>
  );

  const renderScheduleModal = () => (
    <InfoBox
      minWidth="50vw"
      open={scheduleModal.open}
      title={`${t(`reporting.scheduleReport`)} - ${chartInfo.title}`}
      content={
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateInput
              label={t(`reporting.startDate`)}
              date={scheduleDate}
              handler={(newDate) => {
                setScheduleDate(newDate);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              value={selectedTimeInterval}
              handler={(e) => setSelectedTimeInterval(e.target.value)}
              options={timeInterval}
              label={t(`reporting.timeInterval`)}
            />
          </Grid>

          <Grid item xs={12}>
            {renderEditVerifiers()}
          </Grid>

          {businessUnits?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Business Units:</Typography>
              </Grid>
              <Grid item xs={6}>
                {businessUnits.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-businessUnits`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {locations?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Locations:</Typography>
              </Grid>
              <Grid item xs={6}>
                {locations.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-locations`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {disciplines?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Disciplines:</Typography>
              </Grid>
              <Grid item xs={6}>
                {disciplines.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-disciplines`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {contractorCompanies?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Contractors:</Typography>
              </Grid>
              <Grid item xs={6}>
                {contractorCompanies.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-contractorCompanies`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {units?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Units:</Typography>
              </Grid>
              <Grid item xs={6}>
                {units.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-units`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {cardOwner?.value && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Card Owner:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary">
                  {cardOwner?.value.charAt(0).toUpperCase() +
                    cardOwner?.value.slice(1)}
                </Typography>
              </Grid>
            </>
          )}

          {timeIntervalCreate?.value && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Time Interval:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary">
                  {timeIntervalCreate?.value.charAt(0).toUpperCase() +
                    timeIntervalCreate?.value.slice(1)}
                </Typography>
              </Grid>
            </>
          )}

          {behaviours?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Behaviours:</Typography>
              </Grid>
              <Grid item xs={6}>
                {behaviours.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-behaviours`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {behaviourTypes?.value && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Behaviour Types:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary">
                  {behaviourTypes?.value.charAt(0).toUpperCase() +
                    behaviourTypes?.value.slice(1)}
                </Typography>
              </Grid>
            </>
          )}

          {observationLinkedTo?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Observation Linked To:</Typography>
              </Grid>
              <Grid item xs={6}>
                {observationLinkedTo.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-observationLinkedTo`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {subType?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Sub Type:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary">
                  {subType}
                </Typography>
              </Grid>
            </>
          )}

          {lsrType?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">LSR Type:</Typography>
              </Grid>
              <Grid item xs={6}>
                {lsrType.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-lsrType`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {rootCause?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Root Cause:</Typography>
              </Grid>
              <Grid item xs={6}>
                {rootCause.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-rootCause`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {observerType?.value && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Observer Type:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary">
                  {observerType?.value.charAt(0).toUpperCase() +
                    observerType?.value.slice(1)}
                </Typography>
              </Grid>
            </>
          )}

          {byPassedNonBypassed?.value && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">By Passed / Non By Passed:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary">
                  {byPassedNonBypassed?.value.charAt(0).toUpperCase() +
                    byPassedNonBypassed?.value.slice(1)}
                </Typography>
              </Grid>
            </>
          )}

          {cardStatus?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Card Status:</Typography>
              </Grid>
              <Grid item xs={6}>
                {cardStatus.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-cardStatus`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {directorates?.length > 0 && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Directorates:</Typography>
              </Grid>
              <Grid item xs={6}>
                {directorates.map((unit, index) => {
                  return (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      key={`${index}-directorates`}
                    >
                      {unit.label}
                    </Typography>
                  );
                })}
              </Grid>
            </>
          )}

          {groupBy?.value && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Group By:</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" color="textSecondary">
                  {groupBy?.value.charAt(0).toUpperCase() +
                    groupBy?.value.slice(1)}
                </Typography>
              </Grid>
            </>
          )}

          {reportType.value === "total_observation_report" && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Include Action Item:</Typography>
              </Grid>
              <Grid item xs={6}>
                {includeActionItem ? (
                  <Typography variant="body1" color="textSecondary">
                    <CheckIcon />
                  </Typography>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    <ClearIcon />
                  </Typography>
                )}
              </Grid>
            </>
          )}

          {(reportType.value === "card_count" ||
            reportType.value === "participation_percentage" ||
            reportType.value === "employee_card_count") && (
            <>
              <Grid item xs={6}>
                <Typography variant="h6">Include Child:</Typography>
              </Grid>
              <Grid item xs={6}>
                {includeChild ? (
                  <Typography variant="body1" color="textSecondary">
                    <CheckIcon />
                  </Typography>
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    <ClearIcon />
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      }
      handleClose={() => setScheduleModal(scheduleModalDefaults)}
      buttonLabel={t("general.saveChanges")}
      handleAction={() => {
        if (verifierModal.users.length <= 0) {
          displayToast(
            setLoading,
            "scheduleError",
            t("reporting.errors.scheduleNoUserError")
          );
          return;
        }
        const filterObj = {
          reportType: subType,
          timeInterval: selectedTimeInterval,
          startInterval: scheduleDate.format("DD/MM/YYYY"),
          userIds: verifierModal.users.map((user) => user.userId),
          filters: {
            buId:
              businessUnits?.length > 0
                ? JSON.stringify(businessUnits.map((item) => item.value))
                : undefined,
            companyIds:
              contractorCompanies?.length > 0
                ? JSON.stringify(contractorCompanies.map((item) => item.value))
                : undefined,
            includeActionItem: includeActionItem,
            includeChild: includeChild,
            locationIds:
              locations?.length > 0
                ? JSON.stringify(locations.map((item) => item.value))
                : undefined,
            disciplineIds:
              disciplines?.length > 0
                ? JSON.stringify(disciplines.map((item) => item.value))
                : undefined,
            unitIds:
              units?.length > 0
                ? JSON.stringify(units.map((item) => item.value))
                : undefined,
            cardOwners: cardOwner,
            timeInterval: timeIntervalCreate.value,
            behaviorIds:
              behaviours?.length > 0
                ? JSON.stringify(behaviours.map((item) => item.value))
                : undefined,
            behaviorType: behaviourTypes.value,
            observationLinkedTo:
              observationLinkedTo?.length > 0
                ? JSON.stringify(observationLinkedTo.map((item) => item.value))
                : undefined,
            subType: subType,
            lsrType:
              lsrType?.length > 0
                ? JSON.stringify(lsrType.map((item) => item.value))
                : undefined,
            rootCause:
              rootCause?.length > 0
                ? JSON.stringify(rootCause.map((item) => item.value))
                : undefined,
            groupBy: JSON.stringify([
              groupBy.value ? groupBy.value : undefined,
            ]),
            observerType: observerType.value,
            bypassedNonBypassed: byPassedNonBypassed.value,
            cardStatus:
              cardStatus?.length > 0
                ? JSON.stringify(cardStatus.map((item) => item.value))
                : undefined,
            directorateIds:
              directorates?.length > 0
                ? JSON.stringify(directorates.map((item) => item.value))
                : undefined,
          },
        };
        submitScheduleReport(filterObj);
        // setScheduleModal({...scheduleModal, open: false});
      }}
    />
  );

  const submitScheduleReport = (filterObj) => {
    setReportSchedule(filterObj)
      .then((res) => {
        displayToast(
          setLoading,
          "scheduleSuccess",
          "Report scheduled successfully"
        );
        setScheduleModal({ ...scheduleModal, open: false });
        setLoading(false);
      })
      .catch((err) => {
        displayToast(setLoading, "scheduleError", "Error scheduling report");
        console.log(err);
        setLoading(false);
      });
  };

  const renderDownloadingModal = () => (
    <InfoBox
      minWidth="50vw"
      noClose
      open={downloadModal}
      title={"Downloading Report"}
      content={
        <Stack spacing={2}>
          <Typography variant="body1" color="textSecondary">
            {`Please wait while the report is downloading... (${downloadProgress}%)`}
          </Typography>
          <BorderLinearProgress
            variant="determinate"
            value={downloadProgress}
          />
        </Stack>
      }
    />
  );

  const renderReportDetailsModal = () => (
    <InfoBox
      minWidth="50vw"
      open={reportDetailsModal.open}
      title={"Schedule Report"}
      content={
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1" color="textSecondary">
              Time period drop down
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="textSecondary">
              Emails:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" color="textSecondary">
              Contractors:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {contractorCompanies.map((company, index) => {
              return (
                <Typography variant="body1" color="textSecondary">
                  {company.label}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      }
      handleClose={() => setReportDetailsModal(reportDetailsModalDefaults)}
      buttonLabel={t("general.saveChanges")}
      handleAction={() => {
        // TODO: Add schedule report api functionality
        setReportDetailsModal({ ...reportDetailsModal, open: false });
      }}
    />
  );

  return (
    <Page title={t("reporting.viewReport")}>
      <Container maxWidth="xl">
        <Grid container justifyContent={"flex-end"} spacing={2}>
          <Grid item alignSelf={"flex-end"}>
            <SecondaryButton
              icon={<DownloadReportIcon />}
              onClick={(e) => {
                e.preventDefault();
                setAnchorEl(e.currentTarget);
              }}
              label={t("reporting.download")}
              disabled={loading}
              id="download-report-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
          </Grid>
          <Grid item>
            <Tooltip
              title={t("reporting.scheduleSpecificReport", {
                chartTitle: chartInfo.title,
              })}
            >
              <SecondaryButton
                id="Report Scheduling Button"
                label={t("reporting.scheduleReport")}
                icon={<ScheduleReportIcon />}
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  setScheduleModal({ ...scheduleModal, open: true });
                }}
              />
            </Tooltip>
          </Grid>
          {/* <Grid item alignSelf={"flex-end"}>
            <Tooltip title={t("reporting.manageSchedule")}>
              <IconButtonStyled
                aria-label={t("reporting.openDetails")}
                onClick={() =>
                  setReportDetailsModal({ ...reportDetailsModal, open: true })
                }
              >
                <InfoIconStyled />
              </IconButtonStyled>
            </Tooltip>
          </Grid> */}
        </Grid>
        {renderReport}
        <Grid container>
          <Grid item xs={12}>
            <SecondaryButton
              icon={<DownloadReportIcon />}
              onClick={submitDownloadPDF}
              label={t("reporting.download")}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </Container>
      {renderScheduleModal()}
      {renderReportDetailsModal()}
      {renderDownloadingModal()}
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleDownloadMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={submitDownloadPDF}>
          {t("general.downloadPdf")}
        </MenuItem>
        <MenuItem onClick={submitDownloadXLSX}>
          {t("general.downloadExcel")}
        </MenuItem>
      </Menu>
    </Page>
  );
}
