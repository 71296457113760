import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import AutocompleteInput from "./AutocompleteInput";
import { DeleteOutlineOutlined } from "@mui/icons-material";

BehaviorInput.propTypes = {
  behaviors: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  handleNewBehavior: PropTypes.func,
  behaviorOptions: PropTypes.arrayOf(PropTypes.object),
  handleNumberChange: PropTypes.func,
  handleRemoveBehavior: PropTypes.func,
  mandatory: PropTypes.bool,
  displayOnly: PropTypes.bool,
  categoryOptions: PropTypes.arrayOf(PropTypes.object),
  handleGetOtherBehaviors: PropTypes.func,
  displayLSRs: PropTypes.bool,
};

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  ".MuiOutlinedInput-input": {
    height: "10px",
    borderRadius: "10px",
    "&.Mui-disabled": {
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderColor: "#C2C2C2",
    background:
      "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.buttonOrange,
    },
    "&.Mui-disabled": {
      background: "#F1F1F1",
      "&:hover fieldset": {
        borderColor: "rgba(145, 158, 171, 0.24)",
      },
    },
  },
  "& .MuiOutlinedInput-root fieldset": {
    borderColor: "#C2C2C2",
  },
}));

const IconDeleteStyled = styled(DeleteOutlineOutlined)(({ theme }) => ({
  color: theme.palette.primary.white,
  width: 18,
  height: 18,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  height: "43px",
  width: "43px",
  borderRadius: "10px",
  background: theme.palette.primary.red,
  boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": {
    backgroundColor: theme.palette.primary.hoverRed,
  },
}));

export default function BehaviorInput({
  behaviors,
  disabled,
  behaviorOptions,
  handleNewBehavior,
  handleNumberChange,
  handleRemoveBehavior,
  mandatory,
  displayOnly,
  categoryOptions,
  handleGetOtherBehaviors,
  displayLSRs,
}) {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [selectedOtherBehavior, setSelectedOtherBehavior] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const submitNewBehavior = (v) => {
    const targetOption = behaviorOptions.find((b) => b.value === v);
    setSelectedOtherBehavior("");
    handleNewBehavior(targetOption);
  };

  useEffect(() => {
    const categoryDropdownHandler = async () => {
      try {
        if (handleGetOtherBehaviors && selectedCategoryId) {
          await handleGetOtherBehaviors(selectedCategoryId);
        }
      } catch (err) {
        console.log(err);
      }
    };
    categoryDropdownHandler();
  }, [selectedCategoryId]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("behaviorInput.number")}</TableCell>
              <TableCell align="left">{t("behaviorInput.behavior")}</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell align="left"></TableCell>
              <TableCell
                align="left"
                style={{
                  color: theme.palette.primary.green,
                  fontWeight: "bold",
                }}
              >
                {t("behaviorInput.safe")}
              </TableCell>
              <TableCell
                align="left"
                style={{ color: theme.palette.primary.red, fontWeight: "bold" }}
              >
                {t("behaviorInput.unsafe")}
              </TableCell>
              {!mandatory && <TableCell align="left"></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {behaviors.length > 0 ? (
              behaviors.map((row, index) => (
                <TableRow
                  key={`behaviorInputRow-${row.name}-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={1}
                    style={{ fontWeight: "bold" }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    align="left"
                    colSpan={3}
                    style={{ fontWeight: "bold" }}
                  >
                    {displayLSRs ? (
                      <Stack>
                        <p>
                          {currentLanguage === "ar" && row.labelAR
                            ? row.labelAR
                            : row.label}
                        </p>
                        <p
                          style={{
                            fontWeight: "lighter",
                            fontSize: "12px",
                          }}
                        >
                          {currentLanguage === "ar" && row.lsrAr
                            ? row.lsrAr
                            : row.lsr
                            ? row.lsr
                            : ""}
                        </p>
                      </Stack>
                    ) : currentLanguage === "ar" && row.labelAR ? (
                      row.labelAR
                    ) : (
                      row.label
                    )}
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    {displayOnly ? (
                      <p
                        style={{
                          color: theme.palette.primary.green,
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {row.safe}
                      </p>
                    ) : (
                      <TextFieldStyle
                        fullWidth
                        disabled={disabled}
                        type="text"
                        placeholder={0}
                        value={row.safe}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                          style: {
                            color: theme.palette.primary.green,
                            fontWeight: "bold",
                          },
                        }}
                        onChange={(e) => {
                          if (isNaN(e.target.value) || !e.target.value) {
                            handleNumberChange(row.id, "safe", 0);
                          } else {
                            let temp = parseInt(e.target.value);
                            if (temp < 0) temp = 0;
                            handleNumberChange(row.id, "safe", temp ?? 0);
                          }
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    {displayOnly ? (
                      <p
                        style={{
                          color: theme.palette.primary.red,
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        {row.unsafe}
                      </p>
                    ) : (
                      <TextFieldStyle
                        fullWidth
                        placeholder={0}
                        type="text"
                        disabled={disabled}
                        value={row.unsafe}
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                          style: {
                            color: theme.palette.primary.red,
                            fontWeight: "bold",
                          },
                        }}
                        onChange={(e) => {
                          if (isNaN(e.target.value) || !e.target.value) {
                            handleNumberChange(row.id, "unsafe", 0);
                          } else {
                            let temp = parseInt(e.target.value);
                            if (temp < 0) temp = 0;
                            handleNumberChange(row.id, "unsafe", temp ?? 0);
                          }
                        }}
                      />
                    )}
                  </TableCell>
                  {!mandatory && (
                    <TableCell align="center">
                      <Tooltip title={"Remove behavior"}>
                        <IconButtonStyle
                          aria-label={"Remove behavior"}
                          onClick={() => {
                            handleRemoveBehavior(row);
                            setSelectedOtherBehavior(null);
                          }}
                          disabled={disabled}
                        >
                          <IconDeleteStyled />
                        </IconButtonStyle>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography variant="body1" style={{ textAlign: "center" }}>
                    {t("behaviorInput.noneListed")}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {behaviorOptions && handleNewBehavior && (
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          style={{ marginTop: "5px" }}
        >
          <Grid item xs={12} sm={10} md={6}>
            <AutocompleteInput
              handler={(e, v) => {
                if (v) {
                  setSelectedCategoryId(v.value);
                } else {
                  setSelectedCategoryId("");
                }
                setSelectedOtherBehavior("");
              }}
              options={categoryOptions}
              disabled={
                disabled || (categoryOptions && categoryOptions.length === 0)
              }
              label={t("behaviorInput.category")}
            />
          </Grid>
          <Grid item xs={12} sm={10} md={6}>
            <AutocompleteInput
              value={selectedOtherBehavior}
              handler={(e, v) => {
                v ? submitNewBehavior(v.value) : setSelectedOtherBehavior("");
              }}
              options={behaviorOptions}
              disabled={
                disabled || (behaviorOptions && behaviorOptions.length === 0)
              }
              placeholder={
                behaviorOptions && behaviorOptions.length === 0
                  ? t("behaviorInput.noCategory")
                  : t("behaviorInput.behavior")
              }
              label={t("behaviorInput.behavior")}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
