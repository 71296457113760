import "./styles.css";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// material
import { Grid, Stack, Card, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FilterIcon from "@mui/icons-material/SortOutlined";
// components
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import HeatmapChart from "../../../components/charts/HeatmapChart";
import CircularStatus from "../../../components/CircularStatus";
import GroupedBarChart from "../../../components/charts/GroupedBarChart";
import { displayToast } from "../../../utils/general";
import DateInput from "../../../components/inputs/DateInput";
import moment from "moment";
import MultiSelectInput from "../../../components/inputs/MultiSelectInput";
import { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CloudDownload } from "@mui/icons-material";

// ----------------------------------------------------------------------
// apis
import {
  getCompanyFilters,
  getDirectorateAverageMaturity,
  getDirectorateFilters,
  getDirectorateHeatMap,
} from "../../../api/kpiDashboards";
import SelectInput from "../../../components/inputs/SelectInput";

const cardStyling = {
  height: 350,
  padding: "16px ",
  borderRadius: "6px",
};

const centerStyling = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const defaultYears = [
  {
    label: "2023",
    value: 2023,
  },
];

export default function KPIOverview() {
  const { t } = useTranslation();
  const defaultDirectorates = [
    {
      label: t("general.all"),
      value: "all",
    },
  ];

  const auditDropDown = [
    {
      label: t("general.yes"),
      value: "true",
    },
    {
      label: t("general.no"),
      value: "false",
    },
  ];

  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [directorateDropDown, setDirectorateDropDown] =
    useState(defaultDirectorates);
  const [selectedDirectorate, setSelectedDirectorate] = useState(null);
  const [loadingDirectorates, setLoadingDirectorates] = useState(false);
  const [heatMapLoading, setHeatMapLoading] = useState(false);
  const [heatMapData, setHeatMapData] = useState([]);
  const [searchCompanies, setSearchCompanies] = useState([]);
  const [maturityChartLoading, setMaturityChartLoading] = useState(false);
  const [maturityDirectorates, setMaturityDirectorates] = useState([]);
  const [maturityData, setMaturityData] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf("year"));
  const [endDate, setEndDate] = useState(moment().endOf("month"));
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companiesDropDown, setCompaniesDropDown] = useState([]);
  const [showAudit, setShowAudit] = useState("false");
  const pagesRef = useRef([]);
  const chartsRef = useRef([]);
  const timeout = useRef();

  const heatMapOptions = {
    chart: {
      fontFamily: "Cairo, sans-serif",
      events: {
        click: (event, chartContext, config) => {
          if (
            event.toElement?.nextSibling?.innerHTML &&
            event.target.parentNode.classList.contains(
              "quality-score-heatmap-yaxis-label"
            )
          ) {
            const targetName = event.toElement?.nextSibling?.innerHTML;
            const finalText = targetName.replace(/&amp;/g, "&");
            const targetInList = searchCompanies.find(
              (c) => c.name === finalText
            );
            if (targetInList) {
              navigate(
                `/dashboard/kpis/directorate/${targetInList.directorateId}/company/${targetInList.id}`
              );
            }
          }
        },
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: false,
        colorScale: {
          ranges: [
            {
              from: 0,
              to: 65,
              color: "#FF0000",
            },
            {
              from: 65,
              to: 85,
              color: "#FDD600",
            },
            {
              from: 85,
              to: 100,
              color: "#00B050",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val}%`,
      style: {
        fontSize: "14px",
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}%`,
      },
    },
    xaxis: {
      type: "category",
      position: "top",
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: true,
    },
    legend: {
      show: false,
      position: "bottom",
    },
    yaxis: {
      labels: {
        style: {
          cssClass: "quality-score-heatmap-yaxis-label",
        },
      },
    },
  };

  const handleSearchCompanies = async (e) => {
    setSelectedDirectorate(e);
    setCompaniesDropDown([]);
    setLoadingCompanies(true);
    setSelectedCompanies(null);
    clearTimeout(timeout.current);
    if (e) {
      timeout.current = setTimeout(async () => {
        try {
          const result = await getCompanyFilters(e.map((entry) => entry.value));
          setCompaniesDropDown(result?.companies);
          setLoadingCompanies(false);
        } catch (err) {
          setCompaniesDropDown([]);
          setSelectedCompanies(null);
          setLoadingCompanies(false);
        }
      }, 1000);
    } else {
      setCompaniesDropDown([]);
      setSelectedCompanies(null);
    }
  };

  const renderFilters = () => (
    <Grid container spacing={1} sx={{ mt: 1 }} alignItems="flex-end">
      <Grid item xs={12} sm={4} md={2}>
        <MultiSelectInput
          label={t("kpiDashboards.filterDirectorate")}
          disabled={loadingDirectorates}
          value={selectedDirectorate}
          handler={(e) => handleSearchCompanies(e)}
          options={directorateDropDown}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <MultiSelectInput
          label={t("kpiDashboards.filterCompany")}
          disabled={loadingCompanies}
          value={selectedCompanies}
          handler={(e) => setSelectedCompanies(e)}
          options={companiesDropDown}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <DateInput
          disabled={false}
          label={t("kpiDashboards.startDate")}
          date={startDate}
          handler={(newDate) => setStartDate(newDate)}
          monthView={true}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <DateInput
          disabled={false}
          label={t("kpiDashboards.endDate")}
          date={endDate}
          handler={(newDate) => setEndDate(newDate)}
          monthView={true}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={2}>
        <SelectInput
          label={t(`kpi.includeAudit`)}
          disabled={false}
          value={showAudit}
          handler={(e) => setShowAudit(e.target.value)}
          options={auditDropDown}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={2}>
        <SecondaryButton
          icon={<FilterIcon />}
          label={t("observations.viewObservations.filters.apply")}
          alignment="left"
          disabled={loading}
          onClick={() => applyFilters()}
        />
      </Grid>
    </Grid>
  );

  const RenderQualityScoreChart = () => {
    return useMemo(() => {
      return heatMapData.map((entry, index) => (
        <Card
          ref={(el) => {
            if (!pagesRef.sizeCount) pagesRef.sizeCount = [];
            pagesRef.current[index] = el;
            pagesRef.sizeCount[index] = entry?.data?.length;
          }}
          style={{
            ...cardStyling,
            height:
              entry.data?.length > 24
                ? 1700
                : entry.data?.length > 10
                ? 800
                : cardStyling.height,
          }}
        >
          <Typography variant="h6">
            {t("dashboards.implementationDashboards.postImplementationHeatMap")}
          </Typography>
          <Grid container spacing={1} style={{ height: "100%" }}>
            <Grid item xs={12} sm={2} style={centerStyling}>
              <Stack spacing={3}>
                <Typography variant="h4" align="center">
                  {entry?.name}
                </Typography>
                <CircularStatus
                  value={entry.overAll}
                  label={`${entry.overAll}%`}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={10}>
              <HeatmapChart
                height={entry?.data?.length < 3 ? "60%" : "100%"}
                chartData={entry?.data}
                chartOptions={heatMapOptions}
              />
            </Grid>
          </Grid>
        </Card>
      ));
    }, [heatMapData, t]);
  };

  const RenderMaturityBarChart = () => {
    return useMemo(() => {
      return (
        <Card
          ref={(el) => {
            chartsRef.current[0] = el;
          }}
          style={{ ...cardStyling, height: 600 }}
        >
          <Typography variant="h6">
            {t("dashboards.implementationDashboards.maturityLevelPerMonth")}
          </Typography>
          <GroupedBarChart
            height="90%"
            chartData={maturityData}
            chartOptions={{
              chart: {
                type: "bar",
                height: 350,
                toolbar: {
                  show: false,
                },
              },
              colors: [
                "#33FFE9",
                "#33FF57",
                "#3366FF",
                "#FF33E9",
                "#f46a9b",
                "#ef9b20",
                "#16a085",
                "#c0392b",
                "#bdcf32",
                "#87bc45",
                "#27aeef",
                "#b33dc6",
              ],
              plotOptions: {
                bar: {
                  horizontal: false,
                  endingShape: "rounded",
                  dataLabels: {
                    position: "top",
                  },
                },
              },
              dataLabels: {
                style: {
                  colors: ["#333"],
                  fontSize: "10px",
                },
                offsetY: -30,
                dropShadow: {
                  enabled: true,
                  top: 1,
                  left: 1,
                  blur: 0.5,
                  color: "#FFFFFF",
                  opacity: 1,
                },
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories: maturityDirectorates,
              },
              yaxis: {
                min: 0,
                max: 100,
                forceNiceScale: true,
                title: {
                  text: "Maturity level in %",
                },
                labels: {
                  formatter: (val) => `${val}%`,
                },
              },
              fill: {
                opacity: 1,
              },
              tooltip: {
                y: {
                  formatter: (val) => `${val}%`,
                },
              },
            }}
          />
        </Card>
      );
    }, [maturityData, maturityDirectorates, t]);
  };

  const RenderDownloadButton = () => {
    return (
      <div>
        <SecondaryButton
          style={{ display: "flex", width: "auto" }}
          icon={<CloudDownload />}
          label={t("general.downloadPdf")}
          disabled={loading}
          onClick={() => submitDownload()}
        />
      </div>
    );
  };

  const getFilters = async () => {
    try {
      setLoadingDirectorates(true);
      let tempFilters = await getDirectorateFilters();
      if (tempFilters?.directorates?.length > 0) {
        setLoadingDirectorates(false);
        setDirectorateDropDown(tempFilters?.directorates);

        getHeatMap();
        getMaturityLevel();
      }
    } catch (e) {
      displayToast(setLoadingDirectorates, "error", e, "loadingDirectorates");
    }
  };

  const getHeatMap = async () => {
    try {
      let query = `?`;
      if (startDate) {
        query += `startDate=${startDate.format("MM-YYYY")}`;
      }
      if (endDate) {
        query += `&endDate=${endDate.format("MM-YYYY")}`;
      }
      if (selectedDirectorate) {
        let temp = selectedDirectorate.map((entry) => entry.value);
        query += `&directorates=${temp}`;
      }
      if (selectedCompanies) {
        query += `&companies=${selectedCompanies?.map((e) => e.value)}`;
      }
      if (showAudit === "true") {
        query += `&audit=true`;
      }
      setHeatMapLoading(true);
      let tempData = await getDirectorateHeatMap(query);
      if (tempData?.heatMap?.length > 0) {
        let tempCompanies = [];
        tempData?.heatMap.forEach((entry) => {
          entry?.data?.forEach((company) => {
            tempCompanies.push({
              name: company.name,
              id: company?.id,
              directorateId: entry.id,
            });
          });
        });
        setSearchCompanies(tempCompanies);
        setHeatMapData(tempData?.heatMap);
        setHeatMapLoading(false);
      } else {
        setHeatMapLoading(false);
        setHeatMapData([]);
      }
    } catch (e) {
      displayToast(setHeatMapLoading, "error", e, "loadingDirectorates");
    }
  };

  const getMaturityLevel = async () => {
    try {
      let query = `?`;
      if (startDate) {
        query += `startDate=${startDate.format("MM-YYYY")}`;
      }
      if (endDate) {
        query += `&endDate=${endDate.format("MM-YYYY")}`;
      }
      if (selectedDirectorate) {
        let temp = selectedDirectorate.map((entry) => entry.value);
        query += `&directorates=${temp}`;
      }
      if (selectedCompanies) {
        query += `&companies=${selectedCompanies?.map((e) => e.value)}`;
      }
      if (showAudit === "true") {
        query += `&audit=true`;
      }
      setMaturityChartLoading(true);
      let tempData = await getDirectorateAverageMaturity(query);
      if (tempData?.data?.length > 0) {
        setMaturityDirectorates(tempData?.directorates);
        setMaturityData(tempData?.data);
        setMaturityChartLoading(false);
      } else {
        setMaturityData([]);
        setMaturityChartLoading(false);
      }
    } catch (e) {
      displayToast(setMaturityChartLoading, "error", e, "loadingDirectorates");
    }
  };

  const applyFilters = () => {
    getHeatMap();
    getMaturityLevel();
  };

  const submitDownload = async () => {
    const pdf = new jsPDF("portraist", "pt", "a4");
    let pageIterate = 0;
    let tempRender = [];
    let tempPageSize = [];
    let tempMoreSize = [];
    for (let i = 0; i < pagesRef.current.length; i++) {
      if (pagesRef.current[i] && pagesRef.sizeCount[i] < 25) {
        tempRender.push(pagesRef.current[i]);
        tempPageSize.push(pagesRef.sizeCount[i]);
      }
      if (pagesRef.current[i] && pagesRef.sizeCount[i] >= 25)
        tempMoreSize.push(pagesRef.current[i]);
    }

    if (tempMoreSize?.length > 0 && tempRender?.length === 0) {
      for (let j = 0; j < tempMoreSize?.length; j++) {
        let data = await html2canvas(tempMoreSize[j], {
          scale: 1.3,
        });
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        let pdfWidth = pdf.internal.pageSize.getWidth();
        let pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST");
        if (j + 1 === tempMoreSize?.length) {
          pdf.addPage();
          let chartData = await html2canvas(chartsRef.current[0], {
            scale: 1.3,
          });
          const imgData = chartData.toDataURL("image/png");
          const imgPropertiesData = pdf.getImageProperties(imgData);
          pdfWidth = pdf.internal.pageSize.getWidth();
          pdfHeight =
            (imgPropertiesData.height * pdfWidth) / imgPropertiesData.width;
          pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST");
        }
      }
    } else if (tempMoreSize?.length > 0) {
      for (let j = 0; j < tempMoreSize?.length; j++) {
        let data = await html2canvas(tempMoreSize[j], {
          scale: 1.3,
        });
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        let pdfWidth = pdf.internal.pageSize.getWidth();
        let pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight, "", "FAST");
        pdf.addPage();
      }
    }
    for (let i = 0; i < tempRender.length; i++) {
      displayToast(
        setLoading,
        "loading",
        `${t("reporting.downloading")} : ${(
          ((i + 1) / (tempRender.length + 1)) *
          100
        ).toFixed(0)} %`,
        "generatingPDF"
      );
      // One Page can consist of 350px , we have two types of variations , one takes 350px and one takes 700px
      if (pageIterate >= 5) {
        pdf.addPage();
        pageIterate = 0;
      }
      let data = await html2canvas(tempRender[i], {
        scale: 1.3,
      });
      const img = data.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      let pdfWidth = pdf.internal.pageSize.getWidth();
      let pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(
        img,
        "PNG",
        0,
        0 + pageIterate * 150,
        pdfWidth,
        pdfHeight,
        "",
        "FAST"
      );
      if (tempPageSize[i] >= 10) {
        pageIterate += 2;
      } else {
        pageIterate += 1;
      }

      if (i + 1 === tempRender.length && data) {
        if (pageIterate >= 5) {
          pdf.addPage();
          pageIterate = 0;
        }
        displayToast(
          setLoading,
          "loading",
          `${t("reporting.downloading")} (${tempRender.length + 1}/${
            tempRender.length + 1
          })`,
          "generatingPDF"
        );

        let chartData = await html2canvas(chartsRef.current[0], {
          scale: 1.3,
        });
        const imgData = chartData.toDataURL("image/png");
        const imgPropertiesData = pdf.getImageProperties(imgData);
        pdfWidth = pdf.internal.pageSize.getWidth();
        pdfHeight =
          (imgPropertiesData.height * pdfWidth) / imgPropertiesData.width;
        pdf.addImage(
          imgData,
          "PNG",
          0,
          // ((pageIterate % 5 == 4 ? 0 : pageIterate + 1) % 5) * 150,
          pageIterate * 150,
          pdfWidth,
          pdfHeight,
          "",
          "FAST"
        );
      }
    }

    pdf.save("ObservationDashboard.pdf");
    setLoading(false);
    displayToast(
      setLoading,
      "success",
      t("reporting.downloadSuccess"),
      "generatingPDF"
    );
  };

  useEffect(() => {
    getFilters();
  }, []);

  return (
    <Stack spacing={2} direction="column">
      {renderFilters()}
      {directorateDropDown && (
        <>
          {RenderDownloadButton()}
          {RenderQualityScoreChart()}
          {RenderMaturityBarChart()}
        </>
      )}
    </Stack>
  );
}
