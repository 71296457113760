import apiHelper from "./apiHelper";
import { generateFormData } from "../utils/general";

export const getFiltersForReports = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/reports/filters`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getBehaviorFiltersApi = (
  directorateIds,
  companyIds,
  locationIds,
  unitIds = [],
  disciplineIds
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/reports/behaviorFilters`, {
        params: {
          directorateIds,
          companyIds,
          locationIds,
          unitIds,
          disciplineIds,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getDirectorateFiltersForReports = () => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/reports/directorateFilters`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCompanyFiltersForReports = (directorateIds) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/reports/companyFilters`, {
        params: {
          directorateIds: directorateIds,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocationUnitFiltersForReports = (
  directorateIds,
  companyIds,
  locationIds
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/reports/locationUnitFilters`, {
        params: {
          directorateIds,
          companyIds,
          locationIds,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getDisciplinesFiltersForReports = (
  companyIds,
  unitIds,
  locationIds,
  directorateIds
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/reports/disciplineFilters`, {
        params: {
          companyIds: companyIds,
          unitIds: unitIds,
          locationIds: locationIds,
          directorateIds,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getReportData = (reportType, filterObj) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`api/reports/data/${reportType}`, {
        params: {
          ...filterObj,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const setReportSchedule = (filterObj) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`api/reports/schedule`, {
        ...filterObj,
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getReportSchedule = (reportType, page = 1, pageSize = 10) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `api/reports/schedule?page=${page}&pageSize=${pageSize}&report_type=${reportType}`,
        {}
      )
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getReportScheduleWithoutType = (page = 1, pageSize = 10) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`api/reports/schedule?page=${page}&pageSize=${pageSize}`, {})
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getMyScheduledReportsApi = (
  { pageNo = 1, pageSize = 10 },
  createdByMe = false
) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`api/reports/schedule/me`, {
        params: {
          pageNo,
          pageSize,
          createdByMe,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const deleteScheduledReportApi = ({ id, optOut = false }) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .delete(`api/reports/schedule/me/${id}`, {
        params: {
          optOut,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const createCreatorReport = (body) => {
  const bodyFormData = generateFormData(body);
  return new Promise((resolve, reject) => {
    apiHelper
      .post(`api/reports/creator`, bodyFormData)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getCreatorReport = ({ pageNo, pageSize }) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`api/reports/creator`, {
        params: {
          pageNo,
          pageSize,
        },
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getScheduledReport = (scheduleId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`api/reports/schedule/${scheduleId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
