import React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Stack, Box, Badge } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

TabsBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  dense: PropTypes.bool,
  style: PropTypes.object,
  wrapped: PropTypes.bool,
};

const TabStyled = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: "bold",
  borderBottom: "2px solid #F7D5AE",
  color: theme.palette.primary.grey,
  ".MuiTab-iconWrapper": {
    marginLeft: "18px",
  },
}));

export default function TabsBar({
  value,
  handler,
  tabs,
  dense,
  sx,
  style,
  wrapped,
}) {
  return (
    <Box sx={{ ...sx, width: "100%" }} style={{ ...style }}>
      <Box>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handler}
          aria-label="tabbed navigation bar"
        >
          {tabs &&
            tabs.map((tabObj, index) => (
              <TabStyled
                label={
                  tabObj.value ? (
                    <Stack
                      spacing={3}
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                    >
                      <p>{tabObj.label}</p>
                      <p
                        style={{
                          paddingBottom: "2px",
                          paddingRight: 4,
                        }}
                      >
                        <Badge badgeContent={tabObj.value} color="error" />
                      </p>
                    </Stack>
                  ) : (
                    tabObj.label
                  )
                }
                value={tabObj.key || index}
                wrapped={wrapped || false}
                iconPosition="end"
                key={`tabs-bar-${tabObj.label}-${index}`}
                style={{
                  padding: `${dense ? "12px 12px" : "12px 50px"}`,
                }}
              />
            ))}
        </Tabs>
      </Box>
    </Box>
  );
}
