import React from "react";
import PropTypes from "prop-types";
import { TextField, Box, Stack, Typography, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { InfoOutlined, Clear } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import HelpText from "../HelpText";

const TextFieldStyle = styled(TextField)(({ theme }) => ({
  ".MuiOutlinedInput-input": {
    height: "10px",
    borderRadius: "10px",
    "&.Mui-disabled": {
      boxShadow: "none",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderColor: "#C2C2C2",
    background: "linear-gradient(180deg, #FEFEFE 0%, #F0F0F3 100%)",
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.buttonOrange,
    },
    "&.Mui-disabled": {
      background: "#F1F1F1",
      "&:hover fieldset": {
        borderColor: "rgba(145, 158, 171, 0.24)",
      },
    },
  },
  "& .MuiOutlinedInput-root fieldset": {
    borderColor: "#C2C2C2",
  },
}));

const IconStyle = styled(InfoOutlined)(({ theme }) => ({
  height: "20px",
  width: "20px",
  color: theme.palette.primary.orange,
  cursor: "help",
}));

const TextInput = ({
  label,
  helpText,
  disabled,
  value,
  handler,
  type,
  placeholder,
  keyPressHandler,
  icon,
  labelColor,
  clearable,
  clearHandler,
  ...props
}) => {
  const { i18n } = useTranslation();

  return (
    <Box>
      {label && (
        <Stack
          direction="row"
          style={{ paddingBottom: "4px" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" style={{ color: labelColor }}>
            <div style={{ display: "flex" }}>
              {icon}
              {label}
            </div>
          </Typography>
          {helpText && (
            <HelpText title={helpText}>
              <IconStyle />
            </HelpText>
          )}
        </Stack>
      )}

      <TextFieldStyle
        variant="outlined"
        placeholder={placeholder || label}
        fullWidth
        type={type}
        disabled={disabled}
        value={value}
        onChange={handler}
        onKeyPress={keyPressHandler}
        InputProps={{
          spellCheck: true,
          lang: i18n.language === "ar" ? "ar" : "en-US",
          endAdornment: clearable ? (
            <IconButton onClick={clearHandler} disabled={disabled}>
              <Clear />
            </IconButton>
          ) : undefined,
          ...(clearable && { style: { paddingRight: 0 } }),
        }}
        {...props}
      />
    </Box>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func,
  styleProps: PropTypes.object,
  placeholder: PropTypes.string,
  keyPressHandler: PropTypes.func,
  type: PropTypes.string,
  labelColor: PropTypes.string,
  clearable: PropTypes.bool,
  clearHandler: PropTypes.func,
};

export default TextInput;
