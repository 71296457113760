import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import MultiSelectInput from "../../../components/inputs/MultiSelectInput";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { assignReviewApi } from "../../../api/sustainabilityReviews";
import { displayToast } from "../../../utils/general";

function UnitSelection({ data,payload,closeDialogue }) {
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [units, setUnits] = useState([]);

  const handleAssign = async () => {
    try {
      setLoading(true);
      let tempData = {
        locationIds: selectedLocation.map(entry=>(entry.value)),
        unitIds: selectedUnit.map(entry=>(entry.value)),
        companyId: payload.companyId,
        implementationId: payload.planId,
      };
      let assignRequest = await assignReviewApi(tempData);
      if (assignRequest) {
        displayToast(
          setLoading,
          "success",
          "Sustainability review assigned succesfully",
          'successSustainabilityReview'
        );
        closeDialogue()
      }
    } catch (e) {
      displayToast(
        setLoading,
        "error",
        "There was some error assigning task",
        "errorSustainabilityReview"
      );
    }
  };

  useEffect(() => {
    if (data?.units?.length > 0) {
      let tempUnits = [];
      data.units.forEach((unit) => {
        tempUnits.push({ label: unit.name, value: unit.id });
      });
      setUnits(tempUnits);
    }
    if (data?.locations?.length > 0) {
      let tempLocations = [];
      data.locations.forEach((location) => {
        tempLocations.push({ label: location.name, value: location.id });
      });
      setLocations(tempLocations);
    }
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <MultiSelectInput
            label={"Select Location"}
            disabled={selectedUnit.length > 0 ? true : false}
            options={locations}
            handler={(newValue) => {
              setSelectedLocation(newValue);
            }}
            values={selectedLocation}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MultiSelectInput
            label={"Select Unit"}
            disabled={selectedLocation.length > 0 ? true : false}
            options={units}
            handler={(newValue) => setSelectedUnit(newValue)}
            values={selectedUnit}
          />
        </Grid>
        <Grid style={{ marginTop: "20px" }} item xs={12} md={12}>
          <PrimaryButton
            loading={loading}
            onClick={() => handleAssign()}
            label={"Assign to selected units/locations"}
            alignment="left"
            disabled={
              selectedLocation.length > 0 || selectedUnit?.length > 0
                ? false
                : true
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default UnitSelection;
