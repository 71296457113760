import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { displayToast } from "../../../utils/general";
import { orderBy } from "lodash";
//  Material
import { Grid, Typography, Box, Container, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GroupIcon from "@mui/icons-material/Group";
//  Components
import Page from "../../../components/Page";
import HeaderBackButton from "../../../components/buttons/HeaderBackButton";
import TabsBar from "../../../components/navigation/TabsBar";
import CopyBox from "../../../components/CopyBox";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ReviewInput from "../../../components/inputs/ReviewInput";
import AutocompleteInput from "../../../components/inputs/AutocompleteInput";
import BarChart from "../../../components/charts/BarChart";
import InfoBox from "../../../components/InfoBox";
import DefaultTable from "../../../components/tables/DefaultTable";
import ActivityTable from "../../../components/tables/ActivityTable";
//  API
import {
  getLatestReviewApi,
  getStatsApi,
  assignReviewApi,
  getTextResponsesApi,
  getSubmissionsApi,
  getSingleResponseApi,
  getRevisionsApi,
} from "../../../api/sustainabilityReviews";
import { searchUsersApi } from "../../../api/users";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import SelectInput from "../../../components/inputs/SelectInput";
import UnitSelection from "./UnitSelection";
import { getLocationsAndUnitsApi } from "../../../api/implementation";

const widgetStyling = {
  border: "1px solid #F7D5AE",
  background:
    "linear-gradient(180deg, rgba(240, 240, 243, 0) 0%, #F0F0F3 100%)",
  borderRadius: 0,
  padding: "18px",
  margin: "6px",
};

const submissionModalDefaults = {
  open: false,
  submitter: "",
  submissionDate: new Date(),
  responses: [],
};

const defaultlanguageTypes = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "हिन्दी(Hindi)",
    value: "hi",
  },
  {
    label: "عربي(Arabic)",
    value: "ar",
  },
];

export const SustainabilityReviewsActivity = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const theme = useTheme();
  const timeout = useRef();
  const { companyId, planId } = useParams();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [revision, setRevision] = useState("");
  const [revisionsDropdown, setRevisionsDropdown] = useState([]);
  const [defaultChartOptions, setDefaultChartOptions] = useState({
    colors: [
      theme.palette.error.dark,
      theme.palette.error.main,
      theme.palette.warning.main,
      theme.palette.success.main,
      theme.palette.success.dark,
    ],
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    stroke: { colors: [theme.palette.background.paper] },
    legend: { show: false },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    xaxis: {
      categories: [
        t("reviews.likert.sDisagree"),
        t("reviews.likert.disagree"),
        t("reviews.likert.neutral"),
        t("reviews.likert.agree"),
        t("reviews.likert.sAgree"),
      ],
    },
  });
  const [statsTab, setStatsTab] = useState("singleChart");
  const [combinedStats, setCombinedStats] = useState([]);

  const [dataDictionary, setDataDictionary] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [tab, setTab] = useState("sendReviews");

  const [link, setLink] = useState(t("reviews.loadingLink"));
  const [emailList, setEmailList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [userDropdown, setUserDropdown] = useState([]);
  const [stats, setStats] = useState([]);
  const [responseModal, setResponseModal] = useState({
    open: false,
    responses: [],
    title: "",
  });
  const [submissionRows, setSubmissionRows] = useState([]);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterParams, setFilterParams] = useState({ pageSize: 10, pageNo: 1 });
  const [submissionModal, setSubmissionModal] = useState(
    submissionModalDefaults
  );
  const [languageString, setLanguageString] = useState("en");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [locationData, setLocationData] = useState({});

  const submissionColumnDefaults = [
    { field: "id", headerName: t("table.id"), width: 200, sortable: false },
    {
      field: "revision",
      headerName: t("table.revision"),
      width: 200,
      sortable: false,
    },
    {
      field: "submitter",
      headerName: t("table.submittedBy"),
      width: 400,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: t("table.dateSubmitted"),
      width: 200,
      sortable: false,
    },
  ];

  const responseColumnDefaults = [
    { field: "answer", headerName: "Answer", width: 600, sortable: false },
  ];

  const tabsList = [
    {
      label: t(`implementations.sustainabilityReviews.sendReviews`),
      value: 0,
      key: "sendReviews",
    },
    {
      label: t(`implementations.sustainabilityReviews.preview`),
      value: 0,
      key: "preview",
    },
    {
      label: t(`implementations.sustainabilityReviews.statistics`),
      value: 0,
      key: "statistics",
    },
    {
      label: t(`implementations.sustainabilityReviews.submissions`),
      value: 0,
      key: "submissions",
    },
  ];

  const statsTabsList = [
    {
      label: t(`implementations.sustainabilityReviews.statsOptions.basic`),
      value: 0,
      key: "singleChart",
    },
    {
      label: t(`implementations.sustainabilityReviews.statsOptions.combine`),
      value: 0,
      key: "combinedChart",
    },
  ];

  const defaultCombineStatsOptions = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "70%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: [
      theme.palette.error.dark,
      theme.palette.error.main,
      theme.palette.warning.main,
      theme.palette.success.main,
      theme.palette.success.dark,
    ],
    xaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: "10px",
        },
        trim: true,
        rotate: 0,
        hideOverlappingLabels: false,
      },
    },
    yaxis: {
      title: {
        text: "No. of responses",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  const defaultCombineStatsSeries = [
    {
      name: "Strongly Disagree",
      data: [],
    },
    {
      name: "Disagree",
      data: [],
    },
    {
      name: "Neutral",
      data: [],
    },
    {
      name: "Agree",
      data: [],
    },
    {
      name: "Strongly Agree",
      data: [],
    },
  ];

  const renderSendReviews = () => (
    <>
      {/* <Grid item xs={12}>
        <Typography variant="body1">
          {t("implementations.sustainabilityReviews.linkDescription")}
        </Typography>
      </Grid> */}
      <Grid item xs={12} md={7}>
        <CopyBox
          disabled={loading}
          labelText={t("implementations.sustainabilityReviews.linkTitle")}
          copyText={link}
          hoverText={t("general.copy")}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          {t("implementations.sustainabilityReviews.emailDescription")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <AutocompleteInput
          multiple
          value={emailList}
          handler={(e, v) => setEmailList(v)}
          keyPressHandler={handleSearchUser}
          label={t("implementations.sustainabilityReviews.emailTitle")}
          placeholder={t("implementations.sustainabilityReviews.emailUsers")}
          loading={searchLoading}
          disabled={loading}
          options={userDropdown}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <SecondaryButton
          icon={<GroupIcon />}
          onClick={() => submitAssignReview("list")}
          label={t("implementations.sustainabilityReviews.sendEmails")}
          disabled={loading || emailList.length < 1}
          alignment="left"
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <Typography variant="body1">
          {t("implementations.sustainabilityReviews.companyWideText")}
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <PrimaryButton
          onClick={() => submitAssignReview("company")}
          label={t("implementations.sustainabilityReviews.companyWideButton")}
          alignment="left"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <PrimaryButton
          onClick={() => setDialogOpen(true)}
          label={t("implementations.sustainabilityReviews.unitWideButton")}
          alignment="left"
          disabled={loading}
        />
      </Grid>
      <InfoBox
        disabled={
          locationData?.locations?.length > 0 || locationData?.units?.length > 0
        }
        open={dialogOpen}
        title={"Assign to"}
        content={
          <UnitSelection
            closeDialogue={() => setDialogOpen(false)}
            payload={{ planId, companyId }}
            data={locationData}
          />
        }
        handleClose={() => setDialogOpen(false)}
      />
    </>
  );

  const renderReview = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectInput
          // label={t("reviews.changeLanguage")}
          options={defaultlanguageTypes}
          value={languageString}
          disabled={loading}
          handler={(e) => setLanguageString(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <ReviewInput
          disabled={loading}
          questions={questionList}
          languageString={languageString}
        />
      </Grid>
    </Grid>
  );

  const renderSubmissionTable = () => (
    <Grid item xs={12}>
      <DefaultTable
        rows={submissionRows}
        columns={submissionColumnDefaults}
        loading={loading}
        tableInfo={tableInfo}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        minHeight="68vh"
        onRowClick={(r) => handleViewResponse(r.row)}
      />
    </Grid>
  );

  const renderSubmissionModal = () => (
    <InfoBox
      disabled={loading}
      open={submissionModal.open}
      title={submissionModal.title}
      content={
        <div style={{ paddingLeft: "24px" }}>
          <ReviewInput
            disabled={false}
            questions={submissionModal.responses}
            languageString={currentLanguage}
          />
        </div>
      }
      handleClose={() => setSubmissionModal(submissionModalDefaults)}
      minWidth="75vw"
    />
  );

  const renderStats = () => (
    <>
      <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: "16px" }}>
        <TabsBar
          tabs={statsTabsList}
          value={statsTab}
          handler={(e, v) => setStatsTab(v)}
          sx={{ mb: 2 }}
        />
      </Grid>
      <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: "16px" }}>
        <SelectInput
          value={revision}
          handler={(e) => setRevision(e.target.value)}
          options={revisionsDropdown}
          label={t("reviews.viewRevision")}
          disabled={loading || revisionsDropdown.length < 1}
        />
      </Grid>
      {dataDictionary && stats && stats.length > 0 ? (
        statsTab === "singleChart" ? (
          stats.map((i) => (
            <Grid item xs={12} style={widgetStyling}>
              <Stack spacing={1}>
                <Typography variant="h6">{`${i.sequence}. ${
                  i[currentLanguage.includes("en") ? "itemText" : "itemTextAR"]
                }`}</Typography>
                {i.questionType === "TEXTFIELD" ? (
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => getTextResponses(i.id)}
                  >
                    {t("reviews.textResponse")}
                  </p>
                ) : (
                  <BarChart
                    chartOptions={defaultChartOptions}
                    chartData={[
                      {
                        name: t("reviews.responses"),
                        data: dataDictionary[i.id],
                      },
                    ]}
                  />
                )}
              </Stack>
            </Grid>
          ))
        ) : statsTab === "combinedChart" ? (
          combinedStats.map((i) => (
            <Grid item xs={12} style={widgetStyling}>
              <Stack spacing={1}>
                {<BarChart chartOptions={i.options} chartData={i.series} />}
              </Stack>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="body1">{t("reviews.noStats")}</Typography>
          </Grid>
        )
      ) : (
        <Grid item xs={12}>
          <Typography variant="body1">{t("reviews.noStats")}</Typography>
        </Grid>
      )}
    </>
  );

  //  Make this into a table
  const renderResponseModal = () => (
    <InfoBox
      disabled={loading}
      open={responseModal.open}
      title={responseModal.title}
      content={
        <ActivityTable
          rows={responseModal.responses}
          columns={responseColumnDefaults}
          loading={loading}
          tableInfo={{
            totalPages: 1,
            totalCount: responseModal.responses.length,
          }}
        />
      }
      handleClose={() => setResponseModal({ open: false, responses: [] })}
      minWidth="75vw"
      minHeight="90vh"
    />
  );

  const handleSearchUser = (event, value) => {
    clearTimeout(timeout.current);
    if (value) {
      setSearchLoading(true);
      timeout.current = setTimeout(async () => {
        try {
          const result = await searchUsersApi(value, 1, null, false, planId);
          const finalDropdown = [];
          result.forEach((user) => {
            if (user && user.displayName) {
              finalDropdown.push({
                id: user.id,
                label: user.displayName,
              });
            }
          });
          setUserDropdown(finalDropdown);
          setSearchLoading(false);
        } catch (err) {
          console.log(err);
          setSearchLoading(false);
        }
      }, 1000);
    } else {
      setUserDropdown([]);
    }
  };

  const handleViewResponse = async (row) => {
    try {
      setLoading(true);
      const responseData = await getSingleResponseApi({
        planId,
        submissionId: row.dbId,
      });

      const parsedResponses = orderBy(
        responseData[0].items,
        ["sequence"],
        ["asc"]
      ).map((i) => {
        return {
          ...i,
          questionNumber: i.sequence,
        };
      });

      setSubmissionModal({
        open: true,
        title: `Submission by ${row.submitter}`,
        submissionDate: row.createdAt,
        responses: parsedResponses,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getLatestReview = async () => {
    try {
      setLoading(true);
      const reviewData = await getLatestReviewApi();
      const fullURL = window.location.href;
      const generatedLink = `${fullURL.replace(
        pathname,
        ""
      )}/review/view/${planId}`;
      const qList = reviewData
        ? reviewData.items.map((q) => {
            return {
              ...q,
              questionNumber: q.sequence,
            };
          })
        : [];
      setQuestionList(qList);
      setLink(generatedLink);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const submitAssignReview = async (type) => {
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("reviews.assignLoading")
    );
    try {
      const userIds = type === "list" ? emailList.map((e) => e.id) : [];

      const payload = {
        userIds,
        companyId,
        implementationId: planId,
        companyWide: type === "company" ? true : false,
        unitWide: type === "unit" ? true : false,
      };

      await assignReviewApi(payload);
      setEmailList([]);
      displayToast(
        setLoading,
        "success",
        t("reviews.assignSuccess"),
        activeToast
      );
    } catch (err) {
      console.log(err);
      displayToast(setLoading, "error", err, activeToast);
    }
  };

  const getStats = async () => {
    try {
      setLoading(true);
      const finalDictionary = {};
      const populatedCombinedData = [];
      const combinedDataOffset = 5;
      let combinedDataSkip = 0;
      let combinedDataLimit = combinedDataOffset;
      const reviewData = await getStatsApi(planId, revision);
      const chartStats = reviewData[0].question;
      reviewData[0].question.forEach((i) => {
        finalDictionary[i.id] = [
          i.StronglyDisagree,
          i.Disagree,
          i.Neutral,
          i.Agree,
          i.StronglyAgree,
        ];
      });
      const totalStatsQuestions = chartStats.length;
      const totalCombinedStatGroups = parseInt(
        totalStatsQuestions / combinedDataOffset
      );
      let data = [];
      if (totalCombinedStatGroups > 0) {
        for (let i = 0; i < totalCombinedStatGroups; i++) {
          data.push(chartStats.slice(combinedDataSkip, combinedDataLimit));
          combinedDataSkip = combinedDataSkip + combinedDataOffset;
          combinedDataLimit = combinedDataLimit + combinedDataOffset;
        }
        data.push(chartStats.slice(combinedDataSkip, totalStatsQuestions));
      } else {
        data = chartStats;
      }

      data.forEach((questionList) => {
        const series = defaultCombineStatsSeries.map((stat) => ({
          ...stat,
          data: [...stat.data],
        }));
        const options = {
          ...defaultCombineStatsOptions,
          xaxis: {
            ...defaultCombineStatsOptions.xaxis,
            categories: [...defaultCombineStatsOptions.xaxis.categories],
          },
        };
        questionList.forEach((d) => {
          options.xaxis.categories.push(d.itemText);
          series.forEach((stat) => {
            if (stat.name === "Strongly Agree") {
              stat.data.push(d.StronglyAgree);
            }
            if (stat.name === "Strongly Disagree") {
              stat.data.push(d.StronglyDisagree);
            }
            if (stat.name === "Agree") {
              stat.data.push(d.Agree);
            }
            if (stat.name === "Disagree") {
              stat.data.push(d.Disagree);
            }
            if (stat.name === "Neutral") {
              stat.data.push(d.Neutral);
            }
          });
        });
        populatedCombinedData.push({ series: series, options: options });
      });
      setCombinedStats(populatedCombinedData);
      setDataDictionary(finalDictionary);
      setStats(reviewData[0].question);
      setLoading(false);
    } catch (err) {
      setDataDictionary(null);
      setStats([]);
      console.log(err);
      setLoading(false);
    }
  };

  const getTextResponses = async (questionId) => {
    try {
      setLoading(true);
      const reviewData = await getTextResponsesApi(planId, questionId);
      if (reviewData[0]) {
        setResponseModal({
          open: true,
          title: reviewData[0].questionText,
          responses: reviewData[0].response,
        });
      } else {
        displayToast(setLoading, "pureError", t("reviews.noResponses"));
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getSubmissions = async () => {
    try {
      setLoading(true);
      const submissionsData = await getSubmissionsApi({
        planId,
        ...filterParams,
      });
      const parsedData = submissionsData.data.map((s) => {
        return {
          id: s.id.substring(1, 8).toUpperCase(),
          dbId: s.id,
          submitter: s.user
            ? `${s.user.firstName} ${s.user.lastName}`
            : "Anonymous",
          createdAt: moment(s.createdAt).format("DD/MM/YY hh:mm a"),
          revision: s.questionnaire.revision,
        };
      });

      setSubmissionRows(parsedData);
      setTableInfo({
        totalCount: submissionsData.totalItems,
        totalPages: submissionsData.totalPages,
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getRevisions = async () => {
    try {
      setLoading(true);
      const revisionData = await getRevisionsApi();
      const dropdownData = revisionData.map((r) => {
        return {
          label: r.text,
          value: r.revision,
        };
      });
      setRevision(dropdownData[0].value);
      setRevisionsDropdown(dropdownData);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getUnitsData = async () => {
    let tempData = await getLocationsAndUnitsApi(planId);
    if (tempData) setLocationData(tempData);
  };

  useEffect(() => {
    getRevisions();
    getLatestReview();
    getUnitsData();
  }, []);

  useEffect(() => {
    getStats();
  }, [revision]);

  useEffect(() => {
    getSubmissions();
  }, [filterParams]);

  useEffect(() => {
    if (currentLanguage === "ar") {
      setLanguageString("ar");
    } else {
      setLanguageString("en");
    }
  }, [currentLanguage]);

  return (
    <Page
      title={t(
        `implementations.sustainabilityReviews.sustainabilityReviewsTitle`
      )}
    >
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <HeaderBackButton />
            <Typography variant="h4">
              {t(
                `implementations.sustainabilityReviews.sustainabilityReviewsTitle`
              )}
            </Typography>
          </div>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TabsBar
              tabs={tabsList}
              value={tab}
              handler={(e, v) => {
                setTab(v);
              }}
              sx={{ mb: 2 }}
            />
          </Grid>
          {tab === "sendReviews" && renderSendReviews()}
          {tab === "preview" && renderReview()}
          {tab === "statistics" && renderStats()}
          {tab === "submissions" && renderSubmissionTable()}
        </Grid>
        {renderResponseModal()}
        {renderSubmissionModal()}
      </Container>
    </Page>
  );
};
