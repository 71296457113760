import { useEffect, useState, useRef } from "react";
import faker from "faker";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
// material
import {
  Badge,
  Box,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
// components
import Page from "../../components/Page";
import ProgressBar from "../../components/ProgressBar";
import DefaultTable from "../../components/tables/DefaultTable";
import AutocompleteInput from "../../components/inputs/AutocompleteInput";
// api
import {
  getImplementationCompaniesApi,
  getImplementationCompaniesFiltersApi,
} from "../../api/implementation";
import moment from "moment";
import {
  IconButtonStyled,
  IconStyled,
} from "../observation/ListObservations/styledComponents";
import InfoBox from "../../components/InfoBox";
import SelectInput from "../../components/inputs/SelectInput";
import { getImplementationDirectoratesApi } from "../../api/directorate";
import MultiSelectInput from "../../components/inputs/MultiSelectInput";

const filterParamDefaults = {
  implementation: "",
  status: null,
  completionPercentage: 0,
  disciplineId: null,
  progress: null,
  pageSize: 10,
  pageNo: 1,
};

const displayActivitiesStatus = (colors) => {
  return <ProgressBar colors={colors} />;
};

export default function ListImplementation(planId) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [rowsLoading, setRowsLoading] = useState(false);
  const [filterParams, setFilterParams] = useState(filterParamDefaults);
  const [tableInfo, setTableInfo] = useState({ totalPages: 0, totalCount: 0 });
  const [filterModal, setFilterModal] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [directorateDropdown, setDirectorateDropdown] = useState([]);
  const [selectedDirectorate, setSelectedDirectorate] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState(null);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [companiesDropDown, setCompaniesDropDown] = useState([]);
  const [directorateNames, setDirectorateNames] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const timeout = useRef();

  const columns = [
    { field: "id", headerName: "id", sortable: false },
    {
      field: "directorate",
      headerName: t("table.directorate"),
      minWidth: 150,
      sortable: false,
    },
    {
      field: "company",
      headerName: t("table.company"),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("table.status"),
      width: 140,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: t("table.startingDate"),
      width: 150,
      sortable: false,
    },
    // { field: "completionPercentage", headerName: "Completion %", width: 130 },
    // {
    //   field: "progress",
    //   headerName: "Progress",
    //   minWidth: 200,
    //   flex: 1,
    //   renderCell: (param) => displayActivitiesStatus(param.row.statusColors),
    // },
  ];

  const renderListFilters = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MultiSelectInput
            label={t("kpiDashboards.filterDirectorate")}
            disabled={filtersLoading}
            values={selectedDirectorate}
            handler={(e) => handleSearchCompanies(e)}
            options={directorateDropdown}
          />
        </Grid>
        <Grid item xs={6}>
          <MultiSelectInput
            label={t("observations.viewObservations.filters.companyDepartment")}
            disabled={loadingCompanies}
            values={selectedCompanies}
            handler={(e) => setSelectedCompanies(e)}
            options={companiesDropDown}
          />
        </Grid>
      </Grid>
    );
  };

  const handleSearchCompanies = async (e) => {
    setSelectedDirectorate(e);
    setCompaniesDropDown([]);
    setSelectedCompanies(null);
    setLoadingCompanies(true);
    clearTimeout(timeout.current);
    if (e && e.length > 0) {
      timeout.current = setTimeout(async () => {
        try {
          const result = await getImplementationCompaniesFiltersApi(
            e.map((entry) => entry.value)
          );
          setCompaniesDropDown(result?.companies);
          setLoadingCompanies(false);
        } catch (err) {
          setCompaniesDropDown([]);
          setSelectedCompanies(null);
          setLoadingCompanies(false);
        }
      }, 1000);
    } else {
      setCompaniesDropDown([]);
      setSelectedCompanies(null);
      setLoadingCompanies(false);
    }
  };

  const getCompanies = async () => {
    try {
      setRowsLoading(true);
      const companies = await getImplementationCompaniesApi(filterParams);
      const companyRows = companies.data.map((company) => {
        return {
          id: faker.datatype.uuid(),
          directorate: company.directorateName,
          company: company.name,
          status: company.implementationStatus,
          completionPercentage: company.completionPercentage,
          companyId: company.companyId,
          statusColors: company.statusColors,
          createdAt: company.createdAt
            ? moment(company.createdAt).format("DD/MM/YYYY")
            : "",
        };
      });

      setRows(companyRows);
      setTableInfo({
        totalCount: companyRows.length,
        totalPages: companies.totalPages,
      });
      setRowsLoading(false);
      setFilterModal(false);
    } catch (err) {
      console.log(err);
      setRowsLoading(false);
    }
  };

  const getDirectorates = async () => {
    setFiltersLoading(true);
    try {
      const directorateData = await getImplementationDirectoratesApi();
      const directoratelist = directorateData.map((d) => {
        return {
          label: d.name,
          value: d.id,
        };
      });
      setDirectorateDropdown(directoratelist);
      setFiltersLoading(false);
    } catch (err) {
      // console.log(err);
      setFiltersLoading(false);
    }
  };

  const submitFilters = () => {
    const dIds = [];
    const dNames = [];
    const cIds = [];
    const cNames = [];

    if (selectedDirectorate) {
      selectedDirectorate.forEach((d) => {
        dIds.push(d.value);
        dNames.push(d.label);
      });
    }
    if (selectedCompanies) {
      selectedCompanies.forEach((c) => {
        cIds.push(c.value);
        cNames.push(c.label);
      });
    }

    let tempFilters = {
      ...filterParams,
      pageSize: 10,
      pageNo: 1,
      directorateId: dIds,
      companyId: cIds,
    };

    setDirectorateNames(dNames);
    setCompanyNames(cNames);
    setFilterParams(tempFilters);
  };

  useEffect(() => {
    getCompanies();
  }, [filterParams]);

  useEffect(() => {
    getDirectorates();
  }, []);

  return (
    <Page title={t("implementations.view")}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <Typography variant="h4">{t("implementations.view")}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: "20px" }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Tooltip title={t("general.filter")}>
                <IconButtonStyled
                  aria-label={"Open filters"}
                  onClick={() => setFilterModal(true)}
                >
                  <Badge color="primary" variant="dot">
                    <IconStyled />
                  </Badge>
                </IconButtonStyled>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={12}>
            <DefaultTable
              minHeight={"60vh"}
              rows={rows}
              columns={columns}
              hiddenColumns={{
                id: false,
                status: false,
              }}
              loading={rowsLoading}
              tableInfo={tableInfo}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              onRowClick={(p) => {
                navigate(
                  `/implementations/company-implementations/${p.row.companyId}`,
                  {
                    state: {
                      companyName: p.row.company,
                    },
                  }
                );
              }}
              topTextContent={
                <Stack spacing={3} direction="row" sx={{ mb: 2 }}>
                  {(directorateNames.length > 0 || companyNames.length > 0) && (
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.grey,
                        fontWeight: "bold",
                      }}
                    >{`${t("table.filterResults")} "${directorateNames
                      .concat(companyNames)
                      .join(", ")}" `}</Typography>
                  )}
                  <Typography
                    variant="body2"
                    style={{
                      color: theme.palette.primary.orange,
                      fontWeight: "bold",
                    }}
                  >{`${t("table.totalListed")} ${
                    tableInfo.totalCount
                  } `}</Typography>
                </Stack>
              }
            />
          </Grid>
        </Grid>
        <InfoBox
          compact
          disabled={loadingCompanies}
          open={filterModal}
          title={t("observations.viewObservations.filters.title")}
          content={renderListFilters()}
          buttonLabel={t("observations.viewObservations.filters.apply")}
          handleClose={() => setFilterModal(false)}
          handleAction={() => submitFilters()}
          minWidth="1000px"
          overflowV={true}
        />
      </Container>
    </Page>
  );
}
