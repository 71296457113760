import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function ContextMenu({
  anchorEl = null,
  setAnchorEl = null,
  menu = [],
}) {
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {menu.map((item, index) => (
        <MenuItem
          key={`context-option-${item.label || index}`}
          onClick={item.onClick}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
}
