import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

// material
import { Grid, Typography, Dialog, DialogActions } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// components
import { displayToast } from "../../../utils/general";
import TextInput from "./../../../components/inputs/TextInput";

// api
import { getActivityByIdApi, manageActivityApi } from "../../../api/activities";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

const ManageActivity = ({
  isOpen,
  onClose,
  titleJustifyContent = "flex-start",
  actionButton,
  buttonJustifyContent = "flex-end",
  size = "sm",
  transparentBg = false,
  row,
}) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(<Grid></Grid>);
  const [activityDetail, setActivityDetail] = useState({
    minutes: null,
    agenda: null,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 650,
      },
    },
  };

  const handleAttendeesChange = (event) => {
    setActivityDetail({
      ...activityDetail,
      attendeeIds: event.target.value,
    });
  };

  const handleNoShowsChange = (event) => {
    setActivityDetail({
      ...activityDetail,
      noShowIds: event.target.value,
    });
  };

  const setAgendaContent = () => {
    setLoading(true);
    setTitle(`Manage activity`);
    setContent(
      <Grid>
        <Grid>
          <TextInput
            label={t("implementationsList.agenda")}
            placeholder={t("implementationsList.agendaInput")}
            handler={(event) =>
              setActivityDetail({
                ...activityDetail,
                agenda: event.target.value,
              })
            }
            value={activityDetail.agenda || undefined}
          ></TextInput>
        </Grid>
      </Grid>
    );
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const activeToast = displayToast(
      setLoading,
      "loading",
      t("implementations.detailsUpdating")
    );
    try {
      const body = {
        ...activityDetail,
        activityId: row.id,
        activityTypeStub: row.stub,
        planId: state.planId,
        activityTypeId: row.activityTypeId,
      };

      const res = await manageActivityApi(body);

      displayToast(
        setLoading,
        "success",
        t("implementations.detailsUpdated"),
        activeToast
      );
      navigate("/implementations/details", {
        state: { planId: state.planId, companyName: state.companyId },
      });
      setLoading(false);
    } catch (err) {
      if (err === "fileEditError") {
        displayToast(setLoading, "error", t("errors.fileError"), activeToast);
      } else if (err === "backendError") {
        displayToast(
          setLoading,
          "error",
          t("errors.backendError"),
          activeToast
        );
      } else {
        displayToast(setLoading, "error", err, activeToast);
      }
    } finally {
      setLoading(false);
      onClose();
      setActivityDetail({ ...activityDetail, agenda: null });
      setContent(null);
    }
  };

  const createContent = useCallback(async () => {
    setLoading(true);
    const activity = await getActivityByIdApi(row.id);
    if (activity) setActivityDetail({ agenda: activity.agenda });
    switch (row.stub) {
      case "MasterDataSheet":
      case "PreSiteEngagement":
        setAgendaContent();
        break;

      case "TTT":
      case "LeadershipWorkshopCourse":
        break;

      default:
        setAgendaContent();
    }
    setLoading(false);
  }, [row]);

  useEffect(() => {
    createContent();
  }, [createContent]);

  return (
    <Dialog
      PaperProps={
        transparentBg
          ? {
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }
          : {}
      }
      maxWidth={size}
      fullWidth
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="form-dialog-title"
    >
      {title && (
        <DialogTitle
          id="form-dialog-title"
          sx={{ display: "flex", justifyContent: titleJustifyContent }}
        >
          <Grid>
            <Typography variant="h6">{title}</Typography>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent>{content}</DialogContent>
      <Grid sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        {row.stub !== "IdentifyCriticalBehaviors" && (
          <DialogActions>
            <Grid sx={{ display: "flex", gap: 1 }}>
              <PrimaryButton
                variant="outlined"
                color="error"
                label={"Submit"}
                onClick={() => {
                  handleSubmit();
                }}
              ></PrimaryButton>
            </Grid>
          </DialogActions>
        )}
        {actionButton && (
          <DialogActions>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: buttonJustifyContent,
              }}
            >
              {actionButton}
            </Grid>
          </DialogActions>
        )}
      </Grid>
    </Dialog>
  );
};
export default ManageActivity;
