import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import PageLoader from "../PageLoader";
import { returnColourArray } from "../../utils/general";

LineChart.propTypes = {
  height: PropTypes.string,
  data: PropTypes.object,
  name: PropTypes.string,
};

export default function LineChart(props) {
  const { t } = useTranslation();
  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (props.data && props?.demo === false) {
      let tempChartOptions = {
        chart: {
          type: "line",
          // height: 350,
          stacked: false,
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        colors: returnColourArray(),
        stroke: { curve: "smooth" },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: { enabled: false, dropShadow: { enabled: false } },
        xaxis: {
          categories: props?.data?.labels?.map((entry) => {
            if (entry === null) return "Not Defined";
            return entry;
          }),
          labels: {
            trim: true,
            minHeight: 120,
            hideOverlappingLabels: false,
            showDuplicates: true,
          },
        },
      };
      let tempChartData = props?.data?.values?.map((entry) => {
        let tempData = {
          name: entry.name,
          type: "line",
          data: entry?.data.map((value) => {
            if (value === null) return 0;
            return value;
          }),
        };
        return tempData;
      });
      setChartOptions(tempChartOptions);
      setChartData(tempChartData);
    } else {
      let tempChartOptions = {
        chart: {
          type: "line",
          // height: 350,
          stacked: false,
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        stroke: { curve: "smooth" },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: { enabled: false, dropShadow: { enabled: false } },
        xaxis: {
          categories: props?.data?.labels.map((entry) => {
            if (entry === null) return "Not Defined";
            return entry;
          }),
          labels: {
            trim: true,
            minHeight: 120,
            hideOverlappingLabels: false,
            showDuplicates: true,
          },
        },
      };
      let tempChartData = [
        {
          name: "Observations Safe",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
        {
          name: "Observations UnSafe",
          data: [40, 20, 45, 61, 19, 32, 59, 21, 88],
        },
      ];
      setChartOptions(tempChartOptions);
      setChartData(tempChartData);
    }
  }, []);

  useEffect(() => {
    if (props.chartOptions && props.chartData) {
      setChartOptions(props.chartOptions);
      setChartData(props.chartData);
    }
  }, [props.chartData, props.chartOptions]);

  useEffect(() => {
    if (props.data && props?.demo === false) {
      let tempChartOptions = {
        chart: {
          type: "line",
          // height: 350,
          stacked: false,
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        colors: returnColourArray(),
        stroke: { curve: "smooth" },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: { enabled: false, dropShadow: { enabled: false } },
        xaxis: {
          categories: props?.data?.labels?.map((entry) => {
            if (entry === null) return "Not Defined";
            return entry;
          }),
          labels: {
            trim: true,
            minHeight: 120,
            hideOverlappingLabels: false,
            showDuplicates: true,
          },
        },
      };
      let tempChartData = props?.data?.values?.map((entry) => {
        let tempData = {
          name: entry.name,
          type: "line",
          data: entry?.data.map((value) => {
            if (value === null) return 0;
            return value;
          }),
        };
        return tempData;
      });
      setChartOptions(tempChartOptions);
      setChartData(tempChartData);
    }
  }, [props.data]);

  return (
    <>
      {!props?.demo ? (
        <>
          {props?.name && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {props?.name}
            </div>
          )}
          {chartOptions !== null ? (
            <ReactApexChart
              type="line"
              options={chartOptions}
              series={chartData}
              height={props.height || "88%"}
              width={"100%"}
            />
          ) : (
            <PageLoader />
          )}
        </>
      ) : (
        <>
          {chartOptions !== null ? (
            <ReactApexChart
              type="line"
              options={chartOptions}
              series={chartData}
              height={props.height || "88%"}
              width={"100%"}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
