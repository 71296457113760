import apiHelper from "./apiHelper";

export const getLocationsApi = (pageNo = 1, pageSize = 9999) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/locations?pageNo=${pageNo}&pageSize=${pageSize}`)
      .then((res) => {
        resolve(res.data.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocationsForObsApi = (pageNo = 1, pageSize = 9999) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(
        `/api/locations/forObservations?pageNo=${pageNo}&pageSize=${pageSize}`
      )
      .then((res) => {
        resolve(res.data.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};

export const getLocationsForImplementationApi = (companyId, directorateId) => {
  return new Promise((resolve, reject) => {
    apiHelper
      .get(`/api/locations/availableForImplementation?companyId=${companyId}&directorateId=${directorateId}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.errorMessage);
      });
  });
};
