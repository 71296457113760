export const defaultObservationAttr = "observer+observedAt+status+location";

export const observationColumnValueDefaults = [
  {
    label: "Observer",
    value: "observer",
  },
  {
    label: "Observation date",
    value: "observedAt",
  },
  {
    label: "Status",
    value: "status",
  },
  {
    label: "Location",
    value: "location",
  },
];

export const actionColumnDefaults = [
  { field: "generatedActionId", headerName: "ID", width: 200 },
  { field: "status", headerName: "Status", width: 200 },
  { field: "actionRequired", headerName: "Action required", width: 200 },
  { field: "observer", headerName: "Observer", width: 200 },
  {
    field: "targetDate",
    headerName: "Target date to complete action",
    width: 400,
  },
];

export const tasksColumnDefaults = [
  { field: "id", headerName: "ID", width: 200 },
  { field: "title", headerName: "Name", width: 200 },
  { field: "type", headerName: "Type", width: 300 },
  { field: "description", headerName: "Description", width: 400 },
  {
    field: "taskDate",
    headerName: "Task date",
    width: 200,
  },
];

export const filterParamDefaults = {
  pageSize: 10,
  pageNo: 1,
  searchTerm: "",
  sort: null,
  myObservations: {
    type: [],
    startTargetDate: null,
    endTargetDate: null,
    observationType: "ALL",
    attr: defaultObservationAttr,
    columnValues: [],
  },
  allObservations: {
    type: [],
    startTargetDate: null,
    endTargetDate: null,
    observationType: "ALL",
    attr: defaultObservationAttr,
    columnValues: [],
  },
  myActions: {
    type: [],
    startTargetDate: null,
    endTargetDate: null,
    observationType: "ALL",
    attr: defaultObservationAttr,
    columnValues: [],
  },
  myTasks: {
    type: [],
    startTargetDate: null,
    endTargetDate: null,
    observationType: "ALL",
    attr: defaultObservationAttr,
    columnValues: [],
  },
};

const today = new Date();
export const filterModalDefaults = {
  open: false,
  tab: "myObservations",
  myObservations: {
    type: [],
    filterDates: false,
    startTargetDate: today,
    endTargetDate: today,
    observationType: "ALL",
    attr: defaultObservationAttr,
    columnValues: [],
    locationId: "",
    directorateId: "",
    unitId: "",
    companyId: "",
  },
  allObservations: {
    type: [],
    filterDates: false,
    startTargetDate: today,
    endTargetDate: today,
    observationType: "ALL",
    attr: defaultObservationAttr,
    columnValues: [],
    locationId: "",
    directorateId: "",
    unitId: "",
    companyId: "",
  },
  myActions: {
    type: [],
    filterDates: false,
    startTargetDate: today,
    endTargetDate: today,
    observationType: "ALL",
    attr: "",
    columnValues: [],
    locationId: "",
    directorateId: "",
    unitId: "",
    companyId: "",
  },
  myTasks: {
    type: [],
    filterDates: false,
    startTargetDate: today,
    endTargetDate: today,
    observationType: "ALL",
    attr: "",
    columnValues: [],
    locationId: "",
    directorateId: "",
    unitId: "",
    companyId: "",
  },
};

export const actionFilterTypesDefault = [
  { label: "REJECTED", value: "REJECTED" },
  { label: "RETURNED", value: "RETURNED" },
  { label: "RE-ASSIGNED", value: "RE-ASSIGNED" },
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "PENDING", value: "PENDING" },
  { label: "APPROVED", value: "APPROVED" },
  { label: "ALL", value: "ALL" },
];

export const defaultFiltersPayload = {
  myObservationsFilterType: "ALL",
  allObservationsFilterType: "ALL",
  myActionsFilterType: "ALL",
  myObservationsFilterObsType: "ALL",
  allObservationsFilterObsType: "ALL",
  myActionsFilterObsType: "ALL",
  myObservationsFilterDateFlag: false,
  allObservationsFilterDateFlag: false,
  myActionsFilterDateFlag: false,
  myObservationsFilterDateStart: today,
  allObservationsFilterDateStart: today,
  myActionsFilterDateStart: today,
  myObservationsFilterDateEnd: today,
  allObservationsFilterDateEnd: today,
  myActionsFilterDateEnd: today,
  myObservationsAttr: defaultObservationAttr,
  allObservationsAttr: defaultObservationAttr,
  myActionsAttr: defaultObservationAttr,
  myObservationsFilterLocation: "",
  myObservationsFilterDirectorate: "",
  myObservationsFilterUnit: "",
  myObservationsFilterCompany: "",
  allObservationsFilterLocation: "",
  allObservationsFilterDirectorate: "",
  allObservationsFilterUnit: "",
  allObservationsFilterCompany: "",
  myActionsFilterLocation: "",
  myActionsFilterDirectorate: "",
  myActionsFilterUnit: "",
  myActionsFilterCompany: "",
};
