import { useState } from 'react';

export default function useChartData(initialValue) {
   const [data, updateData] = useState(initialValue);

   let current = data;

   const get = () => current;

   const set = newValue => {
      current = newValue;
      updateData(newValue);
      return current;
   }

   return {
      get,
      set,
   }
}